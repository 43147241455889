import React, { Component, Fragment } from 'react';
import '../../../scss/nav_perfil.scss'

import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';

import { auth } from '../../../firebaseConfig';
import { useApi } from '../../../context/apiContext';
import { useCart } from '../../../context/cartContext';

export const NavComponent = () => {

  const { getTextByKey } = useApi();
  const { clearCart } = useCart();

    //Logout Event
    const handleLogout = async () => {
      try {
        await auth.signOut();
        location.href = "/";
        clearCart();
      } catch {
      }
    };

    const scrollTop = () => {
      window.scroll(0, 0);
    }

  return (
    <Fragment>
      <ul className='nav-perfil'>
        <li><NavLink onClick={() => scrollTop()} className="py-lg-3 py-1 mb-3" to="/profile-details"><Icon icon="ant-design:user-outlined" className='me-3 icon' /><span className="gray-1-color text-uppercase">{getTextByKey('detalles-cuenta')}</span></NavLink></li>
        <li><NavLink onClick={() => scrollTop()} className="py-lg-3 py-1 mb-3" to="/profile-address"><Icon icon="bi:send" className='me-3 icon' /><span className="gray-1-color text-uppercase">{getTextByKey('direccion-envio-titulo')}</span></NavLink></li>
        <li><NavLink onClick={() => scrollTop()} className="py-lg-3 py-1 mb-3" to="/profile-orders"><Icon icon="cil:history" className='me-3 icon' /><span className="gray-1-color text-uppercase">{getTextByKey('historial-pedidos')}</span></NavLink></li>
        {/* <li><NavLink onClick={() => scrollTop()} className="py-3 mb-3" to="/profile-methods"><Icon icon="bi:credit-card" className='me-3 icon' /><span>MÉTODOS DE PAGO</span></NavLink></li> */}
        <li><NavLink className="py-lg-3 py-1 mb-3" to="/" onClick={handleLogout}><Icon icon="bx:bx-log-out" className='me-3 icon' /><span className="gray-1-color text-uppercase">{getTextByKey('cerrar-sesion')}</span></NavLink></li>
      </ul>
    </Fragment>
  )
}



// export class NavComponent extends Component {

//   render() {
    
    

//     return (
      
//     )
//   }
// }