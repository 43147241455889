import React, { Fragment, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom';
import { ProductComponent } from '../../components/productComponent'
import CategoriasSliderComponent from './components/CategoriasSliderComponent'
import FiltrosComponent from './components/FiltrosComponent'
import MasvendidosSliderComponent from './components/MasvendidosSliderComponent'
import { getProducts,getRelacionadosProducto } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useCart } from '../../context/cartContext';
import { Icon } from '@iconify/react'
import { Migas } from '../../components/Migas';
import { useState } from 'react';


export default function ShopPage() {

  const { getTextByKey, currentCategories, currentSubcategories, currentSizes, currentColors, currentLines, currentActivities } = useApi();
  const {currentDelivery, tiendaGeneral } = useCart();
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isFilter, setIsFilter] = React.useState(false);
  
  const [show, setShow] = React.useState(false);
  const [productsToShow, setProductsToShow]=  React.useState([]);
  const [catsel, setcatSel] = React.useState('');
  const [subcatsel, setsubcatSel] = React.useState('');
  const [cambio, setcambio] = React.useState(false);
  const params = useParams();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paths = [{to: '/shop', name: 'Tienda'}];
  const OBJECTS_TO_SHOW = 16;

  const [contador, setContador] = React.useState(1);

  const getNameFilter = (key, id) => {
    let name = "";
    switch (key) {
      case "categoria":
        name = currentCategories.find(x => x.id == id)
        if (name) name = name.names[0].nombre;
        break;
      case "subcategorias":
        name = currentSubcategories.find(x => x.id == id)
        if (name) name = name.names[0].nombre;
        break;
      default:
        break;
    }
    return name;
  }

  const generateQueryLink = (key, id) => {
    let query = "";
    switch (key) {
      case "categoria":
        query = `/${id}/${params.subcategorias | 0}/normal`;
        break; 
      case "subcategorias":
        query = `/${params.categoria | 0}/${id}/normal`;
        break;
      case "all":
        query = `./${0}/${0}/${0}/normal`;
        break;
      default:
        break;
    }
    return query;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    /*if (Object.keys(params).length > 0) {
      if (params.categoria == 0 && params.subcategorias == 0 ) {
        setIsFilter(false);
      } else {
        setIsFilter(true);
      }
    } else {
      setIsFilter(false);
    }*/
   
   const relacion= queryParams.get("relacion",'');
   if(relacion){
    getRelacionadosProducto(relacion,tiendaGeneral).then((data) => {
       setTimeout(() => {
        setLoading(false);
      }, 500);
      if (data) {
        console.log(data);
        const prods=data.filter(x => x.cantidad >3);
           setProducts(prods);
           if(prods?.length>OBJECTS_TO_SHOW){
             setShow(true);
             setProductsToShow(prods.slice(0, OBJECTS_TO_SHOW))
           }else{
             setProductsToShow(prods);
           }
        
      } else {
        setProducts([]);
      }
    });
   }else{

    let cat='';
    let subcat='';
    if(currentCategories.length>0 &&  currentSubcategories.length>0 && params){
      if(params.categoria && params.categoria!='categoria'){
        cat=currentCategories.find((categoria)=>categoria.slug==params.categoria);
          if(cat){
          cat=cat.id;
          setcatSel(cat);
        }
      }
    
      if(params.subcategorias && params.subcategorias!='subcategoria'){
        subcat=currentSubcategories.find((subcategoria)=>subcategoria.slug==params.subcategorias);
        if(subcat){
          subcat=subcat.id;
          setsubcatSel(subcat);
        }
        
      }
      setcambio(!cambio);
     console.log(cat,subcat,'acaaaaa',tiendaGeneral);
     fecthdata(cat,subcat,tiendaGeneral);
    }
  }
   
   
  }, [params.categoria, params.subcategorias, params.order, tiendaGeneral,currentCategories,currentSubcategories]);


  const fecthdata=async(cat,sub,tienda)=>{
     
    const data= await getProducts(cat, sub, tienda);
    setLoading(false);

        if (data) {
          if(location.pathname == "/sales") {
            data.filter(x => x.nuevo == true);
          } 
         
          if(location.pathname == "/offers") {
            data.filter(x => x.price.precio_sin_descuento != null);
          }
          
          if (params.order == "price_asc") {
            data.sort((a, b) => a.price.precio - b.price.precio);
          } else if (params.order == "price_desc") {
            data.sort((a, b) => b.price.precio - a.price.precio);
          }

 
        if(params.order== "aleatorio"){
          data.sort(() => Math.random() - 0.5);
        } 
        
          setProducts(data);
          if(data?.length>OBJECTS_TO_SHOW){
            setShow(true);
            setProductsToShow(data.slice(0, OBJECTS_TO_SHOW))
          }else{
            setProductsToShow(data);
          }
        } else {
          setProducts([]);
      }
    
  
 }




  const toggleShow = (componentName, contar) => {
   
    const inicio=OBJECTS_TO_SHOW*contar;
    let final=products?.length;
     if(final>(inicio+OBJECTS_TO_SHOW)){
      final=inicio+OBJECTS_TO_SHOW;
      setShow(true);
     }else{
      setShow(false);
     }
    
    console.log(products,contar,'products',products.slice(0,final));
    setProductsToShow(products.slice(0,final));
    setContador(contar+1);
  }



  return (
    <Fragment>

      <div className="container">
        <div className="row pt-lg-5 pt-3">
          <div className="col-xl-3 col-12">
            <div className="container">
          
              <Migas paths={paths}/>
              <div className='py-lg-3 py-0'>
                <FiltrosComponent />
              </div>
              
            </div>
          </div>

        <div className="col-xl-9">
        <div className='py-2 py-lg-5 mt-3'>
            <div className='container-fluid productos gray-1-color'>
              <div className='row'>
                {!loading ?
                  <>
                    {productsToShow.length > 0 ?(
                    <>
                      {productsToShow.map((product, index) => <ProductComponent key={index} product={product} className="col-6 col-md-4 col-lg-3 px-0" />)}
                        {
                          products.length > OBJECTS_TO_SHOW && (
                              <div className="text-center mt-3 mt-xl-5">
                                   { show ?
                                  <button type="button" className="btn-g primary-bg-color py-1 px-5" onClick={() => toggleShow('products',contador)}>
                                       {getTextByKey("ver-mas")}
                                  </button>
                                 :<></>  }
                              </div>
                          )
                          }
                      </>)
                      : (
                        <div className="col-12 text-center-c">
                          <br /><br />
                          {getTextByKey("no_products")}
                          <br /><br />
                          <hr />
                        </div>
                      )
                    }
                  </>
                  : (
                    <div className='container'>
                      <div className="text-center-c">
                        <div className="loader-box">
                          <img src="/img/SVG/loader.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            </div>
           {/* <div className="text-center only-mobile py-4">
                <button className='btn-g px-5 py-2'>Ver más</button>
                </div>*/}
        </div>
          
        </div>
      </div>
    </Fragment>
  )
}
