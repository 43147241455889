import { Fragment } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import PedidoComponent from "./components/PedidoComponent";
import { useEffect } from "react";
import { listOrdersUser } from "../../api/ordersApi";
import { useAuth } from "../../authContext";
import { useApi } from "../../context/apiContext";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function PedidosPage() {


    const { currentUser } = useAuth();
    const { currentLocale, getTextByKey } = useApi();
    const [listOrders, setlistOrders] = useState([])

    useEffect(() => {
        async function getListOrderUsers() {
            try {
                console.log(currentUser)
                const listOrderss = await listOrdersUser(currentUser.uid, currentLocale);
                if (listOrderss) {
                    setlistOrders(listOrderss)
                    console.log("Productos comprados: ", listOrders);
                }
            } catch (error) {
                console.log('Error: ', error)
            }
        }

        getListOrderUsers();
    }, [])
    

  return (
    <Fragment>
        {/* <div className="quaternary-bg-color py-4 head-perfil">
            <div className="container">
                <div className="text-center">
                    <h1 className="text-uppercase">Cuenta</h1>
                    <NavsupComponent/>
                </div>
            </div>
        </div> */}
       <div className="perfil my-5">
            <div className="container-fluid">
                 <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-5 px-xl-5">
                            <h1 className="primary-color carrefourMetis-bold mb-lg-5 mb-3 text-center text-lg-start">{getTextByKey('perfil')}</h1>
                        </div>
                    </div>
                <div className="row">
                    <div className="col-12 col-lg-5 px-xl-0">
                        <NavComponent/>
                    </div>
                    <div className="col-12 col-lg-6 px-xl-5">
                    <h3 className="gray-1-color source-sans-bold mb-4 text-center text-lg-start">{getTextByKey('mi_historial')}</h3>
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-11 offset-lg-0 col-xl-9 offset-xl-0'>
                                { listOrders.map( order => <PedidoComponent key={order.id} dataOrder={order} nameUser={currentUser.displayName} /> ) }
                                {listOrders.length === 0 &&
                                    <div className="text-center text-lg-start">
                                        <p>{getTextByKey('no_hay_pedidos')}</p>
                                        <div className="mt-4">
                                            <Link to="/shop" className="btn-g py-2 px-3 px-xl-4">{getTextByKey('ir-a-tienda')}</Link>
                                        </div>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
        {/* <div className="container-fluid px-4 px-lg-0 py-5">
            <div className="d-lg-flex">
                <div className="col-nav mb-4 mb-lg-0">
                    <NavComponent/>
                </div>
                <div className="col-content-perfil">
                    <h2 className="text-uppercase">Pedidos</h2>
                    <div className="py-2 py-md-3 py-xl-4"> 
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-11 offset-lg-0 col-xl-9 offset-xl-0'>
                                { listOrders.map( order => <PedidoComponent key={order.id} dataOrder={order} nameUser={currentUser.displayName} /> ) }
                                {listOrders.length === 0 && <p className='text-center'>No hay pedidos</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <ModaleliminarcuentaComponent/>
    </Fragment>
  )
}
