import { Fragment, useState, useEffect } from "react";
import { ProductComponent } from "../../components/productComponent";
import { Link, useParams } from "react-router-dom";
import Picture from "../../components/Picture";
import { useApi } from "../../context/apiContext";
import { Migas } from  "../../components/Migas"
import { Icon } from '@iconify/react';
import {FacebookShareButton, TwitterShareButton} from "react-share";
import '../../scss/recipes_page.scss';
import "../../scss/footer.scss";
import { RatingStar } from "../../components/RatingStar";
import { getRecipe, getRecipeCategories } from "../../api/recipesApi";
import { useCart } from "../../context/cartContext";
import { createDatetime, getMonthName } from "../../helpers/dateHelper";
import ReviewComponent from "../../components/reviewComponent";
import { useAuth } from "../../authContext";

const hiddenStyle = {overflow: 'hidden', maxHeight: '200px'};

export default function RecipePage() {

    const { getTextByKey, currentLocale, setModalCompra, setModalInicio } = useApi();
    const [recipe, setRecipe] = useState(false);
    const [categories, setCategories] = useState(false);
    const [datetime, setDatetime] = useState(false);
    const { addSeveralToCart, showCart, checkIfExistInCart, tiendaGeneral ,addToReceta,addToCart,
        addToCartOld, addToRecetaOld, currentDelivery, setCurrentProduct, currentProduct,
        currentCart,cartIsShowing,hideCart, setCurrentAddCart } = useCart();
    const [showInfo, setShowInfo] = useState(false);
    const params = useParams();
		const [ quantity, setQuantity ] = useState(1);
    const { currentUser } = useAuth();

    
    useEffect(() => {
        const serverData = [];

        serverData.push(getRecipe(params.id, currentLocale,tiendaGeneral))
        serverData.push( getRecipeCategories(currentLocale))

        Promise.all(serverData).then(data => {
            const recipe = data[0];
            setRecipe(recipe);
            setDatetime(createDatetime(recipe.created_at.date));
            
            const recipeCategories = data[1];
            getCategories(recipe, recipeCategories);
        })
    }, [params.id,tiendaGeneral]);

    const getCategories = (recipe_, recipeCategories) => {
        const categoriesIds = recipe_.categories.map(cat => cat.id);
        const recipeFilteredCategories = recipeCategories.filter(cat => categoriesIds.includes(cat.id));
        setCategories(recipeFilteredCategories);
    }

    const getBreadcrumps = () => {
        const paths = [
            {to: '/recetas', name: 'Recetas'}
        ];
        if (recipe) {
            paths.push({to: '', name: recipe.title})
        }
        return paths;
    };

    const showToggle = () => {
        setShowInfo(!showInfo);
    }
     
    const addCart = async(products) => {
                    
         if(currentUser){
            if(!tiendaGeneral){
                for (const product of products) {
                    product.type = "product";
                    product.gramaje = 0;
                    product.id = product.plu;
                    const cantidad = 1;
                    await addToRecetaOld(product, cantidad);
                    console.log(product);
                  }
                setModalCompra(true);
            }
        }else{
            for (const product of products) {
                product.type = "product";
                product.gramaje = 0;
                product.id = product.plu;
                const cantidad = 1;
                await addToRecetaOld(product,cantidad);
                console.log(product);
              }
              setModalInicio(true);
        
        }
        if(tiendaGeneral){
            
            for (const product of products) {
                product.type = "product";
                product.gramaje = 0;
                product.id = product.plu;
                const cantidad = 1;
                await addToReceta(product,cantidad);
                console.log('producto',product);
                
                }

                if (currentCart.length == 0) {
                await showCart();
                } else {
                await showCart();
                await hideCart();
                }

                /* alert("Producto agregado al carrito"); */
        }
                 
    }
  

    if(!recipe)
        return (
        <div className='container'>
            <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
            </div>
        </div>
        );

    return (
        <Fragment>
            <div className="recipe-page">
                <div className="banner-recipe my-5">
                    <div className="container-fluid gx-0">
                        <div className="container">
                            <Migas paths={getBreadcrumps()}/>
                        </div>
                        <div className="row gx-0">
                            <div className="col-12 col-lg-5">
                                <div className="container mb-4 mb-lg-0">
                                    <h1 className="primary-color carrefourMetis-bold">{recipe.title}</h1>
                                    {
                                        datetime && (<p className="mb-0 mb-lg-1"><time>{getTextByKey(datetime.month)} {datetime.day}, {datetime.year}</time></p>)
                                    }
                                    <p className="mb-0 md-p">{getTextByKey("encuentralo-tambien")}  <br />
                                        {
                                            categories.map(category => (
                                                <Link key={category.id} to={`/recetas/${category?.slug}`} className="quaternary-color me-2"><span className="text-underline">{category.name}</span></Link>
                                            ))
                                        }
                                    </p>
                                    <ul className="p-0 d-lg-flex align-items-center mt-4 list-style-none social-media-sharing">
                                        <li className="me-3">
                                            <FacebookShareButton 
                                                url={window.location.href}
                                                >
                                                <img src="/img/SVG/facebook-blue.svg" alt="Facebook icon" className="icon" />
                                            </FacebookShareButton>
                                        </li>
                                        <li className="me-3">
                                            <TwitterShareButton 
                                                url={window.location.href}
                                                >
                                                <img src="/img/SVG/twitter-blue.svg" alt="Twitter icon" className="icon" />
                                            </TwitterShareButton>
                                        </li>
                                    </ul>
																
                                </div>
                            </div>
                            <div className="col-12 col-lg-7">
                                <div className="img-receta position-relative">
                                    {recipe.products?.length>0 &&<div onClick={()=>addCart(recipe.products)} className="add-car-icon cursor-pointer">
                                        <Icon className='icon' icon="carbon:shopping-cart"/>
                                    </div> }
                                    <Picture src={recipe.images[0].image} alt={"Recipe"} classImg={"w-100 img-principal"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ingredients-preparation-recipe">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3 mb-4">
                                <div>
                                    <h3 className="primary-color carrefour-sans-bold">{getTextByKey('ingredientes')}:</h3>
                                    <div className={`text-recipes ${ !showInfo ? 'show' : '' }`}>
                                        <div className='gray-1-color py-2 text' dangerouslySetInnerHTML={{__html: recipe.ingredients}}></div>
                                        <div className="ms-4 my-2 my-lg-4 d-block d-lg-none  text-center text-lg-start">
                                            <button onClick={showToggle} type="button" className="btn-g bb py-1 px-4">
                                                { showInfo ? getTextByKey('ver-mas') : getTextByKey('ver-menos')}
                                            </button>
                                        </div>

                                    </div> 
                                </div>
                            </div>
                            <div className="col-12 col-md-7 mb-4 ms-md-5 ps-md-5">
                                <div>
                                    <h3 className="primary-color carrefour-sans-bold">{getTextByKey('preparacion')}:</h3>
                                    <div className={`text-recipes ${ !showInfo ? 'show' : '' }`}>
                                        <div className='gray-1-color py-2 text' dangerouslySetInnerHTML={{__html: recipe.preparation}}></div>
                                        <div className="ms-4 my-2 my-lg-4 text-center text-lg-start">
                                            <button onClick={showToggle} type="button" className="btn-g bb py-1 px-4">
                                                { showInfo ? getTextByKey('ver-mas') : getTextByKey('ver-menos')}
                                            </button>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="video-recipe">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-12">
                              <iframe className="video" src={`https://www.youtube.com/embed/${recipe.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            <ReviewComponent objectType='recipe' objectReviewId={recipe.id} />
        </div>
        </Fragment>
    );
}
