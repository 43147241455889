import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';




export default function ModalProcederPago({ showModal, setShowModal }) {

    const { getTextByKey, getTextBigByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModal(false);
        gotoTop();
    }
    
    const pagoTarjeta = true;

    return (
        <Fragment>
            <Modal className='sm-modal' show={showModal} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="close-icon pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color source-sans-bold mb-0 mt-2'>{getTextByKey('modal-alerta-titulo')}</p>
           
                        <div className="gray-1-color py-2" dangerouslySetInnerHTML={{ __html: getTextBigByKey("aviso-proceder-pago") }}></div>
                      </div> 
                      <div className="text-center">
                          <button className='btn-g bb px-4 mx-2 py-1' onClick={handleClose}>{getTextByKey('cancelar')}</button>
                          <Link to="/checkout-c" className='btn-g px-2 mx-2 py-1' onClick={handleClose}>{getTextByKey('proceder-pago')}</Link>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}