import { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { ContactComponent } from "../ContactoPage/components/ContactoComponent";
import { useApi } from "./../../context/apiContext";
import { getGallery } from "../../api/galleryApi";
import { Collapse } from './Collapse';
import { Link } from "react-router-dom";

export default function PreguntasPage() {
  const [galleryFaq, setgalleryFaq] = useState([]);
  const [elementsFAQState, setelementsFAQState] = useState([]);
  const { getTextByKey, getTextBigByKey, getTexts, currentLocale } = useApi();

  useEffect(() => {
    getGallery("faq_gallery").then((data) => {
      setgalleryFaq(data);
    });
  }, []);

  return (
    <Fragment>
      <div className="data-customer-service-fixed p-2 mt-4">
        <h3 className="primary-color fw-bold mb-3">Servicio de atención al cliente</h3>
        <ul className="p-0 list-style-none gray-1-color">
          <li className="mb-2">
            <Link className="d-flex align-items-center gray-1-color">
              <img src="/img/SVG/whatsapp-pink.svg" alt="Whtasapp icono" className="me-2" />
              <p className="mb-0">{getTextByKey('numero')}</p>
            </Link>
          </li>
          <li className="mb-2">
            <Link className="d-flex align-items-center gray-1-color">
              <img src="/img/SVG/phone-pink.svg" alt="Phone icono" className="me-2" />
              <p className="mb-0">xxx xxx xxxx</p>
            </Link>
          </li>
          <li className="mb-2">
            <Link className="d-flex align-items-center gray-1-color">
              <img src="/img/SVG/email-pink.svg" alt="Emial icono" className="me-2" />
              <p className="mb-0">mail@email.com</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="pqrs my-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-xl-7">
              <h1 className="primary-color text-center fw-bold mb-5">Formulario de PQRS</h1>
              <p className="text-center">Para nosotros es muy importante conocer su opinión, registra la información de los siguientes campos y pronto nos pondremos en contacto contigo.</p>
              <div className="mt-4">
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="peticion" />
                    <label htmlFor="peticion" className="ms-2">Petición</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="queja" />
                    <label htmlFor="queja" className="ms-2">Queja</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="reclamo" />
                    <label htmlFor="reclamo" className="ms-2">Reclamo</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="sugerencia" />
                    <label htmlFor="sugerencia" className="ms-2">Sugerencia</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="reconocimiento" />
                    <label htmlFor="reconocimiento" className="ms-2">Reconocimiento</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="devoluciones" />
                    <label htmlFor="devoluciones" className="ms-2">Devoluciones</label>
                  </div>
                  <div className="mx-2">
                    <input type="radio" name="tipo-pqr" id="otro" />
                    <label htmlFor="otro" className="ms-2">Otro</label>
                  </div>
                </div>
              </div>
              <div className="text-center primary-border p-3 mt-4">
                <p className="mb-0">
                  Es la minifestación de protesta, censura, descontento o inconformidad que formula una persona en relación con una conducta que considera irregular de uno o varios servidores públicos en desarrollo de sus funciones.
                </p>
              </div>
            </div>
          </div>
          <ContactComponent />
        </div>
      </div>

      {/* <div className='container mb-5'>
        <div className='text-center py-5'>
          <h2 className='text-uppercase primary-color'>{getTextByKey("frequent-questions")} </h2>
        </div> */}

        {/* Galeria de collapse */}
        {/* {galleryFaq.map((gallery, index) =>
          gallery.names.map((element, key) => {
            if (element.locale === currentLocale) {
              return <Collapse key={key} index={index} ask={element} />;
            }
          })
        )}
      </div> */}
    </Fragment>
  );
}
