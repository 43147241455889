import { Fragment } from "react";
import { useApi } from "../../context/apiContext";
import { getGallery } from "../../api/galleryApi";
import { images_products_route } from "../../api/productApi";

import React, { useState, useEffect } from 'react'
import MasvendidosSliderComponent from "../ShopPage/components/MasvendidosSliderComponent";

export default function ModaconscientePage() {

  const [publication, setPublication] = useState([]);
  
  useEffect(() => {
    getGallery("moda-consciente-contenido").then((data) => {
      setPublication(data);
    });
  }, []);

  const { getPropiertyByLocale } = useApi();

  return (
    <Fragment>
      <div className="banner position-relative mb-2 mb-md-5">
        {publication.length > 0 && <img className="w-100" src={images_products_route + publication[0].image} alt={"SHIDO"} />}
        <div className="content-banner position-absolute">
          <h2 className="secundary-color titulo-big mb-0 text-center text-uppercase">
            {publication.length > 1 && getPropiertyByLocale(publication[0].names, "titulo")}
          </h2>
        </div>
      </div>
      <div className="container moda-consciente">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center px-5">
              {/* <p>{}</p> */}
              <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[0].names, "descripcion") }}></div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[1].image} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[1].names, "titulo") }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[1].names, "descripcion") }}></div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[2].names, "titulo") }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[2].names, "descripcion") }}></div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[2].image} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[3].image} alt={"SHIDO"} />}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[3].names, "titulo") }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[3].names, "descripcion") }}></div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div className="p-4">
              <h3 className="quaternary-color mb-4 text-uppercase" >
                <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[4].names, "titulo") }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[4].names, "descripcion") }}></div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {publication.length > 0 && <img className="w-100 my-4" src={images_products_route + publication[4].image} alt={"SHIDO"} />}
          </div>
        </div>
        {/* <div className="row">
            <div className="col-12 py-lg-5">
              <MasvendidosSliderComponent/>
            </div>
          </div> */}
      </div>

      <div className="banner position-relative">
        {publication.length > 0 && <img className="w-100" src={images_products_route + publication[5].image} alt={"SHIDO"} />}
        <div className="content-banner position-absolute">
          <h4 className="secundary-color titulo-big mb-0 text-center text-uppercase w-75 mx-auto">
            <div dangerouslySetInnerHTML={{ __html: publication.length > 1 && getPropiertyByLocale(publication[5].names, "titulo") }}></div>
          </h4>
        </div>
      </div>
    </Fragment>
  )
}



