import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useApi } from '../../context/apiContext';

import { useAuth } from '../../authContext';
// import ModalLogUserComponent from "../../components/ModalLogUserComponent";
import { ModalSatisfactoryResponseSurveyComponent } from "./components/ModalSatisfactoryResponseSurveyComponent";
import { RatingStar } from '../../components/RatingStar';
import { useSearchParams  } from 'react-router-dom';


export default function SatisfactionSurveyPage() {
    const [searchParams] = useSearchParams();
    const { getTextByKey, getTextBigByKey,getSurveyQuestions,createSurvey } = useApi();
    const [ rating, setRating ] = useState(0);
    const [loading, setloading] = useState(false);
    const [showOk, setShowOk] = useState(false);
    const [ questions, setQuestions ] = useState([]);
    const uid = searchParams.get("uid");
    const compraid  = searchParams.get("compraid");
    
    useEffect(()=>{
        setloading(true);
        getSurveyQuestions().then((data)=>{
            setQuestions(data);
            setloading(false);
        }).catch((error)=>{
            
        })
    },[])

    const onSendSurvey = async (event) => {
        event.preventDefault();
        
        setloading(true);
        
        let formData = {
            uid: uid,
            compra: compraid,
            data: questions
        }
        console.log("questions",formData);
        createSurvey(formData).then(() => {
            setloading(false);
            setShowOk(true);
        }).catch((e)=>{
            setloading(false);
        })
    }

    const updateReponse = (rating,question) => {
        question.respuesta = rating;
    }
    const setRatingran = (num) => {
        setRating(rating+1);
       
    }
    

    return (
        <Fragment>
            <div className="satisfaction-survey-page bg-options-repeat py-5" style={{ backgroundImage: `url(/img/bg-satisfaction-survey.jpg)` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            <form className="white-bg-color gray-1-color py-3 px-5 rounded" action="" onSubmit={onSendSurvey} >
                                <div className="text-center">
                                    <h2 className="primary-color text-center carrefourMetis-bold mb-4">{getTextByKey('titulo-encuesta-satisfaccion')}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: getTextBigByKey("texto-encuesta-satisfaccion") }}></div>
                                </div>
                                <div className="mb-4">
                                    {questions.map((q, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <span className="primary-color fw-bold me-2">{index+1}</span>
                                        <div>
                                            <p className="mb-0">{q.pregunta}</p>
                                            <RatingStar key={index} onChange={(rating) => updateReponse(rating,q)} setRating={setRatingran}  rating={q.respuesta}/>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <div className="text-center mt-4 mt-xl-5">
                                    <a href="/" className="btn-g py-1 px-4 mb-3 mb-md-0 me-md-4 col-12 col-md-auto">
                                        Volver al inicio
                                    </a>
                                    <button  className="btn-g py-1 px-4 col-12 col-md-auto">
                                    {
                                    (loading ? (<div className='spinner-border spinner-border-sm' role='status'></div>) : 'Enviar encuesta' )
                                    }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSatisfactoryResponseSurveyComponent show={showOk} setShow={setShowOk}  />
        </Fragment>
    );
}
