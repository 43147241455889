import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useAuth } from "../../authContext";
import { useApi } from '../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import NuevaDireccionForm from '../NuevaDireccionForm';



export default function ModalDireccionEnvio({ modalNuevaDireccion, setModalNuevaDireccion ,setTiendaId,confirmModal,setShowModalDireccion }) {

    const { getTextByKey } = useApi();
    const { currentUser } = useAuth();
    const [ loadDir,  setLoadDir ] = useState(false);
    const [ succesfull, setSuccesfull] = useState('');

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const confirmar =async()=>{
      setLoadDir(true);
      let confirma= await confirmModal();
      setModalNuevaDireccion(false);
      setSuccesfull('');
    }
    const handleClose = () => {
        setModalNuevaDireccion(false);
        gotoTop();
    }

    const volverModal = () => {
    
      setModalNuevaDireccion(false);
      if(currentUser?.isAnonymous){
        
      }else{
          setShowModalDireccion(true);
      }
     
      setSuccesfull('');
    }



    return (
        <Fragment>
            <Modal className="modal-compra" show={modalNuevaDireccion } onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('direccion-envio-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('selecciona-modal-direccion')}</p>
                      </div> 
                     <NuevaDireccionForm succesfull={succesfull} setTiendaId={setTiendaId} setModalNuevaDireccion={setModalNuevaDireccion} setSuccesfull={setSuccesfull}></NuevaDireccionForm>
                      <div className="text-center pt-3">
                          <button className='btn-g bb px-5 mx-md-3 mx-0 my-1' onClick={volverModal}>{getTextByKey('volver')}</button>
                          { succesfull && 
                          <button className='btn-g px-4 mx-md-3 mx-0 my-1' disabled={loadDir} onClick={confirmar}>
                            { !loadDir ? getTextByKey('continuar') : (<div className='spinner-border spinner-border-sm' role='status'></div>) }
                           </button>
                            }
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
