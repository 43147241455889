import React, { Component, useEffect, useState } from 'react';

import { createAddress } from '../api/addressApi'
import PropsTypes from 'prop-types';
import { useApi } from '../context/apiContext';
import { getPaises } from '../api/productApi';
import { getInfoMap } from '../api/mapApi';
import { useAuth } from '../authContext';
import { useForm } from '../hooks/useForm';
import { async } from '@firebase/util';
import { getTiposDocumentos } from '../api/productApi';
import { updateUser ,getUser} from '../helpers/userHelper';
import { Icon } from '@iconify/react';

  
const addressFormFields = {
  nombre: "",
  apellido: "",
  tipoDocumento: 0,
  documento: "",
  //nombre_empresa: "",
  //nit: "",
  email: "",
  celular: "",
  notas:"",
  autoriza:false,
  uid: "unset",
}

export const FormularioDireccion = ({ user, updateAddress, locationMarker, addressFromMap, onFormSubmit }) => {
  
  const [paises, setPaises] = useState([]);
  const [disabledDescDir, setDisabledDescDir] = useState(true);
  const [error, setError] = useState('')
  const [succesfull, setsuccesfull] = useState('');
  const [loading, setloading] = useState(false);
  const [colombiaAddress, setColombiaAddress] = useState(false)
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [ success, setSuccess ] = useState(false);
  const { currentUser } = useAuth();
  const { getTextByKey } = useApi();
  const [ errorTel, setErrorTel ] = useState();
  const [ errorDireccion, setErrorDireccion ] = useState();
  const [ errorDpto, setErrorDpto ] = useState();
  const [ errorCiudad, setErrorCiudad ] = useState();
  const [ errorNombre, setErrorNombre ] = useState();
  const [ errorApellido, setErrorApellido ] = useState();
  const [ errorTipoDoc, setErrorTipoDoc ] = useState();
  const [ errorMail, setErrorMail ] = useState();
  const [ errorNumDoc, setErrorNumDoc ] = useState();
  const [nombresApellidos, setnombresApellidos] = useState({
    nombres: '',
    apellidos: ''
  });
 const [nuevaDireccion, setNuevaDireccion] = useState(false)
  const [ tipoDoc, setTipoDoc ] = useState(0);
  const [addressSearch, setAddressSearch] = useState({
    tipo_direccion: '',
    desc_dir_1: '',
    desc_dir_2: '',
    desc_dir_3: '',
    direccionLarga: '',
    city: 'Bogota',
    country: ''
  })

  const {
    onInputChange: onAddressInputChange,
    formState,
    setFormState,
    emailRegex,
    nameRegex,
    phoneRegex
  } = useForm(addressFormFields);

  const {
    nombre,
    apellido,
    tipoDocumento,
    documento,
    notas,
    //nombre_empresa,
    //nit,
    email,
    celular,
    autoriza,
    uid
  } = formState;

  
  useEffect(() => {
    getTiposDocumentos().then((data) => {
      setTiposDocumentos(data);
   

  });
  }, []);

  const fetchProfile = () => {
           
    try {
      getUser(currentUser?.uid).then(async({ user }) => {
         console.log(user);
        const { nombre, apellido, email, emailnotifica, celular, ciudad, departamento, direccion, tipoDocumento,uid, barrio, documento } = user;
          const updatedProfileInfo = {
          nombre: nombre,
          apellido: apellido,
          email: emailnotifica,
          celular: celular,
          tipoDocumento:tipoDocumento,
          //ciudad: ciudad,
          //direccion: direccion,
          //barrio: barrio,
          documento: documento,
          //departamento: departamento,
          uid:uid
        };
  
        setFormState(updatedProfileInfo);
           });
    } catch (error) {
     
    }
  };
  

  useEffect(() => {
    if(localStorage.getItem('actualizo')){
      fetchProfile();
    }else{
      setFormState({
        ...formState,
        uid: currentUser.uid
      })
    }
      
    
  }, [currentUser?.uid]);

  const resetError=()=>{
      setErrorNombre('');
      setErrorApellido("");
      setErrorTel("");
      setErrorTipoDoc('');
      setErrorNumDoc('');
      setErrorMail("");
      setErrorMail('');
      setError('');
        
  }

  /*const handleTipoDocChange = (e) => {
    //console.log(e.target.value);
    setFormState({
      ...formState,
      tipo_documento: e.target.value
    });
    setTipoDoc(e.target.value);
  };*/

  const onRegisterAddress = async (event) => {
    event.preventDefault();
    resetError();
    setloading(true);
  
    const validar = await validateForm(formState);
    if(!validar){
    
      updateUser(formState).then((data)=>{
        localStorage.setItem('actualizo',1);
        onFormSubmit(formState); 
        setSuccess(true);
        setloading(false);

      }).catch((error)=>{
        setSuccess(false);
         localStorage.removeItem('actualizo');
         setSuccess(false);
         setloading(false);
          if(error.response.data.message){
            setError(error.response.data.message);
          }else{
            setError('Error al actualizar la información, intentelo de nuevo');
          }
      });
     
    }else{
     setloading(false);
    }
    /*console.log(errors);
    if (hasErrors) {
      setloading(false);
      setError(errors);
    } else {
      
    }*/
  };
  

  const validateForm = async (formData) => {
    let errorfound=false;

    if (formData.nombre === "") {
     setErrorNombre('Campo obligatorio');
     errorfound=true;
    }else{
       if(!formData.nombre.match(nameRegex)){
        setErrorNombre('El nombre solo debe contener letras');
        errorfound=true;
       }
    }

    if (formData.apellido === "") {
     setErrorApellido("Campo Obligatorio");
     errorfound=true;
    }else{
       if(!formData.apellido.match(nameRegex)){
        setErrorNombre('El apellido solo debe contener letras');
        errorfound=true;
       }
    } 
    
    if (formData.celular === "") {
      setErrorTel("Campo Obligatorio");
      errorfound=true;
    }else{
      if(!formData.celular.match(phoneRegex)){
       setErrorTel('No es un número valido');
       errorfound=true;
      }
    }
 
    
    if (formData.tipoDocumento ==0) {
      setErrorTipoDoc('Campo Obligatorio');
      errorfound=true;
    }
    if (formData.documento === "") {
      setErrorNumDoc('Campo Obligatorio');
      errorfound=true;
    }

    if (formData.email === "") {
      setErrorMail("Campo Obligatorio");
      errorfound=true;
    }else{
      if(!formData.email.match(emailRegex)){
       setErrorMail('No es un número valido');
       errorfound=true;
      }
    }
 
 
  
    return errorfound;
  };
  
  

  // useEffect(() => {

  //   if( user ) {
  //     const displayName = user.displayName.split(' ');
  //     if( displayName.length === 4 ) {
  //       nombresApellidos.nombres = `${displayName[0]} ${displayName[1]}`
  //       nombresApellidos.apellidos = `${displayName[2]} ${displayName[3]}`
  //     }
  
  //     if( displayName.length === 3 ) {
  //       nombresApellidos.nombres = `${displayName[0]}`
  //       nombresApellidos.apellidos = `${displayName[1]} ${displayName[2]}`
  //     }
  
  //     if( displayName.length === 2 ) {
  //       nombresApellidos.nombres = `${displayName[0]}`
  //       nombresApellidos.apellidos = `${displayName[1]}`
  //     }
  
  //     if( displayName.length < 2 ) {
  //       nombresApellidos.nombres = `${displayName.join(' ')}`
  //     }
  //   } else {
  //     return;
  //   }


  // }, []);

  // useEffect(() => {
  //   let addressToSearch = [];
  //   Object.values(addressSearch).forEach(value => {
  //     if (value != '') {
  //       addressToSearch.push(value);
  //     }
  //   })
  //   async function infoMap() {
  //     const { data: { results } } = await getInfoMap(addressToSearch.join('+'))
  //     addressToSearch = [];
  //     if (results.length > 0) {
  //       if (locationMarker) {
  //         locationMarker(results[0].geometry.location)
  //       }
  //     }
  //   }
  //   infoMap();
  // }, [addressSearch])


  // const ingresarRegistro = async e => {
  //   seterror('')
  //   setsuccesfull('');
  //   const inputData = {
  //     country: "",
  //     city: "",
  //     address: "",
  //     complement: "",
  //     zipcode: "",
  //     phone: "",
  //     user: user.uid,
  //   }
  //   setloading(true);
  //   const { nombres, apellidos, pais, ciudad, direccion, complemento, codigo_postal, telefono, desc_dir_1, desc_dir_2, desc_dir_3, tipo_direccion, country_code } = e.target.elements;
  //   e.preventDefault();

  //   //Validate the form
  //   if (nombres.value === "" || apellidos.value === "" || telefono.value === "" || ciudad.value === "" || tipo_direccion.value === "" || complemento.value === "" || codigo_postal.value === "" || pais.value === "") {
  //     seterror(getTextByKey('error-all-fields-form'));
  //     return;
  //   }
  //   //Validate the phone format
  //   if (!telefono.value.match(/^[0-9]{10}$/)) {
  //     seterror("El teléfono no tiene un formato válido");
  //     return;
  //   }
  //   //Validate the pais no empty
  //   if (pais.value === "0") {
  //     seterror("Debe seleccionar un país");
  //     return;
  //   }

  //   //Validate the pais no empty
  //   if (tipo_direccion.value === "0") {
  //     seterror("Debe seleccionar un tipo de direccion");
  //     return;
  //   }

  //   inputData.city = ciudad.value;
  //   inputData.country = pais.value;

  //   if (pais.value !== 48) {
  //     const { direccionInput } = e.target.elements;
  //     inputData.address = direccionInput
  //   } else {
  //     inputData.address = `${tipo_direccion.value} ${desc_dir_1.value} #${desc_dir_2.value} - ${desc_dir_3.value}`;
  //   }

  //   inputData.zipcode = codigo_postal.value;
  //   inputData.phone = `${country_code.value}${telefono.value}`;
  //   inputData.complement = complemento.value;
  //   createAddress(JSON.stringify(inputData))
  //   Object.keys(inputData).forEach((data) => inputData[data] = "")
  //   Object.keys(e.target.elements).forEach((element) => e.target.elements[element].value = "");
  //   setsuccesfull(getTextByKey('succesfull-create-direction'))
  //   updateAddress();
  //   setTimeout(() => {
  //     setsuccesfull('')
  //   }, 5000);
  // };



  return (
    <div className='primary-border-color p-3 box-check'>
      <form action="" onSubmit={onRegisterAddress}>
        {succesfull ? <div className='succesfull-msg'>{succesfull}</div> : null}
        {/* { currentUser &&
          <div className='border-bottom mb-3 pb-2'>
            <label className="container-check-s">
              <p className='gray-1-color mb-0'>{getTextByKey('nueva-direccion')}</p>
              <input
                type="checkbox"
                onChange={handleChangeNuevaDireccion}
              />
              <span className="checkmark-s">
                <img src="/img/SVG/check.svg" />
              </span>
            </label>
          </div>
        } */}

 
            <h3 className='gray-1-color carrefour-sans-bold'>{getTextByKey('detalles-envio-titulo')}</h3>
            <div className="row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("nombres")}*</label>
                      <input type="text" className="border-start-0 p-2 col-10" name="nombre" value={nombre} onChange={onAddressInputChange} />
                    	<span>{errorNombre ? <div className='alert-danger'>{errorNombre}</div> : null}</span>
                    
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("apellidos")}*</label>
                      <input type="text" className="border-start-0 p-2 col-10" name="apellido" value={apellido} onChange={onAddressInputChange} />
                      <span>{errorApellido ? <div className='alert-danger'>{errorApellido}</div> : null}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("tipo_documento")}*</label>
                      <select className='p-2 select-gris' name="tipoDocumento" value={formState.tipoDocumento} onChange={onAddressInputChange}>
                          <option value="0"  >Selecciona una opción </option>
                          {tiposDocumentos.map((tipoDocumento, index) => (
                              <option key={index} value={tipoDocumento.id}>{tipoDocumento.ref + " (" + tipoDocumento.translations[0].name + ")"}</option>
                          ))}
                      </select>
                      <span>{errorTipoDoc ? <div className='alert-danger'>{errorTipoDoc}</div> : null}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("numero_documento")}*</label>
                      <input type="number" className="border-start-0 p-2 col-10" name="documento" value={documento} onChange={onAddressInputChange} />
                      <span>{errorNumDoc ? <div className='alert-danger'>{errorNumDoc}</div> : null}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("nombre_empresa")}*</label>
                      <input type="text" className="border-start-0 p-2 col-10" name="nombre_empresa" value={nombre_empresa} onChange={onAddressInputChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("nit")}*</label>
                      <input type="number" className="border-start-0 p-2 col-10" name="nit" value={nit} onChange={onAddressInputChange} />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("correo_electronico_form")}*</label>
                      <input type="text" className="border-start-0 p-2 col-10" name="email" value={email} onChange={onAddressInputChange} />
                      <span>{errorMail ? <div className='alert-danger'>{errorMail}</div> : null}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className='gray-1-color'>{getTextByKey("telefono")}*</label>
                      <input type="number" className="border-start-0 p-2 col-10" name="celular" value={celular} onChange={onAddressInputChange} />
                      <span>{errorTel ? <div className='alert-danger'>{errorTel}</div> : null}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label className="mb-1 gray-1-color">{getTextByKey("notas")}</label>
                      <textarea className="p-2 rounded" name="notas"  value={notas} onChange={onAddressInputChange} placeholder={getTextByKey('placeholder_textarea_direccion')}></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="d-flex align-items-start mb-0 radio-button-container">
                      <input type="checkbox" className="form-check-input p-2" name="autoriza" onChange={onAddressInputChange}/>
                      <p className='gray-1-color mb-0 ms-2'>{getTextByKey('suscripcion_novedades')}</p>
                    </label>
                  </div>
                </div>
              </div>        
        {error ? <div className='mt-4 error-msg'>{error}</div> : null}
        { success && 
        <p className='success-msg text-center fw-bold alert-success'>Datos guardados con éxito</p>
        } 
        <div className="text-center py-3">
          <button className='btn-g py-2 px-5'>
            {
               (loading ? (<div className='spinner-border spinner-border-sm' role='status'></div>) : 'Guardar' )
            }
          </button>
        </div>
      </form>
    </div>
  )
}
// FormularioDireccion.propTypes = {
//   uidUser: PropsTypes.string.isRequired
// }