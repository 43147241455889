import React from "react";
import { Icon } from "@iconify/react";

export function Star({ filled, onClick, className }){
    if (!className) {
      className = "cursor-pointer mx-1"
    }
    return(
        
        <div onClick={onClick} className={className}>
          {
            filled ? <Icon className="primary-color" icon="bi:star-fill"></Icon>:
            <Icon className="primary-color" icon="bi:star" ></Icon>
          }
        </div>
    );
}