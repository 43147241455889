import { useApi } from "../context/apiContext";
import { useAuth } from "../authContext";
import { useState } from "react";
import { useEffect } from "react";
import { createAddress } from "../api/addressApi";
import { useForm } from "../hooks/useForm";
import { ActualizaTecnocarnes, getCiudades, getDepartamentos } from "../api/productApi";
import { getInfoMap } from "../api/mapApi";
import { getKml } from '../api/storeApi';
import { updateUser } from "../helpers/userHelper";
import { useCart } from '../context/cartContext';
import ModalGuardarDireccion from "../pages/PerfilPage/components/ModalGuardarDireccion";
import { default as ModalConfirmarEntrega } from './modalesRecibirPedido/ModalConfirmarEntrega';

const addressFromFields={
    alias: "",
    city: 0,
	departamento: 0,
    tipocalle: 0,
    base: "",
    numero: "",
    detalle: "",
    complement: "",
    zipcode: "",
    lat: "",
    lng: "",
    phone: "",
    barrio: "",
    uid: "unset"
}


export default function NuevaDireccionPage() {

	

    const { currentUser } = useAuth();
    const { getTextByKey } = useApi();
    const [ ciudades, setCiudades ] = useState([]);
	const [ departamentos, setDepartamentos ] = useState([]);
	const [ tienda, setTienda ] = useState(0);
	const { updateTienda,updateCurrentdelivery,tiendaGeneral,currentCart,validarCart,validarCartOld,updateDireccion} = useCart();
    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
	const [ succesfull, setSuccesfull] = useState('');
	const [ modalConfirmar, setModalConfirmar ] = useState(false);
	
	const [ disponible, setDisponible ] = useState(0);
    const [ showModalGuardar, setShowModalGuardar ] = useState(false);
		/*const [ addressSearch, setAddressSearch] = useState({
			tipocalle: '',
			base: '',
			numero: '',
			detalle: '',
			direccionLarga: '',
			lat:'',
			lng:'',
			city: ''
		});*/

    const {
        onInputChange: onAddressInputChange,
        formState,
        setFormSate
    } = useForm(addressFromFields);

    const {
        alias,
        city,
		departamento,
        tipocalle,
        base,
        numero,
        detalle,
		lat,
		lng,
        complement,
    } = formState;

		/*useEffect(()=>{
			let addressToSearch = [];
			Object.values(addressSearch).forEach(value => {
				if(value != '') {
					addressToSearch.push(value);
				}
			})
		}, [addressSearch]);*/

		const actualiza=async(dir,store)=>{
			updateCurrentdelivery({tipo:'', dir: {id:dir}});
			updateDireccion(dir);
			console.log(currentCart, currentCart.length);
	    	try{
				if(currentCart.length>0){
					let carritoajustar =await validarCart(store);
					if(carritoajustar.ok){
						setShowModalGuardar(true);
					setDisponible(carritoajustar.total);
					setModalConfirmar(true);
					}
				}else{
					let carritoajustar =await validarCartOld(store);
					if(carritoajustar.ok){
						setShowModalGuardar(true);
					setDisponible(carritoajustar.total);
					setModalConfirmar(true);
					}
				}
			  } catch (error) {
				setShowModalGuardar(true);
				console.error(error);
			  }
			
		}

		const infoMap = async (address) => {
			try {
				const { data: { results } } = await getInfoMap(address);
				if (results && results.length > 0) {
					if(results[0].geometry.location_type=='APPROXIMATE'){
						return false
					}
					const { lat, lng } = results[0].geometry.location;
		            formState.lat=lat;
					formState.lng=lng;
					let tienda= await getKml(lat, lng) 
					if(tienda.id){
						let tecnocarnes=await ActualizaTecnocarnes(tienda.id);
						console.log(tecnocarnes,'tecnocarnes');
						updateTienda(tienda.id);
						return tienda.id;
					}else{
						return false;
					}  

				}else{
					setTienda(0);
					return false;
				}
			} catch (error) {
				console.error(error);
				setTienda(0);
				return false;
			}
		}

    const onSubmit = async (event) => {
		setError('');
        event.preventDefault();
        setLoading(true);
        let { uid } = currentUser
        formState.uid = uid
        formState.password = uid
        formState.autoriza = true
		formState.email = uid + "@cervalle.com";
		const validate = await validateForm(formState);
			if(!validate){
				const ciudadSelect = ciudades.find((city) => city.id == formState.city);
				const dirfull=`${formState.tipocalle} ${formState.base}#${formState.numero}-${formState.detalle}`;
				const address = `${dirfull},${ciudadSelect.name.nombre},Colombia`;
			
				let ubicacion=await infoMap(address);
				if(ubicacion){
	
					let addressFields = {
						...addressFromFields,
						user: formState.uid,
						uid: formState.uid,
						alias: formState.alias,
						city: formState.city,
						tipocalle: formState.tipocalle,
						base: formState.base,
						numero: formState.numero,
						detalle: formState.detalle,
						lat: formState.lat,
						lng: formState.lng,
						complement: formState.complement,
					}

					/*setAddressSearch({
						...addressSearch,
						tipocalle: formState.tipocalle,
						base: formState.base,
						numero: formState.numero,
						detalle: formState.detalle						
					})*/

					setLoading(true)
				
					if (currentUser?.isAnonymous) {
						updateUser(formState).then(() => {
							createAddress(addressFields).then((response) => {
								setSuccesfull('Direccion creada con éxito');
								actualiza(response.id,ubicacion);
							})
							.catch((error)=>{
								setError('Error al actualizar la información');
								setLoading(false);
								setSuccesfull('');
								console.log(error);
							})
						}).catch((error)=>{
							setError('Error al actualizar la información');
							setLoading(false);
							setSuccesfull('');
							console.log(error);
						})
					} else {
						createAddress(addressFields).then((response) => {
							setSuccesfull('Dirección creada con éxito');setSuccesfull('Direccion creada con éxito');
							actualiza(response.id,ubicacion);
						})
						.catch((error)=>{
							setError('Error al actualizar la información');
							setLoading(false);
							setSuccesfull('');
							console.log(error);
						})
					}
				}else{
					setError('En este momento no contamos con cobertura en tu dirección. Te invitamos a montar tu pedido y recoger en la tienda más cercana');
					setLoading(false);
					setSuccesfull('');
				}

			}else{
				setLoading(false);
				setSuccesfull('');
			}
    }

	const validateForm = async () => {
		console.log(departamento,city);
		let errorFound = false;
		if(departamento==0 || !departamento){
			setError('Todos los campos son requeridos *');
			errorFound=true;
		}
		if(city==0 || !city){
			setError('Todos los campos son requeridos *');
			errorFound=true;
		}
		if(tipocalle==0 || !tipocalle){
			setError('Todos los campos son requeridos *');
			errorFound=true;
		}
		if(!alias || !base || !numero || !detalle ){
			setError('Todos los campos son requeridos *');
			errorFound=true;

		}
		
		return errorFound;
	}


		useEffect(()=>{
			getDepartamentos().then((data)=>{
				setDepartamentos(data);
			})
		}, [])

	const handleDpto = (event)=>{
		const dptoId = event.target.value;
		
		getCiudades(dptoId).then((data) => {
			setCiudades(data);
		});
	}




    return (
			<form onSubmit={onSubmit} className="gray-1-color">
				<div className="row">
				{succesfull ? <p className='sm-p alert-sucess'>{succesfull}</p> : null}
					<div className="col-12 mb-4">
						<div className="form-group">
							<label>Alias</label>
							<input
									type="text"
									className="p-2"
									name="alias"
									onChange={onAddressInputChange}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('departamento')}*</label>
								<select name="departamento" className="p-2" value={departamento} onChange={(event)=>{onAddressInputChange(event); handleDpto(event)}}>
									<option  value={0}>{getTextByKey("departamento")}</option>
									{departamentos.map((departamentos, index) => (
										<option key={index} value={departamentos.id}>{departamentos.name.nombre}</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('ciudad')}*</label>
								<select name="city" className="p-2" value={city} onChange={onAddressInputChange}>
									<option  value={0}>{getTextByKey("ciudad")}</option>
									{ciudades.map((ciudad, index) => (
										<option key={index} value={ciudad.id}>{ciudad.name.nombre}</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('direccion')}*</label>
								<select name="tipocalle" id="" value={tipocalle} className="p-2" onChange={onAddressInputChange}>
									<option value="0">Dirección</option>
									<option value="Calle">Calle</option>
									<option value="Carrera">Carrera</option>
									<option value="Transversal">Transversal</option>
									<option value="Avenida">Avenida</option>
									<option value="Circunvalar">Circunvalar</option>
									<option value="Diagonal">Diagonal</option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>Base, número, detalle *</label>
								<div className="d-flex align-items-center">
										<div>
												<input type="text" name="base" className="p-2" value={base} placeholder="10A" onChange={onAddressInputChange} />
										</div>
										<div>#</div>
										<div>
												<input type="text" name="numero" className="p-2" value={numero} placeholder="10" onChange={onAddressInputChange} />
										</div>
										<div>-</div>
										<div>
												<input type="text" name="detalle" className="p-2" value={detalle} placeholder="101" onChange={onAddressInputChange} />
										</div>
								</div>
							</div>
						</div>
						<div className="col-12 mb-4">
							<div className="form-group">
								<label>{getTextByKey('complemento')}</label>
								<input
										type="text"
										className="p-2"
										name="complement"
										value={complement}
										onChange={onAddressInputChange}
								/>
							</div>
						</div>
				</div>
				<div className="text-center">
					{error && <p className="alert-danger">{error}</p> }
				</div>
				<div className="mt-4 text-center">
						<button type="submit" className="btn-g py-1 px-3 px-xl-4 my-1">
							{	loading ? 'Guardando direccion...' :
								`${getTextByKey('guardar_direccion')}`
							}
						</button>
				</div>
      <ModalGuardarDireccion showModalGuardar={showModalGuardar} setShowModalGuardar={setShowModalGuardar} />
	  <ModalConfirmarEntrega disponible={disponible} modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/> 

			</form>
    )
}