import axios from "axios";
import { getEnvVariables } from "../helpers";

const { GOOGLE_API_KEY } = getEnvVariables()

export const getInfoMap = async (infoAddress) => {
    try {
        let address = infoAddress.replace(/\s/g, "%20");
        address = address.replace(/#/g, "%20");
        const URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=${address}`;
        console.log(URL);
         const response = await axios.get(URL);
        if(response)
        return response;
    } catch (error) {
        console.log("Error al consultar informacion de direccion", error);
        return true;
    }
}

export default { getInfoMap };