import React from 'react'
import PropTypes from 'prop-types'
import { Star } from './Star'

const StaticRatingStar = ({rating, className}) => {

  if (!className) {
    className = 'd-flex mb-4';
  }

  return (
    <div className={className}>
        <span className="d-flex">
            {[1, 2, 3, 4, 5].map((value) => (
            <Star
                key={value}
                filled={value <= rating}
                className="mx-1"
            />
            ))}
        </span>
    </div>
  )
}

StaticRatingStar.propTypes = {
    rating: PropTypes.oneOf([0,1,2,3,4,5]).isRequired
}

export default StaticRatingStar