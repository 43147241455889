import React, { Fragment } from 'react'
import { Link, useParams } from 'react-router-dom';
import { BolsaComponent } from '../../components/Bolsa';
import LoaderComponent from "../../components/LoaderComponent";
import SelectDireccionComponent from './components/SelectDireccionComponent';
import StepbyStepComponent from './components/StepbyStepComponent';
import MetodoPagoComponent from '../PerfilPage/components/MetodoPagoComponent';
import { useApi } from "../../context/apiContext";
import '../../scss/checkout.scss'
import FormPagoComponent from './components/FormPagoComponent';
import PaypalComponent from './components/PaypalComponent';
import { useState } from 'react';
import { useEffect } from 'react';
import { createOrder, getTarjetas, pagarOrderPSE, pagarOrderWompi, pagarContraentrega } from '../../api/productApi';
import { useAuth } from '../../authContext';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/cartContext';
import Modalmetodo from '../PerfilPage/components/Modalmetodo';
import { FormularioDireccion } from '../../components/FormularioDireccion';
import Picture from '../../components/Picture';
import { getImages } from '../../api/imageApi';
import { default as ModalConfimacion } from './components/ModalConfirmacion';
import { setDefaultAddress } from '../../api/addressApi';
import ModalNuevaDir  from './components/ModalNuevaDir';
import ModalTarjeta from './components/ModalTarjeta';
import Select from "react-select";
import { getStores,getHorarios} from '../../api/storeApi';
import { async } from '@firebase/util';
import { clear } from '@testing-library/user-event/dist/clear';
import { faL } from '@fortawesome/free-solid-svg-icons';
import ModalProgramarPedido from '../../components/modalesRecibirPedido/ModalProgramarPedido';
import ModalPse from '../PerfilPage/components/ModalPse';


const CheckoutcPage = () => {
  //TODO revisar flujos de pagos

  const [tarjetas, setTarjetas] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [order, setOrder] = useState({});
  const { getTextByKey, getTextBigByKey, currentCurrency, currentLocale } = useApi();
  const { currentUser } = useAuth();
  const params = useParams();
  const { total, envio, coupon, direccion, currentCart, currentDelivery, pesoTotal, 
    pesoXbolsa,impuestoBolsa,clearCart, applyCoupon ,tiendaGeneral,subtotal,descuento,impuesto,impuestoUltra} = useCart();
  const [method, setMethod] = useState("select");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [cuotas, setCuotas] = useState(0);
  const [imgSecurity, setImgSecurity] = useState([]);
  const [terminosCheck, setTerminosCheck] = useState(false);
  const [optionsCuotas, setOptionsCuotas] = useState(Array(36));
  const [showModalMetodo, setShowModalMetodo] = useState(false);
  const [showModalPse, setShowModalPse] = useState(false);
  const [ modalNuevaDir, setModalNuevaDir ] = useState(false);
  const [ exitoso, setExitoso ] = useState(false);
  const [ orderId, setOrderId ] = useState(0);
  const [ showModalTarjeta, setShowModalTarjeta ] = useState(false);
  const [ formData, setFormData ] = useState([]);
  const [ bolsa, setBolsa ] = useState(1);
  const [ tienda, setTienda ] = useState(0);
  const [ loader, setLoader ] = useState(false);
  const [ tiendaId, setTiendaId  ] = useState(0);
    const [ horario, setHorario ] = useState([]);
    const [ showProgramar, setShowProgramar ] = useState(false);
    const [ msg, setMsg ] = useState('');
  // Estado para el tipo de pago
  const [tipoPago, setTipoPago] = useState('')
  const [datosOrden, setDatosOrden] = useState({
    numeroPedido: 0,
    fecha: '',
    metodoPago: '',
    total: ''
  })

  const currentDate = () => {
    const newDate = new Date();
    const formattedDate = newDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const formattedTime = newDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return `${formattedDate} ${formattedTime}`;
  }

  useEffect(() => {
     const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);
    const compraId = urlParams.get('compraid');
    console.log(compraId,'acaaaa', currentUrl);
  if(compraId){
    setOrderId(compraId);
    const datosOrdenAux = {
      numeroPedido: compraId,
      fecha: currentDate(),
      metodoPago: 'pse',
      total: parseFloat(urlParams.get('total'))
    };
    setDatosOrden(datosOrdenAux);
    setShowModal( true )
    window.scroll(0, 0);
  }
  }, []);

  useEffect(()=>{
    if(currentDelivery.tipo === "pickup"){
      setLoader(true);
      getStores().then((data) => {
        let store=data.find((tienda)=>tienda.id==tiendaGeneral);
        setTienda(store);
        setLoader(false);
        });
    }else{

    }
  },[tiendaGeneral,currentDelivery]);

  useEffect(()=>{
    if(tipoPago === "pse"){
      order.uid = currentUser.uid;
      order.method = getMetodoDePago();
      order.store = tiendaGeneral;
      if(currentDelivery.recoger){
        const date=new Date(currentDelivery.recoger);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        order.fecharecogida=formattedDate;
        order.address = 0;
        order.billing = 0;
      }else{
      order.address = parseInt(direccion);
      order.billing = parseInt(direccion);
      }
      order.locale = currentLocale;
      order.currency = currentCurrency;
      order.discount = (coupon!=0 ? coupon.id : 0);
      //order.card = parseInt(selectedCard);
      order.bolsas=bolsa
      order.impuestobolsa=impuestoBolsa*bolsa;
      order.creditBenefitType = {
        type: null
      }
      order.discountMount=descuento;
      order.taxes=parseFloat(impuesto.toFixed(0))
      order.impuestosultra=parseFloat(impuestoUltra.toFixed(0))
      order.delivery_cost = envio;
      order.subtotal = parseFloat(subtotal.toFixed(0));
      order.total = parseFloat(total-applyCoupon(total) + envio+(impuestoBolsa*bolsa));
      order.buyItems = [];
      order.comments = "";
      currentCart.forEach((item) => {
        if (item.type === "giftcard") {
          order.buyItems.push({
            productId: item.id,
            type: item.type,
            price: item.price,
            quantity: item.quantity,
            comments: '',
            discount: 0,
            toppings: '',
            promotionId: '',
          });
        } else {
          order.buyItems.push({
            productId: item.plu,
            type: item.type,
            gramaje: (item.gramaje!=0) ? item.gramaje.id :0,
            price: item.quantity*item.price.precio,
            quantity: item.quantity,
            comments: '',
            discount: 0,
            toppings: '',
            promotionId: '',
            products: item.products ? item.products : []
          });
        }
      });
      setOrder(order);
  
      setShowModalPse(true);
    }else{
      setShowModalPse(false);
    }
    console.log(tipoPago,'acaaaa');
  },[tipoPago]);
    
  useEffect(()=>{
    if(pesoTotal == 0 || pesoTotal == null ){
      setBolsa(1);
    }else{
      setBolsa(Math.ceil(pesoTotal/pesoXbolsa));
    }
    
  },[pesoTotal, pesoXbolsa]);


  useEffect(() => {
     setTarjetas([]);
     if(currentUser){
      getTarjetas(currentUser.uid).then((response) => {
        setTarjetas(response);
      });
    }

    const cuotasAux = [];
    while (cuotasAux.length < 36) {
      const cuotaN = { value: cuotasAux.length + 1, label: cuotasAux.length + 1 }
      cuotasAux.push(cuotaN);
    }
    setOptionsCuotas(cuotasAux);
  }, []);

  const selectCuotas = (e) => {
    setError('');
    if (e.value) setCuotas(e.value);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "black" : "white",
    }),
    control: (provided) => ({
      ...provided,
    }),
  };


  const handleTarjeta = (card) => {
    setSelectedCard(card);
    setError('');
  }

  const changeTarjeta = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCard(0);
    setCuotas(0);
  }

  const handleFormSubmit = (data) => {
    setFormData(data);
  };

  useEffect(() => {
    getImages("checkout-pagos").then((data) => {
      setImgSecurity(data);
    });
  }, []);

  // useEffect(() => {
  //   console.log(tipoPago);
  // }, [tipoPago])

  const createCheckoutOrder = async () => {
    setLoading(true);
    setError('');
    if(currentUser.isAnonymous){
      if(!localStorage.getItem('actualizo')){
        setError('Debe completar la información de envio');
        setLoading(false);
        return;
      }
    }
    console.log('tipo', tipoPago, 'select',selectedCard);
    if (!tipoPago) {
      setError('Debe escoger un método de pago');
      setLoading(false);
      return;
    }
    if(tipoPago=='tarjeta' && selectedCard==0){
      setError('Selecciona una tarjeta de pago');
      setLoading(false);
      return;
    }
    if(tipoPago=='tarjeta' && cuotas==0){
      setError('Selecciona el número de cuotas');
      setLoading(false);
      return;
    }
    if(!terminosCheck){
      setError('Debe aceptar los terminos y condiciones');
      setLoading(false);
      return;
    }
   
    order.uid = currentUser.uid;
    order.method = getMetodoDePago();
    order.store = tiendaGeneral;
    if(currentDelivery.recoger){
      const date=new Date(currentDelivery.recoger);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      order.fecharecogida=formattedDate;
      order.address = 0;
      order.billing = 0;
    }else{
    order.address = parseInt(direccion);
    order.billing = parseInt(direccion);
    }
    order.locale = currentLocale;
    order.currency = currentCurrency;
    order.discount = (coupon!=0 ? coupon.id : 0);
    //order.card = parseInt(selectedCard);
    order.bolsas=bolsa
    order.impuestobolsa=impuestoBolsa*bolsa;
    order.creditBenefitType = {
      type: null
    }
    order.discountMount=descuento;
    order.taxes=parseFloat(impuesto.toFixed(0))
    order.impuestosultra=parseFloat(impuestoUltra.toFixed(0))
    order.delivery_cost = envio;
    order.subtotal = parseFloat(subtotal.toFixed(0));
    order.total = parseFloat(total-applyCoupon(total) + envio+(impuestoBolsa*bolsa));
    order.buyItems = [];
    order.comments = "";
    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.id,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
        });
      } else {
        order.buyItems.push({
          productId: item.plu,
          type: item.type,
          gramaje: (item.gramaje!=0) ? item.gramaje.id :0,
          price: item.quantity*item.price.precio,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
          products: item.products ? item.products : []
        });
      }

    });


    createOrder(order).then( async(response) => {
      //console.log('response: ', response)
      setOrderId(response.id);
      if (!response.id) {
        setError("Error al crear la orden");
        setLoading(false)
      } else {
        if( tipoPago == 'tarjeta' ) {
          if(selectedCard === 0){
            setShowModalTarjeta(true);
            setLoading(false);
          } else{
            await pagoEpayco(response) 
          }
        }
        if(tipoPago == 'efectivo'){
          await pago(response)
        }
        if(tipoPago == 'ce-tarjeta'){
          await pago(response)
        }
      }
    }).catch((error) => {
      setLoading(false);
      console.log('error: ', error)
      setError(error.message);
    })
  }


  const pagoEpayco = async (ordenCreated) => {
      let jsonEpayco = {
        tarjeta: selectedCard,
        user: currentUser.uid,
        compra: ordenCreated.id,
        cuota: cuotas,
        //tipo_documento: formData.tipo_documento,
        //numero_documento: formData.numero_documento
      };
    pagarOrderWompi(jsonEpayco).then(({ id, msg }) => {
      console.log(jsonEpayco);
      if( id ) {
       clearCart();
       setCuotas(0);
       setSelectedCard(0);
        setLoading(false);
        const datosOrdenAux = {
          numeroPedido: ordenCreated.id,
          fecha: currentDate(),
          metodoPago: tipoPago,
          total: parseFloat(total-applyCoupon(total) + envio +(impuestoBolsa*bolsa))
        };
        setDatosOrden(datosOrdenAux);
        setShowModal( true )
        setExitoso(true);
        window.scroll(0, 0);
      } else {
        setError(`Ocurrio un error a realizar el pago, vuelva a intentarlo`);
        setLoading(false);
        //setShowModal(true);
      }
    })
    .catch((error)=>{
      setError(`Ocurrio un error a realizar el pago, vuelva a intentarlo`);
        console.log('no paso' , error);
        setLoading(false);
    });
  }
 
  const pago = async (ordenCreated) => {
      let jsonPago = {
        compra: ordenCreated.id,
        user: currentUser.uid,
      };
     
      pagarContraentrega(jsonPago).then(({data, message})=>{
        console.log('RESPUESTA', data);
          clearCart();
          setLoading(false);
          const datosOrdenAux = {
            numeroPedido: ordenCreated.id,
            fecha: currentDate(),
            metodoPago: tipoPago,
            total: parseFloat(total-applyCoupon(total) + envio +(impuestoBolsa*bolsa))
          };
          setDatosOrden(datosOrdenAux);
          setShowModal( true )
          window.scroll(0, 0);
          //setError(`Error al pagar la orden : ${message}`);
          console.log('message', message);
          setShowModal(true);
        
      })
  }

  const [showModal, setShowModal] = useState(false);

  const getMetodoDePago = () =>  {
    let metodoPagoID = '';

    switch (tipoPago) {
      case 'efectivo':
        metodoPagoID = 1
        break;
      case 'ce-tarjeta':
        metodoPagoID = 2
        break;
      case 'tarjeta':
        metodoPagoID = 3
        break;
      case 'pse':
      metodoPagoID = 3
      break; 

      default:
        break;
    }

    return metodoPagoID;
  }

  const formatDate=(dateTimeString)=> {
    const date = new Date(dateTimeString);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  }

  const openModal = () => {
    setShowModal(true);
  }

  const handleClick = () => {
    setModalNuevaDir(true);
  }

  const changeTerminos = (e) => {
    setError('');
    setTerminosCheck(e.target.checked)
  }

  const updateTarjetas = async () => {
    try {
      const listTarjetas = await getTarjetas(currentUser.uid);
      setTarjetas(listTarjetas);
    } catch (error) {
      setError("Error al actualizar tarjeta de crédito.");
    }
  };

  const onChangeCuotas = (e) => {
    setCuotas(e.target.value);
  }

  useEffect(() => {
    if(tiendaId){
      getHorarios(tiendaId).then((data)=>{
        setHorario(data);
        setShowProgramar(true);
        setLoading(false);
      }).catch((error)=>{
          setError('La tienda no tiene horarios');
          setLoading(false);
      })
    }else{
      setHorario([]);
      setError('');
      setLoading(false);
    }
  }, [tiendaId])



  return (
    <Fragment>
       <LoaderComponent load={loader}/>
      {/* <StepbyStepComponent step={3} /> */}
      { currentCart.length > 0 ? (
        <div className="container py-lg-4 py-3 mt-3 checkout">
          <div className="text-center">
            <h1 className='mt-2 pb-4 primary-color fw-bold'>{getTextByKey('realizar-pedido-titulo')}</h1>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xxl-4 col-lg-5">

                
              { currentUser && !currentUser.isAnonymous ? (
                <div className='mb-4'>
                  {currentDelivery.tipo === "pickup" ? (
                    <div className='select-direccion primary-border-color rounded p-2 position-relative'>
                      <div className='form-group'>
                      { tienda!=0 && <>
                        <p className="gray-1-color mb-0">Recoger: <strong>{tienda?.nombre} </strong></p>
                        <p className="gray-1-color mb-0">Dirección: <strong>{tienda?.direccion}</strong></p>
                        <p className="gray-1-color mb-0">Fecha: <strong>{formatDate(currentDelivery.recoger)}</strong></p>
                      </>
                      }
                      </div>
                                   
                    </div>
                  ):
                  
                  (<>
                    <SelectDireccionComponent step={3}/> 
                    <button onClick={handleClick} className='btn-g px-3 py-1 my-3 rounded'>Nueva direccion</button>
                    <ModalNuevaDir modalNuevaDir={modalNuevaDir}  setTiendaId={setTiendaId} setModalNuevaDir={setModalNuevaDir}></ModalNuevaDir>
                    </>)
                  }
                </div>) : 
                <FormularioDireccion onFormSubmit={handleFormSubmit} />
              
              }
            
            </div>
            <div className="col-xl-5 ps-xl-4 col-lg-6 offset-lg-1 offset-0">
              <div className="container mt-lg-0 mt-5">
                <BolsaComponent setTipoPago={setTipoPago} tipoPago={tipoPago} bolsa={bolsa} setBolsa={setBolsa} />
                 {tipoPago == "tarjeta" && (
                  <>
                   { selectedCard==0 && <div className='error-msg text-grey text-start bg-transparent'>*Seleccione o agrega una tarjeta</div>}
    
                    {tarjetas.length > 0 ? (
                      tarjetas.map((tarjeta, index) => (
                        <MetodoPagoComponent
                          tarjeta={tarjeta}
                          key={index}
                          selected={selectedCard}
                          handleTarjeta={handleTarjeta}
                          updateTarjetas={updateTarjetas}
                        />
                      ))
                    ) : (
                      <p className="gray-1-color source-sans-bold">
                        {getTextByKey("no-payment-methods")}
                      </p>
                    )}
                    {
                      selectedCard == 0 && (
                        <button
                          className="btn-g bb px-3 py-1 my-3"
                          onClick={() => setShowModalMetodo(true)}
                        >
                          {getTextByKey("add-card-button")}
                        </button>
                      )
                    }
                    {selectedCard > 0 && (
                      <>
                        <div className="w-100 d-block mb-2">
                          <p className="mb-2 pt-2 gray-1-color source-sans-bold">Cuotas</p>
                          <div className="py-6">
                            <Select
                              styles={customStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={optionsCuotas}
                              onChange={selectCuotas}
                              placeholder={
                                currentLocale === "es" ? "Seleccionar" : "Select"
                              }
                            />
                          </div>
                        </div>
                        <button
                          className="btn-g bb px-3 py-1 mb-1 mt-3 my-md-3"
                          onClick={changeTarjeta}
                        >
                          {getTextByKey("change-card")}
                        </button>
                      </>
                    )}
                  </>
                )}
                <div className="secundary-bg-color p-3 rounded my-4">
                  <div className='primary-bg-color white-color p-2 rounded d-flex align-items-start'>
                    <div className='icon-alert me-2'>
                      <Icon icon="la:exclamation-circle"></Icon>
                    </div>
                    <div className='big-text' dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-checkout-1") }}></div>
                  </div>
                  <div className='mt-2 gray-1-color' dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-checkout-2") }}></div>
                  {tipoPago!='pse' ?
                  <form action="">
                    <div className='form-group mb-4 mt-4'>
                      <label className="container-check-s">
                        <p className='gray-1-color mb-0'>
                          <Link className="gray-1-color" to='/aviso-de-privacidad'><div className='gray-1-color mb-0 sm ms-1 terminos' dangerouslySetInnerHTML={{ __html: getTextBigByKey('he_leido') }}></div></Link>
                        </p>
                        
                        <input
                          type="checkbox"
                          name="accet"
                          onChange={changeTerminos}
                        />
                           
                        <span className="checkmark-s">
                          <img src="/img/SVG/check.svg" />
                        </span>
                      </label>
                    </div>
                  </form>
                     :''} 
                  </div>
               
                <div className="text-center">
                { error && <p className='alert-danger'>{error}</p>}
                {tipoPago!='pse' ?
                     <button className='btn-g px-5 py-2' onClick={createCheckoutOrder}>
                    {
                      !loading ? (<>{getTextByKey('realizar-pedido-titulo')}</>) : (<div className='spinner-border spinner-border-sm' role='status'></div>)
                    }
                  </button>
                 :''}
               </div>
              
                <div className="text-center mt-5">
                  {imgSecurity.length > 0 ? <Picture classImg={'img-security'} src={imgSecurity[0].image} alt={"img security"} /> : ''}
                </div>
              </div>

            </div>
          </div>
        </div>
      ) : (
        
            <div className="my-5 py-5">
              <div className='text-center py-4'>
                <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('no-productos')}</p>
                <div className='gray-1-color mt-2 mb-3' dangerouslySetInnerHTML={{ __html: getTextBigByKey("aviso-sin-productos-carrito") }}></div>
                <Link to="/shop" className='btn-g px-5 py-1 mt-2'>{getTextByKey('ir-a-tienda')}</Link>
              </div>
            </div>
      )

      }

      <Modalmetodo />

      <ModalConfimacion orderId={orderId} exitoso={exitoso} showModal={showModal} setShowModal={setShowModal} datosPagoExitoso={datosOrden} />
      <ModalTarjeta showModalTarjeta={showModalTarjeta} setShowModalTarjeta={setShowModalTarjeta} />
      <Modalmetodo
        showModal={showModalMetodo}
        setShowModal={setShowModalMetodo}
        updateTarjetas={updateTarjetas}
      />
       <ModalPse
       order={order}
        showModal={showModalPse}
        setShowModal={setShowModalPse}
        setTipoPago={setTipoPago}
       
      />
      <ModalProgramarPedido msg={msg} tiendaId={tiendaId} horario={horario} showProgramar={showProgramar} setShowProgramar={setShowProgramar} setShowModalRecoger={setModalNuevaDir} />

    </Fragment>
  )
}

export default CheckoutcPage