import React from 'react'
import { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useCart } from '../context/cartContext';



export default function ModalAlertaPeso({}) {
    const [ showAlertaPeso, setShowAlertaPeso ] = useState(false);
    const { getTextByKey } = useApi();
    const { currentCart, pesoTotal, calculatePeso } = useCart();
    const [ alertaBolsa, setAlertaBolsa ] = useState(false);
    
    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowAlertaPeso(false);
        gotoTop();
    }

    
    useEffect(() => {
        calculatePeso();
      }, [currentCart]);
      
      useEffect(() => {
        if(!localStorage.getItem('alerta_bolsa')){
          if (pesoTotal > 5000 && !alertaBolsa) {
            //setShowAlertaPeso(true);
            setAlertaBolsa(true);
            localStorage.setItem('alerta_bolsa',1);
          }
       }
      }, [pesoTotal, currentCart, alertaBolsa ]);
      


    return (
        <Fragment>
            <Modal show={showAlertaPeso} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color source-sans-bold'>{getTextByKey('modal-alerta-titulo')}</p>
                        { alertaBolsa ? <p className='gray-1-color'>{getTextByKey('alerta-limite-compra')}</p> : '' }
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('entendido')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}