import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { images_products_route } from '../api/productApi';
import { getImages } from '../api/imageApi';
import Picture from './Picture';
import { useForm } from "../hooks/useForm";
import { createSuscribe } from "../api/suscribeApi";




const suscribeFormFields = {
    email: "",
    lan: "es"
}

export default function ModalLogUserComponent({ showModal, setShowModal }) {

    const { getTextByKey, extractNameFromLocate, getTextBigByKey, currentLocale } = useApi();


    const [ imgModal, setImgModal ] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getImages('img-modal-home').then((data)=> {
            setImgModal(data);
        })
    }, []);

    // const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModal(false);
        localStorage.setItem('modal-suscripcion',1);
       gotoTop();
    }


    const {
        onInputChange: onSuscribeInputChange,
        formState, emailRegex
    } = useForm(suscribeFormFields);

    const { email, conditionTerms } = formState;
    const [checkNewsletter, setCheckNewsletter] = useState( false );
    const [emailCorrect, setEmailCorrect] = useState( true );
    const [emailUserNewsletter, setEmailUserNewsletter] = useState( '' );
    const [ message, setMessage ] = useState('');
    const [ success, setSuccess ] = useState('')
    const [ isChecked, setIsChecked ] = useState(false);

    const onEmailSuscribeSubmit = async (event) => {
        event.preventDefault();
        setSuccess('');
        const validate = await validateForm(formState)
        if(!validate){
            setMessage('');
            setLoading(true);
            formState.lan = currentLocale;
            const newSuscribe = await createSuscribe(formState, setMessage,setSuccess);
            setLoading(false);
        }
    }

    const validateForm = async (formData) => {
        let errorFound = false;

        if(!email.match(emailRegex)){
            errorFound = true;
            setMessage("Email incorrecto")
        }
        if(!isChecked){
            errorFound = true;
            setMessage('Debes aceptar términos y condiciones');
        }
        return errorFound;
    }

    const handleCheckTermsCondition = () => {
        setIsChecked(!isChecked ? true : false );
    }

  

    return (
        <Fragment>
            <Modal show={showModal} onHide={handleClose} size="lg" className='modal-home'  centered>
                <Modal.Body className="p-0">
                
                    <div className="row">
                        <div className="col-md-5 d-md-block d-none p-0">
                           { imgModal.length &&
													 	<Picture src={imgModal[0].image} alt={'img-modal'} classImg={'w-100 h-100 img-modal'} /> 
                          	} 
                        </div>
                        <div className="col-md-7">
                            <div className="text-end p-3">
                                <Icon className='cursor-pointer gray-1-color close-modal' icon="material-symbols:close-rounded" onClick={handleClose}></Icon>
                            </div>
                            <div className="container pt-5">
                                { (message || success) ?
                                <div className='mb-4'>
                                    {message && <p className={`note source-sans-bold alert-danger`}>{message}</p>}
                                    {success && <p className={`note source-sans-bold alert-sucess`}>{success}</p>}    
                                </div>
                                :<></>
                                }
                                
                                <h2 className='primary-color carrefourMetis-bold'>{getTextByKey('suscribete')}</h2>
                                <h3 className='gray-1-color mb-4'>{getTextByKey('suscribete-modal')}</h3>
                                <form onSubmit={onEmailSuscribeSubmit}>
                                    <input name="email" type='email' onChange={onSuscribeInputChange} placeholder={getTextByKey('ingresa_correo')} className='p-2 primary-border-color input-border' />
                                    <button type='submit' className='btn-g py-2 w-100  mt-2 mb-3' disabled={loading}>
                                    { loading &&
                                        <img height={30} src="/img/SVG/loader.svg" alt="" />
                                    }
                                    {!loading && getTextByKey('suscribirme')}
                                    </button>
                                    <label className='container-check-s'>
                                        <small className="gray-1-color">{getTextByKey("he_leido_nuevo")} <a className="gray-1-color" target="_blank" href="/terminos-condiciones">{getTextByKey('texto-terminos-condiciones').toLowerCase()} </a> 
                       y <a className="gray-1-color" href="/aviso-de-privacidad" target="_blank"> {getTextByKey('politica_privacidad').toLowerCase()}</a></small>
                                        {/* <input
                                            type='checkbox'
                                            onChange={(event) => {
                                            setCheckNewsletter(event.target.checked);
                                            }}
                                        /> */}
                                        <input
                                            type='checkbox'
                                            name='conditionTerms'
                                            value={isChecked}
                                            onChange={handleCheckTermsCondition}
                                            checked={ isChecked }
                                        />
                                        <span className='checkmark-s'>
                                            <img src='/img/SVG/check.svg' />
                                        </span>
                                    </label>
                                </form>
                                <div className="text-center pt-3">
                                    <p className='gray-1-color text-underline cursor-pointer mb-0' onClick={handleClose}>{getTextByKey('no-gracias')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}
