import { render } from '@testing-library/react';
import React from 'react';
import { useApi } from '../context/apiContext';
import '../scss/product.scss';

export function PrecioSinDcto(precio) {

  const { convertCurrency } = useApi();

  const dct = Math.round(((precio.precio.precio_sin_descuento - precio.precio.precio) / precio.precio.precio_sin_descuento) * 100);

  let cad_dct = "";
  // let cad_dct_caja = "";

  if (dct > 0) {
    cad_dct = <span className='me-2'>
      {convertCurrency(precio.precio.precio_sin_descuento)}
    </span>
    // cad_dct_caja = <p className="d-inline-block py-1 px-2 mb-0 ">
    //   -{dct}%
    // </p>
  }
  return (
    <div>
      <p className='mb-0'>
            <span className='price-normal'>
              {cad_dct}
            </span>
            <span className='price-discount'>
              {convertCurrency(precio.precio.precio)}
            </span>
      </p>
      {/* <div>
        {cad_dct_caja}
      </div> */}
    </div>
  )

}