import sellyApi from './sellyApi'
import Product from '../classes/product'
import { getEnvVariables } from '../helpers'

export const images_products_route = getEnvVariables().IMAGES_URL_PRODUCTS;
export const images_collections_route = getEnvVariables().IMAGES_URL_COLLECTIONS;
export const images_categories_route = getEnvVariables().IMAGES_URL_CATEGORIES;


export const getRecommendedHome = async (tiendaid=null) => {
  try {
    let params='';
    if(tiendaid)
     params='?tienda='+tiendaid;
    const { data } = await sellyApi.get(`/product/recomendados_home`+params)
    return data;
  } catch (error) {
    return error;
  }
}

export const getOfertasHome = async (tiendaid=null) => {
  try {
    let params='';
    if(tiendaid)
     params='?tienda='+tiendaid;
    const { data } = await sellyApi.get(`/product/oferta`+params)
    return data;
  } catch (error) {
    return error;
  }
}

export const misFavoriteUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritos?usuario=${uidUser}`);
    return data;
  } catch (error) {
    console.log("Error listando dirección", error);
    return [];
  }
};

export const toggleFav = async (user, product) => {
  try {
    const { data } = await sellyApi.post(`/product/tooglefavorito`, JSON.stringify({
      "usuario": user,
      "producto": product,
    }));

    return data;
  } catch (error) {
    return error;
  }
};

export const favoritouser = async (uidUser, productId) => {
  try {
    const { data } = await sellyApi.get(`/product/favoritouser?usuario=${uidUser}&producto=${productId}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getNewCollections = async () => {
  try {
    const { data } = await sellyApi.get(`/collection/colecciones_nuevas`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProducts= async (categorias = "", subcategorias="", tiendaId=null) => {
  try {
    let query = "";
    console.log(categorias.length > 0,subcategorias.length > 0,'pruebaaaa');
    if(categorias!="") { query += `categorias=${categorias}` }
    if(subcategorias!="") { query += `&subcategorias=${subcategorias}` }
    if(tiendaId) { query += `&tienda=${tiendaId}` }
    console.log('tiendaId',tiendaId);
    const { data } = await sellyApi.get(`/product/products?${query}`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductsSearch= async (query = "") => {
  try {
    query = encodeURIComponent(query);
    const { data } = await sellyApi.get(`/product/products?search=${query}`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductBestSellers= async () => {
  try {
    const { data } = await sellyApi.get(`product/mas_vendidos`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getProductDetail = async (id,tiendaid=null) => {
  try {
    let params='';
    if(tiendaid){
      params='?tienda='+tiendaid;
    }
    const { data } = await sellyApi.get(`/product/detail/${id}`+params)
    return data;
  } catch (error) {
    throw error;
  }
}

export const getCombinationsById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/combinations/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getTallasById = async (id) => {
  try {
    const { data } = await sellyApi.get(`/tallas/${id}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createUser = async (body) => {
  try {
    const { data } = await sellyApi.post(`/user/create`, body)
    return data;
  } catch (error) {
    return error;
  }
}

export const getPaises = async () => {
  try {
    const { data } = await sellyApi.get(`/user/paises`)
    return data;
  } catch (error) {
    return error;
  }
}
export const getDepartamentosAll = async () => {
  try {
    let departamentos=[];
     if(localStorage.getItem('departamentos')){
       departamentos=JSON.parse(localStorage.getItem('departamentos'));
       
     }
    return departamentos;
    

   
  } catch (error) {
    return error;
  }
}

export const getCiudadesAll = async (id) => {
  try {
    let ciudades=[];
    if(localStorage.getItem('ciudades')){
      let cities=JSON.parse(localStorage.getItem('ciudades'));
      ciudades=cities.filter(ciudad=>ciudad.departamento==id);
      
    }
   
    return ciudades;
  } catch (error) {
    return error;
  }  
}
export const getDepartamentos = async () => {
  try {
    let departamentos=[];
     if(localStorage.getItem('departamentos')){
       let dptos=JSON.parse(localStorage.getItem('departamentos'));
       departamentos=dptos.filter(dpto=>dpto.tienda==true);
     }
    return departamentos;
    

   
  } catch (error) {
    return error;
  }
}

export const getCiudades = async (id) => {
  try {
    let ciudades=[];
    if(localStorage.getItem('ciudades')){
      let cities=JSON.parse(localStorage.getItem('ciudades'));
      ciudades=cities.filter(ciudad=>ciudad.tienda==true && ciudad.departamento==id);
      
    }
   
    return ciudades;
  } catch (error) {
    return error;
  }
}


export const getBarrios = async () => {
  try {
    const { data } = await sellyApi.get(`/barrio`)
    return data;
  } catch (error) {
    return error;
  }
}

export const getTiposDocumentos = async () => {
  try {
    const { data } = await sellyApi.get(`/document_type`)
    return data;
  } catch (error) {
    return error;
  }
}


export const getBancos = async () => {
  try {
    const { data } = await sellyApi.get(`/card/epayco/bancos`)
    return data;
  } catch (error) {
    return error;
  }
}

//Tarjetas
export const getTarjetas = async (uid) => {
  try {
    const { data } = await sellyApi.get(`/card?uid=${uid}`)
    return data;
  } catch (error) {
    return error;
  }
}

export const createTarjeta = async (uid,body) => {
  try {
    const { data } = await sellyApi.post(`/card/create?uid=${uid}`, body)
    return data;
  } catch (error) {
    throw error;
  }
}

export const PagarPse = async (body) => {
  try {
    const { data } = await sellyApi.post(`card/epayco/pse`, body)
    return data;
  } catch (error) {
    throw error;
  }
}

export const deleteTarjeta = async (id) => {
  try {
    const { data } = await sellyApi.delete(`/card/${id}`)
    return data;
  } catch (error) {
    throw error;
  }
}

export const validateCoupon = async (coupon,email) => {
  try {
    let emailencoded = encodeURIComponent(email);
    const { data } = await sellyApi.get(`/cart/discount?code=${coupon}&email=${emailencoded}`)
    return data;
  } catch (error) {
    throw error;
  }
}

export const createOrder = async (json) => {
  try {
    //change baseurl sellyapi
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`cart/general/create`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const pagarOrderWompi = async (json) => {
  try {
    const { data } = await sellyApi.post(`card/pagar`, json);
    return data;
  } catch (error) {
    throw error;
  }
}

export const pagarContraentrega = async (json) => {
  try {
    const { data } = await sellyApi.post(`cart/pagar-contraentrega`, json);
    return data;
  } catch (error) {
    return error;
  }
}



export const byStatus = async (id) => {
  try {
    const { data } = await sellyApi.get(`/pagos/buyStatus?transaction_id=${id}`)
    return data;
  } catch (error) {
    throw error;
  }
}

export const getBanks = async () => {
  try {
    const { data } = await sellyApi.get(`pagos/bancos`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}

export const pagarOrderPSE = async (json) => {
  try {
    // sellyApi.defaults.baseURL = getEnvVariables().API_URL_TEST;
    const { data } = await sellyApi.post(`pagos`, json);
    return data;
  } catch (error) {
    return error;
  }
}

export const getOrderPSE = async (id) => {
  try {
    const { data } = await sellyApi.get(`pagos/getStatus?transaction_id=${id}`);
    return data;
  } catch (error) {
    return error;
  }
}

//SEO
export const getSeo = async (url) => {
  try {
    if(url === "/") url = "home";
    //delete only first slash
    url = url.replace(/^\/+/g, '');
    const { data } = await sellyApi.get(`seo/getSeo?url=${url}`).catch((error) => false);
    return data;
  } catch (error) {
    return error;
  }
}

export const getInventario = async (id, tienda_id) => {
  try {
    const { data } = await sellyApi.get(`inventario/inventario_producto?producto=${id}&tienda=${tienda_id}`);
    return data;
  } catch (error) {
    return error;
   }
}

export const getRelacionados = async (id,tiendaid=null) => {
  try {
    let params='';
    if(tiendaid)
     params='?tienda='+tiendaid;
    const { data } = await sellyApi.get(`/product/relacionados_producto/${id}`+params);
    return data;
  } catch (error) {
    return error;
   }
}

export const getRelacionadosProducto= async (productos = "", tiendaId=null) => {
  try {
    let query = "";
    if(productos.length > 0) { query += `productos=${productos}` }
    if(tiendaId) { query += `&tienda=${tiendaId}` }
    const { data } = await sellyApi.get(`/product/relations_products?${query}`).catch((error) => {
      return [];
    })
    return data;
  } catch (error) {
    return error;
  }
}


export const getReviews = async (id) => {
  try {
    const data = await sellyApi.get(`/producto_reviews/${id}`);
    return data.data;
  } catch (error) {
    throw error;
  }
}

export const createReview = async ({title = '', comment = '', score = '', productPlu, name = '', email = '', userUid = ''}) => {
  try {
    const data = await sellyApi.post(`/producto_reviews/`, {title, comment, score, productPlu, name, email, userUid});
    return data.data;
  } catch (error) {
    return error;
  }
}


export const ActualizaTecnocarnes = async (tienda_id) => {
  try {
    const { data } = await sellyApi.get(`/conexion/actualiza-tecnocarnes?tienda=${tienda_id}`);
    return data;
  } catch (error) {
    return error;
   }
}


export default {
  getRecommendedHome,
  getOfertasHome,
  getNewCollections,
  getProducts,
  getProductBestSellers,
  getProductDetail,
  getCombinationsById,
  getTallasById,
  createUser,
  getPaises,
  getTarjetas,
  createTarjeta,
  deleteTarjeta,
  toggleFav,
  validateCoupon,
  images_products_route,
  getInventario,
  getRelacionados,
  ActualizaTecnocarnes
}
