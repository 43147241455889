import React from 'react'
import { Icon } from '@iconify/react'
import '../scss/carrito.scss'
import ItemCarrito from './ItemCarrito'
import QuantyControls from './QuantyControls'
import { Link, NavLink } from 'react-router-dom'
import { useCart } from "../context/cartContext";
import { useEffect, useState } from 'react';
import { useApi } from "../context/apiContext";
import { useAuth } from '../authContext'
import CheckoutFastPage from '../pages/CheckoutPage/CheckoutFastPage'

export default function CarritoComponent() {

    const { getTextByKey, convertCurrency, address, cards } = useApi();
    const { currentCart, currentAddCart, cartIsShowing, 
        hideCart, total, envio, impuesto, impuestoUltra, impuestoBolsa, descuento, 
        calculateDescuento, calculateImpt,calculateImptUltra, pesoTotal,calculateSubtotal, subtotal, pesoXbolsa, calculateTotal,tiendaGeneral, 
        coupon, updateCoupon,updateTope,tope ,pesoMaxCompra} = useCart();
    const { currentUser } = useAuth();

    const [oneClick, setOneClick] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [cardss, setCardss] = useState([]);
    const [ bolsa, setBolsa ] = useState(1);
    

    const closeCart = event => {
        hideCart();
        /* toggleCarrito(); */
    };

    useEffect(() => {
        if(pesoTotal>pesoMaxCompra){
            updateTope(1);
        }else{
            updateTope(0);
        }
    
        calculateSubtotal();
        calculateTotal();
        calculateImpt();
        calculateImptUltra();
        calculateDescuento();
    }, [tiendaGeneral,currentCart, cartIsShowing]);

    

    /* useEffect(() => {
       
        const handleOutsideClick = (event) => {
          const carritoElement = document.getElementById('carrito-rapido');
          if (carritoElement && !carritoElement.contains(event.target)) {
                hideCart(); 
          }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
    
         return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []); */


    useEffect(()=>{
        if( pesoTotal == 0 || pesoTotal == null ){
            setBolsa(1);
          }else{
            setBolsa(Math.ceil(pesoTotal/pesoXbolsa));
          }
    },[bolsa, pesoTotal, pesoXbolsa]);

    /*useEffect(()=>{
        console.log("actualizando carrito");
    }, [currentCart, cartIsShowing])*/


    /* useEffect(() => {
        console.log('currentAddCart', currentAddCart);
        if (currentAddCart != 0) {
            alert('¡Producto agregado al carrito!');
        }
    }, [currentAddCart]); */

    return (
        cartIsShowing && (
            <div className='carrito-rapido py-2 py-md-3 ps-2 ps-md-3 pe-2 show animate__animated animate__fadeInDown' id="carrito-rapido">
                <div className="flecha">
                    <img src="/img/Polygon.png" alt="polygon" />
                </div>
                <div className="text-end p-xl-2 p-3">
                    <a className='close gray-1-color' onClick={closeCart}><Icon icon="carbon:close" /></a>
                </div>
                <div className={!oneClick ? 'content-carrito' : 'content-carrito min'}>
                    {currentCart.map((item, index) => (
                        <ItemCarrito key={index} index={index} item={item} min={oneClick} />
                    ))}
                    <div className='text-center'>
                        {currentCart.length === 0 && <p className='primary-color source-sans-bold'>{getTextByKey('sin_productos')}</p>}
                    </div>
                </div>
                {
                    currentCart.length > 0 && (
                        <div className='container pt-3 pb-2 px-0'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('subtotal')}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency(subtotal.toFixed(0))}</p>
                                </div>
                                  {descuento!=0 &&<div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('descuento')}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency(descuento)}</p>
                                </div> }
                                <div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('impuestos')}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency(impuesto.toFixed(0))}</p>
                                </div>
                                { impuestoUltra!=0 ? 
                                <div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('impuesto-ultraprocesado')}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency(impuestoUltra.toFixed(0))}</p>
                                </div>
                                :<></>}
                                <div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('impt-bolsa')} x {bolsa}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency((impuestoBolsa)*(bolsa))}</p>
                                </div>
                                <div className='col-12 d-flex justify-content-between align-items-center border-bottom py-1'>
                                    <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('envio')}</p>
                                    <p className='source-sans gray-1-color mb-0'>{convertCurrency(envio)}</p>
                                </div>
                              
                                <div className='col-12 d-flex justify-content-between align-items-center py-2'>
                                    <p className='fw-bold source-sans-bold primary-color mb-0'>{getTextByKey('resumen_carrito')}</p>
                                    <p className='source-sans primary-color mb-0'>{getTextByKey('total')} {convertCurrency(total-descuento + envio  + ((impuestoBolsa)*(bolsa)))}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                {!oneClick && (
                    <>
                        <div className='text-center'>
                            {
                                currentCart.length > 0 && 
                                <NavLink to="/checkout-b" onClick={closeCart} className='btn-g py-2 px-5'>
                                    <div className='d-flex align-items-center'>
                                        <Icon className='white-color me-2' icon="mdi:cart-outline"/>
                                        {getTextByKey('ver_carrito')}
                                    </div>
                                </NavLink>
                            }
                        </div>
                        {/* {
                            currentCart.length > 0 && (
                                <>
                                    {(currentUser && address.length > 0 && cards.length > 0) &&
                                        <div className='d-flex mt-2 justify-content-between align-items-center'>
                                            <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer' onClick={() => setOneClick(true)}>Pagar a un click</div>
                                        </div>
                                    }
                                </>
                            )
                        } */}
                    </>
                )}
                {oneClick && (
                    <>
                        <CheckoutFastPage></CheckoutFastPage>
                    </>
                )}
            </div>
        )
    )
}
