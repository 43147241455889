import sellyApi from './sellyApi'

export const getSearchResult = async (search, locale = null,tienda=null) => {
  try {
    let params='';
    if(tienda){
      params='?tienda='+  tienda;
    }
    const data = await sellyApi.get(`/search/${encodeURIComponent(search)}${params}`)
    return data.data;
  } catch (error) {
    return error;
  }
}