import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export default function ProductPreOrderComponent() {
    return (
        <Fragment>
            {
                products && products.map( product => {
                    return(
                        <div className='col-6 col-md-4 col-lg-3'>
                            <div className='item-preorder' key={ product.id }>
                                <img className='w-100 mb-4' src={ product.img }/>
                                <h3 className='fw-bold text-uppercase'>{ product.nameProduct }</h3>
                                <h3 className='text-uppercase'>${ product.price }</h3>
                                {/* <div className='text-center py-4'>
                                    <Link to="/" className='btn-g bb py-2 px-3 text-uppercase'>WAITLIST</Link>
                                </div> */}
                            </div>
                        </div>
                    )
                } )
            }
        </Fragment>
    )
}
