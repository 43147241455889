import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toggleFav, favoritouser } from "../api/productApi";
import "../scss/product.scss";
import { useAuth } from "../authContext";
import { useApi } from "../context/apiContext";
import ModalLogUserComponent from "./ModalLogUserComponent";

const FavoriteClick = ({ product }) => {

  const [isFav, setIsFav] = useState(false);

  const { currentUser } = useAuth();

  const { updateFavs , currentFav} = useApi();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
  if (product) {
      let findited = currentFav.find((x) => x.plu === product.plu);
      if (findited) {
          setIsFav(true);
      }
  }
  }, [product]);


  const press = (product) => {
    console.log("press", product);
    if(product){
      if(currentUser){
        setLoading(true);
        toggleFav(currentUser.uid, product.plu).then((data) => {
          setLoading(false);
          if (data.code === 201) {
            setIsFav(true);
          } else {
            setIsFav(false)
          }
          updateFavs();
        });
      }else{
        handleShowModal();
      }
    }
  };

  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <a
        className='Fav position-absolute primary-color'
        onClick={() => {
          press(product);
        }}
      >
        {!loading ? (
          <>
            {!isFav ? <Icon icon='akar-icons:heart' /> : <Icon icon='ant-design:heart-filled' style={{ color: "black" }} />}
          </>
        ) : (
          <div className='spinner-border spinner-border-sm' role='status'></div>
        )}
      </a>
      <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal}/>
    </>
  );
};

export default FavoriteClick;