import GifLoader from "react-gif-loader";
import React, { Component } from "react";
import { useApi } from "../context/apiContext";
import { useEffect } from "react";
import ModalInicio from "./modalesRecibirPedido/ModalInicio";
import ModalCompra from "./modalesRecibirPedido/ModalCompra";
import ModalAlertaTope from "./ModalAlertaTope";


export default function Modals() {

    const { modalInicio, modalCompra, setModalInicio, setModalCompra, showAlertaTope, setShowAlertaTope } = useApi();

    return (
        <>
          <ModalInicio showModalInicio={modalInicio} setShowModalInicio={setModalInicio}  />
          <ModalCompra showModalCompra={modalCompra} setShowModalCompra={setModalCompra} />
          <ModalAlertaTope showAlertaTope={showAlertaTope} setShowAlertaTope={setShowAlertaTope} />
        </>
    )
}