import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { setDefaultAddress, deleteAddress } from "../../../api/addressApi";
import { useApi } from "../../../context/apiContext";
import { ModalEditarDireccionComponent } from "./ModalEditarDireccionComponent";
import { ModalEliminarDireccionComponent } from "./ModalEliminarDireccionComponent";
import { default as ModalConfirmarEntrega } from '../../../components/modalesRecibirPedido/ModalConfirmarEntrega';
import { getInfoMap } from "../../../api/mapApi";
import { getKml } from '../../../api/storeApi';
import { useCart } from '../../../context/cartContext';

import PropsTypes from "prop-types";
import { ActualizaTecnocarnes } from "../../../api/productApi";

export default function DireccionComponent({ currentUser, direccion }) {
    const [disableButton, setDisableButton] = useState(false);
    const [ direccionId, setDireccionId ] = useState([]);
    const [ modalEliminar, setModalEliminar ] = useState(false);
	const { updateTienda,updateCurrentdelivery,tiendaGeneral,validarCart, validarCartOld, currentCart,updateDireccion} = useCart();
	const [ modalConfirmar, setModalConfirmar ] = useState(false);
	const [ store, setStore ] = useState(0);
	
	const [ error, setError ] = useState('');
    const [ disponible, setDisponible ] = useState(0);


    const handleSetDefaultAddress =async (event) => {
		setError('');
        event.preventDefault();
        setDisableButton(true);
		let tienda= await getKml(direccion.lat, direccion.lng) 
		if(tienda.id){
			let tecnocarnes=await ActualizaTecnocarnes(tienda.id);
			console.log(tecnocarnes,'tecnocarnes');
			updateTienda(tienda.id);
			setStore(tienda.id);
			updateDireccion(direccion.id);
			setDireccionId(direccion.id);
			updateCurrentdelivery({tipo:'', dir: {id:direccion.id}});
			let predeterminada =await setDefaultAddress(currentUser.uid, direccion.id, setDisableButton);
			setDisableButton(false);
			console.log(currentCart, currentCart.length);
			try{
				
				if(currentCart.length>0){
					let carritoajustar =await validarCart(tienda.id);
					if(carritoajustar.ok){
						setDisponible(carritoajustar.total);
						setModalConfirmar(true);
						//location.href='/profile-address';
					}
				}else{
					let carritoajustar =await validarCartOld(tienda.id);
					if(carritoajustar.ok){
						setDisponible(carritoajustar.total);
						setModalConfirmar(true);
						//location.href='/profile-address';
					}
				}
			} catch (error) {
				console.error(error);
				setDisableButton(false);
			}
		}else{
			setError('En este momento no contamos con cobertura en tu dirección. Te invitamos a montar tu pedido y recoger en la tienda más cercana');
			setDisableButton(false);
			setDireccionId(0);
		}
		
    };

    const handleModalEliminar = (event) => {
        event.preventDefault();
        setDireccionId(direccion.id);
        setModalEliminar(true);
    }

                 

    return (
        <div className="col-12">
					<div className="mb-4 mb-xl-5 primary-border-color rounded p-3">
						<div className="d-flex align-items-start justify-content-between">
								<div>
										<h4 className="source-sans-bold mb-0">{direccion.alias}</h4>
										{direccion.predeterminada ? 
												<p className="source-sans-semibold">Dirección actual</p>
												: null
										}
								</div>
								{/*<a onClick={(event)=>handleModalEliminar(event, direccion.id)}>
										<img src="/img/SVG/trash-pink.svg" alt="Trash icon" />
									</a>*/}
						</div>

						<div className="row">
								<div className="col-12 col-lg-4">
									<p classsName="mb-0 gray-1-color source-sans-bold">Ciudad:</p>
									<p classsName="gray-1-color mb-0">{direccion.ciudades[0].nombre} </p>
								</div>
								<div className="col-12 col-lg-4">
									<p classsName="gray-1-color mb-0 source-sans-semibold">Dirección: </p>
									<p classsName="gray-1-color mb-0 ">{direccion.address}</p>
								</div>
								<div className="col-12 col-lg-4">
									<p classsName="gray-1-color mb-0 source-sans-semibold">Complemento:</p>
									<p classsName="gray-1-color mb-0 source-sans-semibold">{direccion.comentarios}</p>
								</div>
						</div>
						
						<button className={`btn-g py-1 px-2 ${!direccion.predeterminada ? (direccion.id==direccionId) ? '' :'bb inactive' : ''}`} onClick={handleSetDefaultAddress} disabled={disableButton}>
								{disableButton ? (<div className='spinner-border spinner-border-sm' role='status'></div>): 'Predeterminada'}
						</button>
						{error && <p className="alert-danger">{error}</p> }
    
            <ModalEliminarDireccionComponent direccionId={direccionId} modalEliminar={modalEliminar} setModalEliminar={setModalEliminar} setDisableButton={setDisableButton} />
			<ModalConfirmarEntrega disponible={disponible} modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/> 

		</div>
        </div>
	    );
}

DireccionComponent.propTypes = {
    direccion: PropsTypes.object.isRequired,
    //nameUser: PropsTypes.string.isRequired,
};
