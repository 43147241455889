import React, {useState, useEffect} from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import { useCart } from '../../context/cartContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { getRelacionadosProducto } from "../../api/productApi";
import { getInventario } from '../../api/productApi';


export default function ModalConfirmarEntrega({ modalConfirmar, disponible,setModalConfirmar, msg }) {

    const { getTextByKey, getTextBigByKey } = useApi();
    const { addToCart, addSeveralToCart, showCart, currentProduct,getNodisponibles,currentNoDisponible,tiendaGeneral} = useCart();
    const [ relacionados, setRelacionados ] = useState('');
	const [ loading, setloading ] = useState(true);
	const [ existerelacion, setExisterelacion ] = useState(true);

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setModalConfirmar(false);
        gotoTop();
    }

    const handleShop = () => {
		/*console.log("currentProduct",currentProduct)
			if(currentProduct){
				currentProduct.forEach(async (item)=>{
					let forend = await addToCart(item.product, item.quantity);
					if(forend) showCart();
				});
				
			}*/
			setModalConfirmar(false);
    }
    
		useEffect(() => {
			
			/*if (msg) {
				if (msg.response && msg.response.status === 400) {
					setDisponible( false);
					console.log('no existe');
				} else {
					setDisponible( true);
					console.log('existe');
				}
			}*/
			//console.log('current',CurrentDelivery);
		}, [msg]);

		useEffect(() => {
			setloading(true);
			const products= getNodisponibles();
			console.log('no disponibles',products);
			const relacionados= [];

			products?.forEach((prod) => {
				relacionados.push(prod?.plu);
			  });

			  const relacionadosString = relacionados.join(',');
			  setRelacionados(relacionadosString);

			  getRelacionadosProducto(relacionadosString,tiendaGeneral).then((data) => {
				if (data) {
					setloading(false);
					const prods=data.filter(x => x.cantidad >3);
					if(prods.length>0){
						setExisterelacion(true);
					}else{
						setExisterelacion(false);
					}
			   } else {
				setloading(false);
				setExisterelacion(false);
			   }
			 });
			  
		}, [currentNoDisponible]);
		
  
    return (
        <Fragment>
            <Modal className="modal-compra" show={modalConfirmar} onHide={handleClose}  centered>
                <Modal.Body>
										
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div> 
					{!loading ?
					<>
							{ disponible!=0 && (
									<div className="container">
										<div className="text-center">
											<h4 className='gray-1-color'>{getTextByKey('no-disponible-entrega')}</h4>
											{existerelacion ?
												<div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-producto-no-disponible") }}></div>
												: 
												<div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("alerta-producto-no-hay-disponible") }}></div>
											}
										</div> 
										<div className="text-center pt-3">
										{existerelacion ?
											<Link to={`/shop?relacion=${relacionados}`} className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('productos-similares')}</Link>
										:
											<Link to='/shop' className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('volver-tienda')}</Link>
										}
										</div>
									</div>
									)}
									{ disponible==0 && (
									<div className="container">
										<div className="text-center">
											<h4 className='gray-1-color'>{getTextByKey('confirmacion-metodo-entrega')}</h4>
											<p className='gray-1-color'>{getTextByKey('hemos-guardado')}</p>
											<div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-confirmacion-texto") }}></div>
										</div> 
										<div className="text-center pt-3">
												<button className='btn-g px-4' onClick={handleShop}>{getTextByKey('aceptar')}</button>
										</div>
									</div> )} 
						    </>
                           :<>
						   <div className='container'>
							<div className="text-center-c">
								<div className="loader-box">
								<img src="/img/SVG/loader.svg" alt="" />
								</div>
							</div>
					        </div>
						   </>}

                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}