export const getEnvVariables = () => {
  return {
    API_URL: 'https://api.cervalle.iridian.co/api',
    API_URL_ALT: 'https://api.cervalle.iridian.co/api',
    API_URL_TEST: 'https://127.0.0.1:8000/api',
    TokenAPI: '123456fabian',
    TOKEN:'cf0041900cce4ee40faf4aeace411c5957656db59d146cbbd59017f27d33c02e',
    IMAGES_URL_PRODUCTS: "https://d1gvlij56ll33n.cloudfront.net/products/",
    IMAGES_URL_COLLECTIONS: "https://d1gvlij56ll33n.cloudfront.net/collections/",
    IMAGES_URL_CATEGORIES: "https://d1gvlij56ll33n.cloudfront.net/category/",
    GOOGLE_API_KEY: 'AIzaSyAtqdt87lNSboSM8lX3CXMxSx5S8nkOMpE'
  }
}
