import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { App } from './App';
import { Header } from './components/header';
import './App.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

// ReactDOM.render(<App/>, document.getElementById('root'));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);