import React from 'react';
import { useState } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../authContext';
import { useCart } from '../../context/cartContext';
import { listAddressUser } from '../../api/addressApi';
import { default as ModalCompraRecoger } from './ModalCompraRecoger';
import { default as ModalDireccionEnvio } from './ModalDireccionEnvio';
import { useEffect } from 'react';



export default function ModalCompra({ showModalCompra, setShowModalCompra }) {

    const { getTextByKey, getTextBigByKey } = useApi();
    const [showModalRecoger, setShowModalRecoger] = useState(false);
    const [showModalDireccion, setShowModalDireccion ] = useState(false);
    const [ addressList, setAddressList ] = useState([]);
     const { currentUser } = useAuth();
    const [ modalNuevaDireccion, setModalNuevaDireccion ] = useState(false);

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModalCompra(false);
        gotoTop();
    }

    const handleOpenModal = () => {
      setShowModalCompra(false);
      setShowModalRecoger(true);
    }

    const getAddresses = async () => {
      if(currentUser){
       let direcciones= await listAddressUser(currentUser.uid);
          setAddressList(direcciones);
      }
      return true;
    }

    useEffect(() => {
      getAddresses();
    }, [showModalCompra]);

    const handleOpenEnvio = async() => {

      if(currentUser.isAnonymous){
        setShowModalCompra(false);
        setShowModalDireccion(false);
        setModalNuevaDireccion(true);
      }else{
        let dir= await getAddresses();
        if(addressList.length>0){
          setShowModalCompra(false);
          setShowModalDireccion(true);
          setModalNuevaDireccion(false);
        }else{
          setShowModalCompra(false);
          setShowModalDireccion(false);
          setModalNuevaDireccion(true);
        }
      }
      
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalCompra} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-color' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                      <h4 className='gray-1-color'>{getTextByKey('modal-compra-titulo')}</h4>
                    </div>
                    <div className="content-opt d-md-flex justify-content-center d-block py-3">

                      <div className='wp-opt'>
                        <div className="box-opt secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0">
                          <div className="d-flex justify-content-start align-items-center title-card">
                            <div className='icon-compra primary-color d-flex align-items-center'>
                              <Icon icon="iconoir:small-shop"></Icon>
                            </div>
                            <p className='primary-color ms-2 mb-0'>{getTextByKey('modal-compra-recoge')}</p>
                          </div>
                          <div className="text-opt d-flex align-items-center gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-compra-recoger-texto") }}></div>
                          <div className="text-center">
                            <button className='btn-g px-4' onClick={() => handleOpenModal('pickup')}>{getTextByKey('seleccionar')}</button>
                          </div>
                        </div>
                      </div>

                      <div className="wp-opt">
                        <div className='box-opt secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0'>
                          <div className="d-flex justify-content-start align-items-center title-card">
                            <div className='icon-compra primary-color d-flex align-items-center'>
                              <Icon icon="bi:truck"></Icon>
                            </div>
                            <p className='primary-color ms-2 mb-0'>{getTextByKey('modal-compra-envio')}</p>
                          </div>
                          <div className="text-opt d-flex align-items-center gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-compra-envio-texto") }}></div>
                          <div className="text-center">
                            <button className='btn-g px-4' onClick={() => handleOpenEnvio('delivery')}>{getTextByKey('seleccionar')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  
                </Modal.Body>
            </Modal>      
            <ModalCompraRecoger showModalRecoger={showModalRecoger} setShowModalRecoger={setShowModalRecoger} setShowModalCompra={setShowModalCompra} />
            <ModalDireccionEnvio showModalDireccion={showModalDireccion} setShowModalDireccion={ setShowModalDireccion} 
            setShowModalCompra={setShowModalCompra} addressList={addressList} modalNuevaDireccion={modalNuevaDireccion} setModalNuevaDireccion={setModalNuevaDireccion} ></ModalDireccionEnvio>
        </Fragment>
    )
}
