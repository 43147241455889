import React, {useEffect, useState} from 'react'
import ModalAlerta from './ModalAlerta';
import { useCart } from "../context/cartContext";
import { useApi } from '../context/apiContext';

export function QuantyControls ({quantity, setQuantity ,agrega=true,product,onQuantityChange}) {

  const [ limiteDetected, setLimiteDetected ] = useState(false);
  const {setShowAlertaTope  } = useApi();
  const { setCartQuantityValue, pesoTotal, currentCart,tope,updateTope,agotado,limite,limitePromo,peso,pesoMaxCompra} = useCart();
  const [ limiteProductos, setLimiteProductos ] = useState(false);
  const [ limiteInventario, setLimiteInventario ] = useState(false);

  const handleIncrement = () => {
    const cantidadagregando = quantity+1;
    console.log("cantidad",cantidadagregando,pesoTotal,limite,product.cantidad)
    let peso = 0;
    if(!agrega){ //vista de producto donde el incrementar no agrega directamente
      peso=product.peso*(cantidadagregando);
    }else{
      peso = product.peso;
    }
    peso=peso+pesoTotal;
    if(peso>pesoMaxCompra){
      updateTope(1);
      setShowAlertaTope(true);
      return;
    }

    let cantidad = cantidadagregando;
    //la cantidad debe ser la cantidad acumulada de ese producto
    if(!agrega){
      let index = currentCart.findIndex((item) => item.id == product.plu);
      if(index >= 0){
        cantidad=currentCart[index].quantity+(parseInt(cantidadagregando) ? parseInt(cantidadagregando) : 1);
      }
    }
    if(product.cantidad -cantidad <agotado){
      setCartQuantityValue(product.cantidad-agotado);
      setLimiteInventario(true);
      return;
    }
    if( cantidad > product.cantidad ){//validar inventario
      if(agrega){
        setCartQuantityValue(product.cantidad);
        setLimiteInventario(true);
        return;
      }else{
        //setQuantity(product.cantidad);
        setLimiteInventario(true);
        return;
      }
    }
   
    if(product.price.precio_sin_descuento){
      if(cantidadagregando <= limitePromo){
        if(agrega){
         setCartQuantityValue(product,quantity + 1);
        }else{
          setQuantity(quantity + 1);
        } 
      }
      if(cantidadagregando === limitePromo){
        setLimiteDetected(true);
      }
    }else{
      if(cantidadagregando <= limite){
        if(agrega){
         setCartQuantityValue(product,quantity + 1);
        }else{
          setQuantity(quantity + 1);
        } 
      }
      if(quantity === limite){
        setLimiteProductos(true);
      }
    }

  };

  const handleDecrement = () => {
    let peso=product.peso*quantity;
    peso=peso+pesoTotal;
      if (quantity > 1) {
        if(agrega){
        setCartQuantityValue(product,quantity -1);
        }else{
          setQuantity(quantity - 1);
          if(peso<pesoMaxCompra){
            updateTope(0);
              
          }
        } 
        setLimiteInventario(false);
     
    }
  };
  
  useEffect(()=>{
      
    if(pesoTotal>pesoMaxCompra){
       
        if(!localStorage.getItem('alerta-tope')){
            setShowAlertaTope(true);
            localStorage.setItem('alerta-tope',1);
        }
       
        updateTope(1);
    }else{
        updateTope(0);
    }

},[currentCart,pesoTotal])
  
  return (
    <>
     <div className='controls-quantity'>
      <button type='button' className='control control-minus'  onClick={handleDecrement}>
        <img src="/img/SVG/minus-pink.svg" alt="Minus icon" className='icon' />
      </button>
      <input type="number" readOnly value={quantity} />
      <button disabled={tope} type='button' className='control control-plus'  onClick={handleIncrement}>
        <img src="/img/SVG/plus-pink.svg" alt="Plus icon" className='icon' />
      </button>
    </div>
    <ModalAlerta limiteProductos={limiteProductos} limiteInventario={limiteInventario} product={product} limiteDetected={limiteDetected} />
    </>

  )
}

export default QuantyControls
