import React from 'react';
import { NavLink, Router } from 'react-router-dom';
import { useApi } from "../../context/apiContext";

export const CheckoutaPage = () => {
  
  const { getTextByKey } = useApi();

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <div className="p-5 secundary-bg-color text-center">
            <h2 className='text-uppercase'>
              <span className='mb-3'>{getTextByKey('iniciar-sesion')}</span><br />
              <span className='mb-3'>{getTextByKey('checkout-rapido')}</span>
            </h2>
            <form action="">
                <div className="form-group mb-2">
                  <label className="my-1 text-uppercase d-flex justify-content-center align-items-center gap-2">{getTextByKey('remember')}<input className="p-2" type="checkbox"/></label>
                </div>
            </form>
            <div className="text-center py-4">
              <NavLink to="/login" className="btn-g primary-bg-color py-2 px-3 text-uppercase">{getTextByKey('iniciar-sesion')}</NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6">
        <div className="p-5 secundary-bg-color text-center">
            <h2 className='text-uppercase'>
              {getTextByKey('ingresar-invitado')}
            </h2>
            <p className='text-uppercase py-3'>{getTextByKey('ingreso-registro-despues')}</p>
            <div className="text-center py-4">
              <NavLink to="/checkout-b" className="btn-g primary-bg-color py-2 px-3 text-uppercase">{getTextByKey('continuar-invitado')}</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
