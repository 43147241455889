import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { auth } from '../../../firebaseConfig';
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../../../context/apiContext';

export function ModalSatisfactoryResponseSurveyComponent({show,setShow}) {

    const { getTextByKey, getTextBigByKey } = useApi();

    // const [email, setEmail] = React.useState('');

    // const onClickDelete = () => {
    //     deleteUser(profileInfo).then((response) => {
    //         handleLogout();
    //     });
    // }

    // //Logout Event
    // const handleLogout = async () => {
    //     try {
    //         await auth.signOut();
    //         location.href = "/";
    //     } catch {
    //     }
    // };

    // useEffect (() => {
    //     if(profileInfo){
    //         setEmail(profileInfo.email);
    //     }
    // }, [profileInfo]);
    const handleClose = () => {
        setShow(false);
    }

    return (
        <Fragment>
             <Modal show={show} onHide={handleClose}  centered>
                <Modal.Body>
                    <div show={show} className=" gray-1-color" id="satisfactoryResponseSurveyModal" tabIndex="-1" aria-labelledby="satisfactoryResponseSurveyModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-center border-0">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                                </div>
                                <div className="modal-body py-0 border-0">
                                    <div className="text-center">
                                        <h3 className="carrefour-sans-bold mb-3">{getTextByKey('titulo-encuesta-enviada-satisfactoriamente')}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: getTextBigByKey("texto-encuesta-enviada-satisfactoriamente") }}></div>
                                        <div class="modal-footer justify-content-center border-0">
                                            <button type="button" className="btn-g py-2 px-5" onClick={handleClose}>Aceptar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>    
        </Fragment>
    )
}