import { Icon } from '@iconify/react';
import { getAllByText } from '@testing-library/react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import LoaderComponent from "../../../components/LoaderComponent";

import { NavLink, useNavigate } from 'react-router-dom'
import { listAddressUser } from '../../../api/addressApi';
import { useAuth } from '../../../authContext';
import { useApi } from "../../../context/apiContext";
import { useCart } from '../../../context/cartContext';
import { getKml } from '../../../api/storeApi';
import { Fragment } from 'react';
import { ActualizaTecnocarnes } from '../../../api/productApi';

export default function SelectDireccionComponent({ step }) {
  const { getTextByKey, currentLocale } = useApi();
  const [addressList, setAddressList] = useState([]);
  const { currentUser } = useAuth();
  const { updateDireccion, direccion ,updateTienda,updateCurrentdelivery,validarCart, validarCartOld,currentCart} = useCart();
  const [ predAddress, setPredAddress ] = useState([]);
  const [ error, setError ] = useState('');
  const navigate = useNavigate();
  const [ loader, setLoader ] = useState(false);


  const selectAddress = async(event) => {
     event.preventDefault();
       setError('');
       setLoader(true);
      if(event.target.value==0){
        setError('Escoge una dirección');
        setLoader(false);
        return;
      }
      
      let direlegida= addressList.filter((dir)=>dir.id==event.target.value);
      let dir=direlegida[0];
      if(dir.id){
        let tienda= await getKml(dir.lat, dir.lng) 
        if(tienda.id){
          let tecnocarnes=await ActualizaTecnocarnes(tienda.id);
          console.log(tecnocarnes,'tecnocarnes');
           updateTienda(tienda.id);
          updateCurrentdelivery({tipo:'', dir: {id:dir.id}});
          updateDireccion(dir.id);
          console.log(currentCart, currentCart.length);
         
          try{
           
            if(currentCart.length>0){
              let carritoajustar =await validarCart(tienda.id);
              if(carritoajustar.ok){
                //setDisponible(carritoajustar.total);
                //setModalConfirmar(true);
                setLoader(false);
              }
            }else{
              let carritoajustar =await validarCartOld(tienda.id);
              if(carritoajustar.ok){
                //setDisponible(carritoajustar.total);
                //setModalConfirmar(true);
                setLoader(false);
              } 
            }
            } catch (error) {
            
            console.error(error);
            setLoader(false); 
            }
        
        }else{
          setLoader(false);
         setError('No tiene cobertura');
        }
      }else{
        setLoader(false);
        setError('No tiene dirección');
      }
   
  }

  useEffect (() => {
        
         async function getListAddress() {
          setLoader(true);
          try {
              const listAddressResponse = await listAddressUser(currentUser.uid);
               setAddressList( listAddressResponse )
               setPredAddress(listAddressResponse.find(address => address.predeterminada === true));
               setLoader(false);
          } catch (error) {
              console.log('Error: ', error)
          }
      }
      getListAddress();

  }, [])

  useEffect(() => {
    if(!direccion && predAddress){
      updateDireccion(predAddress.id);
    }
  }, [direccion]);

  return (
     <Fragment>
      <LoaderComponent load={loader}/>
   
    <div className='select-direccion primary-border-color rounded p-2 position-relative'>
        { addressList ? 
          <div className='form-group'>
          <select className={step==3 ? 'px-2 py-1 select-only-read w-100' : 'px-2 py-1'} onChange={selectAddress} value={direccion} >
          <option value='0' >{currentLocale ==='es' ? 'Seleccionar' : 'Select'}</option>
            {/* predAddress ? 
               <option value={predAddress.id} disabled selected>Dirección predeterminada: {predAddress.address}</option> :
               <option value='0' disabled selected>{currentLocale ==='es' ? 'Seleccionar' : 'Select'}</option>
        */}
            {addressList.map((address, index) => (
              <option key={index} value={address.id}>{address.address}</option>
            ))}
          </select>
          { error && <p className='alert-danger'>{error}</p>}
          
          </div>
          : 
          <div>
            No tienes direcciones guardadas
          </div>
        }
        {step !== 3 ? (
        <div className="form-group">
          {direccion == null ? (
            <div className='btn-g bb py-2 px-3' role="button">{getTextByKey('select-address')}</div>
          ) : (
            <NavLink to="/checkout-c" className="btn-g bb py-2 px-3">{getTextByKey('enviar-direccion')}</NavLink>
          )}
        </div>
        ) : null }
    </div>
    </Fragment>
  )
}
