import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';




export default function ModalPqrsMandado({ showMsg, setShowMsg, text, onResetForm, error }) {

    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowMsg(false);
        gotoTop();
    }

    const handleConfirm = () => {
        setShowMsg(false);
        if(!error){
            onResetForm();
        }
    }
     

    return (
        <Fragment>
            <Modal show={showMsg} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="text-end pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color source-sans-bold'>{text ? text.main : getTextByKey('pqrs-mandado')}</p>
                        <p className='gray-1-color'>{text ? text.sub : getTextByKey('pqrs-mandado-sub')}</p>
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleConfirm}>{getTextByKey('entendido')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}