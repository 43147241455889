import sellyApi from './sellyApi'

export const createAddress = async (inputData) => {
  try {
    const { data } = await sellyApi.post('/address', inputData)
    return data;
  } catch (error) {
   throw error
  }
}

export const listAddressUser = async (uidUser) => {
  try {
    const { data } = await sellyApi.get(`/address?uid=${uidUser}`);
    return data;
  } catch (error) {
    return [];
  }
}

export const deleteAddress = async (addressId, setDisableButton) => {
  try {
    const { data } = await sellyApi.delete(`/address/${addressId}`)
    return data;
  } catch (error) {
    return error;
    //console.log('Error eliminando dirección', error)
  }
}

export const setDefaultAddress = async (uid, addressId, setDisableButton) => {
  try {
    const { data } = await sellyApi.put(`/address/default?uid=${uid}&address=${addressId}`)
    return data;
  } catch (error) {
    return error;
  }
}

export default { createAddress, deleteAddress, listAddressUser, setDefaultAddress }
