import { Fragment } from "react";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";

import '../../scss/404page.scss';

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


export default function Error404Page() {
    return(
        <Fragment>
            <div className="error-404-page my-5">
                <div className="container">
                    <div className="text-center">
                        <span className="primary-color carrefourMetis-bold title-error mb-2">404</span>
                        <span className="gray-1-color carrefourMetis-bold text-ops mb-2">Ooops...</span>
                        <span className="gray-1-color source-sans-light page-not-found mb-2">Página no encontrada</span>
                        <p className="mt-4">Lo sentimos. El contenido que buscas no existe.<br /> O se eliminó o escribiste mal el enlace.</p>
                        <div className="mt-5">
                            <Link to="/" className="btn-g py-2 px-5">Salir de aquí</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}