import React, { Fragment } from 'react';
import { useEffect, useState } from "react";
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';
import { useAuth } from "../../../authContext";
import { updatePassword } from "firebase/auth";
import { useForm } from '../../../hooks/useForm';



const updatePassFormFields = {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
}

export function ModalCambiarPasswordComponent() {
    const { getTextByKey } = useApi();

    const [logged, setLogged] = React.useState(false);

    const { currentUser, logout ,handleChangePassword} = useAuth();
    React.useEffect(() => {
        if (currentUser) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [currentUser]);


    const [errors, setErrors] = useState("");

    const {
        onInputChange: changePasswordNew,
        formState,
        // passwordRegex,
    } = useForm(updatePassFormFields);

    const {currentPassword, newPassword, repeatNewPassword } = formState;

    const handleclose=()=>{
        setErrors('');
    }

    const updatePasswordFormSubmit = async (event) => {
        event.preventDefault();
        // console.log("Mi actual contraseña: ", currentPassword);
        // console.log("Mi nueva contraseña: ", newPassword);
        // console.log("Repitiendo está contraseña: ", repeatNewPassword);

        const validate = validateForm(formState);

        if (!validate) {
           
        setErrors("Tu contraseña está a punto de actualizarse...");
        handleChangePassword(currentPassword, newPassword)
        .then(() => {
            handleLogout();
        })
        .catch((error) => {
            console.log(error);
          if(error.message=='auth/wrong-password'){
            setErrors("La contraseña actual es errónea");
          }else{
          setErrors("¡Ups!, algo salió mal en el último momento, vuelva a intentar por favor");
          }
        });
          
            /*setTimeout(() => { updatePassword(currentUser, newPassword)
                .then(() => {
                    handleLogout();
                })
                .catch((error) => {
                    console.log(error.err);
                    setErrors("¡Ups!, algo salió mal en el último momento, vuelva a intentar por favor");
                })
            }, 4000);*/
        }
    };

    const validateForm = (formData) => {
        let errorFound = false;

        for (const [key, value] of Object.entries(formData)) {
            if (value === "") {
                errorFound = true;
            }
            setErrors("Todos los campos son obligatorios");
        }
        if(newPassword.length<6){
            errorFound = true;
            setErrors("Las contraseñas deben tener al menos 6 caracteres");
        }
        if (newPassword !== repeatNewPassword) {
            errorFound = true;
            setErrors("Las contraseñas no coinciden");
        }  
        return errorFound;
    }


    const handleLogout = async () => {
        try {
             location.href = "/profile-details";
        } catch {
        }
    };




    return (
        <Fragment>
            <div className="modal fade modal-user" id="cambiarPasswordModal" tabIndex="-1" aria-labelledby="cambiarPasswordModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="text-end">
                        <Icon className='cursor-pointer gray-1-color close-modal' icon="material-symbols:close-rounded" data-bs-dismiss="modal" onClick={handleclose}></Icon>
                        </div>
                        <div className="modal-body gray-1-color">
                            <div>
                                <div className="text-center pt-4">
                                    <p className="carrefour-sans-bold mb-3" id="guardarCuentaModal">{getTextByKey('cambiar_contrasena')}</p>
                                </div>
                                {errors ? <div className='error-msg'>{errors}</div> : null}
                                <form onSubmit={updatePasswordFormSubmit} className='px-xl-4'>
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('contrasena_actual')}</label>
                                                <input type="password" name="currentPassword" value={currentPassword} onChange={changePasswordNew} />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('contrasena_nueva')}</label>
                                                <input type="password" name="newPassword" value={newPassword} onChange={changePasswordNew}  />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="">{getTextByKey('repetir_contrasena_nueva')}</label>
                                                <input type="password" name="repeatNewPassword" value={repeatNewPassword} onChange={changePasswordNew} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <button type="button" data-bs-dismiss="modal" onClick={handleclose} className="btn-g bb py-1 px-4">{getTextByKey('cancelar')}</button>
                                        <button type="submit" className="btn-g py-1 px-3">{getTextByKey('actualizar_contrasena')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}