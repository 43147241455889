import React, { Fragment, useState, useEffect, useRef } from "react";
import { getAlmacenes, getStores } from "../../api/storeApi";
import { MapComponent } from "../../components/MapComponent";
import { Migas } from "../../components/Migas";
import { useApi } from "../../context/apiContext";
import { useCart } from "../../context/cartContext";
import { useAuth } from "../../authContext";
import { listAddressUser } from "../../api/addressApi";

export default function PointsSalePage() {

    const { currentLocale, getTextByKey, getTextBigByKey } = useApi();
    const { direccion } = useCart();
    const { currentUser } = useAuth();
    const [stores, setStores] = useState(false);
    const [almacenes, setAlmacenes] = useState([]);
    const [nearestStore, setNearestStore] = useState(false);
    const [locacion, setLocation] = useState(false);
    const [ miga, setMiga ] = useState('Puntos cervalle');
    const [ seccion, setSeccion ] = useState('puntos');
    const [ almacen, setAlmacen] = useState(false);
    const [ addressList, setAddressList ] = useState([]);
    const [ selectedAddress, setSelectedAddress ] = useState([]);
    const [ direccionSel, setDireccionSel ] = useState([]);
    const [ubicacion, setUbicacion] = useState(false);



    useEffect(()=>{
      
        getAlmacenes(currentLocale).then(data => {
            setAlmacenes(data);
            if (data) {
                if (data.length) {
                    setAlmacen(data[0]);
                }
            }
          })

       /* if(currentUser){
            listAddressUser(currentUser.uid).then((addressResponse)=>{
							//console.log('DIRECCIONES GUARDADAS',addressResponse);
              setAddressList(addressResponse);
            })
          }

        if(addressList.length > 0){
            for (let i = 0; i < addressList.length; i++) {
                if (addressList[i].predeterminada) {
                setSelectedAddress(addressList[i]);
                  break;
                }
            }
        }
        if(direccion){
            const dir = addressList.find(obj => obj.id === direccion);
            setDireccionSel(dir);
        }*/
    }, [])


    
    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const id = params.get('tienda'); 
        const sec= params.get('sec');

        if(sec){
            setSeccion(sec);
        }else{
            setSeccion('puntos');
        }
      
		 getStores(currentLocale).then(data => {
				data = data.filter(tienda=> tienda.lat != null && tienda.lng !=null)
        setStores(data);

        if(!id){
           setNearestStore(data[0]);
        }else{
            const tienda= data.find(tienda=> tienda.id ==id);
            setNearestStore(tienda);
        }
				/*if(direccionSel){
					setLocation({
						lat: parseFloat(direccionSel.lat),
						lng: parseFloat(direccionSel.lng)
					})
				}else if(selectedAddress){
            setLocation({
							lat: parseFloat(selectedAddress.lat),
							lng: parseFloat(selectedAddress.lng)
          })
        }else{
					setLocation({
						lat: 3.4337724100366582,
						lng: -76.52496088872111
					})
				}*/
      })

			
    }, [location.search]);

    useEffect(() => {
      
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
            setUbicacion(true);
          },
          (error) => {
            setLocation({
                lat: 3.4337724100366582,
                lng: -76.52496088872111
            })
            setUbicacion(false);
           console.log(error);
          }
        );
      }, []);
		


    const changeNearestStore = (store) => {
        console.log(store);
        setNearestStore(store);
        gotoTop();
    }


    const paths = [
        {to: '/puntos-venta', name: 'Puntos de Venta'},
        {to: '/puntos-venta', name: 'Puntos' }
    ];

    if(!nearestStore)
        return (
        <div className='container'>
            <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
            </div>
        </div>
        );

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

     
    return(
        <Fragment>
            <div className="container pt-5">
                <Migas paths={paths}/>
            </div>
            <div className="points-sale-page gray-1-color mb-lg-5 mb-2 mt-2 px-4">
                <div className="container">
                    <h1 className="primary-color text-center fw-bold mb-4">{getTextByKey("puntos-venta")}</h1>
                    <ul className="nav nav-pills d-block d-md-flex justify-content-between align-items-center mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${seccion=='puntos' ?'active' :''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected={seccion=='puntos'} onClick={() => setMiga(`${getTextByKey('puntos_cervalle')}`)}>{getTextByKey('puntos_cervalle')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${seccion=='cadena' ?'active' :''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected={seccion=='cadena'} onClick={() => setMiga(`${getTextByKey('almacenes_cadena')}`)}>{getTextByKey('almacenes_cadena')}</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className={`tab-pane fade ${seccion=='puntos' ?' show active' :''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row">
                                <div className="col-lg-5 col-12">
                                <div className="text-center text-md-start pe-3" dangerouslySetInnerHTML={{ __html: getTextBigByKey("horario_atencion_puntos") }}></div>
                                </div>
                            </div>
                            <div className="row pt-lg-5 pt-3">
                                <div className="col-12 col-xl-5 mb-4">
                                
                                    <div>
                                        <h4 className="fw-bold text-center text-md-start">{getTextByKey('punto_venta_cercano')}</h4>
                                        <p className="fw-bold">{nearestStore.nombre}</p>
                                        <div className="featured-part">
                                            <p className="mb-1">{nearestStore.direccion}</p>
                                            <div className="mb-1">
                                                <p className="fw-bold mb-0">Teléfono:</p>
                                                <p className="mb-0">{nearestStore.telefono}</p>
                                            </div>
                                            <div>
                                                <p className="fw-bold mb-0">Horario:</p>
                                                <div dangerouslySetInnerHTML={{ __html: nearestStore.horarios_text }}></div>
                                            </div>
                                        </div>
                                        <div className="d-block d-xl-none my-3">
                                            <img src="/img/google-maps.jpg" alt="Google maps" className="w-100" />
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="fw-bold text-center text-md-start">Otros puntos de venta</h4>
                                            <ul className="p-0 list-style-none">
                                                {
                                                    stores.map(store => {
                                                        if (store == nearestStore) return;
                                                        if (store.id == 1) return;
                                                        return (
                                                        <li key={store.id} className="w-auto cursor-pointer" onClick={() => { changeNearestStore( store ) }}>
                                                            <p className="fw-bold m-0">{store.nombre}</p>
                                                        </li>
                                                    )})
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 offset-xl-1 mb-4 d-none d-xl-block">
                                    {
                                        locacion && <MapComponent setNearestLocation={setNearestStore} navigator={ubicacion} locations={stores} locationMarker={locacion} />
                                    }
                                </div>
                            </div>

                        </div>
                        <div className={`tab-pane fade ${seccion=='cadena' ?' show active' :''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                <div className="col-md-5 col-12">
                                    <div className="text-center text-md-start" dangerouslySetInnerHTML={{ __html: getTextBigByKey("referencias_puntos_ventas") }}></div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-xl-5 mb-4">
                                    <h4 className="fw-bold text-center text-md-start">{getTextByKey('almacen_cercano')}</h4>
                                    {
                                        almacenes.map(almacen_ => (
                                            <React.Fragment key={almacen_.id}>
                                                <p onClick={() => setAlmacen(almacen_)} className="fw-bold">{almacen_.city}</p>
                                                <div className={almacen_ == almacen ? "featured-part" : "d-none"} dangerouslySetInnerHTML={{ __html: almacen_.information }}></div>
                                            </React.Fragment>
                                        ))
                                    }

                                    {/* <p className="fw-bold">{getTextByKey('almacen_cercano_subtitle')}</p>
                                    <div className="featured-part" dangerouslySetInnerHTML={{ __html: getTextBigByKey("almacenes_cadena_listado") }}>
                                    </div>
                                    <div className="mt-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("almacenes_cadena_listado_secundario") }}>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}