import { useApi } from "../context/apiContext";
import { useAuth } from "../authContext";
import { useState } from "react";
import { useEffect } from "react";
import { createAddress } from "../api/addressApi";
import { useForm } from "../hooks/useForm";
import { ActualizaTecnocarnes, getCiudades, getDepartamentos } from "../api/productApi";
import { getInfoMap } from "../api/mapApi";
import { getKml } from '../api/storeApi';
import { useCart } from '../context/cartContext';
import { getUser,updateUser } from "../helpers/userHelper";
import { getTienda} from '../api/storeApi';


const addressFromFields={
    alias: "",
    ciudad: 0,
	department: 0,
	city:0,
    tipocalle: 0,
	direccion:"",
    base: "",
    numero: "",
    detalle: "",
    complement: "",
    zipcode: "",
    lat: "",
    lng: "",
    phone: "",
    barrio: "",
    uid: "unset"
}


export default function NuevaDireccionPage({succesfull,setTiendaId,setModalNuevaDireccion,setSuccesfull}) {




    const { currentUser } = useAuth();
    const { getTextByKey } = useApi();
    const [ ciudades, setCiudades ] = useState([]);
	const { updateTienda,updateCurrentdelivery,tiendaGeneral,updateDireccion} = useCart();
	const [ tienda, setTienda ] = useState(null);
	const [ departamentos, setDepartamentos ] = useState([]);
    const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
	const [ viewtienda, setViewTienda ] = useState(false);
    const [ stores, setStores ] = useState([]);
	/*const [ addressSearch, setAddressSearch] = useState({
		tipocalle: '',
		base: '',
		numero: '',
		lat:'',
		lng:'',
		detalle: '',
		direccionLarga: '',
		complement:'',
		department:0,
		city: 0
	});*/


    const {
        onInputChange: onAddressInputChange,
        formState,
        setFormState
    } = useForm(addressFromFields);

	const {
        alias,
        city,
		department,
        tipocalle,
        base,
		lat,
		lng,
        numero,
        detalle,
        complement,
    } = formState;

		useEffect(()=>{
			getDepartamentos().then((data)=>{
				setDepartamentos(data);
			})
		
			
		}, [])
		useEffect(()=>{
			fetchProfile();
				
		}, [])
		

		const infoMap = async (address) => {
			
			try {
				const { data: { results } } = await getInfoMap(address);
				console.log(results);
				if (results && results.length > 0) {
					if(results[0].geometry.location_type=='APPROXIMATE'){
						return false
					}
				
					const { lat, lng } = results[0].geometry.location;
		            formState.lat=lat;
					formState.lng=lng;

					let tienda= await getKml(lat, lng) 
					if(tienda.id){
						let tecnocarnes=await ActualizaTecnocarnes(tienda.id);
						console.log(tecnocarnes,'tecnocarnes');
						updateTienda(tienda.id);
						setTienda(tienda.id);
						return true;
					}else{
						return false;
					}  
					  
	              		
				}else{	
					return false;
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		}
	
		const fetchProfile = () => {
           
			try {
			  getUser(currentUser?.uid).then(async({ user }) => {
			 	 const {email } = user;
				  const updatedProfileInfo = {
				  email:email
				};
		  
				setFormState({...formState,...updatedProfileInfo});
				   });
			} catch (error) {
			 
			}
		  };
		  
		
    const onSubmit = async (event) => {
	    event.preventDefault();
        setLoading(true);
		setViewTienda(false);
		setError('');
        const validate = await validateForm();
		if(!validate){
			let { uid } = currentUser;
			const ciudadSelect = ciudades.find((city) => city.id == formState.city);
		    const dirfull=`${formState.tipocalle} ${formState.base}#${formState.numero}-${formState.detalle}`;
			const address = `${dirfull},${ciudadSelect.name.nombre},Colombia`;
			formState.uid = uid;
			//formState.password = uid
			formState.autoriza = true;
			//formState.email = uid + "@cervalle.com";
			formState.ciudad=formState.city;
			formState.direccion=dirfull+ ' '+formState.complement;
			
			let ubicacion=await infoMap(address);
			if(ubicacion){
				let addressFields = {
					...addressFromFields,
					user: formState.uid,
					uid: formState.uid,
					alias: formState.alias,
					city: formState.city,
					tipocalle: formState.tipocalle,
					base: formState.base,
					numero: formState.numero,
					lat: formState.lat,
					lng: formState.lng,
					detalle: formState.detalle,
					complement: formState.complement,
				}
              	/*setAddressSearch({
					...addressSearch,
					tipocalle: formState.tipocalle,
					base: formState.base,
					numero: formState.numero,
					lat:formState.lat,
					lng:formState.lng,
					detalle: formState.detalle,
					complement: formState.complement,

				})*/

						
				if (currentUser?.isAnonymous) {
					updateUser(formState).then(() => {
						localStorage.removeItem('actualizo');
						createAddress(addressFields).then((response) => {
							console.log(addressFields)
							
							setSuccesfull('Direccion creada con éxito');
							updateCurrentdelivery({tipo:'', dir: {id:response.id}});
							updateDireccion(response.id);
							//setDireccionGuardada(true);
						}).catch((error)=>{
							setError('Error al actualizar la información');
							setSuccesfull('');
							setLoading(false);
						})
					}).catch((error)=>{
						setError('Error al actualizar la información');
						setLoading(false);
						setSuccesfull('');
						console.log(error);
					})
				} else {
					createAddress(addressFields).then((data) => {
						console.log(data.id);
						setSuccesfull('Dirección creada con éxito');
						updateCurrentdelivery({tipo:'', dir: {id:data.id}});
						updateDireccion(data.id);
						//setDireccionGuardada(true);
					}).catch((error)=>{
						setError('Error al actualizar la información');
						setLoading(false);
						setSuccesfull('');
					})
				}
			}else{
				setError('En este momento no contamos con cobertura en tu dirección. Te invitamos a montar tu pedido y recoger en la tienda más cercana');
				setLoading(false);
				setViewTienda(true);
				setSuccesfull('');
			}

		}else{
			setLoading(false);
		}
    }

		const validateForm = async () => {
		    console.log(department,city);
			let errorFound = false;
			if(department==0 || !department){
				setError('Todos los campos son requeridos *');
				errorFound=true;
			}
			if(city==0 || !city){
				setError('Todos los campos son requeridos *');
				errorFound=true;
			}
			if(tipocalle==0 || !tipocalle){
				setError('Todos los campos son requeridos *');
				errorFound=true;
			}
			if(!alias || !base || !numero || !detalle ){
				setError('Todos los campos son requeridos *');
				errorFound=true;

			}
			
			return errorFound;
		}

		const handleDpto = (event)=>{
			const dptoId = event.target.value;
			setViewTienda(false);
			setError('');
			getCiudades(dptoId).then((data) => {
				setCiudades(data);
			});
		}

		const handleChage = (event) => {
			
			const id_ciudad = event.target.value;
			setStores([]);
			setError('');
			setViewTienda(false);
			if(id_ciudad!=0){
			  getTienda(id_ciudad).then((data) => {
				 setStores(data);
			  });
			}
			setTiendaId(0);
		  }

		  const handleTienda = (event) => {
			let tienda=event.target.value;
			setTiendaId(tienda);
			setModalNuevaDireccion(false);
    		
		  }
	  


    return (
			<form onSubmit={onSubmit} className="gray-1-color">
				<div className="row">
				
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('departamento')}*</label>
								<select name="department" className="p-2" value={department} onChange={(event)=>{onAddressInputChange(event); handleDpto(event);}}>
									<option value="0">{getTextByKey("departamento")}</option>
									{departamentos.map((departamentos, index) => (
										<option key={index} value={departamentos.id}>{departamentos.name.nombre}</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('ciudad')}*</label>
								<select name="city" className="p-2" value={city} onChange={(event)=>{onAddressInputChange(event);handleChage(event)}}>
									<option  value="0">{getTextByKey("ciudad")}</option>
									{ciudades.map((ciudad, index) => (
										<option key={index} value={ciudad.id}>{ciudad.name.nombre}</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('direccion')}*</label>
								<select name="tipocalle" id="" className="p-2" onChange={onAddressInputChange}>
									<option value="0">Dirección</option>
									<option value="Calle">Calle</option>
									<option value="Carrera">Carrera</option>
									<option value="Transversal">Transversal</option>
									<option value="Avenida">Avenida</option>
									<option value="Circunvalar">Circunvalar</option>
									<option value="Diagonal">Diagonal</option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>Base, número, detalle *</label>
								<div className="d-flex align-items-center">
										<div>
												<input type="text" name="base" className="p-2" placeholder="10A" value={base} onChange={onAddressInputChange} />
										</div>
										<div>#</div>
										<div>
												<input type="text" name="numero" className="p-2" placeholder="10" value={numero} onChange={onAddressInputChange} />
										</div>
										<div>-</div>
										<div>
												<input type="text" name="detalle" className="p-2" placeholder="101" value={detalle} onChange={onAddressInputChange} />
										</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
							<div className="form-group">
								<label>{getTextByKey('complemento')}</label>
								<input
										type="text"
										className="p-2"
										name="complement"
										value={complement}
										onChange={onAddressInputChange}
								/>
							</div>
						</div>
						<div className="col-12 col-md-6 mb-4">
						<div className="form-group">
							<label>{getTextByKey('alias')}*</label>
							<input
									type="text"
									className="p-2"
									name="alias"
									value={alias}
									onChange={onAddressInputChange}
							/>
						</div>
					</div>
					{viewtienda  && stores.length>0? 
					<div className="col-12">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color'  name="tienda" onChange={handleTienda}>
                              <option value="0">{getTextByKey("tienda")}</option>
                              {stores.map((tienda, index) => (
                                <option key={index} value={tienda.id}>{tienda.direccion}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                     :<></>}
				</div>
				
				<div className="text-center">
					{error && <p className="alert-danger">{error}</p> }
					{succesfull ? <p className='alert-sucess'>{succesfull}</p> : null}
				</div>
				<div className="mt-4 text-center">
						{ !succesfull &&<button type="submit" disabled={loading} className="btn-g py-2 px-3 px-xl-4">
							{	loading ? (<div className='spinner-border spinner-border-sm' role='status'></div>) :
								`${getTextByKey('guardar_direccion')}`
							}
						</button>
                      }
				</div>
			</form>
    )
}