import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useApi } from '../../../context/apiContext';

export const ModalEstadoOrden = ({ showModal, setShowModal }) => {

    const { getTextByKey } = useApi();

    const handleClose = () => {
        setShowModal(false);
    }


    return (
        <Modal show={showModal} onHide={handleClose} className='modal-estado-envio'>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='content mb-5 d-flex flex-column justify-content-center align-items-center'>
                    <h2 className='text-uppercase text-center mb-5 title-modal'>{getTextByKey("order-status-title-modal")}</h2>
                    <div className='wp my-3'>
                        <p className="text-uppercase mb-0">{getTextByKey("desc-company-modal-order")} envia sas</p>
                    </div>
                    <div className='wp my-3'>
                        <p className="text-uppercase text-center mb-0">{getTextByKey("desc-page-1-company-modal-order")} PAGINA.COM</p> 
                        <p className="text-uppercase text-center mb-0">{getTextByKey("desc-page-2-company-modal-order")}</p>
                    </div>

                    <a className="btn-g bb py-2 px-3 text-uppercase mt-5" onClick={handleClose}>{getTextByKey("btn-close-modal")}</a>
                </div>
            </Modal.Body>
        </Modal>
    )
}
