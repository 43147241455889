import React, { Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { useApi } from "../context/apiContext";
import { getImages } from '../api/imageApi';
import { images_products_route } from '../api/productApi';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import '../scss/drop-menu.scss';
import Picture from './Picture';

export default function DropMenuComponent() {

    const { getTextByKey, currentCategories, extractNameFromLocate } = useApi();
    const [ pse, setPse ] = useState([]);
    const [ imgMenu, setImgMenu ] = useState([]);
    const closeDrop = event => {
        const drop = document.getElementById('drop-menu');
        drop.classList.remove('show');
    };

    const handleResize = () => {
        if( window.innerWidth <= 420 ) {
            closeDrop();
        }
    }

    useEffect(() => {
     getImages('pse').then((data) => {
        setPse(data)
      })
			getImages('menu-desplegable-img').then((data)=>{
				setImgMenu(data);
			}) 
    }, [])
    
    
    window.addEventListener('resize', handleResize);
    

    
  return (
    <Fragment>
        <div className="drop-menu primary-bg-color" id="drop-menu">
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-7 col-lg-5 col-xl-3 ps-0'>
                        <ul className='py-3 mt-3 ps-5'>
                            <li><NavLink to="/recetas" onClick={closeDrop} className="mb-1 white-color"><h3 className='d-flex align-items-center'><Icon icon="tabler:chef-hat"/>{getTextByKey("recetas")}</h3></NavLink></li>
                            <li><NavLink to="/blogs" onClick={closeDrop} className="mb-1 white-color"><h3 className='d-flex aling-items-center'><Icon icon="material-symbols:edit-note-sharp"/>{getTextByKey("blog")}</h3></NavLink></li>
                            <li><NavLink to="/nosotros" onClick={closeDrop} className="mb-1 white-color"><h3 className='d-flex aling-items-center'><Icon icon="ic:round-star-outline"/> {getTextByKey("nosotros")}</h3></NavLink></li>
                            <li><NavLink to="/contacto" onClick={closeDrop} className="mb-1 white-color"><h3 className='d-flex aling-items-center'><Icon icon="fluent:call-12-regular"/>{getTextByKey("contacto")}</h3></NavLink></li>
                            <li><NavLink to='/puntos-venta' onClick={closeDrop} className="mb-1 white-color"><h3 className='d-flex aling-items-center'><Icon icon="ph:map-pin"/>{getTextByKey("puntos-venta")}</h3></NavLink></li>
                            { pse && pse[0] &&
                                <li>
                                { pse[0].link ? (
                                    <a href={pse[0].link} target="_blank" onClick={closeDrop} className="mb-1 white-color">
                                        <h3 className='d-flex aling-items-center text-nowrap'>
                                        {/*<Picture classImg={'img-pse'} src={pse[0].image} alt={"pse-logo"} />*/}{getTextByKey("pago_clientes")}</h3>
                                    </a>
                                    ) :(
                                        <a onClick={closeDrop} className="mb-1 white-color">
                                            <h3 className='d-flex aling-items-center text-nowrap'>
                                            {/*<Picture classImg={'img-pse'} src={pse[0].image} alt={"pse-logo"} />*/}{getTextByKey("pago_clientes")}</h3>
                                        </a>
                                    )
                                }
                            </li>}
                            
                        </ul>
                    </div>
                    <div className='d-md-none d-lg-block col-lg-5 col-xl-9 img-dropdown p-0 position-relative'>
                        <a className='white-color postion-absolute close-icon px-2' onClick={closeDrop}>
                            <Icon icon="material-symbols:close"></Icon>
                        </a>
												{
													imgMenu && imgMenu.length > 0 ?
													<Picture src={imgMenu[0].image} alt={'img-menu'} classImg={'w-100'} /> : ''
												}
                  
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
