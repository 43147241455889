import React, { Fragment, useEffect } from 'react'
import CombinacionComponent from './components/CombinacionComponent'
import { images_products_route } from "../../api/productApi";
import { useApi } from "../../context/apiContext";

export default function CombinacionesPage() {
    const { getTextByKey, currentCombination } = useApi();

    useEffect(() => {
    }, []);

    return (
        <Fragment>
            <div className='px-1'>
                <div className='container-fluid'>
                    <div className='col-12'>
                        <div className='py-5 text-center'>
                            <h2 className='text-uppercase'>{getTextByKey("combinaciones")}</h2>
                        </div>
                    </div>
                    <div className='row'>
                        {currentCombination.map((combination, index) => (
                            <div className='col-md-6 col-lg-3 ps-lg-0 pe-lg-1 mb-lg-5' key={index}>
                                <CombinacionComponent combination={combination} route={images_products_route} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
