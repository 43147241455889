import { Fragment, useState, useEffect } from "react";
import {  Link, useParams } from "react-router-dom";
import { useApi } from "../../context/apiContext";
import { Migas } from "../../components/Migas"
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {  Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBlog } from "../../api/blogApi";
import { createDatetime } from "../../helpers/dateHelper";
import Picture from "../../components/Picture";
import '../../scss/recipes_page.scss';

export default function AboutSingularBlogPage() {
    const { getTextByKey, currentLocale } = useApi();
    const [blog, setBlog] = useState();
    const [datetime, setDatetime] = useState();
    const params = useParams();

    const getBreadcrumps = () => {
        const paths = [
            {to: '/blogs', name: 'Blogs'}
        ];
        if (blog) {
            paths.push({to: '', name: blog.title})
        }
        return paths;
    };

    useEffect(() => {
        getBlog(params.id, currentLocale).then(data => {
            setBlog(data);
            setDatetime(createDatetime(data.created_at.date));
        });
    }, [params.id]);

    if(!blog)
        return (
            <div className='container'>
                <div className="text-center-c">
                <div className="loader-box">
                    <img src="/img/SVG/loader.svg" alt="" />
                </div>
                </div>
            </div>
            );
    return (
        <Fragment>
            <div className="blog my-4">
                <div className="container">
                    <Migas paths={getBreadcrumps()}/>
                </div>
                <div className="banner-entry-blog mb-3 mb-lg-5">
                        <div className="container-fluid gx-0">
                            <div className="row gx-0">
                                <div className="col-12 col-lg-5">
                                    <div className="p-3 p-sm-5">
                                        <h1 className="primary-color fw-bold text-center text-lg-start">{blog.title}</h1>
                                        {
                                            datetime && <p className="mb-2">{getTextByKey(datetime.month)} {datetime.day}, {datetime.year}</p>
                                        }
                                        <p className="quaternary-color">{blog.subtitle}</p>
                                        <ul className="p-0 d-flex align-items-center mt-4 list-style-none social-media-sharing">
                                            <li className="me-3">
                                                <FacebookShareButton 
                                                    url={window.location.href}
                                                    >
                                                    <img src="/img/SVG/facebook-blue.svg" alt="Facebook icon" className="icon" />
                                                </FacebookShareButton>
                                            </li>
                                            <li className="me-3">
                                                <TwitterShareButton 
                                                    url={window.location.href}
                                                    >
                                                    <img src="/img/SVG/twitter-blue.svg" alt="Twitter icon" className="icon" />
                                                </TwitterShareButton>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 px-3 px-lg-0">
                                    <Picture src={blog.images[0].image} alt={"Recipe"} classImg={"w-100 img-blog"} />
                                </div>
                            </div>
                        </div>
                </div>

                <div className="txt-about-entry my-lg-5 my-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11 col-lg-8" dangerouslySetInnerHTML={{__html: blog.content}}>
                            </div>
                        </div>
                    </div>
                </div>
                { blog?.relations && blog?.relations.length>0 ? 
                <section className="related entries my-lg-5 my-3 px-lg-5 px-3">
                    <div className="container">
                        <hr className="hr-color py-lg-3 py-2" />
                        <div className="text-center py-lg-3 py-2">
                            <h3 className="primary-color carrefour-sans-bold">{getTextByKey('noticias-relacionadas')}</h3>
                        </div>
                        <div className="row">
                            <div className="col-12 offset-lg-1 col-lg-10">
                                <div className="position-relative swiper-blogs">
																<Swiper modules={[ Autoplay, Navigation]}
																		navigation
																		autoplay={{ delay: 4000, disableOnInteraction: true }}
																		breakpoints={{
																			320: {
																					slidesPerView: 1,
																					spaceBetween: 0,
																			},
																			576: {
																					slidesPerView: 2,
																					spaceBetween: 10,
																			},
																			992: {
																					slidesPerView: 3,
																					spaceBetween: 30,
																			},
																		}}>
																		{
																			blog.relations.map(rel => (
																				<SwiperSlide>
                                                                                    
                                                                                        <div>
                                                                                        <Link to={"/blog/"+rel?.slug }>
                                                                                            <div>
                                                                                                    <Picture src={rel.image} alt={rel.title} classImg={"w-100"} />
                                                                                            </div>
                                                                                            <div className="mt-3">
                                                                                                    <p className="mb-0 carrefour-sans-bold gray-1-color">{rel.title}</p>
                                                                                            </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                  
																				</SwiperSlide>
																				))
																		}
                                	</Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :<></>}
            </div>
        </Fragment>
    );
}
