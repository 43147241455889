import axios from 'axios'
import { getEnvVariables } from '../helpers'

const { API_URL, TokenAPI,TOKEN } = getEnvVariables()

const sellyApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Accept': 'application/json',
    'token': 'Bearer ' +TOKEN,
   
  },
  baseURL: API_URL,
  withCredentials: false,
  TokenAPI: TokenAPI
})

sellyApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  }
  return config
})

export default sellyApi
