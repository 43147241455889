import React from "react";
import { Star } from "./Star";
import { useState } from "react";

export function RatingStar({onChange,rating,setRating}){

  

  const changeRating = (newRating) => {
    setRating?.(newRating);
    onChange?.(newRating);
  };

    return(
      <span className="d-flex">
        {[1, 2, 3, 4, 5].map((value) => (
          <Star
            key={value}
            filled={value <= rating}
            onClick={() => changeRating(value)}
          />
        ))}
      </span>
    );
}
