import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FavoriteClick from "./FavoriteClick";
import "../scss/product.scss";
import PropTypes from "prop-types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { images_products_route } from "../api/productApi";
import { useApi } from "../context/apiContext";

export default function ProductsmallComponent({product})  {

    const { convertCurrency } = useApi();

    const goToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
      <div className='product position-relative'>
        <picture className='position-relative'>
          <a>
            <img className='w-100' src={product.images && images_products_route + product.images[0].image} />
          </a>
          <FavoriteClick product={product} />
        </picture>
        <div className='py-2 py-md-4'>
          <div className='d-lg-flex justify-content-between align-items-center mb-1'>
            <h3 className='gotham-Bold mb-0'>{product.name && product.name.nombre}</h3>
          </div>
          <div className='d-lg-flex justify-content-between align-items-center'>
            <p className='mb-0'>
              <strong className='primary-color'> {product.price && convertCurrency(product.price.precio)}</strong>
            </p>
          </div>
        </div>
        <div className='options p-2'>
          <picture className='position-relative'>
            <Link to={"/product/" + product.plu} onClick={goToTop}>
              <img className='w-100' src={product.images && images_products_route + product.images[0].image} />
            </Link>
            {/* <FavoriteClick /> */}
          </picture>
          <div className='py-2 py-md-4'>
            <div className='d-lg-flex justify-content-between align-items-center'>
              <h3 className='gotham-Bold mb-0'>{product.name && product.name.nombre}</h3>
            </div>
            <div className='d-lg-flex justify-content-between align-items-center'>
              <p className='mb-0'>
                <span className='me-2 gray-1-color'>{product.price && convertCurrency(product.price.precio)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}