import Modal from 'react-bootstrap/Modal';
import React, { Component, Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { PagarPse, createOrder, createTarjeta, getBancos, getTiposDocumentos } from '../../../api/productApi';
import { useAuth } from '../../../authContext';
import { useApi } from '../../../context/apiContext';
import { BtnClose } from '../../../components/BtnClose';
import { Icon } from '@iconify/react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm';
import { Link } from 'react-router-dom';

  
const FormFields = {
    nombre: "",
    apellido: "",
    tipodocumento: 0,
    documento: "",
    email: "",
    user: "unset",
    banco:0,
    tipopersona:""
  }

export default function ModalPse({showModal,order, setShowModal,setTipoPago }) {

    const { getTextByKey ,getTextBigByKey} = useApi();
    const [loading, setloading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const [cardBrand, setCardBrand] = useState('');
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [Bancos, setBancos] = useState([]);
    const [ errorNombre, setErrorNombre ] = useState();
    const [ errorApellido, setErrorApellido ] = useState();
    const [ errorTipoDoc, setErrorTipoDoc ] = useState();
    const [ errorTipoPersona, setErrorTipoPersona] = useState();
    const [ errorbanco, setErrorbanco ] = useState();
    const [ errorMail, setErrorMail ] = useState();
    const [ errorNumDoc, setErrorNumDoc ] = useState();
    const [terminosCheck, setTerminosCheck] = useState(false);


    useEffect(() => {
        getTiposDocumentos().then((data) => {
          setTiposDocumentos(data);
       
    
      });
      getBancos().then((data) => {
        setBancos(data);
    
    });
    if(currentUser.isAnonymous){
      if(!localStorage.getItem('actualizo')){
        setError('Debe completar la información de envio');
       
      }
    }
      }, []);

      
  const changeTerminos = (e) => {
    setError('');
    setTerminosCheck(e.target.checked)
  }
   
  

      const {
        onInputChange: onInputChange,
        formState,
        setFormState,
        emailRegex,
        nameRegex,
        phoneRegex
      } = useForm(FormFields);

   
      const {
        nombre,
        apellido,
        tipodocumento,
        tipopersona,
        documento,
        user,
        banco,
      } = formState;
      const Pagar = async (event) => {
        event.preventDefault();
         setloading(true);
      
        const validar = await validateForm(formState);
        if(!validar){
            console.log(formState);
                
          if(!terminosCheck){
            setError('Debe aceptar los terminos y condiciones');
            setloading(false);
            return;
          }

          if(currentUser.isAnonymous){
            if(!localStorage.getItem('actualizo')){
              setError('Debe completar la información de envio');
              setloading(false);
              return;
            }
          }
               
       

        createOrder(order).then( async(response) => {
            //console.log('response: ', response)
          
            if (!response.id) {
              setError("Error al crear la orden");
              setloading(false)
            } else {
                formState.user=currentUser.uid;
                formState.compra=response.id;
                PagarPse(formState).then((data) => {
                    setloading(false);
                    if(data.link){
                        window.location.href=data.link;
                    }else{
                       
                        setError('No se pudo procesar el pago');
                         
                    }
              
                         
                }).catch((err) => {
                    console.log(err);
                    setError('No se pudo procesar el pago');
                    setloading(false);
                
                  
                })
            }
          }).catch((error) => {
            setloading(false);
            console.log('error: ', error)
            setError(error.message);
          })
        
      

       
         
        }else{
         setloading(false);
        }
      
      };


      const validateForm = async (formData) => {
        let errorfound=false;
        setErrorbanco('');
        setErrorNombre('');
        setErrorApellido('');
        setErrorNumDoc('');
        setErrorTipoPersona('');
        setErrorTipoDoc('');

        
        if (formData.banco ==0) {
            setErrorbanco('Campo Obligatorio');
            errorfound=true;
          }
          if (formData.banco === "") {
            setErrorbanco('Campo Obligatorio');
            errorfound=true;
          }

        if (formData.nombre === "") {
         setErrorNombre('Campo obligatorio');
         errorfound=true;
        }else{
           if(!formData.nombre.match(nameRegex)){
            setErrorNombre('El nombre solo debe contener letras');
            errorfound=true;
           }
        }
    
        if (formData.apellido === "") {
         setErrorApellido("Campo Obligatorio");
         errorfound=true;
        }else{
           if(!formData.apellido.match(nameRegex)){
            setErrorNombre('El apellido solo debe contener letras');
            errorfound=true;
           }
        } 
        
      
          if (formData.tipopersona === "") {
            setErrorTipoPersona('Campo Obligatorio');
            errorfound=true;
          }    
        
        if (formData.tipodocumento ==0) {
          setErrorTipoDoc('Campo Obligatorio');
          errorfound=true;
        }
        if (formData.documento === "") {
          setErrorNumDoc('Campo Obligatorio');
          errorfound=true;
        }
    
        
     
      
        return errorfound;
      };

      const handleclose=()=>{
        setError('');
        setTipoPago('');
        setShowModal(false); 
    }

    return (
        <Modal show={showModal} className="modal-new-card">
            <Modal.Header className='justify-content-center px-3 px-md-5'>
                {/* <Modal.Title className="px-5">
                </Modal.Title> */}
                <h3 className="w-100 modal-title text-center mb-0" id="exampleModalLabel">{getTextByKey("pagar-pse")}</h3>
                <button type="button" className="btn p-0 border-none" onClick={handleclose}><BtnClose /></button>
            </Modal.Header>
            <Modal.Body className="px-2 px-md-4">
                <div className="w-100 px-2 px-md-4">
                    <form onSubmit={Pagar}>
                    {Bancos && Bancos.length>0 ? 
                    <div className="form-group mb-3">
                        <label >{getTextByKey("banco-pse")}*</label>
                        <select  name="banco" value={banco} onChange={onInputChange}>
                          {Bancos.map((banco, index) => (
                                <option key={index} value={banco.bankCode}>{banco.bankName}</option>
                            ))}
                        </select>
                        <span>{errorbanco ? <div className='alert-danger'>{errorbanco}</div> : null}</span>
                    </div>  
                     :''}
                        <div className="form-group mb-3">
                            <label>{getTextByKey("names-form")}*</label>
                            <input type="text" className="p-2" value={nombre} name="nombre" onChange={onInputChange} />
                            <span>{errorNombre ? <div className='alert-danger'>{errorNombre}</div> : null}</span>
                 
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("last-names-form")}*</label>
                            <input type="text" className="p-2" value={apellido} name="apellido" onChange={onInputChange} />
                       
                         <span>{errorApellido ? <div className='alert-danger'>{errorApellido}</div> : null}</span>
                    </div>

                    <div className="form-group mb-3">
                        <label >{getTextByKey("tipo_documento")}*</label>
                        <select  name="tipodocumento" value={tipodocumento} onChange={onInputChange}>
                            <option value="0"  >Selecciona una opción </option>
                            {tiposDocumentos.map((tipoDocumento, index) => (
                                <option key={index} value={tipoDocumento.ref}>{tipoDocumento.ref + " (" + tipoDocumento.translations[0].name + ")"}</option>
                            ))}
                        </select>
                        <span>{errorTipoDoc ? <div className='alert-danger'>{errorTipoDoc}</div> : null}</span>
                    </div>   
                    <div className="form-group mb-3">
                        <label >{getTextByKey("tipo_persona")}*</label>
                        <select  name="tipopersona" value={tipopersona} onChange={onInputChange}>
                            <option value=""  >Selecciona una opción </option>
                            <option value="0"  >Persona Natural</option>
                            <option value="1"  >Persona Jurídica</option>
                           
                        </select>
                        <span>{errorTipoPersona? <div className='alert-danger'>{errorTipoPersona}</div> : null}</span>
                    </div>
                        <div className="form-group mb-3">
                            <label >{getTextByKey("numero_documento")}*</label>
                            <input type="number"  name="documento" value={documento} onChange={onInputChange} />
                            <span>{errorNumDoc ? <div className='alert-danger'>{errorNumDoc}</div> : null}</span>
                        </div>
                   
                        <form action="">
                    <div className='form-group mb-4 mt-4'>
                      <label className="container-check-s">
                        <p className='gray-1-color mb-0'>
                          <Link className="gray-1-color" to='/aviso-de-privacidad'><div className='gray-1-color mb-0 sm ms-1 terminos' dangerouslySetInnerHTML={{ __html: getTextBigByKey('he_leido') }}></div></Link>
                        </p>
                        
                        <input
                          type="checkbox"
                          name="accet"
                          checked={terminosCheck}
                          onChange={changeTerminos}
                        />
                           
                        <span className="checkmark-s">
                          <img src="/img/SVG/check.svg" />
                        </span>
                      </label>
                    </div>
                  </form>
                      
                        { error && <div className='error-msg text-grey text-start bg-transparent'>*{error}</div>}
                        <div className="form-group mb-2 text-center">
                            <button className="btn-g py-2 px-4 text-uppercase" type='submit'>
                                {loading ? <span className="spinner-border text-ligth" role="status"></span> : <span>{getTextByKey("pagar-pse")}</span>}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}