import sellyApi from './sellyApi'

export const getRecipeReviews = async (id) => {
  try {
    const data = await sellyApi.get(`/recipe_reviews/${id}`);
    return data.data;
  } catch (error) {
    return error;
  }
}

export const createRecipeReview = async ({title = '', comment = '', score = '', recipeId, name = '', email = '', userUid = ''}) => {
  try {
    const data = await sellyApi.post(`/recipe_reviews/`, {title, comment, score, recipeId, name, email, userUid});
    return data.data;
  } catch (error) {
    return error;
  }
}

export const getProductReviews = async (id) => {
  try {
    const data = await sellyApi.get(`/recipes/${id}`);
    return data.data.list;
  } catch (error) {
    return error;
  }
}

export const createProductReviews = async (id) => {
    try {
      const data = await sellyApi.post(`/recipes/categories/${id}`);
      return data.data;
    } catch (error) {
      return error;
    }
  }
  
