import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react'
import { useApi } from "../../../context/apiContext";

export default function TallaComponent({ producto, handleTalla }) {

    const { currentSizes } = useApi();
    const [sizesEnables, setSizesEnables] = React.useState([]);

    const handleChange = (e) => {
        handleTalla(e.target.value);
    }

    useEffect (() => {
        if(producto){
            let sizesEnablesArray = [];
            if(currentSizes){
                currentSizes.forEach((size) => {
                    size.productos.forEach((item) => {
                        if(item.includes(producto.plu)){
                            sizesEnablesArray.push(size.id);
                        }
                    });
                });
            }
            setSizesEnables(sizesEnablesArray);
        }
    }, [currentSizes, producto.plu]);


    return (
        <Fragment>
            {currentSizes.length > 0 && (
                <>
                {currentSizes.map((size, index) => (
                <div className={sizesEnables.includes(size.id) ? 'talla me-2' : 'talla me-2 disabled'} key={index}>
                    <input type="radio" className="css-checkbox" name="talla" id={`talla-${producto.plu}-${index}`} value={size.id} onChange={handleChange}/>
                    <label htmlFor={`talla-${producto.plu}-${index}`}  name="checkbox1_lbl" className="css-label lite-green-check">{size.nombre}</label>
                </div>
                ))}
                </>
            )}
        </Fragment>
    )
}
