import { Fragment, useState, useEffect } from "react";
import { ProductComponent } from "../../components/productComponent";
import { default as BtnWhatsapp } from "../../components/BtnWhatsapp";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getRecommendedHome, getOfertasHome,images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { getImages } from "../../api/imageApi";
import { useApi } from "../../context/apiContext";
import { useCart } from '../../context/cartContext';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Picture from "../../components/Picture";
import "../../scss/home.scss";
import "../../scss/footer.scss";
import { useAuth } from './../../authContext';
import ModalLogUserComponent from "../../components/ModalLogUserComponent";
import product from "../../classes/product";

export default function HomePage() {
    const [products, setProducts] = useState([]);
    const [ofertas, setOferts] = useState([]);
    const [collecion, setCollecion] = useState([]);
    const [gallery, setGallery] = useState([]);
    const navigate = useNavigate();

    const { getTextByKey,getTextByKeyLink, getTextBigByKey, extractNameFromLocate, currentFav, getPropiertyByLocale } = useApi();
    const { currentUser } = useAuth();


    // const [ctaVsitShop] = useState

    const [bgVisitarTienda, setBgVisitarTienda] = useState([]);

    const { tiendaGeneral} = useCart();
    const [galleryChooseYourFavoriteCombo, setGalleryChooseYourFavoriteCombo] = useState([]);
    const [galleryUnmissablePromotionMobile, setGalleryUnmissablePromotionMobile] = useState([]);
    const [galleryUnmissablePromotionDesk, setGalleryUnmissablePromotionDesk] = useState([]);
    const [galleryCervalleKitchen, setGalleryCervalleKitchen] = useState([]);
    const [ bannerHome, setBannerHome ] = useState([]);
		const [ bannerVisitar, setBannerVisitar ] = useState([]);
		const [ bannerVisitarMobile, setBannerVisitarMobile ] = useState([]);
		const [ clockImg, setClockImg ] = useState([]);
		const [ deliveryImg, setDeliveryImg ] = useState([]);
    const [showModal, setShowModal] = useState(true);

       
    
        useEffect(() => {
        /*getRecommendedHome().then((data) => {
            setProducts(data);
        });

        getOfertasHome().then((data) => {
            setOferts(data);
        });*/
        getImages("bg-visitar-tienda").then((data)=> {
            setBgVisitarTienda(data);
        });
        getImages('clock').then((data)=>{
            setClockImg(data);
        });
        getImages('delivery').then((data)=>{
            setDeliveryImg(data);
        });
        getGallery("elige-tu-combo-favorito").then((data)=> {
            setGalleryChooseYourFavoriteCombo(data);
        });
        getGallery("promocion-imperdible-mobile").then((data)=> {
            setGalleryUnmissablePromotionMobile(data);
        });
        getGallery("promocion-imperdible-desk").then((data)=> {
            setGalleryUnmissablePromotionDesk(data);
        });
        getGallery("cocina-cervalle").then((data)=> {
            setGalleryCervalleKitchen(data);
        });
        getImages('banner-home').then((data) => { 
            setBannerHome(data);
        });
        getImages('banner-visita-tienda').then((data) => {
            setBannerVisitar(data);
        });
				getImages('banner-visita-tienda-mobile').then((data) => {
					setBannerVisitarMobile(data);
				});
        if(localStorage.getItem('modal-suscripcion')){
            setShowModal(false);
        }
      
    }, []);

    useEffect(() => {
        setProducts([]);
        setOferts([]);
        getRecommendedHome(tiendaGeneral).then((data) => {
            setProducts(data);
        });
        getOfertasHome(tiendaGeneral).then((data) => {
            setOferts(data);
        })
    },[tiendaGeneral]);

    const gotoTop = event => {
        hideCart();
        window.scrollTo(0, 0);
    };

    return (
        <Fragment>
            {/* se oculta por peticion del cliente */}
            {/* <div className='p-2 px-4 note-publi'>
                <Link to='/news-letter'>{getTextByKey("news-letter")}</Link>
            </div> */}
            <BtnWhatsapp />

            <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal} />
					 { bannerHome?.length > 0 &&
            <div className="video-banner">
                { bannerHome[0].link ? (
                    <iframe className="video" src={bannerHome[0].link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    ) : (
                    <Picture src={bannerHome[0].image} alt={'bg-banner-home'} classImg={'w-100'}  />  
                    )
                }
                { bannerHome[0].names ? 
                <div className="video-banner-link text-center py-3 py-md-0">
                    <a href={bannerHome[0].url} target={bannerHome[0].url.startsWith("https:") ? "_blank" : "_self"} className="btn-g py-2 px-4 px-xl-5">{getPropiertyByLocale(bannerHome[0].names, "titulo")}</a>
                </div> : ''
                }
            </div>
            }
						 
            <div className="section-features-service py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-6 col-lg-5 col-xl-4 offset-md-2 offset-lg-0 mb-3 mb-lg-0">
                            <OverlayTrigger overlay={<Tooltip className="bg-transparent d-md-none"> <div dangerouslySetInnerHTML={{ __html: getTextBigByKey("horario_desc_mobile") }}  /> </Tooltip>}>
                                <span className="d-inline-block">
                                    <div className="d-md-flex text-center text-md-start">
                                        <div className="me-md-3">
                                            { clockImg && clockImg.length > 0 ? 
                                                <Picture src={clockImg[0].image} alt={'Clock fast icon'} classImg={'icon-singular-feature'} /> : ''
                                            }			
                                        </div>
                                        <div>
                                            <p className="gray-1-color source-sans-bold mb-0">{getTextByKey('horarios')}</p>
                                            <div className="mb-0 descripcion-features d-none d-md-block" dangerouslySetInnerHTML={{ __html: getTextBigByKey("horarios_desc") }}></div>
                                        </div>
                                    </div>
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div className="col-6 col-lg-5 col-xl-4 offset-md-2 offset-lg-0">
                        <OverlayTrigger overlay={<Tooltip className="bg-transparent d-md-none">
                        <div dangerouslySetInnerHTML={{ __html: getTextBigByKey("tiempos_desc_mobile") }}  />
                        </Tooltip>}>
                                <span className="d-inline-block">
                                    <div className="d-md-flex text-center text-md-start">
                                        <div className="me-md-3">
                                            { deliveryImg && deliveryImg.length > 0 ? 
                                                <Picture src={deliveryImg[0].image} alt={'Delivery fast icon'} classImg={'icon-singular-feature'} /> : ''
                                            }
                                        </div>
                                        <div>
                                            <p className="gray-1-color source-sans-bold mb-0">{getTextByKey('tiempo_entrega')}</p>
                                            <div className="mb-0 descripcion-features d-none d-md-block" dangerouslySetInnerHTML={{ __html: getTextBigByKey("tiempos_desc") }}></div>
                                        </div>
                                    </div>
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desk">
                { bannerVisitar.length > 0 && 
                    <div className="cta-visit-shop bg-options-repeat" style={{ backgroundImage: `url(${images_products_route + bannerVisitar[0].image})` }}>
                        <div className="cta-visit-shop-link">
                                <Link to={bannerVisitar?.[0]?.link} onClick={gotoTop} className="btn-g py-2 px-4 px-xl-5 text-nowrap">{getTextByKey('visitar-tienda')}</Link>
                        </div>
                    </div>
                }
            </div>
            <div className="mobile">
                { bannerVisitarMobile.length > 0 && 
                    <div className="cta-visit-shop bg-options-repeat" style={{ backgroundImage: `url(${images_products_route + bannerVisitarMobile[0].image})` }}>
                        <div className="cta-visit-shop-link">
                                <Link to={bannerVisitarMobile?.[0]?.link} onClick={gotoTop} className="btn-g py-2 px-4 px-xl-5 text-nowrap">{getTextByKey('visitar-tienda')}</Link>
                        </div>
                    </div>
                }
            </div>
            <div className="gray-1-color section-offers my-5">
                <div className="container">
                    <h2 className="primary-color text-center fw-bold mb-4">{getTextByKey('ofertas')}</h2>
                    <div className="position-relative">
                        <Swiper modules={[Navigation, Autoplay]}
                                navigation
                                autoplay={{ delay: 6000, disableOnInteraction: true }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    576: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                        spaceBetween: 0,
                                    },
                                }}>
                            {ofertas && ofertas.map((product, index) => (
                                <SwiperSlide key={index} className="px-4 px-xl-0">
                                    <ProductComponent key={index} product={product} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
            <section className="pink-1-bg-color white-color py-5 section-skew section-our-recommended">
                <div className="container">
                    <h2 className="text-center fw-bold mb-4">{getTextByKey('recomendados')}</h2>
                    <div className="position-relative">
                        <Swiper modules={[Navigation, Autoplay]}
                                navigation
                                autoplay={{ delay: 6000, disableOnInteraction: true }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 0,
                                    },
                                    576: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                        spaceBetween: 0,
                                    },
                                }}>
                            {products ? products.map((product, index) => (
                                <SwiperSlide key={index} className="px-4 px-xl-0">
                                    <ProductComponent key={index} product={product} />
                                </SwiperSlide>
                            )): ''}
                        </Swiper>
                    </div>
                </div>
            </section>
            {galleryChooseYourFavoriteCombo.length > 0 &&
                <div className="cta-choose-your-favorite-combo my-5">
                    <div className="container-fluid px-xl-4">
                        <h2 className="text-center primary-color fw-bold mb-4">{getTextByKey('elige_combo')}</h2>
                        <div className="row">
                        {galleryChooseYourFavoriteCombo.map((combo, index) => (
                            <div className="col-12 col-md-6 px-2 mb-2" key={index}>
                                <div className="singular-choose-your-favorite-combo">
									<Picture src={combo.image} alt={"bg-choose-your-favorite-combo"} classImg={"w-100 bg-singular-choose-your-favorite-combo"} />
                                    <div className="cta-choose-your-favorite-combo-link py-3 py-md-0">
                                      <a href={combo.link} className="btn-g py-2 px-4 px-xl-5">{getPropiertyByLocale(combo.names, 'titulo')}</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            }
            {galleryUnmissablePromotionMobile.length > 0 &&
                <div className="cta-unmissable-promo my-5 d-lg-none">
                    <div className="container-fluid gx-0">
                        <h2 className="text-center primary-color mb-4 fw-bold">{getTextByKey('promo_imperdible')}</h2>
                        <div className="row gx-0">
                            <div className="col-12">
                                <Swiper modules={[Navigation, Pagination, Autoplay]}
                                        /* pagination={{ clickable: true }} */
                                        autoplay={{ delay: 4000, disableOnInteraction: false }}
                                        slidesPerView={1}
                                        >
                                    {galleryUnmissablePromotionMobile.map((promotion, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="singular-choose-your-favorite-combo">
												<Picture src={promotion.image} alt={'bg-choose-your-favorite-combo'} classImg={'w-100 bg-singular-choose-your-favorite-combo'}/>
                                                <div className="cta-choose-your-favorite-combo-link py-3 py-md-0">
                                                    <Link to={promotion.link} className="btn-g py-2 px-4 px-xl-5">{getPropiertyByLocale(promotion.names, 'titulo')}</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {galleryUnmissablePromotionDesk.length > 0 &&
                <div className="cta-unmissable-promo my-5 d-none d-lg-block">
                    <div className="container-fluid gx-0">
                        <h2 className="text-center primary-color mb-4 fw-bold">{getTextByKey('promo_imperdible')}</h2>
                        <div className="row gx-0">
                            <div className="col-12">
                                <Swiper modules={[Navigation, Autoplay]}
                                        /* pagination={{ clickable: true }} */
                                        autoplay={{ delay: 4000, disableOnInteraction: false }}
                                        slidesPerView={1}
                                        >
                                    {galleryUnmissablePromotionDesk.map((promotion, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="singular-choose-your-favorite-combo">
																							 <Picture src={promotion.image} alt={'bg-choose-your-favorite-combo'} classImg={"w-100 bg-singular-choose-your-favorite-combo"} />
                                                <div className="cta-choose-your-favorite-combo-link py-3 py-md-0">
                                                    <Link  to={promotion.link} className="btn-g py-2 px-4 px-xl-5">{getPropiertyByLocale(promotion.names, 'titulo')}</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="quinary-bg-color white-color my-5 py-5 section-skew section-cook-with-us">
                <div className="container-fluid">
                    <div className="p-md-5 p-3">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-5 col-xl-3 mb-4">
                                <div className="text-center text-lg-start">
                                    <h1 className="tertiary-color carrefour-sans-bold mb-4">{getTextByKey('cocina_con_cervalle')}</h1>
                                    <div className="big-text" dangerouslySetInnerHTML={{ __html: getTextBigByKey("texto-cocina-cervalle-home") }}></div>
                                    <div className="mt-4">
                                        <Link to={getTextByKeyLink('todas_recetas')} className="btn-g py-2 px-4 px-xl-5">{getTextByKey('todas_recetas')}</Link>
                                    </div>
                                </div>
                            </div>
                                <div className="col-12 col-lg-6 col-xl-7 px-0 pe-xl-5 mb-4">
                                    {galleryCervalleKitchen.length > 0 && 
                                        <div className="row">
                                            {galleryCervalleKitchen.map((product, index) => (
                                                <div className="col-6 text-center mb-4" key={index}>
                                                    <div className="singular-product product-hover p-0">
                                                        <div className="image-product">
                                                        <Picture src={product.image} alt={"Product item"} classImg={"w-100 image"} />
                                                        </div>
                                                        <div className="mt-3">
                                                            <h3 className="mb-0 product-name">{getPropiertyByLocale(product.names, "titulo")}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
