import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
//import ReactImageMagnify from 'react-image-magnify';
import '../../scss/product_page.scss';
import { useCart } from "../../context/cartContext";
import { useAuth } from '../../authContext';
import { images_products_route, getProductDetail, getRecommendedHome } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductImage } from './components/ProductImage';
import QuantyControls from '../../components/QuantyControls'
import { ProductComponent } from '../../components/productComponent';
import {Migas} from '../../components/Migas';
import { RatingStar } from '../../components/RatingStar';
import { useState } from 'react';
import { getRelacionados } from '../../api/productApi';
import Picture from '../../components/Picture';
import {  default as ModalGramaje} from './components/ModalGramaje';
import ReviewComponent from "../../components/reviewComponent";



import { Navigation, Pagination, Autoplay, Thumbs, FreeMode, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function ProductPage() {

  const params = useParams();

  const { getTextByKey, getPropiertyByLocale, convertCurrency, setModalInicio, setModalCompra ,setShowAlertaTope } = useApi();

  const [productData, setProductData] = useState(0);

  const [products, setProducts] = useState([]);

  const [quantity, setQuantity] = useState(1);


  const { addToCart, showCart, checkIfExistInCart,
          currentDelivery, addToCartOld,
          setCurrentProduct, tiendaGeneral,tope,currentCart,limite,agotado } = useCart();

  const { currentUser } = useAuth();        
  const [widthheight, setWidthheight] = useState({ width: 0, height: 0 });

  const [showGramaje, setShowGramaje] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [selectedGramaje, setSelectedGramaje] = useState(0);
  const [error, setError] = useState('');
  const [ limiteDcto, setLimiteDcto ] = useState(false);

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const getBreadcrumps = () => {
    const paths = [
      {to: '/shop', name: 'Tienda'}
    ];
    if (productData!=0) {
      paths.push(
        {
          to: '',
          name: productData?.name?.nombre
        }
      )
    }
    return paths;
  }

  const handleQuantityChange = (data) => {
    setQuantity(data);
  };

  const addCart = (event, product) => {
    event.stopPropagation();
    event.preventDefault();
    product.gramaje=0;
    product.id=product.plu;
    if(product.gramajes.length>0){
      if(selectedGramaje==0){
        setError('Escoge un tamaño');
        return;
      }else{
        product.gramaje=selectedGramaje;
        product.id=product.plu+''+selectedGramaje.id;
      }
    }
    product.type="product"; 
    if(currentUser){
       if(!tiendaGeneral){
        addToCartOld(product,quantity);
        setModalCompra(true);
      }
    }else{
      addToCartOld(product,quantity);
      setModalInicio(true);
     
    }
    
    if(tiendaGeneral){
      addToCart(product, quantity);
      if(currentCart.length == 0){
        showCart();
      }
    }
  }

  const checkIfExist = (actualItemId) => {
    return checkIfExistInCart(actualItemId);
  }

  const changeCantidad = (e) => {
    setCant(e.target.value);
  }

  /*const handleTalla = (talla) => {
    setProductData({
      ...productData,
      talla: talla
    });
  }

  const handleColor = (color) => {
    setProductData({
      ...productData,
      color: color
    });
  }*/

  useEffect(() => {
    window.scrollTo(0, 0);
    setProductData(0);
    getProductDetail(params.plu,tiendaGeneral).then((data) => {
      setProductData(data);
      if(data){
        let newImg = new Image();
        newImg.onload = function() {
          var height = newImg.height;
          var width = newImg.width;
          setWidthheight({ width: width, height: height });
        }
        newImg.src = images_products_route + data.images[0].image;
      }
      if(data.price.precio_sin_descuento){
        setLimiteDcto(3);
      }
    }).catch(error=>{
      
    });
   
    
  }, [params.plu,tiendaGeneral]);

  useEffect(() => {
    if(productData){
      getRelacionados(productData?.plu,tiendaGeneral).then((data)=>{
        setProducts(data);
      }) 
    }
   
  }, [productData,tiendaGeneral]);


  function findPrincipal(images){
    return(
      images.find( e => e.principal)
    )
  }

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  let paramsGallery = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 30,
    slidesPerView: 1,
    simulateTouch: true,
    lazy: true,
    loop: true,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  let paramsThumbs = {
    modules: [ Scrollbar, A11y, Thumbs, FreeMode],
    spaceBetween: 1,
    slidesPerView: 5,
    lazy: true,
    freeMode: true,
    // loop: true,
    direction: "vertical"
  }

  const handleGramaje = (event) => {
    setError('');
    let gramaje=productData.gramajes.find((gramaje)=>gramaje.id==event.target.value);
    if(gramaje){
      setSelectedGramaje(gramaje);
    }else{
      setSelectedGramaje(0);
      setError('Escoja un tamaño');
    }
  }

  function handleSelectedGramaje(gramaje) {
    console.log('Selected Gramaje:', gramaje);
    setSelectedGramaje(gramaje);
    setShowGramaje(false); // close the modal
  }



  if(productData==0)
    return (
      <div className='container'>
        <div className="text-center-c">
          <div className="loader-box">
            <img src="/img/SVG/loader.svg" alt="" />
          </div>
        </div>
      </div>
    );


  return (
    <Fragment>

      <div className='product-page my-5'>
        {productData!=0 && productData?.plu ? (
          <div className='container'>
              <Migas className="py-3" paths={getBreadcrumps()} />
            <div className='row'>
              <div className="col-xl-7 col-lg-12">
                <div className='form-group only-mobile'>
                  <h1 className='primary-color fw-bold mb-0 text-center'>{productData.name.nombre}</h1>
                  {productData.categorias && productData.categorias.length > 0 && (
                    <>
                     {productData.categorias.map((categoria) => (
                    <p className='sm-p gray-1-color' key={categoria.id}>{categoria.nombre}</p>
                  ))}
                  </>
                  )}
                            
                </div>
                <div className="only-mobile mb-3">
                  <div className="d-flex align-items-center">
                    <div className="form-group d-flex align-items-center">
                  
                    {productData.price.precio_sin_descuento ? <span className='price-before price-normal me-2'> {convertCurrency(productData.price.precio_sin_descuento)}</span>:<></>} 
                      <h3 className='source-sans-bold gray-1-color mb-0'>
                       
                        {convertCurrency(productData.price.precio)}
                      </h3>
                      { (productData.price.precio_sin_descuento != undefined && productData.price.precio_sin_descuento > 0 ) && 
                        <p className="d-inline-block tertiary-bg-color white-color source-sans-bold rounded py-1 px-2 mb-0 ms-2">
                          { (((productData.price.precio_sin_descuento - productData.price.precio) * 100) / (productData.price.precio_sin_descuento)).toFixed(0)}%
                        </p>
                      }
                    </div>
                    <p className='sm-p gray-1-color ms-4'>Gramo a {(productData.price.precio/productData.peso).toFixed(2)}</p>
                  </div>
                </div>
                <div className='images'>
                  <div className="row">
                    <div className="col-2 d-none d-lg-block">
                        <Swiper {...paramsThumbs} watchSlidesProgress onSwiper={setThumbsSwiper}>
                            {productData.images.length &&
                             productData?.images
                             ?.sort((a, b) => a.orden - b.orden)
                             ?.map((image, index) => {
                                return(
                                  <SwiperSlide className='w-slide my-1' key={index}>
                                    <div className='h-100 overflow-hidden rounded'>
                                      <Picture classImg={"h-100 img-product"} src={image.image} alt={'producto miniatura'} />
                                    </div>
                                  </SwiperSlide>
                                );
                            })}
                      </Swiper>
                    </div>
                    <div className="col-lg-10 col-12">
                      <Swiper {...paramsGallery} thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}>
                          {
                            productData.images.length && 
                            productData.images?.sort((a, b) => a.orden - b.orden)
                            ?.map((image, index) => {
                                return(
                                  <SwiperSlide key={index}>
                                      <ProductImage imageSrc={ image.image && images_products_route + image.image } />
                                  </SwiperSlide>
                                );
                            })}
                            <div className="only-mobile">
                              <div className="swiper-button-next"></div>
                              <div className="swiper-button-prev"></div>
                            </div>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-12">
                  <div className='product-description'>
                    <form className='px-lg-4'>
                      <div className='form-group only-desk'>
                      {productData.categorias && productData.categorias.length > 0 && (
                      <>
                      {productData.categorias.map((categoria) => (
                      <p className='sm-p gray-1-color' key={categoria.id}>{categoria.nombre}</p>
                     ))}
                     </>
                     )}
                     
                        <h1 className='primary-color fw-bold mb-0'>{productData.name.nombre}</h1>
                      </div>
                      <hr className='hr-color-1' />
                      <div className="only-desk">
                        <div className="form-group d-flex align-items-center">
                            { selectedGramaje && selectedGramaje.price ?
                              ( selectedGramaje.price.precio_sin_descuento ? 
                              <h3 className='source-sans-bold gray-1-color mb-0'>
                                <span className='price-normal me-3'>{convertCurrency(selectedGramaje.price.precio_sin_descuento)}</span>
                                {convertCurrency(selectedGramaje.price.precio) }
                              </h3> : 
                              <h3 className='source-sans-bold gray-1-color mb-0'>
                                {convertCurrency(selectedGramaje.price.precio) }
                              </h3>
                                
                              ) :
                              (
                                productData.price.precio_sin_descuento ?
                                <h3 className='source-sans-bold gray-1-color mb-0'>
                                 <span className='price-normal me-3'> {convertCurrency(productData.price.precio_sin_descuento)}</span>
                                 {convertCurrency(productData.price.precio)}
                                </h3> :
                                 <h3 className='source-sans-bold gray-1-color mb-0'>
                                  {convertCurrency(productData.price.precio)}
                                 </h3>
                                
                              )
                            }
                         
                          { selectedGramaje && selectedGramaje.price && selectedGramaje.price.precio_sin_descuento ?
                            (
                              <p className="d-inline-block tertiary-bg-color white-color source-sans-bold rounded py-1 px-2 mb-0 ms-4">
                                { (((selectedGramaje.price.precio_sin_descuento - selectedGramaje.price.precio) * 100) / (selectedGramaje.price.precio_sin_descuento)).toFixed(0)}%
                              </p>
                            ): 
                            ( (productData.price.precio_sin_descuento != undefined && productData.price.precio_sin_descuento > 0)  && (
                              <p className="d-inline-block tertiary-bg-color white-color source-sans-bold rounded py-1 px-2 mb-0 ms-4">
                                {(((productData.price.precio_sin_descuento - productData.price.precio) * 100) / (productData.price.precio_sin_descuento)).toFixed(0)}%
                              </p>
                            ))
                          }
                          
                        </div>
                        { selectedGramaje && selectedGramaje.pesofinal ? 
                          (<p className='sm-p gray-1-color'>Gramo a ${(selectedGramaje.price.precio/selectedGramaje.pesofinal).toFixed(2)}</p>) :
                          ( productData.peso ? 
                            <p className='sm-p gray-1-color'>Gramo a ${(productData.price.precio/productData.peso).toFixed(2)}</p>
                            : '' )
                        }
                      </div>
                
  

                      <div className='form-group'>
                        <div className='gray-1-color py-2' dangerouslySetInnerHTML={{__html: productData.resume.resumen}}></div>
                        <div className='gray-1-color py-2' dangerouslySetInnerHTML={{__html: productData.preparacion.fabricacion}}></div>
                        <div className='gray-1-color py-2' dangerouslySetInnerHTML={{__html: productData.presentacion.inspiracion}}></div>
                      </div>

                      { productData.gramajes.length > 0 && 
                      <div className='row secundary-bg-color d-flex rounded p-2 d-flex align-items-center mb-3'>
                        <div className='col-7 d-flex align-items-center'>
                          <Icon className='primary-color' icon="ic:outline-plus"></Icon>
                          <p className="mb-0 ms-2 gray-1-color"><strong>Elige el tamaño de tu producto:</strong></p>
                        </div>
                        <div className="col-5">
                          <select className='w-100 text-capitalize' name="" id="" onChange={handleGramaje}>
                            <option value="">Escoge</option>
                            {
                              productData.gramajes.map((gramaje, index)=>(
                                <option className='text-capitalize' key={index} value={gramaje.id}>
                                 <span>{gramaje.nombre}</span> <span className='ms-3'>{gramaje.price && gramaje.price.precio}</span>
                                </option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      }
                        {error && <p className="alert-danger">{error}</p> }
                      <ModalGramaje 
                        gramajes={productData.gramajes} 
                        showGramaje={showGramaje} 
                        setShowGramaje={setShowGramaje}
                        onSelectedGramaje={handleSelectedGramaje} />

                      { productData.cantidad <= agotado && <p className="gray-1-color py-2">Disponibilidad: <strong>AGOTADO</strong></p> }
                      { productData.cantidad > agotado &&
                        <div className='d-flex flex-wrap align-items-center buttons-container'>
                        <div className='col-md-5 col-5'>
                          <QuantyControls 
                          quantity={quantity}
                          agrega={false}
                          product={productData}
                          setQuantity={setQuantity}
                          onQuantityChange={handleQuantityChange} 
                           className="quanty-center"  />
                        </div>
                        <div className='col-md-6 col-7'>
                          <button onClick={(event) => addCart(event, productData)} 
                            className={!checkIfExist(productData.plu) ? 
                            'btn-g py-2 px-4 me-2 d-flex align-items-center btn-add-car pointer' : 
                            'btn-g py-2 px-4 me-2 d-flex align-items-center btn-add-car pointer animate__animated animate__bounce'}>
                            <Icon className={!checkIfExist(productData.plu) ? 
                              'icon-add-car me-2 me-md-3' : 'icon-add-car me-2 me-md-3 animationIconCart'} 
                              icon="carbon:shopping-cart" />

                            {!checkIfExist(productData.plu) ? `${getTextByKey('agregar-carrito')}` : `${getTextByKey('agregado-carrito')}`}
                          </button>
                        </div>
                      </div>}
                    </form>
                  </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className="text-center-c">
              <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
              </div>
            </div>
          </div>
        )}
          <ReviewComponent objectType='product' objectReviewId={productData.plu} />
        {/*<section className='reviews gray-1-color my-3 mt-5'>
          <div className='container pt-5'>
            <h3 className='primary-color text-center carrefour-sans-bold mb-5'>{getTextByKey('resenas')}</h3>
            <div className="row py-2">
              <div className="col-12 col-md-7 col-xl-6">
                  <h3 className='mb-4 fw-bold'>{getTextByKey('resumen-opiniones')}</h3>
                  <div className='row'>
                    <div className='col-lg-5 col-6'>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='me-2'>5</span>
                        <div className="progress w-100">
                          <div className="progress-bar rounded-pill" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='me-2'>4</span>
                        <div className="progress w-100">
                          <div className="progress-bar rounded-pill" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='me-2'>3</span>
                        <div className="progress w-100">
                          <div className="progress-bar rounded-pill" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='me-2'>2</span>
                        <div className="progress w-100">
                          <div className="progress-bar rounded-pill" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='me-2'>1</span>
                        <div className="progress w-100">
                          <div className="progress-bar rounded-pill" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3 col-6'>
                      <div className="text-center">
                        <span className='overall-score'>4,3</span>
                        <div className='d-flex justify-content-center'>
                          <Icon className="primary-color me-1" icon="bi:star-fill" />
                          <Icon className="primary-color mx-1" icon="bi:star-fill" />
                          <Icon className="primary-color mx-1" icon="bi:star-fill" />
                          <Icon className="primary-color mx-1" icon="bi:star-fill" />
                          <Icon className="primary-color mx-1" icon="bi:star" />
                        </div>
                        <p className='gray-2-color mb-0'>3 opiniones</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-12 offset-md-1 col-md-4 offset-xl-2 col-xl-3 my-3 my-lg-0 pt-5">
                <div className="order-by px-2 pt-3">
                    <label htmlFor="select-sort-by" className='d-block w-100'>Ordenar por:</label>
                    <select name="" id="select-sort-by" className='border-0 border-bottom d-block w-100'>
                      <option defaultValue="">Más relevantes</option>
                      <option defaultValue="">Otra opción</option>
                    </select>
                </div>
              </div>
            </div>
            <hr className='hr-color-1' />
            <div className='the-reviews'>
              <div className='singular-review py-2 my-3'>
                <p className='fw-bold mb-0 gray-1-color'>Muy buenos</p>
                <p className='mb-0 gray-1-color'>Por Juan</p>
                <div className='d-flex mb-4'>
                    <Icon className="primary-color me-1" icon="bi:star-fill" />
                    <Icon className="primary-color mx-1" icon="bi:star-fill" />
                    <Icon className="primary-color mx-1" icon="bi:star-fill" />
                    <Icon className="primary-color mx-1" icon="bi:star-fill" />
                    <Icon className="primary-color mx-1" icon="bi:star" />
                </div>
                <p className='mt-2 gray-2-color'>Cumplen con todas mis expectativas</p>
                <hr className='hr-color-1' />
              </div>
            </div>
            <div className='p-2'>
              <h4 className='source-sans-semibold mb-4'>Escribe una reseña</h4>
              <form action="" className='p-lg-0 p-2'>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="form-group">
                      <label htmlFor="input-name" className='d-block w-100'>{getTextByKey('nombres')}</label>
                      <input type="text" id='input-name' placeholder='Ingresa tu nombre' />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="form-group">
                      <label htmlFor="input-email" className='d-block w-100'>{getTextByKey('correo_electronico_form')}</label>
                      <input type="email" id='input-email' placeholder='Ingresa tu correo electrónico' />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <p className='mb-0'>Calificar</p>
                    <RatingStar />
                  </div>
                  <textarea></textarea>
                  <div className='text-end mt-4'>
                    <button type='submit' className='btn-g primary-color py-2 px-5'>{getTextByKey('enviar')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>*/}





      <div className="others-products my-5">
        <div className="container-fluid px-3">
          <h3 className="text-center primary-color mb-4 carrefour-sans-bold">Otros productos</h3>
          <div className="px-xl-3">
            <div className="row">
              <div className="col-12">
              <div className="position-relative swiper-recomdendados">
                <Swiper modules={[Autoplay, Navigation]}
                    navigation
                    autoplay={{ delay: 4000, disableOnInteraction: true }}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 0,
                        },
                        }}>
                 
                    {products ?  products.map((product, index) => (
                        <SwiperSlide key={index}>
                            <ProductComponent key={index} product={product} />
                        </SwiperSlide>
                    )): ''}
                      
                </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>




      {/* <div className='container'>
        <div className='text-center py-2 py-md-3 py-lg-5'>
          <h2 className='text-uppercase'>{getTextByKey('best-sellers')}</h2>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-10 offset-1 px-0 mb-4'>
            <MasvendidosSliderComponent />
          </div>
        </div>
      </div> */}

      {/* <div className="modal fade" id="FindmySizeModal" tabIndex="-1" aria-labelledby="FindmySizeModal" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content py-4">
            <a className='close-modal primary-color' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ci:close-big" /></a>
            <div className="modal-body">
              <FindmysizeComponent/>
            </div>
          </div>
        </div>
      </div> */}

      

    </Fragment>
  )
}
