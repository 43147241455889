import React from 'react'
import { Fragment } from 'react'
import '../../scss/womenPage.scss'

export default function WomenPage() {
  return (
    <Fragment>
        <div className='banner banner-women position-relative'>
            <img className='w-100' src="/img/women.jpg"/>
            <div className='container text-center'>
                <h2>SHIDO WOMEN IN ALL LATAM ARE<br/> COMING TO STAND TOGETHER</h2>
                <p>COMING SOON</p>
            </div>
        </div>
    </Fragment>
  )
}
