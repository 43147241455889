import React, {useState, useEffect} from 'react'
import { Icon } from '@iconify/react'
import '../scss/carrito.scss'
import { useCart } from "../context/cartContext";
import Picture from './Picture';
import { useApi } from '../context/apiContext';
import { QuantyControls } from '../components/QuantyControls';
import AlertaEliminarProducto from './AlertaEliminarProducto';

export default function ItemCarrito({ item, index, min }) {

    const { updateCart,updateTope,tope,pesoTotal,limite,limitePromo } = useCart();
		const { convertCurrency, setShowAlertaTope  } = useApi();
    const [ showEliminar, setShowEliminar ] = useState(false);
    const [ limiteDcto, setLimiteDcto ] = useState(false);
	const [ alertaTope, setAlertaTope ] = useState(false);
        const peso =  item.peso * item.quantity;

    const removeItem = () => {
        setShowEliminar(true);
    }
		
    useEffect(()=>{
      
       if(item.price.precio_sin_descuento){
            setLimiteDcto(limite);
        }
    },[item.quantity])

    const handleQuantityChange = (newQuantity) => {
        const newProduct = { ...item, quantity: newQuantity };
        updateCart(index, newProduct);
    };

    const renderProductItem = () => {
        return (
        <div className={min ? 'item-carrito-min item-carrito mb-3 me-2 animate__animated animate__fadeIn animate__delay-1s' : 'item-carrito mb-2 me-2 animate__animated animate__fadeIn animate__delay-1s'}>
 
            <div className="d-flex py-1">
                <div className="img-cart">
                    {item.images.length > 0 && <Picture classImg={'w-100 rounded'} src={item.images[0].image} alt={'item carrito'} />}
                </div>
                <div className="info-cart">
                    <div className="row">
                        <div className="col-10">
                            <h4 className="gray-1-color source-sans-bold mb-1">{item.name.nombre}</h4>
                            {item.gramaje!=0 &&
                               <h6>{item.gramaje.nombre}</h6>
                             }
                        </div>
                        
                        <div className="col-2">
                        <div className="text-end">
                            <a className='primary-color icon-eliminar' onClick={()=>removeItem(item)}><Icon icon="akar-icons:trash-can" /></a>
                        </div>
                        </div>
                    </div>
            
                    <div className="text-end">
             
                        { item.peso ? <p className='ms-lg-4 ms-0 mb-0'>Peso: {peso} gr</p>: ""}
                    </div>
                    <div className="row py-2">
                        <div className="col-7">
                            <QuantyControls product={item} quantity={item.quantity} onQuantityChange={handleQuantityChange}/>
                        </div>
                        <div className="col-5">
                            <p className='gray-1-color source-sans-bold mb-0 md-p my-lg-0 my-2'>{item.quantity} x {convertCurrency(item.price.precio)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <AlertaEliminarProducto nameProd={item.name.nombre} index={index} showEliminar={showEliminar} setShowEliminar={setShowEliminar} />
        </div>
        )
    }

    return (
        <>
            {
                item.type === "giftcard" ? renderCardGiftItem() : renderProductItem()
            }
        </>
    )
}
