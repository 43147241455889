import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useApi } from '../../../context/apiContext';


export default function ModalGuardarDireccion({ showModalGuardar, setShowModalGuardar }) {

    const { getTextByKey } = useApi();


    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
      setShowModalGuardar(false);  
      location.href='/profile-address'
        //gotoTop();
    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalGuardar} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                      <h4 className='gray-1-color'>{getTextByKey('guardar_cambios')}</h4>
                    </div>
                      <div className="text-center">
                      <p className='gray-1-color px-5 my-3'>{getTextByKey('alerta_modal_guardar_direccion')}</p>
                        <Link to="/profile-address" className='btn-g px-4'>{getTextByKey('aceptar')}</Link>
                      </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}
