import { Fragment, useState, useEffect } from "react";
import { Link, useParams} from "react-router-dom";
import { getGallery } from "../../api/galleryApi";
import { useApi } from "../../context/apiContext";
import Picture from "../../components/Picture";
import { getRecipeCategories, getRecipes, filterRecipe } from "../../api/recipesApi";


import '../../scss/recipes_page.scss';
import "../../scss/footer.scss";
import RecipeComponent from "../../components/recipeComponent";

export default function RecipesPage() {
    const { getTextByKey, getTextBigByKey, getPropiertyByLocale, currentLocale } = useApi();
    const [ recetas, setRecetas ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ banner, setBanner ] = useState([]);
    const [ showMore, setShowMore ] = useState(false);
    const [ isActive, setIsActive ] = useState('all');
    const [scrollPosition, setScrollPosition] = useState(0);

    const params = useParams();

    useEffect(() => {
        getRecipeCategories(currentLocale).then((data) => {
            setCategories(data)
        })
      getRecipes({locale: currentLocale}).then((data)=>{
        setRecetas(data)
      })
      getGallery('recipes-banner').then((images) => {
        setBanner(images[0].image);
      })
      window.scroll(0, 0);
    
    }, [])

    useEffect(()=>{
       if(params.category){
           const cat=categories.find((c)=>c.slug==params.category);
          handleCategory(cat?.id);
       }else{
        handleCategory('all');
       }
      
    },[params,categories])

    const handleCategory = (categoryId) => {
 
        if(categoryId==='all'){
					setIsActive('all');
					getRecipes({locale: currentLocale}).then((data)=>{
						setRecetas(data);
                        window.scroll(0, 450);
					})
        } else{
					setIsActive(categoryId);
					filterRecipe(categoryId).then((data) => {
						console.log(data, 'filtro recetas');
						setRecetas(data.list);
                        window.scroll(0, 450);
					 })
                   
        }
        
      
    }

    
    
    return (
        <Fragment>
        {categories?.length==0 ?
         <>
        <div className='container'>
            <div className="text-center-c">
            <div className="loader-box">
                <img src="/img/SVG/loader.svg" alt="" />
            </div>
            </div>
        </div>
        </>
        :
       
            <div className="recipes-page">
                <div className="banner-recipes gray-1-color">
                    <div className="container-fluid gx-0">
                        <div className="row align-items-center gx-0">
                            <div className="col-12 col-lg-6">
                                <div className="p-2 p-md-5 text-center text-lg-start">
                                    <h1 className="primary-color fw-bold mb-0">{getTextByKey('titulo-cocina-cervalle')}</h1>
                                    <div className="text-recetas-general" dangerouslySetInnerHTML={{ __html: getTextBigByKey("texto-pregunta-cocina-cervalle") }}></div>
                                    <div className="text-recetas-general" dangerouslySetInnerHTML={{ __html: getTextBigByKey("texto-cocina-cervalle") }}></div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <Picture src={banner} alt={"Bg Recipes"} classImg={"w-100 img-principal"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="recipes mb-5">
                    <div className="container px-5">
                        <div className="filters d-flex justify-content-center aligns-items-center flex-wrap mt-3 mb-5">
                           <h4 className={`singular-filter cursor-pointer my-2 mx-3 ${isActive === 'all' ? 'active' : ''}`} 
															onClick={() => handleCategory('all')} >Todas</h4>
                            {
                                categories.map(category => (
                                    <h4 onClick={() => handleCategory(category.id)} className={`singular-filter cursor-pointer my-2 mx-3 ${isActive == category.id ? 'active' : ''}`} >{category.name}</h4>
                                 
                                ))
                            }
                              {/* <h4><Link onClick={() => handleCategory(category.id)} key={category.id} to={`/recetas/${category.id}`} 
																			className={`singular-filter my-2 mx-3 ${isActive == category.id ? 'active' : ''}`}>{category.name}</Link>
                                </h4>*/}
                        </div>
                        <div className="row" >
													{ recetas.length === 0 ? (
															<div className="text-center">
																<h3 className="source-sans-bold gray-1-color">{getTextByKey('no-recetas')}</h3>
															</div>
																
														) : (
																recetas.slice(0, showMore ? recetas.length : 3).map((receta)=>(
																		<RecipeComponent key={receta.id} recipe={receta} />
																))
														)}           
                        </div>
                        <div className="text-center mt-3 mt-xl-5">
                            <button type="button" className="btn-g primary-bg-color py-1 px-5" onClick={()=> setShowMore(!showMore)}>
                                
                                { showMore ? 
                                    `${getTextByKey('mostrar-menos')}` : 
                                    `${getTextByKey('mostrar-mas')}`
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          }
        </Fragment>
        
    );
}
