import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { default as ModalConfirmarEntrega } from './ModalConfirmarEntrega';
import { default as ModalNuevaDireccion } from './ModalNuevaDireccion';
import { listAddressUser } from '../../api/addressApi';
import { useAuth } from '../../authContext';
import { getKml } from '../../api/storeApi';
import { useCart } from '../../context/cartContext';
import { getHorarios} from '../../api/storeApi';
import { ActualizaTecnocarnes, getInventario } from '../../api/productApi';
import ModalProgramarPedido from './ModalProgramarPedido';

export default function ModalDireccionEnvio({ showModalDireccion, addressList,setShowModalDireccion, modalNuevaDireccion, setModalNuevaDireccion, setShowModalCompra }) {

    const { getTextByKey, getPropiertyByLocale } = useApi();
    const { currentProduct,updateTienda, updateDireccion,setCurrentDelivery,direccion,updateCurrentdelivery,
      validarCart, validarCartOld,currentCart,tiendaGeneral } = useCart();
    const { currentUser } = useAuth();
    const [ modalConfirmar, setModalConfirmar ] = useState(false);
    const [ disponible, setDisponible ] = useState(0);
   const [ tienda,  setTienda ] = useState(0);
   const [ dir,  setDir ] = useState(0);
   const [ loadDir,  setLoadDir ] = useState(false);
   const [ error, setError ] = useState('');
    const [ kml, setKml ] = useState([]);
    const [ msg, setMsg ] = useState('');
    const [ tiendaId, setTiendaId  ] = useState(0);
    const [ horario, setHorario ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ showProgramar, setShowProgramar ] = useState(false);

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };
    
    const handleClose = () => {
        setShowModalDireccion(false);
        setShowModalCompra(false);
        gotoTop();
    }

    const volverModal = () => {
      setShowModalDireccion(false);
      setShowModalCompra(true);
    }

    const validaDir=()=>{
      if(dir==0){
          setError('Seleccione una dirección');
        return;
      }
      if(tienda==0){
        setError('No tiene cobertura');
        return
      }
      confirmModal();
    }

    const confirmModal =async () => {
       
      console.log(currentCart, currentCart.length);
      try{
        
        if(currentCart.length>0){
        let carritoajustar =await validarCart(tiendaGeneral);
          if(carritoajustar.ok){
            setDisponible(carritoajustar.total);
            setShowModalDireccion(false);
            setShowModalCompra(false);
            setModalConfirmar(true);
            setDir(0);
            setTienda(0);
          }
       }else{
        let carritoajustar =await validarCartOld(tiendaGeneral);
        if(carritoajustar.ok){
          setDisponible(carritoajustar.total);
          setShowModalDireccion(false);
          setShowModalCompra(false);
          setModalConfirmar(true);
          setDir(0);
          setTienda(0);
        }
       }
       return true;
      } catch (error) {
        return true;
        // Manejar el error si ocurre
        console.error(error);
      }
     
    }

    const nuevaDireccion = () => {
      setShowModalDireccion(false);
      setModalNuevaDireccion(true);
    }

    const getAddresses = () => {
      if(currentUser){
        listAddressUser(currentUser.uid).then((addressResponse)=>{
          setAddressList(addressResponse);
        })
      }
    }

    
    
    const handleChange = async (event) => {
      event.preventDefault();
      setError('');
      setLoadDir(true);
      setTienda(0);
      if(event.target.value==0){
        setError('Escoge una dirección');
        setLoadDir(false);
        return;
      }
     
      let dir= addressList.find((dir)=>dir.id==event.target.value);
      if(dir.id){
        setDir(dir.id);
        let tienda= await getKml(dir.lat, dir.lng) 
        if(tienda.id){
          let tecnocarnes=await ActualizaTecnocarnes(tienda.id);
				  console.log(tecnocarnes,'tecnocarnes');
          updateTienda(tienda.id);
          setTienda(tienda.id);
          updateCurrentdelivery({tipo:'', dir: {id:dir.id}});
		      updateDireccion(dir.id);
         
        }else{
           setError('No tiene cobertura');
        }
      }else{
         setError('No tiene dirección');
      }
      setLoadDir(false); 
     /*const options = event.target.options;
      const selectedAddresses = [];
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.selected && option.value) {
          const selectedAddress = addressList.find(address => address.id === parseInt(option.value));
          if (selectedAddress) {
            selectedAddresses.push(selectedAddress);
          }
        }
      }*/
      //console.log('direccion', selectedAddresses);
     
      

    }
    
    useEffect(() => {
      if(tiendaId){
        getHorarios(tiendaId).then((data)=>{
          setHorario(data);
          setShowProgramar(true);
          setLoading(false);
        }).catch((error)=>{
            setError('La tienda no tiene horarios');
            setLoading(false);
        })
      }else{
        setHorario([]);
        setError('');
        setLoading(false);
      }
    }, [tiendaId])

      /* useEffect(()=>{
      if(tienda && tienda.length > 0){
        const latC = tienda[0].lat;
        const lngC = tienda[0].lng;
  
        getKml(latC, lngC).then((data)=>{ 
          //console.log('kml', data); 
          setKml(data);     
        })
        updateDireccion(tienda[0].id);
        console.log(currentProduct);
      }
    }, [tienda])*/

    /*useEffect(()=>{
      if(currentProduct && kml && kml.id){
        currentProduct.forEach((item)=>{
          getInventario(item.product.plu, kml.id).then((data)=>{
            setMsg(data);
          });
        })
      }
    }, [kml])*/


    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalDireccion } onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="px-3">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('direccion-envio-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('selecciona-modal-direccion')}</p>
                      </div> 
                      <form action="" className='pb-4 pt-3'>
                        <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-2">
                            { addressList ?
                             (<select className='p-2 gray-1-color' name="direccion" onChange={handleChange}>
                              <option value={0}>{getTextByKey('selecciona_direccion')}</option>
                              {
                                addressList.map((address, index) => (
                                  <option key={index} value={address.id}>{address.address} - {getPropiertyByLocale(address.ciudades, "nombre")}</option>
                                )) 
                              }
                            </select>) : (
                              <p className='gray-1-color source-sans-bold'>{getTextByKey('sin-direcciones')}</p>
                            ) }
                          </div>
                        </div>
                        </div>
                      </form>
                      
                     {error && <p className="alert-danger">{error}</p> }
                      <div className="text-center pt-3 d-flex flex-md-row flex-column  justify-content-center align-items-center gap-2">
                          <button className='btn-g bb px-5' onClick={volverModal}>{getTextByKey('volver')}</button>
                          <button className='btn-g bb px-3 mx-2' onClick={nuevaDireccion}>{getTextByKey('agregar_direccion')}</button>
                          <button className='btn-g px-4' disabled={loadDir} onClick={validaDir}>{ !loadDir ? getTextByKey('confirmar') : 'verificando...'}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalNuevaDireccion modalNuevaDireccion={modalNuevaDireccion} confirmModal={confirmModal} setModalNuevaDireccion={setModalNuevaDireccion} setTiendaId={setTiendaId} tiendaId={tiendaId} setShowProgramar={setShowProgramar} setShowModalDireccion={setShowModalDireccion}/>
            <ModalConfirmarEntrega msg={msg} disponible={disponible} modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/>
            <ModalProgramarPedido msg={msg} tiendaId={tiendaId} horario={horario} showProgramar={showProgramar} setShowProgramar={setShowProgramar} setShowModalRecoger={setModalNuevaDireccion} />
        </Fragment>
    )
}
