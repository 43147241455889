import { Fragment } from "react";
import { SlideBComponent } from "./components/SliderBComponent";
import  SliderComponent  from "./components/SliderComponent";

export default function FeedbacksliderPage() {
  return (
    <Fragment>
        <div className="banner feedback position-relative">
            <img className="w-100 fondo" src="img/Group-142.jpg"/>
            <div className="content-banner">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                    <SliderComponent/>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                    <SlideBComponent/>
                  </div>
                </div>
              </div>
            </div>
        </div> 
    </Fragment>
  )
}
