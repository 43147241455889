import Modal from 'react-bootstrap/Modal';
import React, { Component, Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { createTarjeta } from '../../../api/productApi';
import { useAuth } from '../../../authContext';
import { useApi } from '../../../context/apiContext';
import { BtnClose } from '../../../components/BtnClose';
import { Icon } from '@iconify/react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Modalmetodo({ updateTarjetas, showModal, setShowModal }) {

    const { getTextByKey } = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const [cardBrand, setCardBrand] = useState('');

    const formatCard = (e) => {
        let card = e.target.value;
        card = card.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
        if (card.length > 19) {
            card = card.substring(0, 19);
        }
        e.target.value = card;
        getBrandFromCard(card);
    }

    const createNewCard = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        let number = (e.target.number.value).replace(/\s/g, '');
        const card = {
            "brand": getBrandFromCard(number),
            "number": number,
            "titular": e.target.names.value + " " + e.target.lastnames.value,
            "year": e.target.year.value,
            "month": e.target.month.value,
            "cvv": e.target.cvv.value,
            "uid": currentUser.uid,
            "cuota": 1
        }

        let formularioVacio = false;

        Object.keys(card).forEach( (key) => {
            if( card[key] === '' ) {
                formularioVacio = true;
            }
        })
        
        if(formularioVacio) {
            setLoading(false);
            setError('Todos los campos son obligatorios');
            setTimeout(() => {
                setError('')
            }, 5000);
            return;
        }
        if(number.length==15){
            setLoading(false);
            setError('Revisa el número de tu tarjeta si solo tiene 15 digitos ingresa el 0 al principio');
            return;
        }


        createTarjeta(card.uid, card).then((data) => {
            setLoading(false);
            updateTarjetas();     
            setShowModal(false);
                 
        }).catch((err) => {
            console.log(err);
            setError('No se pudo agregar la tarjeta compruebe los datos');
            updateTarjetas();
            setLoading(false);
        
          
        })
    }

    const handleclose=()=>{
        setError('');
        setShowModal(false); 
    }


    const getBrandFromCard = (number) => {
        let card = number;
        card = card.replace(/\s/g, '');
        let cardType = '';
        if (card.length > 0) {

            if (card[0] == '4') {
                cardType = 'visa';
            } else if (card[0] == '5') {
                cardType = 'mastercard';
            } else if (card[0] == '3' || (card[0] == '0' && card[1] == '3')) {
                cardType = 'amex';
            } else if (card[0] == '6') {
                cardType = 'discover';
            }else{
                cardType = 'unknown';
            }
        }
        setCardBrand(cardType);
        return cardType;
    }

    const tooltip = (
        <Tooltip id="tooltip" contentStyle={{ backgroundColor: '#4A4A4A' }} >
          <small className='link-reset-password'>{getTextByKey('cvv-help')}</small>
        </Tooltip>
      );

    return (
        <Modal show={showModal} className="modal-new-card">
            <Modal.Header className='justify-content-center px-3 px-md-5'>
                {/* <Modal.Title className="px-5">
                </Modal.Title> */}
                <h3 className="w-100 modal-title text-center mb-0" id="exampleModalLabel">{getTextByKey("add-card-title")}</h3>
                <button type="button" className="btn p-0 border-none" onClick={handleclose}><BtnClose /></button>
            </Modal.Header>
            <Modal.Body className="px-2 px-md-4">
                <div className="w-100 px-2 px-md-4">
                    <form onSubmit={createNewCard}>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("names-form")}*</label>
                            <input type="text" className="p-2" name="names" />
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("last-names-form")}*</label>
                            <input type="text" className="p-2" name="lastnames" />
                        </div>
                        <div className="form-group mb-3 position-relative">
                            <label>{getTextByKey("card-number")}*</label>
                            <input type="text" className="p-2" onChange={formatCard} name="number" />
                             {cardBrand.length > 0 && <div className="float-brand">
                                      {cardBrand}
                           </div>}
                            {/*cardBrand.length > 0 && <div className="float-brand">
                                <img src={"/payment/"+cardBrand+".png"} alt="visa" />
                           </div>*/}
                        </div>
                        <div className="form-group mb-3">
                            <label>{getTextByKey("card-date")}*</label>

                            <div className="d-flex flex-wrap">
                                <div className="col-6 pe-1 pe-md-2">
                                    <input type="text" className="p-2" name="month" placeholder='Mes'  maxLength={2}/>
                                </div>
                                <div className="col-6 ps-1 ps-md-2 ms-auto">
                                    <input type="text" className="p-2" name="year" placeholder='Año' maxLength={2} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-5">
                            <div className="d-flex justify-content-start align-items-center cvv-label">
                                <label>CVV</label>
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Icon icon="material-symbols:help" className='icon link-reset-password ms-3 ms-md-0' />
                                </OverlayTrigger>
                            </div>
                            <input type="password" className="p-2" maxLength={4} name="cvv" />
                        </div>
                        { error && <div className='error-msg text-grey text-start bg-transparent'>*{error}</div>}
                        <div className="form-group mb-2 text-center">
                            <button className="btn-g py-2 px-4 text-uppercase" type='submit'>
                                {loading ? <span className="spinner-border text-ligth" role="status"></span> : <span>{getTextByKey("add-card-button")}</span>}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}