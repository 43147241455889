import React, { Component } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { validateCoupon } from '../api/productApi';
import { useApi } from "../context/apiContext";
import { useCart } from '../context/cartContext';
import { Icon } from '@iconify/react';
import Picture from './Picture';
import { getImages } from '../api/imageApi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useAuth } from '../authContext';

export function BolsaComponent({ setTipoPago,tipoPago,bolsa, setBolsa }){
  const { getTextByKey, getTextBigByKey, convertCurrency, getPropiertyByLocale } = useApi();
  const { 
    calculateDescuento,
    calculateImpt,
    calculateImptUltra,
    calculatePeso,
    total,
    envio,
    descuento, 
    coupon, 
    impuesto, 
    impuestoUltra,
    impuestoBolsa, 
    pesoTotal, 
    subtotal,
    pesoXbolsa, 
    updateCoupon, 
    currentCart, 
    applyCoupon } = useCart();
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [logoEpayco, setLogoEpayco ] = useState([]);
  const { currentUser } = useAuth();
 
  useEffect(()=>{
    calculatePeso();
    calculateImpt();
    calculateImptUltra(),
    calculateDescuento();
    console.log('coupon', coupon);
  }, [currentCart]);
  
  
  useEffect (() => {
   
    getImages('epayco').then((data) =>{
      setLogoEpayco(data);
    });
  }, []);

  /*useEffect(()=>{
    if(bolsa == 0 || pesoTotal == 0 || pesoTotal == null ){
      setBolsa(1);
    }else{
      setBolsa(Math.round(pesoTotal/pesoXbolsa));
    }
  },[pesoTotal, pesoXbolsa]);*/


  const validateCodeCoupon = () => {
    setLoadingCoupon(true);
    setCouponMsg("");
    const coupon = document.getElementById('coupon').value;
    let email = currentUser.email;
    validateCoupon(coupon,email).then((res) => {
      console.log(res);
      setLoadingCoupon(false);
      if(Object.keys(res).length == 0){
        setCouponMsg(getTextByKey('cupon_no_valido'));
        updateCoupon(0);
      }else{
        setCouponMsg(getTextByKey('cupon_aplicado'));
        updateCoupon({coupon: coupon, id:res.id,descuento: res.descuento, porcentaje: res.porcentaje});
      }
    }).catch((error) => {
      console.log(error);
      setLoadingCoupon(false);
      setCouponMsg(getTextByKey('cupon_no_encontrado'));
      
    });
  }



  

  const deleteCodeCoupon = () => {
    updateCoupon(0);
  }

    return (
      <div className='primary-border-color box-check'>
        <div className='py-2 border-bottom'>
          <h3 className='gray-1-color px-2 px-lg-3 carrefour-sans-bold mb-0'>{getTextByKey('titulo_carrito')}</h3>
        </div>
        <div className="bolsa-checkout">
          <div className="mb-3">
              <div className='d-flex justify-content-between border-bottom py-2 px-2 px-lg-3'>
                <p className='gray-1-color source-sans-bold text-start pb-1 mb-0'>{getTextByKey('producto-titulo-tabla')}</p>
                <p className='gray-1-color source-sans-bold text-end pb-1 mb-0'>{getTextByKey('precio-titulo-tabla')}</p>
              </div>
              <div className="pe-2">
                <div className="products-table py-2 px-2 px-lg-3">
                  {
                      currentCart.map((item, index) => (
                      <div className='d-flex justify-content-between border-bottom pb-1 px-2' key={index}>
                        <div className='vertical-top w-75'>
                          <p className='gray-1-color mb-0'>{item.name.nombre}  <strong>x{item.quantity}</strong></p>
                          { item.gramaje!=0 && <p className='gray-1-color sm-p mb-0'>{item.gramaje.nombre}</p>}
                          <p className='gray-1-color sm-p mb-0'>Peso: {item.peso*item.quantity} gr</p>
                        </div>
                        <div className='vertical-top'>
                          <p className='gray-1-color mb-0 text-end'>{convertCurrency(item.price.precio*item.quantity)}</p>
                        </div>
                      </div>
                      ))
                  }
                </div>
              </div>
          </div>
          <div>
              <div className="border-bottom d-flex justify-content-between px-3 py-1">
                  <p className='gray-1-color text-start source-sans-bold mb-0'>{getTextByKey('subtotal')}</p>
                  <p className='gray-1-color text-end mb-0'>{convertCurrency(subtotal.toFixed(0))}</p>
              </div>
              {descuento!=0 && <div className="border-bottom d-flex justify-content-between px-3 py-1">
                  <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('descuento')}</p>
                  <p className='gray-1-color text-end mb-0'> {convertCurrency(descuento)}</p>
              </div> }

              {coupon != 0 && (
              <div className="border-bottom d-flex justify-content-between px-3 py-1 mb-2">
                <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('cupon')}</p>
                <p className='gray-1-color text-end mb-0'> {convertCurrency(applyCoupon(total).toFixed(0))}</p>
              </div>
              )}
              <div className="border-bottom d-flex justify-content-between px-3 py-1">
                  <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('impuestos')}</p>
                  <p className='gray-1-color text-end mb-0'> {convertCurrency((impuesto).toFixed(0))}</p>
              </div>
              { impuestoUltra!=0 ? 
              <div className='border-bottom d-flex justify-content-between px-3 py-1'>
                  <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('impuesto-ultraprocesado')}</p>
                  <p className='gray-1-color text-end mb-0'>{convertCurrency(impuestoUltra.toFixed(0))}</p>
              </div>
                :<></>}
     
              <div className="border-bottom d-flex justify-content-between px-3 py-1">
                  <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('impt-bolsa')}x{bolsa}</p>
                  <p className='gray-1-color text-end mb-0'> {convertCurrency((impuestoBolsa)*(bolsa))}</p>
              </div>
              <div className="border-bottom d-flex justify-content-between px-3 py-1">
                  <p className='gray-1-color source-sans-bold mb-0'>{getTextByKey('envio')}</p>
                  <p className='text-end gray-1-color mb-0'>{convertCurrency(envio)}</p>
              </div>
             
             
          </div>
          <div className='px-3 pb-1 pt-2'>
            <form action="">
              <div className="form-group mb-4 position-relative d-flex justify-content-between aling-items-center">
                {coupon != 0 ? (
                  <input type="text" className="p-2 input-cupon" value={coupon.coupon} disabled/>
                ):(
                  <input type="text" className="p-2 input-cupon" placeholder={getTextByKey('codigo_cupon')} id="coupon"/>
                )}
                  {loadingCoupon ? (
                    <div className="btn-g bb py-2 px-3" role="button"><div className='spinner-border spinner-border-sm' role='status'></div></div>
                  ) : (
                    <>
                    {coupon != 0 ? (
                      <button className="btn-g bb py-1 px-2" role="button" onClick={deleteCodeCoupon}>{getTextByKey('borrar_cupon')}</button>
                    ): (
                      <button className="btn-g bb py-1 px-2" role="button" onClick={validateCodeCoupon}>{getTextByKey('aplicar_cupon')}</button>
                    )}
                    </>
                  )}
              </div>
              {couponMsg != "" && (
              <div className="form-group mb-2 position-relative">
                {couponMsg}
              </div>
              )}
            </form>
          </div>
          <div className="px-3 py-2 border-bottom">
            <div className="d-flex justify-content-between align-items-center primary-bg-color px-3 rounded">
              <h4 className='py-2 white-color mb-0 source-sans-semibold'>{getTextByKey('total')}</h4>
              <h4 className='py-2 text-end white-color mb-0 source-sans-semibold'>{convertCurrency(total-applyCoupon(total).toFixed(0)+envio+((impuestoBolsa)*(bolsa)))}</h4>
            </div>
          </div>
          <div className='pt-3'>
            <form action="">
              <ul className='p-0'>
                <li className="form-check list-style-none border-bottom pt-1 px-lg-3 px-2">
                  <div className='px-3 py-2 d-flex align-items-start'>
                    <input className="form-check-input p-2" type="radio" checked={tipoPago=='tarjeta'} name="flexRadioDefault" id="flexRadioDefault1" onClick={() => { setTipoPago( 'tarjeta' ) }} />
                    <div className='gray-1-color big-text  mb-0 ms-2' dangerouslySetInnerHTML={{ __html: getTextBigByKey("epayco-carrito")}}></div> 
                    <OverlayTrigger placement="bottom" overlay={
                    <Tooltip className="bg-transparent" >
                      ePayco - Tu pago en línea es un aproximado
                      del valor total, es posible que recibas dinero
                      al momento de la entrega como salgo a favor
                      después de facturado tu pedido.
                    </Tooltip>}>
                      <div className='question-mark primary-color ms-1'>
                        <Icon icon="ant-design:question-circle-outlined"></Icon>
                      </div>
                    </OverlayTrigger>
                   
                  </div>
                  <div className="text-end">
                    { logoEpayco.length ? <Picture classImg={'img-epayco'} src={logoEpayco[0].image} alt={"logo epayco"} />: ""}
                    </div>
                </li>
                <li className="form-check list-style-none border-bottom pt-1 px-lg-3 px-2">
                  <div className='px-3 py-2 d-flex align-items-start'>
                    <input className="form-check-input p-2" type="radio" checked={tipoPago=='pse'} name="flexRadioDefault" id="flexRadioDefault1" onClick={() => { setTipoPago( 'pse' ) }} />
                    <div className='gray-1-color big-text  mb-0 ms-2' dangerouslySetInnerHTML={{ __html: getTextBigByKey("pse-carrito")}}></div> 
                                
                  </div>
                  <div className="text-end">
                    { logoEpayco.length ? <Picture classImg={'img-epayco'} src={logoEpayco[0].image} alt={"logo epayco"} />: ""}
                    </div>
                </li>
                <li className="form-check list-style-none border-bottom pt-1 px-lg-3 px-2">
                  <div className='px-3 py-2 d-flex align-items-start'>
                    <input className="form-check-input p-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => { setTipoPago( 'efectivo' ) }}/>
                    <div className='gray-1-color big-text mb-0 ms-2' dangerouslySetInnerHTML={{ __html: getTextBigByKey("efectivo-carrito")}}></div>
                    <OverlayTrigger placement="bottom" overlay={
                      <Tooltip className="bg-transparent" >
                        Se paga con efectivo al momento de recibir el pedido a domicilio.
                      </Tooltip>}>
                        <div className='question-mark primary-color ms-1'>
                          <Icon icon="ant-design:question-circle-outlined"></Icon>
                        </div>
                    </OverlayTrigger>
                  </div>
                </li>
                <li className="form-check list-style-none border-bottom pt-1 px-lg-3 px-2">
                  <div className='px-3 py-2 d-flex align-items-start'>
                    <input className="form-check-input p-2" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={() => { setTipoPago( 'ce-tarjeta' ) }}/>
                    <div className='gray-1-color big-text mb-0 ms-2' dangerouslySetInnerHTML={{ __html: getTextBigByKey("tarjeta-carrito")}}></div>
                    <OverlayTrigger placement="bottom" overlay={
                      <Tooltip className="bg-transparent" >
                        Se paga con tarjeta en un datáfono al
                        momento de recibir el pedido a domicilio.
                      </Tooltip>}>
                        <div className='question-mark primary-color ms-1'>
                          <Icon icon="ant-design:question-circle-outlined"></Icon>
                        </div>
                    </OverlayTrigger>
                  </div>
                </li>
               
              </ul>
            </form>
          </div>
        </div>
      </div>
    )
}