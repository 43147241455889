import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import { useCart } from '../context/cartContext';
import Modal from 'react-bootstrap/Modal';




export default function AlertaEliminarProducto({ showEliminar, setShowEliminar, index, nameProd }) {

    const { getTextByKey } = useApi();
    const { removeToCart } = useCart();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowEliminar(false);
        gotoTop();
    }

    const handleRemove = () => {
        removeToCart(index);
        setShowEliminar(false);
    }
    

    return (
        <Fragment>
            <Modal show={showEliminar} onHide={handleClose}  centered className='alerta-eliminar'>
                <Modal.Body>
                    <div className="close pe-2">
                      <Icon className='cursor-pointer white-colo' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                    <p className='gray-1-color source-sans-bold my-2'>{getTextByKey('eliminar-producto-titulo')}</p>
                      <p className='gray-1-color source-sans-pro pb-2 pt-1'>
                        ¿{getTextByKey('eliminar-producto')} <strong>{nameProd}</strong>? 
                        </p>
                        <button className='btn-g bb px-4 mx-2 py-1' onClick={handleClose}>{getTextByKey('cancelar')}</button>
                        <button className='btn-g px-4 mx-2 py-1' onClick={handleRemove}>{getTextByKey('eliminar')}</button>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}