import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../context/apiContext';

export default function ModalAlertaTope({ showAlertaTope, setShowAlertaTope }) {
  
    const { getTextByKey } = useApi();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowAlertaTope(false);
        gotoTop();
    }

    return (
        <Fragment>
            <Modal show={showAlertaTope} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color source-sans-bold'>{getTextByKey('modal-alerta-titulo')}</p>
                        <p className='gray-1-color'>{getTextByKey('alerta-tope')}</p>
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('entendido')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}