import { Fragment, useState, useEffect } from "react";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import RecomendadosSliderComponent from "../../components/RecomendadosSliderComponent";
import { useApi } from "../../context/apiContext";
import { misFavoriteUser } from "../../api/productApi";
import { useAuth } from './../../authContext';
import ProductsmallComponent from "../../components/productsmallComponent";


export default function FavoritosPage() {
  const { currentUser } = useAuth();
  const [favoritesNew, setfavoritesNew] = useState([])
  const [haveFavorites, sethaveFavorites] = useState(false)
// console.log("favoritesNew", favoritesNew);
  useEffect(() => {
    // console.log("User", currentUser);
    async function callListFavorites() {
      const favorites = await misFavoriteUser(currentUser.uid);
      // console.log("favorites -> ", favorites);
      if (favorites.length > 0) {
        sethaveFavorites(true)
        setfavoritesNew(favorites)
      }
    }
    callListFavorites();
  }, [])


  return (
    <Fragment>
      <div className='quaternary-bg-color py-4 head-perfil'>
        <div className='container'>
          <div className='text-center'>
            <h1 className='text-uppercase'>Cuenta</h1>
            <NavsupComponent />
          </div>
        </div>
      </div>
      <div className='container-fluid px-4 px-lg-0 py-5'>
        <div className='d-lg-flex'>
          <div className='col-nav mb-4 mb-lg-0'>
            { currentUser && <NavComponent />}
          </div>
          <div className='col-content-perfil'>
            <h2 className='text-uppercase mb-2'>Favoritos</h2>
            <h6 className='fw-bold'>{favoritesNew.length} items</h6>
            <div className='py-2 py-md-3 py-xl-4'>
              <div className='row'>
                {haveFavorites ? (
                  favoritesNew.map((fav, index) => (
                    <div className='col-6 col-md-3' key={index}>
                      <ProductsmallComponent key={index} product={fav}/>
                    </div>
                  ))
                ) : (
                  <h6 className='fw-bold'> NO TENEMOS FAVORITOS </h6>
                )}
              </div>
            </div>
            <h2 className='text-uppercase'>Recomendados</h2>
            <div className='py-2 py-md-3 py-xl-4'>
              <RecomendadosSliderComponent />
            </div>
          </div>
        </div>
      </div>
      <ModaleliminarcuentaComponent />
    </Fragment>
  );
}
