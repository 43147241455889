import React from 'react'
import PropTypes from 'prop-types'
import { useApi } from '../context/apiContext';
import { Icon } from '@iconify/react';
import StaticRatingStar from './StaticRatingStar';

const RatingBarsComponent = ({ratings}) => {
    const { getTextByKey, currentLocale } = useApi();


    const getRatingData = (rating) => {
        if (!ratings) {
            return {numberOf: 0, ratio: 0};
        }
        const numberOf = ratings.filter(rat => rat == rating);
        const ratio = (numberOf.length * 100) / ratings.length;
        return {numberOf, ratio};
    }

    const getAverage = () => {
            if (ratings.length) {
                return (ratings.reduce((a, b) => a + b) / ratings.length).toFixed(1); 
            }
            return 0;
    }

    const toCommaDecimal = (float) => {
        return float.toString().replace('.',',').replace(' ','');
    }

    return (
        <div className="col-12 col-md-7 col-xl-6">
            <h3 className='mb-4 fw-bold'>{getTextByKey('resumen-opiniones')}</h3>
            <div className='row'>
                <div className='col-lg-5 col-6'>
                    {
                        [5,4,3,2,1].map(value => {
                            const ratingData = getRatingData(value);
                            return (
                                <div key={value} className='d-flex align-items-center mb-2'>
                                    <span className='me-2'>{value}</span>
                                    <div className="progress w-100">
                                        <div className="progress-bar rounded-pill" role="progressbar" style={{ width: `${ratingData.ratio}%` }} aria-valuenow={ratingData.ratio} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            )
                        }
                        )
                            
                    }
                </div>
                <div className='col-lg-3 col-6'>
                <div className="text-center">
                    <span className='overall-score'>{toCommaDecimal(getAverage())}</span>
                    <div className='d-flex justify-content-center'>
                        <StaticRatingStar className='d-flex mb-2' rating={Math.floor(getAverage())} />
                    </div>
                    <p className='gray-2-color mb-0'>{ratings.length} opiniones</p>
                </div>
                </div>
            </div>
        </div>
  )
}

RatingBarsComponent.propTypes = {
    ratings: PropTypes.array.isRequired
}

export default RatingBarsComponent