import { Icon } from "@iconify/react";
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../authContext";
import { useApi } from "../../../context/apiContext";
import { useForm } from "../../../hooks/useForm";
import { ModalEstadoOrden } from "./ModalEstadoOrden";
import { ModalExp } from "./ModalExp";
import { getCiudadesAll, getDepartamentosAll } from "../../../api/productApi";

export function ContactComponent({onInput, formState, disabled}){
  const [showModal, setShowModal] = useState(false);
  const [ showExt, setShowExt ] = useState(false);
  const { getTextByKey, getTextBigByKey } = useApi();
  const { currentUser } = useAuth();
  const [valueFile, setValueFile] = useState('')
  const [ ciudades, setCiudades ] = useState([]);
  const [ departamentos, setDepartamentos ] = useState([]);
  const [ DptoId, setDptoId ] = useState('');

    useEffect(()=>{
      getDepartamentosAll().then((data) => {
        setDepartamentos(data);
      });
    },[])

    useEffect(()=>{
      getCiudadesAll(DptoId).then((data) => {
        setCiudades(data);
      });
    },[DptoId])

    const handleShowModal = () => {
      if( currentUser ) {
        location.href = '/profile-orders';
      } else {
        setShowModal(true)
      }
    }

    const handleDpto = (event) =>{
      setDptoId(event.target.value);
    }

    const onFile = (e) => {
      const allowedExtensions = /(\.doc|\.pdf|\.docx|\.odt|\.jpg|\.jpeg|\.png)$/i;
      if (allowedExtensions.exec(e.target.files[0].name)) {
        formState.file=e.target.files[0];
        const event = {
          target: {
            name: '',
            value: e.target.files[0]
          }
        }
        const event2 = {
          target: {
            name: 'nameFile',
            value: e.target.files[0].name
          }
        }
        onInput(event);
        onInput(event2);
      } else {
        // Display error message or reset file input field
        e.target.value = '';
        setShowExt(true);
      }
    }
    
    function handleKeyDown(event) {
      const regex = /^[a-zA-Z\s]*$/; // regular expression to match letters and spaces
      const key = event.key;
      if (!regex.test(key)) {
        event.preventDefault(); // prevent the key from being entered
      }
    }

    const changePoliticas = (e) => {
      const event = {
        target: {
          name: 'autorizoDatos',
          value: e.target.checked
        }
      }
      onInput(event);
    }

    const clearFile = (e) => {
      e.preventDefault();
      const event = {
        target: {
          name: e.target.name,
          value: ""
        }
      }
      const event2 = {
        target: {
          name: "nameFile",
          value: ""
        }
      }
      onInput(event);
      onInput(event2);
      setValueFile('')
    }



    return(
      <div className="row">
        <div className="col-12">
          <label className="mb-1 gray-1-color">{getTextByKey('persona')}*</label>
          <ul className="mb-3 d-flex p-0">
            <li className='list-style-none'>
              <label className="d-flex align-items-center radio-button-container">
                <input type="radio" name='juridico' value={false} onChange={onInput} checked={formState.juridico == "false"} />
                <p className='gray-1-color mb-0'>{getTextByKey('natural')}</p>
              </label>
            </li>
            <li className='list-style-none ms-4'>
              <label className="d-flex align-items-center radio-button-container">
                <input type="radio" name='juridico' value={true} onChange={onInput} onInput={onInput} checked={formState.juridico == "true"}  />
                <p className='gray-1-color mb-0'>{getTextByKey('juridica')}</p>
              </label>
            </li>
          </ul>
        </div>
        {
          formState.juridico == 'false' ? (
            <>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('nombres')}*</label>
                    <input value={formState.name} onInput={onInput} onKeyDown={handleKeyDown} name='name' type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('apellidos')}*</label>
                    <input value={formState.surname} onInput={onInput} name='surname' onKeyDown={handleKeyDown} type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey('numero_identificacion')}*</label>
                    <input value={formState.identification_number} onInput={onInput} name='identification_number' type="number" className="p-2" required/>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-lg-8">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey("razon_social")}*</label>
                    <input onInput={onInput} value={formState.business_name} name='business_name' type="text" className="p-2" required/>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group mb-3">
                    <label className="mb-1 gray-1-color">{getTextByKey("nit")}*</label>
                    <input onInput={onInput} value={formState.NIT} name='NIT' type="text" className="p-2" required/>
                </div>
              </div>
            </>
          )
        }
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">{getTextByKey('direccion')}*</label>
                <input value={formState.address} onInput={onInput} name='address' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Departamento*</label>
                <select className='p-2 select-gris' required name="department" value={formState.department} onInput={onInput}  onChange={handleDpto} >
                  <option value="" selected>Selecciona tu departamento </option>
                  {departamentos && departamentos.map((departamento, index) => (
                    <option key={index} value={departamento.id}>
                      {  departamento.name.nombre }
                      </option>
                  ))}
                </select>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Ciudad*</label>
                <select className='p-2 select-gris' required name="city" value={formState.city}  onInput={onInput}>
                  <option value="">Selecciona tu ciudad </option>
                  {ciudades.map((ciudad, index) => (
                    <option key={index} value={ciudad.id}>{ciudad.name.nombre}</option>
                  ))}
                </select>
            </div>
        </div>
        <div className="col-md-6 col-lg-8">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Correo electrónico*</label>
                <input value={formState.email} onInput={onInput} name='email' type="text" className="p-2" required/>
            </div>
        </div>
        <div className="col-md-6 col-lg-4">
            <div className="form-group mb-3">
                <label className="mb-1 gray-1-color">Teléfono móvil/fijo</label>
                <input value={formState.telephone} onInput={onInput} type="number" name='telephone' className="p-2"/>
            </div>
        </div>
        <div className="col-12">
            <div className="form-group mb-3">
                <label className="mb-3">Descripción del requerimiento*</label>
                <textarea value={formState.comment} onInput={onInput} name='comment' className="p-2" required></textarea>
            </div>
        </div>
        <div className="col-12">
          <div className="form-group mb-3">
            <label htmlFor="" className="mb-1 gray-1-color">{getTextByKey('adjunta_documentos')}</label>
              <div className="add-file position-relative">
                <input accept=".doc,.pdf, .DOCX, .odt, .jpg, .jpeg, .png"  name='file' type="file" onChange={onFile} value="" />
                <button className="btn-g py-2 px-5">{getTextByKey('seleccionar_archivo')}</button>
              </div>
              <div className="sm gray-1-color mt-1" dangerouslySetInnerHTML={{ __html: getTextBigByKey("archivo_permitido") }}></div>
              <p className="gray-1-color sm-p">{getTextByKey('tamano')}</p>
              {
                formState.nameFile && (
                  <div className="my-4">
                    <label htmlFor="" className="mb-1 gray-1-color">Documentos agregados</label>
                    <div className="mt-2 d-flex justify-content-start align-items-center px-5">
                      <Icon icon="gg:file-document" className="me-2"/>
                      <small className="gray-1-color w-auto mb-0">{formState.nameFile}</small>
                      <button type="button" onClick={clearFile} className="btn p-0 ms-4">
                        <Icon icon="material-symbols:close"  className="p-0"/>
                      </button>
                    </div>
                  </div>
                )
              }
          </div>
        </div>
        <ModalExp showExt={showExt} setShowExt={setShowExt} />
        {/* <div className="col-md-12">
          <label className="d-flex align-items-center radio-button-container my-3">
              <input type="checkbox" name='accept' className="me-2" onChange={changePoliticas}/>
              <p className='gray-1-color mb-0'>{getTextByKey('autorizo_contacto')} 
                <strong><Link className="gray-1-color" to="/politica-de-datos"> {getTextByKey('autorizo_contacto_parrafo_link')}*</Link></strong>
              </p>
          </label>
        </div> */}
        <div className="col-12">
          <div className='form-group mb-4'>
            <label className="container-check-s">
              <p className='gray-1-color mb-0'>{getTextByKey('autorizo_contacto')}
                <strong><Link className="gray-1-color" to="/politica-de-datos"> {getTextByKey('autorizo_contacto_parrafo_link')}*</Link></strong>
              </p>
              <input
                type="checkbox"
                checked={formState.autorizoDatos}
                name="autorizoDatos"
                onChange={changePoliticas}
              />
              <span className="checkmark-s">
                <img src="/img/SVG/check.svg" />
              </span>
            </label>
          </div>
        </div>
        <div className="col-12 mt-4 mb-5">
          <button type="submit" className="btn-g px-5 py-2" disabled={disabled}>{disabled ? getTextByKey('enviando') : getTextByKey('enviar')}</button>
        </div>
      </div>
    );
}