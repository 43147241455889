import React from 'react'
import { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../../context/apiContext';
import Modal from 'react-bootstrap/Modal';



export default function ModalGramaje({ showGramaje, setShowGramaje, gramajes, onSelectedGramaje}) {

  const { getTextByKey, getTextBigByKey } = useApi();
  const [selectedGramaje, setSelectedGramaje] = useState(null);

  const gotoTop = event => {
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    setShowGramaje(false);
    setSelectedGramaje(null);
    gotoTop();
  }

  const handleSelectedGramaje = (gramaje) => {
    setSelectedGramaje(gramaje);
    onSelectedGramaje(gramaje)
  };


  return (
    <Fragment>
      <Modal show={showGramaje} onHide={handleClose} centered>
        <Modal.Body>
          <div className="text-end pe-2">
            <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
          </div>
          <div className="container">
            {gramajes.length > 0 &&
              gramajes.map((gramaje, index) => (
                <div className="d-flex justify-content-between align-items-center mb-3 cursor-pointer" key={index}>
                  <div className='position-relative'>
                  <input className='position-absolute input-gramaje' type="radio" id={gramaje.id} 
                    checked={selectedGramaje && selectedGramaje.id === gramaje.id} 
                    onChange={() => handleSelectedGramaje(gramaje)}/>
                    <div className="d-flex">
                      <Icon className={selectedGramaje && selectedGramaje.id === gramaje.id ? 'primary-color' : 'white-color'} 
                       icon="ic:outline-check">
                      </Icon>
                      <div className='ms-2'>
                        <p className={`mb-0 text-capitalize ${ selectedGramaje && selectedGramaje.id === gramaje.id ? 'source-sans-bold' : ''}`}>
                          {gramaje.nombre}
                        </p>
                        <p className={`mb-0 ${ selectedGramaje && selectedGramaje.id === gramaje.id ? 'source-sans-bold' : ''}`}>
                          Peso aprox entre {gramaje.pesoinicial} a {gramaje.pesofinal}
                        </p>
                        <p className={`mb-0 ${selectedGramaje && selectedGramaje.id === gramaje.id ? 'source-sans-bold' : ''}`}>
                          {gramaje.descripcion}
                        </p>
                      </div>
                    </div>
                  </div>
                  {
                    gramaje.price ? 
                    <p className={`mb-0 ${ selectedGramaje && selectedGramaje.id === gramaje.id ? 'source-sans-bold' : ''}`}>
                    ${gramaje.price.precio}
                    </p> : 'No existe'
                  }
                 
                </div>
              ))
            }

          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}