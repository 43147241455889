import React, { Fragment, useState } from 'react'
import { Icon } from '@iconify/react';
import '../../../scss/filtros.scss';
import CheckDropdownComponent from './CheckDropdownComponent';
import { useApi } from "../../../context/apiContext";
import BuscadorComponent from '../../../components/BuscadorComponent';
import { getSearchResult } from '../../../api/searchApi';
// import { getProducts } from "../../api/productApi";



export default function FiltrosComponent () {


    const { getTextByKey } = useApi();


    const openFilter = event => {
        const filter = document.getElementById('filter');
        filter.classList.toggle('view');
    };

    const closeFilter = event => {
        const filter = document.getElementById('filter');
        filter.classList.remove('view');  
    };


    return (
        <Fragment>
            <div>
                <div className='text-start text-md-end opcion-mobile'>
                    <p className='pt-3 px-4 primary-color d-flex align-items-center mb-0 source-sans-bold' onClick={openFilter}>
                        <span className='me-2'>{getTextByKey("filtrar")}</span>
                        <Icon icon="material-symbols:filter-list" />
                    </p>
                </div>

                <form className="product-filter" id="filter">
                    <BuscadorComponent 
                    classProp={`search-box-shop d-flex pt-2 pb-3`}
                    classIcon={`d-none`} />

                    <div className='container-fluid'>
                        <div className='row'>
                            <CheckDropdownComponent/>
                        </div>
                    </div>
                    
                    <div className="text-center only-tablet">
                        <button className='btn-g px-5 py-2' onClick={closeFilter}>Aceptar</button>
                    </div>
{/* 
                    <div className='form-group text-center opcion-mobile'>
                        <a className='btn-g py-2 px-4' onClick={closeFilter}>
                            <span className='me-2'>{getTextByKey("aceptar")}</span>
                        </a>
                    </div> */}
                </form>
            </div>
        </Fragment>
    )
}
