import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import ModalLogUserComponent from "../../components/ModalLogUserComponent";
import { useApi } from "../../context/apiContext";
import { getBlogs } from "../../api/blogApi";
import Picture from "../../components/Picture";

import '../../scss/blog_page.scss';
import { createDatetime } from "../../helpers/dateHelper";

export default function BlogPage() {

    const { getTextByKey, getPropiertyByLocale, currentLocale } = useApi();

    const [ posts, setPosts ] = useState([]);
		const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        getBlogs({locale: currentLocale}).then((data) => {
            console.log(data);
            setPosts(data);
        });
    }, [])
    

    return (
        <Fragment>
            <div className="blog my-lg-5 my-4">
                <div className="container px-lg-0 px-4 blog-container">
                    <h1 className="primary-color text-center fw-bold py-lg-4 py-0 mb-lg-4 mb-3">{getTextByKey('blog')}</h1>
                    <div className="row">
                        {posts.slice(0, showMore ? posts.length : 3).map((post) => {
                            const datetime = createDatetime(post.created_at.date);
                            return(
                        <div className="col-12 col-md-6 col-lg-4 px-2 mb-4" key={post.id}>
                            <div className="singular-entry-blog">
                                <div>
                                    <Picture src={post.BlogPostImages[0].coreImage.image} alt={"Blog entry item"} classImg={"w-100"} />
                                </div>
                                <div className="mt-3">
                                    <h3 className="primary-color carrefour-sans-bold">{post.translations.length ? post.translations[0].title : ""}</h3>
                                    <p className="gray-2-color md-p"><span>{post.translations.length ? post.translations[0].subtitle : ""}</span> •  
                                    <span> 
                                        {
                                            datetime && <> {getTextByKey(datetime.month)} {datetime.day}, {datetime.year}</>
                                        }
                                    </span>
                                    </p>
                                    <p className="gray-1-color content">{post.translations.length ? post.translations?.[0]?.resumen : ""}</p>
                                    <Link to={`/blog/${post?.slug}`} className="btn-g bb py-1 px-4 px-lg-5">{getTextByKey('leer-mas')}</Link>
                                </div>
                            </div>
                        </div>
                        )})}
                    </div>
                    <div className="text-center mt-5">
                        <button type="button" className="btn-g py-1 px-5" onClick={()=> setShowMore(!showMore)}>{ showMore ? `${getTextByKey('mostrar-menos')}` : `${getTextByKey('mostrar-mas')}`}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
