import { Fragment } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import { FormularioDireccion } from "../../components/FormularioDireccion";
import { MapComponent } from "../../components/MapComponent";
import DireccionComponent from "./components/DireccionComponent";
import { useApi } from "../../context/apiContext";
import { useAuth } from "../../authContext";
import { useState } from "react";
import { listAddressUser } from "../../api/addressApi";
import { useEffect } from "react";
import LoaderComponent from "../../components/LoaderComponent";
import { useRef } from "react";
import { useMemo } from "react";
import { ModalEditarDireccionComponent } from "./components/ModalEditarDireccionComponent";

export default function DireccionesPage() {
    const { currentUser } = useAuth();
    const [addressList, setAddressList] = useState([]);
    const [addressFromMap, setAddressFromMap] = useState({});
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const [locationMarker, setLocationMarker] = useState({});
    const [ loader, setLoader ] = useState(true);

    // const updateAddress = async () => {
    //     try {
    //         const listAddressResponse = await listAddressUser(currentUser.uid)
    //         setAddressList(listAddressResponse)
    //     } catch (error) {
    //         console.log('Error: ', error)
    //     }
    // }

    // const updateAddressFromMap = e => {
    //     setAddressFromMap(e);
    // }

    useEffect(() => {
		 listAddressUser(currentUser.uid).then((addressResponse) => {
            setAddressList(addressResponse);
            console.log(addressResponse);
            setLoader(false);
        })
    }, [])

    // useEffect(() => {
    //     console.log(locationMarker);
    // }, [locationMarker])




    return (
        <Fragment>
              <LoaderComponent load={loader}/>
            <div className="perfil my-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-5 px-xl-5">
                            <h1 className="primary-color carrefourMetis-bold mb-lg-5 mb-3 text-center text-lg-start">{getTextByKey('perfil')}</h1>
                            <div className="mb-5 text-center text-lg-start">
                                {/* <a className="btn-g py-1 px-4" data-bs-toggle="collapse" href="#collapseFormulariodireccion" role="button" aria-expanded="false" aria-controls="collapseFormulariodireccion" id="btnActiveModal">Nueva dirección</a> */}
                                <Link to="/nueva-direccion" className="btn-g py-1 px-4">{getTextByKey('nueva_direccion')}</Link>
                            </div>
                            {/* <h2 className="mb-4">Mi lista de direcciones</h2> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-5 px-xl-0">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-6 px-lg-5 px-4 gray-1-color">
                            <div className="row">
															{
															addressList?.map((address, index) =>
															(
																	<DireccionComponent
																			currentUser={currentUser}
																			direccion={address}
																			key={index}
																	/>
															))}
                            {/* {addressList.join()} */}
                            {addressList.length === 0 &&
                                <div className="text-center text-lg-start">
                                    <p className="source-sans-bold">{getTextByKey('no_tienes_direccion')}</p>
                                    <div dangerouslySetInnerHTML={{__html: getTextBigByKey('agregar_direccion_mensaje')}}></div>
                                </div> 
                            }
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container collapse" id="collapseFormulariodireccion">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <h2>{getTextByKey("title-form-new-address")}</h2>
                        {
                            <FormularioDireccion
                                user={currentUser}
                                updateAddress={updateAddress}
                                addresFromMap={addressFromMap}
                                locationMarker={setLocationMarker}
                                addressFromMap={addressFromMap}
                            />
                        }
                    </div>
                    <div className="col-12 col-lg-7 offset-lg-1">
                        <div className="mapUbicacion">
                            <MapComponent locationMarker={locationMarker} updateAddressForm={updateAddressFromMap} />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <ModalEditarDireccionComponent /> */}
        </Fragment>
    )
}