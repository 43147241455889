import React, { Component, Fragment } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { NavLink } from 'react-router-dom';



export class SlideBComponent extends Component {
    
  render() {
    return (
      <Fragment>
        <form className='slide-process'>
            <Swiper 
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                    nextEl: ".next"
                }}
                effect="fade"
                simulateTouch={false}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}>
                <SwiperSlide>
                    <div className='py-2'>
                        <img className='w-100' src="/img/slide-1.png"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='py-2'>
                        <img className='w-100' src="/img/slide-2.png"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='py-2'>
                        <img className='w-100' src="/img/slide-2.png"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='py-2'>
                        <img className='w-100' src="/img/slide-3.png"/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </form>
      </Fragment>
    )
  }
}
