import { Fragment, useEffect, useState } from "react";
import LoaderComponent from "../../components/LoaderComponent";
import { ModaleliminarcuentaComponent } from "./components/ModaleliminarcuentaComponent";
import { ModalGuardarCuentaComponent } from "./components/ModalGuardarCuentaComponent";
import { ModalCambiarPasswordComponent } from "./components/ModalCambiarPasswordComponent";
import { ModalGuardar } from "./components/ModalGuardar";
import { NavComponent } from "./components/NavComponent";
import { NavsupComponent } from "./components/NavsupComponent";
import '../../scss/perfilPage.scss'
import { NavLink } from "react-router-dom";
import { useForm } from '../../hooks/useForm';
import { Icon } from '@iconify/react';

import { useApi } from "../../context/apiContext";
import { getUser, updateUser, deleteUser } from '../../helpers/userHelper';
import { getCiudades, getDepartamentos } from "../../api/productApi";
import { useAuth } from "../../authContext";
import { updatePassword } from "firebase/auth";
import { faL } from "@fortawesome/free-solid-svg-icons";



export default function DetallescuentaPage() {
    const { currentUser } = useAuth();

    const { getTextByKey} = useApi();

    const [getDataProfile, setGetDataProfile] = useState([]);
    const [load, setLoad] = useState(false);
    const {
       phoneRegex,
        nameRegex
    } = useForm();

    const [profileInfo, setProfileInfo] = useState({
			nombre: '',
			apellido: '',
			email: '',
			celular: '',
			departamento: 0,
	     	ciudad: 0,
			direccion: '',
			barrio: '',
			documento: '',
			uid: currentUser.uid
		});
		
        const [ciudades, setCiudades] = useState([]);
		const [ departamentos, setDepartamentos ] = useState([]);
        const [ errorTel, setErrorTel ] = useState();
        const [ errorDireccion, setErrorDireccion ] = useState();
        const [ errorDpto, setErrorDpto ] = useState();
        const [ errorCiudad, setErrorCiudad ] = useState();
        const [ errorNombre, setErrorNombre ] = useState();
        const [ errorApellido, setErrorApellido ] = useState();
        const [ errorNumDoc, setErrorNumDoc ] = useState();
        const [ errorBarrio, setErrorBarrio ] = useState();
        const [ success, setSuccess ] = useState(false);
        const [ dptoId, setDptoId ] = useState(0);
        const [ errors, setErrors ] = useState('');
        const [ loader, setLoader ] = useState(true);


        useEffect(() => {
            if (currentUser) {
                if(currentUser.isAnonymous)
                    location.href = "/";
            }
         }, []);
		useEffect(()=>{
		 	getDepartamentos().then((data)=>{
		    setDepartamentos(data);
        })
		},[])

        const handleDpto = (event)=>{
            setDptoId(event.target.value);
             setProfileInfo({
                ...profileInfo,
                departamento:event.target.value,
                ciudad: 0
            });
        }

        useEffect(()=>{
            setCiudades([]);
            if(dptoId!=0){
                getCiudades(dptoId).then((data) => {
                    setCiudades(data);
                });
            }
        },[dptoId])

		const fetchProfile = () => {
           
			try {
				getUser(currentUser?.uid).then(async({ user }) => {
					console.log('usuario',user);
					const { nombre, apellido, email, celular, ciudad, departamento, direccion, uid, barrio, documento } = user;
					setDptoId(departamento);	
					const updatedProfileInfo = {
						nombre: nombre,
						apellido: apellido,
						email: email,
						celular: celular,
						ciudad: ciudad,
						direccion: direccion,
						barrio: barrio,
						documento: documento,
						departamento: departamento,
						uid:uid
					};
		
					setProfileInfo(updatedProfileInfo);
                    setLoader(false);
				});
			} catch (error) {
				console.log("Error...", error);
			}
		};
		

    useEffect(() => {
        fetchProfile()
			
    }, [currentUser?.uid]);

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setProfileInfo({
            ...profileInfo,
            [name]: value
        });
    }

    const onSubmit = async(event) => {
        setLoad(true);
        event.preventDefault();
        setErrors('');
        const validate= await validateForm(profileInfo)
        if (!validate) {
            updateUser(profileInfo).then((response) => {
                setSuccess(true);
                setLoad(false);
                setTimeout(() => {
                    setSuccess(false)
                     }, 5000);
            }).catch((error)=>{
                setErrors('Ha ocurrido un error al actualziar la información, intentalo de nuevo');
                setLoad(false);
            })
         }else{
            setLoad(false);
         }
    }

    const validateForm = async () => {
        let errorFound = false;
        if(!profileInfo.nombre.match(nameRegex)){
            errorFound = true;
            setErrorNombre('Formato inválido, este campo solo recibe letras');
        }
        if(profileInfo.apellido){
            if(!profileInfo.apellido.match(nameRegex)){
                errorFound = true;
                setErrorApellido('Formato inválido, este campo solo recibe letras');
            }
        }
    
        if (!profileInfo.celular.match(phoneRegex)){
            errorFound = true;
            setErrorTel("El teléfono no tiene un formato válido");
        } else {
            errorFound = false;
            setErrorTel("");
        }
        //Validar si todos los campos están diligenciados de forma individual
        if (!profileInfo.nombre ) {
            errorFound = true;
            setErrorNombre('Este campo es requerido');
        } else {
            errorFound = false;
            setErrorNombre('');
        }

        if(!profileInfo.documento){
            errorFound = true;
            setErrorNumDoc('Este campo es requerido');
        } else {
            errorFound = false;
            setErrorNumDoc('');
        }
        if(!profileInfo.direccion){
            errorFound = true;
            setErrorDireccion('Este campo es requerido');
        } else {
            errorFound = false;
            setErrorDireccion('');
        }
        if(!profileInfo.barrio){
            errorFound = true;
            setErrorBarrio('Este campo es requerido');
        } else {
            errorFound = false;
            setErrorBarrio('');
        }
		if(profileInfo.departamento==0){
			errorFound = true;
			setErrorDpto("Debe seleccionar un departamento");
		}else{
			errorFound = false;
			setErrorDpto("");
		}
        if (profileInfo.ciudad ==0) {
            errorFound = true;
            setErrorCiudad("Debe seleccionar una ciudad");
        } else {
            errorFound = false;
            setErrorCiudad("");
        }

        return errorFound;
    }



    return (
        <Fragment>
             <LoaderComponent load={loader}/>
            <div className="perfil perfil-editar-cuenta my-5 pb-5">
                <div className="container-fluid">
                 
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-5 px-xl-5">
                            <h1 className="primary-color carrefourMetis-bold mb-lg-5 mb-2 text-center text-lg-start">{getTextByKey('perfil')}</h1>
                        </div>
                    </div>
                    <div className="row">
                
                        <div className="col-12 col-lg-4 px-xl-0">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-1 px-lg-5 px-4">
                            <h3 className="gray-1-color source-sans-bold mb-4 text-center text-lg-start">{getTextByKey('editar_cuenta')}</h3>
                            { profileInfo && <form onSubmit={onSubmit} className="gray-1-color">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('nombres')}</label>
                                            <input
                                                type="text"
                                                className="p-2"
                                                name="nombre"
                                                value={profileInfo.nombre}
                                                onChange={onInputChange}
                                            />
                                            	<div className='icon-war'>{ errorNombre ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.nombre && !errorNombre ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorNombre ? <div className='alert-danger'>{errorNombre}</div> : null}</span>
                                        </div>
                                    </div>
                                    <div className={`col-12 col-md-6 mb-4 `}>
                                        <div className="form-group">
                                            <label>{getTextByKey('apellidos')}</label>
                                            <input
                                                type="text"
                                                className="p-2"
                                                name="apellido"
                                                value={profileInfo.apellido}
                                                onChange={onInputChange}
                                            />
                                            <div className='icon-war'>{ errorApellido ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.apellido && !errorApellido ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
											<span>{errorApellido ? <div className='alert-danger'>{errorApellido}</div> : null}</span>
                                
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('correo_electronico_form')}</label>
                                            <input
                                                type="email"
                                                className="p-2"
                                                value={profileInfo.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('numero_telefono_form')}</label>
                                            <input
                                                type="tel"
                                                className="p-2"
                                                name="celular"
                                                value={profileInfo.celular}
                                                onChange={onInputChange}
                                            />
                                             <div className='icon-war'>{ errorTel ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.celular && !errorTel ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorTel ? <div className='alert-danger'>{errorTel}</div> : null}</span>
                                
                                        </div>
                                    </div>
																		<div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('departamento')}</label>
                                            <select className="p-2" name="departamento"value={profileInfo.departamento} onChange={handleDpto}>
                                                <option value="0">Selecciona un departamento</option>
                                                {departamentos.map((departamento, index) => (
                                                  <option key={index} value={departamento.id}>{departamento.name.nombre}</option>
                                                ))}
                                            </select>
                                            <div className='icon-war'>{ errorDpto ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.departamento && !errorDpto ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorDpto ? <div className='alert-danger'>{errorDpto}</div> : null}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('ciudad')}</label>
                                            <select className="p-2" name="ciudad" value={profileInfo.ciudad}  onChange={onInputChange} >
                                                <option value="0"> Selecciona una ciudad</option>
                                                {ciudades.map((ciudad, index) => (
                                                  <option key={index} value={ciudad.id}>{ciudad.name.nombre}</option>
                                                ))}
                                            </select>
                                            <div className='icon-war'>{ errorCiudad ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.ciudad && !errorCiudad ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorCiudad ? <div className='alert-danger'>{errorCiudad}</div> : null}</span>
                                        </div>
                                    </div>
              
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>{getTextByKey('direccion')}</label>
                                            <input
                                                type="text"
                                                className="p-2"
                                                name="direccion"
                                                value={profileInfo.direccion}
                                                onChange={onInputChange}
                                            />
                                               <div className='icon-war'>{ errorDireccion ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.direccion && !errorDireccion ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorDireccion ? <div className='alert-danger'>{errorDireccion}</div> : null}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>Barrio</label>
                                            <input
                                                type="text"
                                                className="p-2"
                                                name="barrio"
                                                value={profileInfo.barrio}
                                                onChange={onInputChange}
                                            />
                                            <div className='icon-war'>{ errorBarrio ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.barrio&& !errorBarrio ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorBarrio ? <div className='alert-danger'>{errorBarrio}</div> : null}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group position-relative">
                                            <label>{getTextByKey('contrasena_form')}</label>
                                            <input
                                                type="password"
                                                className="p-2"
                                                value="123456"
                                                required
                                            />
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#cambiarPasswordModal" className="border-0 bg-transparent p-0 btn-edit-address">
                                                <img src="/img/SVG/pencil-pink.svg" alt="Pencil icono" className="icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <div className="form-group">
                                            <label>N° documento</label>
                                            <input
                                                type="text"
                                                className="p-2"
                                                name="documento"
                                                value={profileInfo.documento}
                                                onChange={onInputChange}
                                            />
                                             <div className='icon-war'>{ errorNumDoc ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( profileInfo.documento && !errorNumDoc ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
												<span>{errorNumDoc ? <div className='alert-danger'>{errorNumDoc}</div> : null}</span>
                                            
                                        </div>
                                    </div>
                                </div>
                             
                                <div className="mt-4 text-center text-lg-start">
                                    <button type="submit" className="btn-g py-2 px-3 px-xl-5">{load ? 'guardando..' :getTextByKey('guardar')}</button>
                                </div>
                                {errors && <p className="mt-4 alert-danger">{errors}</p>}
                                {success && <p className="mt-4 source-sans-bold">Se ha guardado la información exitosamente</p>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            
            <ModalCambiarPasswordComponent />
            <ModalGuardarCuentaComponent />
            <ModalGuardar />

        </Fragment>
    )
}