import React, { useRef, useState } from 'react'
import ImageZoom from "react-image-zooom";

export const ProductImage = ({ imageSrc }) => {

    // const figureImage = useRef()

    // const [styleFigure, setStyleFigure] = useState({
    //     backgroundImage: `url(${imageSrc})`,
    //     backgroundSize: '0 0',
    //     backgroundPosition: '0 0'
    // })
    // const [sizeZoomBox, setSizeZoomBox] = useState({})
    // const [sizeZoomImage, setSizeZoomImage] = useState({})
    // const [positionSelector, setPositionSelector] = useState({
    //     top: '0',
    //     left: '0'
    // })

    // const handleMouseMove = (e) => {
    //     const { left, top, width, height } = e.target.getBoundingClientRect()
    //     let x = (e.pageX - left) / width * 100;
    //     let y = (e.pageY - top) / height * 100;
    //     console.log(x, y)
    //     const ratio = 1.25;
    //     if( x > 50 || y > 50 ) {
    //         return;
    //     }
    //     setStyleFigure({
    //         backgroundImage: `url(${imageSrc})`,
    //         backgroundSize: `${figureImage.current.offsetWidth * ratio}px ${figureImage.current.offsetHeight * ratio}px`,
    //         backgroundPosition: `-${x * ratio}px -${y * ratio}px`
    //     })
    //     setSizeZoomBox({
    //         width: figureImage.current.offsetWidth,
    //         height: figureImage.current.offsetHeight,
    //     })
    //     setSizeZoomImage({
    //         transform:`translate(-${x}%, -${y}%)`,
    //     })
    // } 

    return (
        <figure className='image-product-interna'>
            {/* <div className="zoom-element" style={styleFigure}></div>
            <div className="selector" style={positionSelector}></div>  */}
            {/* <img className='image-principal' src={imageSrc} ref={figureImage} />
            <div className="zoom-box" style={sizeZoomBox}>
                <img src={imageSrc} style={sizeZoomImage}/>
            </div> */}
            <ImageZoom className='image-principal w-100' src={imageSrc} alt="Shido Product" zoom="200"/>
        </figure>
    )
}
