import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider  } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCv69Z5-LtjZpan--Oa5DvpnsH7Vt2TMQ8",
  authDomain: "cervalle-73a07.firebaseapp.com",
  projectId: "cervalle-73a07",
  storageBucket: "cervalle-73a07.appspot.com",
  messagingSenderId: "654639094810",
  appId: "1:654639094810:web:b19cab5955dececd6485a3",
  measurementId: "G-M4SDYXY66Z"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth, EmailAuthProvider };
