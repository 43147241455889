import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { default as ModalConfirmarEntrega } from './ModalConfirmarEntrega';
import { useState, useEffect } from 'react';
import { getHorarios } from '../../api/storeApi';
import { useCart } from '../../context/cartContext';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../scss/datepicker.scss';
import { ActualizaTecnocarnes } from '../../api/productApi';

export default function ModalProgramarPedido({ showProgramar, horario,setShowProgramar, setShowModalRecoger, tiendaId, msg }) {

    const { getTextByKey,getHolidays } = useApi();
    const { setCurrentDelivery, updateCurrentdelivery,currentCart, updateTienda, validarCart,validarCartOld,updateDireccion} = useCart();
    //const [ horario, setHorario ] = useState([]);
    const [ modalConfirmar, setModalConfirmar ] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [ horasDisponibles, sethorasDisponibles ] = useState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
    const [error, setError] = useState('');
    
    const [ horaInicioTienda, setHoraInicioTienda ] = useState(new Date());
    const [ horaFinalTienda, setHoraFinalTienda ] = useState(new Date());
    const [ disponible, setDisponible ] = useState(0);
    const dias=['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
    const [ loader, setLoader ] = useState(false);
    const [holidays, setHolidays] = useState([]);

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowProgramar(false);
        gotoTop();
    }

    const volverModal = () => {
      setShowProgramar(false);
      setShowModalRecoger(true);
    }

    const confirmarEntrega = async() => {
      setLoader(true);
      let tecnocarnes=await ActualizaTecnocarnes(tiendaId);
      console.log(tecnocarnes,'tecnocarnes');
      updateTienda(tiendaId);
      updateDireccion('');
      updateCurrentdelivery({tipo:'pickup',recoger:selectedDate, dir: {id:tiendaId}});
      console.log(currentCart, currentCart.length);
     
      try{
        if(currentCart.length>0){
          let carritoajustar =await validarCart(tiendaId);
          if(carritoajustar.ok){
            setShowProgramar(false);
            setDisponible(carritoajustar.total);
            setModalConfirmar(true);
           
          }
          setLoader(false); 
       }else{
        let carritoajustar =await validarCartOld(tiendaId);
        if(carritoajustar.ok){
          setShowProgramar(false);
          setDisponible(carritoajustar.total);
          setModalConfirmar(true);
         }
         setLoader(false);
       }
    } catch (error) {
      // Manejar el error si ocurre
      console.error(error);
      setLoader(false);
    }
          
    }

    /*useEffect(() => {
      if(tiendaId){
        getHorarios(tiendaId).then((data)=>{
          setHorario(data);
          console.log('HORARIOS',data);
        })
      }
    }, [tiendaId])*/

    useEffect(()=>{
      if(horario.length>0){
        let horainicio = '08:00:00';
        let minTime = new Date();
    
        let day=horario.filter(hora=>hora.dia==dias[minTime.getDay()])[0];
        if(day){
          horainicio=day.horainicio;
        }
        let horas=horainicio.split(':');
        minTime.setHours(parseInt(horas[0]),parseInt(horas[1]), parseInt(horas[2]),
        );
        setHoraInicioTienda(minTime);
   
      }
    },[horario]);

    useEffect(()=>{
      if(horario.length>0){
        let horaFinal = '18:00:00';
        let maxTime = new Date();
        let day=horario.filter(hora=>hora.dia==dias[maxTime.getDay()])[0];
        if(day){
          horaFinal=day.horacierre;
        }
        let horas=horaFinal.split(':');
        maxTime.setHours(parseInt(horas[0]),parseInt(horas[1]),parseInt(horas[2]));
        setHoraFinalTienda(maxTime);
      }
    },[horario]);

    useEffect(() => {
      getHolidays().then((data)=>{
        setHolidays(data);
      }).catch((error)=>{
          setErrors('La tienda no tiene horarios');
      })
    }, []);

    const disabledDates=[];
    const festivos = holidays.map(holiday => new Date(holiday.fecha+' 12:00:00'));

    // const enableDays = [];
    // if(horario && horario.length > 0){
    //   horario.forEach((item)=>{
    //     enableDays.push(item.dia);
    //   })
    // }
    const disabledDays =  ['lunes','martes', 'miércoles', 'jueves', 'viernes', 'sábado','domingo'];
    const weekdays = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const disabledWeekdays = disabledDays.map((day) => weekdays.indexOf(day));

    const isWeekdayDisabled = (date) => {
    const day = date.getDay();
    return disabledWeekdays.includes(day);
   };

  
   const handleChangeDate = (date) =>{
    setError('');
    const horainicio= new Date();
    const horafinal= new Date();
    const actualTime = new Date();
    const mismo = actualTime.toDateString() === date.toDateString();
    let day=horario.find(hora=>hora.dia==dias[date.getDay()]);
    let validafestivo=festivos.find(festivo=>festivo.toDateString()==date.toDateString());
    console.log('festivo',validafestivo);
    if(validafestivo){
      day=horario.find(hora=>hora.dia==dias[0]);
          
    }
    if(day){
      let horasini=day.horainicio.split(':');
      let horasfini=day.horacierre.split(':');
      horainicio.setHours(parseInt(horasini[0]),parseInt(horasini[1]),parseInt(horasini[2]));
      setHoraInicioTienda(horainicio);
      horafinal.setHours(parseInt(horasfini[0]),parseInt(horasfini[1]),parseInt(horasfini[2]));
      setHoraFinalTienda(horafinal);
     if(mismo){
        if((actualTime) > date){
          setSelectedDate('');
          setError('Seleccione un horario mayor');
          //setSelectedDate(date.setHours(actualTime.getHours()+1,0,0));
         return;
        }
      }
      if(date.getHours() === 0){
        setSelectedDate(date.setHours(parseInt(horasini[0]),parseInt(horasini[1]),parseInt(horasini[2])));
      }else{
        setSelectedDate(date);
      }
    }else{
      setSelectedDate('');
      setError('No hay horarios disponibles, escoge otra tienda');

    }
       
   }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showProgramar} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('programar-pedido')}</h4>
                        <p className='gray-1-color'>{getTextByKey('modal-programar-elegir')}</p>
                      </div> 
                      <DatePicker
                        excludeDates={disabledDates}
                        selected={selectedDate}
                        onChange={(date) => handleChangeDate(date)}
                        minDate={new Date()}
                        showTimeSelect 
                        timeFormat="HH:mm"
                        filterDate={isWeekdayDisabled}
                        timeIntervals={30} 
                        timeCaption="Time" 
                        minTime={horaInicioTienda} 
                        maxTime={horaFinalTienda} 
                        dateFormat="dd/MM/yyyy -  HH:mm"
                        placeholderText={getTextByKey('texto-seleccione-fecha-popup')}
                        className='w-100 datepicker my-3'
          
                      />
                      <div className="text-center">
                        {error && <p className='alert-danger'>{error}</p>}
                      </div>
                      <div className="text-center pt-3">
                        <button className='btn-g bb px-5 my-2 my-md-1' onClick={volverModal}>{getTextByKey('volver')}</button>
                        {!error && selectedDate ? 
                        <button className='btn-g px-4 mx-md-3 mx-0 my-2 my-md-1' disabled={loader} onClick={confirmarEntrega}>
                          {	loader ? (<div className='spinner-border spinner-border-sm' role='status'></div>) :
                          `${getTextByKey('confirmar')}`
                          }
                          </button>
                         :<></>}

                      </div>
                    </div>
                </Modal.Body>
            </Modal>    
            <ModalConfirmarEntrega msg={msg} disponible={disponible} modalConfirmar={modalConfirmar} setModalConfirmar={setModalConfirmar}/>  
        </Fragment>
    )
}