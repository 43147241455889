import React from 'react'
import { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useCart } from '../context/cartContext';



export default function ModalAlerta({ limiteDetected, limiteProductos ,product,limiteInventario}) {
    const [ showAlerta, setShowAlerta ] = useState(false);
    const { getTextByKey} = useApi();
    const { currentCart, pesoTotal, descuento, calculateDescuento, calculatePeso } = useCart();
    const [ alertaTope, setAlertaTope ] = useState(false);
    const [ alertaDcto, setAlertaDcto ] = useState(false);
    const [ alertaLimite, setAlertaLimite ] = useState(false);
    const [ alertaInventario, setAlertaInventario ] = useState(false);
    
    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowAlerta(false);
        gotoTop();
    }
    
   /* useEffect(() => {
        calculateDescuento();
      }, [currentCart]);
      */
      useEffect(() => {

        if (limiteDetected) {
          if(!localStorage.getItem('alerta_descuento')){
            setShowAlerta(true);
            setAlertaDcto(true);
            localStorage.setItem('alerta_descuento',1);
          }
        }

        if(limiteProductos){
          if(!localStorage.getItem('alerta_productos')){
            setShowAlerta(true);
            setAlertaLimite(true);
            localStorage.setItem('alerta_productos',1);
          }
        }
        if(limiteInventario){
          //if(!localStorage.getItem('alerta_inv'+product.id)){
            setShowAlerta(true);
            setAlertaInventario(true);
            localStorage.setItem('alerta_inv'+product.id,1);
          //}
        }
      },[limiteDetected,limiteProductos,limiteInventario]); 
      //[pesoTotal, descuento, currentCart, setShowAlerta, alertaTope, alertaDcto, alertaLimite]);
      


    return (
        <Fragment>
            <Modal show={showAlerta} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2">
                      <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <p className='gray-1-color source-sans-bold'>{getTextByKey('modal-alerta-titulo')}</p>
                       
                        { alertaDcto ? <p className='gray-1-color'>{getTextByKey('alerta-descuento-limite')}</p> :''}
                        { alertaLimite ? <p className='gray-1-color'>{getTextByKey('alerta-limite-unidades')}</p> : ''}
                        { alertaInventario ? <p className='gray-1-color'>{getTextByKey('alerta-limite-inventario')}</p> : ''} 
                        
                      </div> 
                      <div className="text-center pt-3">
                          <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('entendido')}</button>
                      </div>
                    </div>
                </Modal.Body>
            </Modal>      
        </Fragment>
    )
}