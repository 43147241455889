import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import { useAuth } from '../../authContext';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { default as ModalProgramarPedido } from './ModalProgramarPedido';
import { getCiudades, getDepartamentos } from '../../api/productApi';
import { getTienda ,getHorarios} from '../../api/storeApi';
import { useCart } from '../../context/cartContext';
import { getInventario } from '../../api/productApi';

export default function ModalCompraRecoger({ showModalRecoger, setShowModalRecoger, setShowModalCompra }) {

    const { getTextByKey } = useApi();
    const [ ciudades, setCiudades ] = useState([]);
    const [ departamentos, setDepartamentos ] = useState([]);
    //const [ dptoId, setDptoId ] = useState('');
    const [ tienda, setTienda ] = useState([]);
    const [ showProgramar, setShowProgramar ] = useState(false);
    const [ tiendaId, setTiendaId  ] = useState(0);
    const [ departamento, setDepartamento] = useState(0);
    const [ horario, setHorario ] = useState([]);
    const [ ciudad, setCiudad] = useState(0);
    const [ errors, setErrors  ] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentProduct, getAddressDelivery, updateDireccion } = useCart();
    const [ msg,  setMsg ] = useState('');
    const { currentUser } = useAuth();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setShowModalRecoger(false);
        gotoTop();
    }

    const volverModal = () => {
      setShowModalRecoger(false);
      setShowModalCompra(true);
    }

    useEffect(()=>{
      if(currentUser){
        getDepartamentos().then((data)=>{
          setDepartamentos(data);
        })
      }
    },[showModalRecoger])

    const confirmModal = () => {
      setShowModalRecoger(false);
      setShowProgramar(true);
    }

    const handleDpto = (event) => {
      const dptoId = event.target.value;
      setDepartamento(dptoId);
      setCiudad(0);
      setCiudades([]);
      setTienda([]);
      getCiudades(dptoId).then((data) => {
        setCiudades(data);
      });
      setTiendaId(0);
    }

    const handleChage = (event) => {
      const id_ciudad = event.target.value;
      setCiudad(id_ciudad);
      setTienda([]);
      if(id_ciudad!=0){
        getTienda(id_ciudad).then((data) => {
           setTienda(data);
        });
      }
      setTiendaId(0);
    }

    const handleTienda = (event) => {
      setErrors('');
      setLoading(true);
      setHorario([]);
      let tienda=event.target.value;
      setTiendaId(tienda);
      /*if(currentProduct){
        console.log('current',currentProduct);
        currentProduct.forEach((item)=>{
          getInventario(item.product.plu, tienda).then((data)=>{
            setMsg(data);
          });
        })
      }*/
    }

    useEffect(() => {
      if(tiendaId){
        getHorarios(tiendaId).then((data)=>{
          setHorario(data);
          setLoading(false);
        }).catch((error)=>{
            setErrors('La tienda no tiene horarios');
            setLoading(false);
        })
      }else{
        setHorario([]);
        setErrors('');
        setLoading(false);
      }
    }, [tiendaId])


    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalRecoger} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="px-3">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('modal-recoger-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('modal-recoger-elegir')}</p>
                      </div> 
                      <form action="" className='pb-4 pt-3'>
                        <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color' value={departamento} name="departamento" onChange={handleDpto}>
                              <option  value="0">{getTextByKey("departamento")}</option>
                              {departamentos.map((departamento, index) => (
                                <option key={index} value={departamento.id}>
                                  { departamento.name.nombre }
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color' value={ciudad} name="ciudad" onChange={handleChage}>
                              <option  value="0">{getTextByKey("ciudad")}</option>
                              {ciudades.map((ciudad, index) => (
                                <option key={index} value={ciudad.id}>
                                    {ciudad.name.nombre}
                                  
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <select className='p-2 gray-1-color' value={tiendaId} name="tienda" onChange={handleTienda}>
                              <option value="0">{getTextByKey("tienda")}</option>
                              {tienda.map((tienda, index) => (
                                <option key={index} value={tienda.id}>{tienda.direccion}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        </div>
                      </form>
                      <div className="text-center">
                        {errors && <p className='alert-danger'>{errors}</p>}
                      </div>
                      <div className="text-center pt-3 d-flex justify-content-center align-items-center gap-2">
                          <button className='btn-g bb px-4' onClick={volverModal}>{getTextByKey('volver')}</button>
                         { !errors && horario.length>0 ? (<button className='btn-g px-4' onClick={confirmModal}>{getTextByKey('confirmar')}</button> ):
                          <> {loading && (
                            <button className='btn-g px-4'><div className='spinner-border spinner-border-sm' role='status'></div></button>
                           ) }
                           </>
                          }
                      </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalProgramarPedido msg={msg} tiendaId={tiendaId} horario={horario} showProgramar={showProgramar} setShowProgramar={setShowProgramar} setShowModalRecoger={setShowModalRecoger} />   
        </Fragment>
    )
}
