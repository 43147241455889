import { Fragment } from "react";
import { NavpoliticasComponent } from "./components/NavpoliticasComponent";
import { useApi } from "../../context/apiContext";
import { useState } from "react";

export default function PoliticaReversionPagoPage() {

const { getTextByKey, getTextBigByKey } = useApi();
const [isActive, setIsActive] = useState(false);

    const handleClick =() =>{
        setIsActive(!isActive)
    }

  return (
    <Fragment>
        <div className="container pb-5 pt-3 pt-md-5">
            <div className="row">
                <div className="row">
                    <div className="col-12 col-lg-8 ms-auto">
                        <div className="py-3 px-2">
                            <h1 className="primary-color carrefourMetis-bold text-start d-none d-lg-block">{getTextByKey("politica_reversion")}</h1>
                            <h1 className="primary-color fw-bold text-center d-block d-lg-none">{getTextByKey('politicas')}</h1>
                            <h2 className="primary-color fw-bold text-start d-block d-lg-none source-sans-bold">{getTextByKey("politica_reversion")}</h2>
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-block col-lg-4">
                    <NavpoliticasComponent/>
                </div>
                <div className="col-12 col-lg-8">
                    <div className={`pe-lg-5 pe-3 text-justify texto-politicas ${isActive ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: getTextBigByKey("politicas-retracto") }}></div>
                </div>
                <div className="d-flex justify-content-center py-4">
                    <button className="d-block d-lg-none btn-g bb py-1 px-4" onClick={handleClick}>{getTextByKey('leer-mas')}</button>
                </div>
                <div className="d-block d-lg-none col-12">
                    <NavpoliticasComponent/>
                </div>
            </div>
        </div>
        
    </Fragment>
  )
}
