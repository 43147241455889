import React, { Fragment } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { getOrderPSE } from '../../api/productApi'
import RecomendadosSliderComponent from '../../components/RecomendadosSliderComponent'
import { useApi } from '../../context/apiContext'

export default function PagoExitosoPage() {

  const [order, setOrder] = React.useState(0)
  const [msgOrder, setMsgOrder] = React.useState('Pago Pendiente');
  const [loading, setLoading] = React.useState(false);
  const { getTextByKey, currentLocale } = useApi();

  const location = useLocation();

  React.useEffect(() => {
    console.log(location)
    if (location.state) {
      setOrder(location.state.order);
      setMsgOrder(location.state.msg);
    }
    if(location.search){
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const id = params.get('id');
      getOrderPSE(id).then((res) => {
        console.log(res);
        if(res.data.status){
          setOrder(res.data.reference);
          if(res.data.status == "APPROVED"){
            setMsgOrder( getTextByKey('compra-aprobada') );
          }else{
            setMsgOrder( getTextByKey('compra-pendiente') );
          }
        }
        setLoading(false);
      });
    }
  }, [location])


  const convertDateAndHourFromIso = (date) => {
    const dateObj = new Date(date);
    const dateStr = dateObj.toLocaleDateString();
    const hourStr = dateObj.toLocaleTimeString();
    return `${dateStr} ${hourStr}`;
  }

  return (
    <Fragment>
      {!loading ? (
        <div className='container text-center py-2 py-md-3 py-xl-5'>
            <div className='p-2 p-md-3 p-lg-5 secundary-bg-color mb-2 mb-lg-5 ficha-pago-exitoso'>
                <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>{msgOrder}</h2>
                <p className='text-uppercase'>{getTextByKey('fecha-compra')}: {convertDateAndHourFromIso(new Date())}</p>
                <p className='text-uppercase'>{getTextByKey('numero-pedido')} {order}</p>
                <p className='text-uppercase mb-2 mb-md-4'>{getTextByKey('recibo-compra')}</p>
                <NavLink to="/" className='btn-g bb px-3 py-2 text-uppercase my-3'>{getTextByKey('back-to-shop')}</NavLink>
            </div>
            <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>{getTextByKey('recomendados-titulo')}</h2>
            <RecomendadosSliderComponent/>
        </div>
      ) : (
        <div className='container text-center py-2 py-md-3 py-xl-5'>
          <h2 className='text-uppercase mb-2 mb-md-4 mb-lg-5'>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
          </h2>
        </div>
      )}
    </Fragment>
  )
}
