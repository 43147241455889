import React from 'react'
import PropTypes from 'prop-types'

import '../scss/recipe_component.scss';
import { Link } from 'react-router-dom';
import Picture from './Picture';

const RecipeComponent = ({recipe}) => {

  return (
    <div className="col-12 col-sm-6 col-lg-4 px-xl-2 mb-4">
        <Link to={"/receta/" + recipe?.slug}>
            <div className="singular-recipe text-center">
                <div className="image-recipe">
                    {
                        recipe.images.length && (
                            <Picture src={recipe.images[0]?.image} alt={"Recipe item"} classImg={"w-100 image"} />
                        )
                    }
                </div>
                <div className="mt-3">
                    <h3 className="recipe-name source-sans-bold">{recipe.title ? recipe.title : recipe?.translations?.[0]?.title}</h3>
                </div>
            </div>
        </Link>
    </div>
  )
}

RecipeComponent.propTypes = {
    recipe: PropTypes.object.isRequired
}

export default RecipeComponent