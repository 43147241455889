import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from '../context/apiContext';
import { getImages } from '../api/imageApi';
import { images_products_route } from '../api/productApi';

export default function BtnWhatsapp() {

  const [imgWpp, setImgWpp ] = useState([]);
  const { getTextByKey } = useApi();

  useEffect(() => {
    getImages("whatsapp").then((data) => {
        setImgWpp(data);
    });
  }, []);
  

    return (
      <div className='btn-whatsapp'>
        <a href={`https://api.whatsapp.com/send?phone=+57${getTextByKey("numero-whatsapp")}&text=${getTextByKey('texto-adicional-whatsapp')}`} target="_blank">
         { imgWpp.length > 0 && <img src={images_products_route + imgWpp[0].image} alt="logo whatsapp" />}
        </a>
      </div>
    )
}
