import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import ProductsCarComponent from './components/ProductsCarComponent';
import { useApi } from "../../context/apiContext";
import '../../scss/checkout.scss'
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../authContext';
import { QuantyControls } from '../../components/QuantyControls';
import { default as ModalProcederPago } from './components/ModalProcederPago';
import AlertaEliminarProducto from '../../components/AlertaEliminarProducto';
import { getRecommendedHome } from '../../api/productApi';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Picture from '../../components/Picture';
import ModalCompra from '../../components/modalesRecibirPedido/ModalCompra';
import ModalInicio from '../../components/modalesRecibirPedido/ModalInicio';

const CheckoutbPage = () => {
  const { getTextByKey, getTextBigByKey, convertCurrency,setShowAlertaTope } = useApi();
  const { currentCart, total, envio, descuento, impuesto, impuestoUltra, impuestoBolsa, pesoTotal, subtotal, pesoXbolsa, tiendaGeneral, updateCart } = useCart();
  const { currentUser } = useAuth();
  const { clearCart } = useCart();

  const [ showModal, setShowModal] = useState(false);
  const [showModalCompra, setShowModalCompra ] = useState(false);
  const [ showModalInicio, setShowModalInicio ] = useState(false);

  const openModal = () =>{
    if(currentUser===null){
      setShowModalInicio(true);
    }else if(!tiendaGeneral){
      setShowModalCompra(true);
    }else{
      setShowModal(true);
    }
  }

  const [ carritoVacio, setCarritoVacio] = useState(false);
  const [ showEliminar, setShowEliminar ] = useState(false);
  const [ bolsa, setBolsa ] = useState(1);
  const [ indexProd, setIndexProd ] = useState([])
  const [ nameProd, setNameProd ] = useState('');
  const [ recomendados, setRecomendados ] = useState([]);

  const vaciarCart = () => {
    setCarritoVacio(true);
    clearCart();
  }

  // const handleQuantityChange = (quantity, itemIndex) => {
  //   const newCart = [...currentCart];
  //   const updatedItem = { ...newCart[itemIndex], quantity };
  //   updatedItem.subtotal = updatedItem.price.precio * updatedItem.quantity;
  //   newCart[itemIndex] = updatedItem;
  //   updateCart(itemIndex, updatedItem);
  // }

  const handleQuantityChange = (quantity, itemIndex) => {
    const newCart = [...currentCart];
    const item = newCart[itemIndex];
    item.quantity = quantity;
    // item.subtotal = item.price.precio * item.quantity;
    updateCart(itemIndex, item);
  }


  const removeItem = (index) => {
    setIndexProd(index);
    setShowEliminar(true);
  }

  useEffect (() => {

    setBolsa(Math.ceil(pesoTotal/pesoXbolsa));

  }, [pesoTotal, pesoXbolsa]);

  useEffect(()=>{
    if(pesoTotal == 0 || pesoTotal == null ){
      setBolsa(1);
    }
  },[bolsa, pesoTotal, pesoXbolsa])

  /*useEffect(()=>{
    console.log('DIRECCION', direccion);
    calculatePeso();
    calculateImpt();
    calculateDescuento();
  }, [currentCart, impuesto, direccion]);}*/

  useEffect(()=>{
    getRecommendedHome(tiendaGeneral).then((data) => {
      console.log('recomendados', data);
      setRecomendados(data);
    });
  },[tiendaGeneral])




  return (
    <Fragment>
      <div className="ckeckout my-5">
        <div className="container px-lg-4">
          <div className="text-center">
            <h1  className="primary-color mb-3 carrefourMetis-bold">{getTextByKey('mi-carrito')}</h1>
          </div>
          {
            carritoVacio || currentCart.length == 0 ? 
            <div className="row">
              <div className="text-center pt-4 pb-5">
                <p className='source-sans-bold gray-1-color mb-0'>{getTextByKey('no-productos')}</p>
                <div className='gray-1-color mt-2 mb-3' dangerouslySetInnerHTML={{ __html: getTextBigByKey("aviso-sin-productos-carrito") }}></div>
                <Link to="/shop" className='btn-g px-5 py-1 mt-2'>{getTextByKey('ir-a-tienda')}</Link>
              </div>
            </div> :
            <div className="row">
              <div className="col-xl-5 col-lg-7">
                <div className="list-products">
                  <div className="pe-2">
                    <p className='source-sans-bold gray-1-color ms-3 mb-0'>Producto</p>
                      {
                        currentCart.map((item, index) => (
                          <div className="row singular-product border-bottom border-1 py-3" key={index}>
                            <div className="col-md-3 col-3">
                              {item.images.length > 0 && <Picture classImg={'w-100 rounded'} src={item.images[0].image} alt={'product'} />}
                            </div>
                            <div className="col-md-9 col-9">
                              <div className="me-3 me-xl-4">
                                <h4 className='source-sans-bold gray-1-color'>{item.name.nombre}</h4>
                                {item.gramaje!=0 &&<span>{item.gramaje.nombre}</span>}
                                <div className="d-md-flex align-items-center">
                                  {item.peso ? <p className='mb-0 gray-1-color'>Peso: <span>{item.peso}gr</span></p> : "" }
                                </div>
                                <div className="row py-2 py-lg-0">
                                  <div className="col-md-8 col-7">
                                    <QuantyControls 
                                      quantity={item.quantity} 
                                      product={item}
                                      onQuantityChange={(quantity) => handleQuantityChange(quantity, index)} />
                                  </div>
                                  <div className="col-md-4 col-5">
                                  <p className="mb-0 my-2 my-md-0 source-sans-bold"><span>{item.quantity}</span> x <span>{convertCurrency(item.price.precio)}</span></p>
                                  </div>
                        
                                </div>
                              </div>
                            </div>
                            <div className="trash-product primary-color cursor-pointer" onClick={()=>removeItem(index)}><Icon icon="akar-icons:trash-can" /></div>

                        </div>
                        ))}
                        <AlertaEliminarProducto nameProd={currentCart[indexProd]?.name?.nombre} index={indexProd} showEliminar={showEliminar} setShowEliminar={setShowEliminar} />
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button className="btn-g bb py-2 px-4 mb-5 mb-lg-0" onClick={vaciarCart} >{getTextByKey('vaciar-carrito')}</button>
                </div>
               
              </div>
                <div className="col-xl-4 offset-xl-2 col-lg-5 offset-lg-0">
                  { currentCart.length > 0 && 
                    <div className="px-3">
                      <div className="primary-border-color resumen-carrito gray-1-color">
                        <h3 className="py-2 px-2 px-lg-3 mb-0 fw-bold border-bottom">{getTextByKey('total-carrito-titulo')}</h3>
                        <p className="d-flex justify-content-between py-2 px-3 mb-0 border-bottom"><span className="fw-bold">{getTextByKey('subtotal')}</span><span>{convertCurrency(subtotal.toFixed(0))}</span></p>
                        {descuento!=0 && <p className="d-flex justify-content-between py-2 px-3 mb-0 border-bottom"><span className="fw-bold">{getTextByKey('descuento')}</span><span>-{convertCurrency(descuento)}</span></p>}
                        <p className="d-flex justify-content-between py-2 px-3 mb-0 border-bottom"><span className="fw-bold">{getTextByKey('impuestos')}</span><span>{convertCurrency((impuesto).toFixed(0))}</span></p>
                        {impuestoUltra!=0 ? <p className="d-flex justify-content-between py-2 px-3 mb-0 border-bottom"><span className="fw-bold">{getTextByKey('impuesto-ultraprocesado')}</span><span>{convertCurrency((impuestoUltra).toFixed(0))}</span></p>:<></>}
                        <p className="d-flex justify-content-between py-2 px-3 mb-0 border-bottom"><span className="fw-bold">{getTextByKey('impt-bolsa')}<span className="fw-normal">x{(bolsa)}</span></span><span>{convertCurrency((impuestoBolsa)*(bolsa))}</span></p>
                        <div className="pt-2 px-3 border-bottom">
                          <p className="fw-bold mb-0">{getTextByKey('envio')}</p>
                          <p className="mb-0 sm-p">{getTextByKey('alerta-costes-envio')}</p>
                        </div>
      
                        <p className="d-flex justify-content-between p-2 mb-0 primary-color fw-bold"><span className="fw-bold">{getTextByKey('total')}</span><span>{convertCurrency(total + envio +  ((impuestoBolsa)*(bolsa)))}</span></p>
                      </div>
                      <div className="d-xl-flex justify-content-center text-center">
                        <Link to='/shop' className='btn-g bb px-3 py-2 my-3'>{getTextByKey('seguir-comprando')}</Link>
                        <button className='btn-g px-3 py-2 ms-xl-2 ms-md-3 ms-2 my-3' onClick={openModal}>{getTextByKey('finalizar-compra')}</button>
                      </div>
                    </div> 
                  }
                  
                </div>
            </div>
      
          }

        </div>

        <div className="add-other-car pt-5 my-2">
          <div className="container">
            <h4 className="source-sans-semibold primary-color mb-0">{getTextByKey('agregar-producto')}</h4>
            <div className="position-relative py-3">
              <div className="swiper-checkout">
                <Swiper modules={[Navigation, Autoplay]}
                  navigation
                  autoplay={{ delay: 6000, disableOnInteraction: true }}
                  breakpoints={{
                      320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                      },
                      768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                      },
                      1200: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                      },
                  }}>
                  { recomendados ? recomendados.map((item, index)=> (
                    <SwiperSlide key={index} className="px-4 px-xl-0">
                      <ProductsCarComponent item={item} />
                    </SwiperSlide>
                  )) : ''}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalProcederPago showModal={showModal} setShowModal={setShowModal} />
      <ModalCompra showModalCompra={showModalCompra} setShowModalCompra={setShowModalCompra} />
      <ModalInicio showModalInicio={showModalInicio} setShowModalInicio={setShowModalInicio} />
    </Fragment>



    // <Fragment>


    //   <StepbyStepComponent step={2}/>
    //   <div>
    //     <div className="container py-5 checkout">
    //       <div className="row justify-content-md-center">
  
    //         <div className="col-md-8 col-lg-5">
    //           <h2 className='text-uppercase mb-4'>{getTextByKey('resumen-carrito-titulo')}</h2>
    //             <div className='content-carrito'>
    //               {currentCart.map((item, index) => (
    //                   <ItemCarrito key={index} index={index} item={item} />
    //               ))}
    //               {currentCart.length === 0 && <p className='text-center-c'>{getTextByKey('notice-cart-without-products')}</p>}
    //           </div>
    //           <h2 className='text-uppercase my-4'>{getTextByKey('direccion-envio-titulo')}</h2>
  
    //           <form className='mb-4'>
    //             <SelectDireccionComponent/>
    //           </form>
  
    //           <button className='btn-g bb p-3 py-2 text-uppercase my-3' data-bs-toggle="collapse" data-bs-target="#collapseForm" aria-expanded="false" aria-controls="collapseForm">{getTextByKey('agregar-direccion-nueva')}</button>
              
    //           <div className="collapse" id="collapseForm">
    //             <FormularioDireccion user={currentUser} />
    //           </div>
    //         </div>
  
    //         <div className="col-md-4 col-lg-5 offset-lg-1">
    //           <div className="container">
    //             <BolsaComponent  />
  
    //             <div className='py-5 text-center'>
    //               <h3>{getTextByKey('metodo-pago')}</h3>
    //               <div className="d-flex justify-content-center align-items-center flex-wrap">
    //                 <a className='m-md-2 m-1 icono-metodo'><img src='/img/SVG/american.svg' /></a>
    //                 <a className='m-md-2 m-1 icono-metodo'><img className='visa' src="img/visa.png" alt="logo" /></a>
    //                 <a className='m-md-2 m-1 icono-metodo'><img src="img/mastercard.png" alt="logo" /></a>
    //                 {/* <a href="" className='m-md-2 m-1 icono-metodo'><img src="img/nequi.png" alt="logo" /></a> */}
    //                 <a className='m-md-2 m-1 icono-metodo'><img src="img/pse.png" alt="logo" /></a>
    //               </div>
    //             </div>
  
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  )
}

export default CheckoutbPage