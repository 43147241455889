export const getMonthName = (month) => {
    const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return monthNames[month];
}

export const createDatetime = (datetime_) => {
    const date = new Date(datetime_);
    return {
        month: getMonthName(date.getMonth()),
        day: date.getDate(),
        year: date.getFullYear()
    }
}