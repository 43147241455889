import sellyApi from './sellyApi'

export const createSuscribe = async (inputData, setMessage,setSuccess) => {
  try {
    const data = await sellyApi.post(`/user/Suscribe`, inputData)
    console.log('suscribe', data);
    if (data?.status === 200) {
      console.log('confirma');
      setSuccess('Suscripción exitosa');
      localStorage.setItem('modal-suscripcion',1);
    }
  } catch (error) {
    console.log('Error en la suscripcion', error);
    if(error?.response.status === 404){
      setMessage('El usuario ya tiene una suscripción activa');
      localStorage.setItem('modal-suscripcion',1);
    }
    else{
      setMessage('Error en la suscripción');
    }
  }
}

export default { createSuscribe }
