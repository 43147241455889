import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { auth } from '../../../firebaseConfig';
import { useApi } from '../../../context/apiContext';

export function ModalGuardarCuentaComponent() {

    // const { getTextByKey, getTextBigByKey } = useApi();

    // const [email, setEmail] = React.useState('');

    // const onClickDelete = () => {
    //     deleteUser(profileInfo).then((response) => {
    //         handleLogout();
    //     });
    // }

    // //Logout Event
    // const handleLogout = async () => {
    //     try {
    //         await auth.signOut();
    //         location.href = "/";
    //     } catch {
    //     }
    // };

    // useEffect (() => {
    //     if(profileInfo){
    //         setEmail(profileInfo.email);
    //     }
    // }, [profileInfo]);
        

    return (
        <Fragment>
            <div className="modal fade" id="guardarCuentaModal" tabIndex="-1" aria-labelledby="guardarCuentaModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-0 border-0">
                            <div className="text-center">
                                <h3 className="carrefour-sans-bold mb-3" id="guardarCuentaModal">Guardar cambios</h3>
                                    ¿Estás seguro que desea guardar estos <br /> cambios?
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button type="button" className="btn-g bb py-2 px-4 px-xl-5 mx-xl-3">Cancelar</button>
                            <button type="button" className="btn-g py-2 px-4 px-xl-5 mx-xl-3">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
