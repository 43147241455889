import React from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../../context/apiContext';
import { useCart } from '../../../context/cartContext';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import NuevaDireccionForm  from '../../../components/NuevaDireccionForm';
import { ActualizaTecnocarnes } from '../../../api/productApi';



export default function ModalNuevaDir({ modalNuevaDir, setModalNuevaDir, setTiendaId}) {

    const { getTextByKey } = useApi();
    const [ succesfull, setSuccesfull] = useState('');
    const { validarCart,validarCartOld, currentCart,tiendaGeneral } = useCart();

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setModalNuevaDir(false);
        gotoTop();
    }

    const handleConfirm = async() => {
      console.log(currentCart, currentCart.length);
      try{
        if(currentCart.length>0){
          let carritoajustar =await validarCart(tiendaGeneral);
          if(carritoajustar.ok){
            //setDisponible(carritoajustar.total);
            setModalNuevaDir(false);
            window.location.reload();
          }
        }else{
          let carritoajustar =await validarCartOld(tiendaGeneral);
          if(carritoajustar.ok){
            //setDisponible(carritoajustar.total);
            setModalNuevaDir(false);
            window.location.reload();
          }
        }
        } catch (error) {
          // Manejar el error si ocurre
          console.error(error);
        }
       
      
    }


    return (
        <Fragment>
            <Modal className="modal-compra" show={modalNuevaDir } onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="container">
                      <div className="text-center">
                        <h4 className='gray-1-color'>{getTextByKey('direccion-envio-titulo')}</h4>
                        <p className='gray-1-color'>{getTextByKey('selecciona-modal-direccion')}</p>
                      </div> 
                      <NuevaDireccionForm succesfull={succesfull} setModalNuevaDireccion={setModalNuevaDir} setTiendaId={setTiendaId} setSuccesfull={setSuccesfull} />
                      <div className="text-center pt-3">
                        {succesfull &&
                          <button className='btn-g px-4 mx-3' onClick={handleConfirm}>{getTextByKey('confirmar')}</button>}
                      </div>
                    </div>
                </Modal.Body>
            </Modal>

        </Fragment>
    )
}
