import React from "react";
import { images_products_route } from "../api/productApi";

const img_route = images_products_route;

function Picture({ src, alt, classImg }) {
  return (
    <picture>
      <source srcSet={img_route +src+'?format=webp'} type="image/webp"/>
      <source srcSet={img_route + src+'?format=jpg'} type="image/jpeg"/>
      <img className={`${classImg}`} src={img_route+src} alt={alt}/>
    </picture>
  );
}
export default Picture;