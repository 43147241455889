import React from "react";
import { Link, useRoutes } from "react-router-dom";
import { useApi } from "../context/apiContext";

export function Migas({ paths }) {
  if (!paths) {
    paths = [];
  }
  const { updateLocale, currentCurrency, currentLocale, updateCurrency } = useApi();

  function clasesMigas(i) {
    if (i == paths.length - 1) {
      return "gray-1-color mx-1";
    }
    return "gray-1-color mx-1 miga";
  }

  return (
    <ul className="migas d-none d-lg-flex align-items-center mb-4 p-0">
      {
        paths.map((path, i) => (
          <li key={path.name}>
            <Link to={path.to} className={clasesMigas(i)}>{path.name}</Link>
          </li>
        ))
      }
    </ul>

  );
}