import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Picture from '../../../components/Picture'
import { PrecioSinDcto } from '../../../components/PrecioSinDcto'
import { Icon } from '@iconify/react'
import { useCart } from '../../../context/cartContext'
import { useApi } from '../../../context/apiContext'
import { useAuth } from '../../../authContext'

export default function ProductsCarComponent({ item }) {

  
  const { addToCart, showCart, checkIfExistInCart, setCurrentProduct, currentDelivery,tiendaGeneral,currentCart } = useCart();
  const [ quantity, setQunatity] = useState(1);
  const {setModalInicio, setModalCompra} = useApi();
  const { currentUser } = useAuth();

  const addCart = async (e, product) => {
    e.preventDefault();
    if(product.gramajes.length>0){
      location.href="/product/"+product.plu;
      return;
    }
    product.type="product"; 
    product.gramaje=0;
    product.id=product.plu;
    if(currentUser){
       if(!tiendaGeneral){
        setModalCompra(true);
      }
    }else{
      setModalInicio(true);
     
    }
    
    if(tiendaGeneral){
      addToCart(product, quantity);
      if(currentCart.length == 0){
        showCart();
      }
    }
    
  }

  return (
    <div>
      <div className="singular-product">
        <Link to={"/product/"+item?.slug }>
          <div className="row">
            <div className="col-lg-4 col-md-5 col-12 px-md-1 px-2 position-relative">
              <div className="image-product">
                <Picture src={item.images[0].image} alt={"Product-item"} classImg={"w-100"} />
                {item.cantidad >3 &&
                <div className="add-car-icon cursor-pointer" onClick={(event) => addCart(event, item)}>
                  <Icon className='icon' icon="carbon:shopping-cart"/>
                </div> 
                }
                { item.cantidad <= 3 &&
                    <div className="agotado position-absolute white-color tertiary-bg-color source-sans-semibold px-2">Agotado</div> 
                  }
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-12 px-md-3 px-2">
              <div>
                <p className="mb-0 sm-p gray-1-color">{item.subcategorias.nombre}</p>
                <h4 className='gray-1-color source-sans-bold'>{item.name.nombre}</h4>
                <PrecioSinDcto precio={item.price} />
                { item.peso ? 
                  <p className='gray-1-color'>Gramo a <span>{(item.price.precio/item.peso).toFixed(1)}</span></p> : ''
                }
                
              </div>
            </div>
          </div>
        </Link>
        </div>
    </div>
  )
}
