import { sellyApi } from '../api'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword
} from "firebase/auth";
import { useAuth } from '../authContext';
import { useCart } from '../context/cartContext';
 

export const createUser = async (formData, setErrors, setloading, setRegistro) => {

  try {
    const auth = getAuth();
    try{
      let data =  await createUserWithEmailAndPassword(auth, formData.email, formData.password)
      //.then((data) => {
        const user = auth.currentUser;
        try{
          let d = await updateProfile(user, {
            displayName: formData.nombre + " " + formData.apellido,
            phoneNumber: formData.celular,
          });
        //.then(async () => {

          formData.uid = data.user.uid;
          formData.providerData = JSON.stringify(data.user.providerData);
          formData.direccion = formData.tipocalle + " "+ formData.base + " # "+formData.numero+" - "+formData.detalle + " "+formData.complement;
          try{
          const newUser = await sellyApi.post(`/user/create`, formData);
            console.log("user create ", newUser,formData);
            if (newUser?.status === 200) {
              //setRegistro(1);
              //location.href = "/";
            }else{
              setloading(false);
              const delUser = auth.currentUser;
              await delUser.delete();
            }
            return newUser.data;
          }catch(error){
            setloading(false);
            setErrors(error.response.data.message);
            const delUser = auth.currentUser;
            await delUser.delete();
            return true;
          }
        }catch(error) {
          setloading(false)
          const delUser = auth.currentUser;
          await delUser.delete();
          setErrors(error.response.data.message);
        };
      }catch(error){
        switch (error.code) {
          case 'auth/email-already-in-use':
            setloading(false);
            setErrors('Este correo se encuentra registrado')
            break;
        }
      }
    
  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador')
  }
}

export const loginUser = async (formData, setErrors, setloading, setLogeo) => {
  try {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then((user) => {
        console.log('llega', user);
        //Llamar nuevo endpoint: validar que el usuario existe status 200
        getUser(user.user.uid).then(async(res)=>{
          console.log('getUser', res);   
          if(!res.user.id){
            const delUser = auth.currentUser;
            await delUser.delete();

            location.href ="/register";
          }
          //Login success redirect to home
          setTimeout(() => {
            setloading(false);
            if( sessionStorage.getItem('something-to-tell') ) {
              const routeFeedback = sessionStorage.getItem('something-to-tell');
              sessionStorage.removeItem('something-to-tell');
              setLogeo(1);
              //location.href = `/${routeFeedback}`;
              return true;
            } else {
             
              setLogeo(1);
              return true;
            }
          }, 1000);
        }).catch(async(error)=>{
          console.log('auth error', error);
          location.href ="/register";
        })
      })
      .catch((error) => {
        //Login error show the error message
        setloading(false)
        switch (error.code) {
          case 'auth/wrong-password':
            setErrors('La contraseña es incorrecta.')
            break;
          case 'auth/user-not-found':
            setErrors('El correo no se encuentra registrado.')
            break;
          case 'auth/too-many-requests':
            setErrors('Lleva demasiados intentos para acceder, intente más tarde.')
            break;
        }
      });
  } catch (error) {
    setloading(false)
    setErrors('Ha ocurrido un error, contacte al administrador')
  }
}


export const createAnonymousUser = async (formData) => {
  try {
    const response= await sellyApi.post(`/user/create`, formData);
      return response;
    
  } catch (error) {
    console.log(error);
      throw new Error('eror al crear usuario');
  }
}

export const getUser = async (uid) => {
  try {

    const { data } = await sellyApi.get(`/user?usuario=${uid}`)

    return {
      user: data,
    };
  } catch (error) {
    return error;
  }
}

export const updateUser = async (inputData) => {
  try {

    console.log('data to update', inputData);
    const { data } = await sellyApi.put(`/user/update`, inputData)

    return data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (inputData) => {
  try {

    console.log('data to delete', inputData);
    const { data } = await sellyApi.delete(`/user/${inputData.uid}`)

    return data;
  } catch (error) {
    return error;
  }
}

export default {
  createUser,
  getUser,
  updateUser,
  deleteUser,
  loginUser
}
