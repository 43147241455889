import React, { useState, useEffect } from 'react';
import { Fragment } from "react";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import Picture from '../../components/Picture';
import '../../scss/about_page.scss';
import { Link } from "react-router-dom";
import { Migas } from "../../components/Migas";
import { Icon } from "@iconify/react";



export default function SocialResponsabilityPage() {

    const { getTextByKey, getTextBigByKey, getPropiertyByLocale } = useApi();

    const [ rotador2, setRotador2 ] = useState([]);

    useEffect(() => {
        getGallery('rotador-nutriendo-futuro').then((data)=>{
          setRotador2(data);
        })
        window.scroll(0, 0)
      }, [])

    const getBreadcrumps = () => {
        const paths = [
            { to: '/nosotros', name: 'Nosotros' },
            { to: '/social-responsability', name: 'Programa de responsabilidad social' }
        ];
        return paths;
    }

    return (
        <Fragment>
            <div className="my-5">
                <div className="container">
                    <Migas className="py-3" paths={getBreadcrumps()} />
                </div>
                <div className="nurturing-future section-skew pt-5 my-5">
                    <div className="container">
                        <div className="row px-2 px-md-5 py-2 py-md-5">
                            <div className="col-12 col-md-12">
                                <div className="col-12 col-md-6">
                                    <h3 className="primary-color carrefour-sans-bold">{getTextByKey("nutriendo_futuro")}</h3>
                                    <div className="gray-1-color" dangerouslySetInnerHTML={{__html: getTextBigByKey("responsabilidad_social")}}></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pe-2 pe-md-5">
                                <div className="gray-1-color text-center text-lg-start" dangerouslySetInnerHTML={{__html: getTextBigByKey("responsabilidad_social_parrafo1")}}></div>
                            </div>
                            <div className="col-12 col-md-6 align-self-end ps-2 ps-md-5">
                                <div className="gray-1-color text-center text-lg-start" dangerouslySetInnerHTML={{__html: getTextBigByKey("responsabilidad_social_parrafo2")}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-slides social-responsability text-center mt-5">
                    <div className="container contianer-swiper">
                        <Swiper modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
                            navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 6000, disableOnInteraction: true }}
                            effect={"coverflow"}
                            centeredSlides={true}
                            coverflowEffect={{ rotate: 0, stretch: -180, depth: 800, modifier: 1, slideShadows: false }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 25,
                                },
                            }}>
                            {rotador2 && rotador2.map((rotador2, index) => (
                                <SwiperSlide key={index}>
                                    <div className="text-center">
                                        <Picture src={rotador2.image} alt={"About slide item"} classImg={"img-rotador w-100"} />
                                        <div className="txt-content mt-5"> </div>
                                    </div>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}