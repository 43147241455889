import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Icon } from '@iconify/react';
import { images_products_route } from "../api/productApi";
import { getImages } from "../api/imageApi";
import { getGallery } from "../api/galleryApi";
import { useApi } from "../context/apiContext";
import { createSuscribe } from "../api/suscribeApi";
import { useCart } from "../context/cartContext";
import { useAuth } from "../authContext";
import { useForm } from "../hooks/useForm";
import Picture  from './Picture';
import { Collapse } from "react-bootstrap";



const suscribeFormFields = {
  email: "",
  lan: "es"
}

export function Footer(){
    const [loading, setLoading] = useState(false);
    const { getTextByKey, getTextByKeyLink ,extractNameFromLocate,getPropiertyByLocale, getTextBigByKey, currentLocale } = useApi();
    const navigate = useNavigate();
    const [ isChecked, setIsChecked ] = useState(false);
    const [ message, setMessage ] = useState()
    const {
      onInputChange: onSuscribeInputChange,
      formState, emailRegex
    } = useForm(suscribeFormFields);

    const { email, conditionTerms } = formState;
    const [checkNewsletter, setCheckNewsletter] = useState( false );
    const [tiendas, setTiendas] = useState([]);
    const [emailCorrect, setEmailCorrect] = useState( true );
    const [emailUserNewsletter, setEmailUserNewsletter] = useState( '' );
    const [ logo, setLogo ] = useState([]);
    const [ success, setSuccess ] = useState('')

    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/g;

    // const handleChangeEmailNewsletter = (event) => {
    //     setEmailUserNewsletter( event.target.value );
    //     setEmailCorrect( regex.test(emailUserNewsletter) );
    // }

    // const subscribeNewsletter = async (event) => {
    //     event.preventDefault();
    //     const dataSubscribe = {
    //         email: emailUserNewsletter,
    //         lan: currentLocale,
    //     };

    //     const responseSubscribe = await createSuscribe(dataSubscribe);
    //     setCheckNewsletter( false ) ;
    //     setEmailCorrect( false );
    //     setEmailUserNewsletter('');
    //     console.log(responseSubscribe);
    // }

    const { hideCart } = useCart();

    const gotoTop = event => {
        hideCart();
        window.scrollTo(0, 0);
    };


    const handleShowModal = (currentUser) => {
      if (currentUser) {
        sessionStorage.setItem('something-to-tell', 'feedback-a')
        navigate('/login');
      }else{
        console.log('no user');
      }
    }

    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
      setIsActive(current => !current);
    };

    useEffect(() => {
      getImages('logo').then((data) => {
        setLogo(data);
      })
      getGallery("footer-tiendas").then((data)=> {
        setTiendas(data);
    });

    }, [])
    
    const onEmailSuscribeSubmit = async (event) => {
      event.preventDefault();
      const validate = await validateForm(formState)
      setSuccess('');
      if(!validate){
        setLoading(true);
        formState.lan = currentLocale;
        const newSuscribe = await createSuscribe(formState, setMessage,setSuccess);
         setLoading(false);
      }
    }

    const handleCheckTermsCondition = () => {
      setIsChecked(!isChecked ? true : false );
    }

    const validateForm = async (formData) => {
      let errorFound = false;
      if(!email.match(emailRegex)){
        errorFound = true;
        setMessage("Email incorrecto")
      }
      if(!isChecked){
        errorFound = true;
        setMessage('Debes aceptar términos y condiciones');
      }
      return errorFound;
    }

    const [openItem, setOpenItem] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10:false
    });
    
    const toggleOpenItem = (itemId) => {
        setOpenItem((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleCloseItem = (itemId) => {
        scrollToTop();
        setTimeout(() => {
            setOpenItem((prevState) => ({
                ...prevState,
                [itemId]: false,
            }));    
        }, 500);
        
    };


    return (
      <div>
        <footer className='secundary-bg-color footer-bg pt-3 pb-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-1'>
                  <NavLink to="/" className="logo mb-3">
                      {logo.length > 0 && 
                      <Picture classImg={"logo"} src={logo[0].image} alt={"Logo"} />
                      }
                  </NavLink>
              </div>
              <div className='col-10 offset-1 mb-2 mb-md-0 offset-xl-3 col-md-5 col-lg-4 offset-md-0 col-xl-2 ps-xl-4 text-start'>
                
                <div className={isActive ? 'toggle-arrow' : ''} 
                  /* onClick={handleClick} */
                  onClick={() => toggleOpenItem(1)}
                  aria-controls="collapseOne"
                  aria-expanded={openItem[1]}>
                  <div className="d-flex align-items-center pt-4 options-footer">
                    <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>
                      {getTextByKey('donde_comprar')}
                    </p>
                    <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                  </div>
                </div>

                {/* <div className="titlle" 
                    onClick={() => toggleOpenItem(1)}
                    aria-controls="collapseOne"
                    aria-expanded={openItem[1]}>
                    <TittleH6 styleGroup="primary-color fw-bold cursor-pointer" >{getTextByKey('politica_garantia')}</TittleH6>
                </div> */}
                <Collapse in={openItem[1]}>
                    <div id="collapseOne">
                        {tiendas.length > 0 &&
                          <ul className='mb-5 mb-xl-4'>
                          {tiendas.map((tienda, index) => (
                            <li key={index}>
                              <Link to={tienda.link} onClick={gotoTop} className='gray-1-color mb-1'>
                              {getPropiertyByLocale(tienda.names, 'titulo')}
                              </Link>
                            </li>)
                          )}
                          </ul>
                        }
                    </div>
                </Collapse>

                
              
              </div>
              {/* <ModalLogUserComponent showModal={showModal} setShowModal={setShowModal}/> */}
              <div className='col-10 offset-1 mb-2 mb-md-0 col-md-4 col-lg-4 col-xl-2 offset-md-0 text-start ps-xl-4'>
                <div className={isActive ? 'toggle-arrow' : ''} 
                  /* onClick={handleClick} */
                  onClick={() => toggleOpenItem(2)}
                  aria-controls="collapseTwo"
                  aria-expanded={openItem[2]}>
                  <div className="d-flex align-items-center pt-4 options-footer">
                    <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>{getTextByKey('sobre_cervalle')}</p>
                    <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                  </div>
                </div>

                <Collapse in={openItem[2]}>
                  <ul className='mb-5 mb-xl-0'>
                      <li>
                        <Link to='/nosotros' onClick={gotoTop} className='gray-1-color mb-1'>
                          {getTextByKey("nosotros")}
                        </Link>
                      </li>
                      <li>
                        <Link to='/blogs' onClick={gotoTop} className='gray-1-color mb-1'>
                          {getTextByKey("blog")}
                        </Link>
                      </li>
                      <li>
                        <Link to='/recetas' onClick={gotoTop} className='gray-1-color mb-1'>
                          {getTextByKey("recetas")}
                        </Link>
                      </li>
                      <li>
                        <Link to='/contacto' onClick={gotoTop} className='gray-1-color mb-1'>
                          {getTextByKey("contacto")}
                        </Link>
                      </li>
                      <li>
                        <Link to='/terminos-condiciones' onClick={gotoTop} className='gray-1-color mb-1'>
                          {getTextByKey("texto-terminos-condiciones")}
                        </Link>
                      </li>
                    </ul>
                </Collapse>
              </div>
              <div className='col-10 offset-1 mb-2 mb-md-0 col-md-3  col-lg-4 col-xl-2 offset-md-0 text-start ps-xl-4'>
                <div className={isActive ? 'toggle-arrow' : ''} 
                  /* onClick={handleClick} */
                  onClick={() => toggleOpenItem(3)}
                  aria-controls="collapseThree"
                  aria-expanded={openItem[3]}>
                  <div className="d-flex align-items-center pt-4 options-footer">
                    <p className='gray-1-color mb-0 me-2 carrefourMetis-bold'>{getTextByKey('politicas')}</p>
                    <Icon className="icon-arrow" icon="material-symbols:keyboard-arrow-down"></Icon>
                  </div>
                </div>

                <Collapse in={openItem[3]}>
                  <ul className='mb-5 mb-xl-0'>
                    <li>
                      <Link to='/politicas-de-garantia' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_garantia")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/politica-de-cambios-devoluciones' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_cambios")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/politicas-de-retracto' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_retracto")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/politicas-de-reversion' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_reversion")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/derechos-y-deberes' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("derechos_deberes")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/aviso-de-privacidad' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_privacidad")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/politica-de-datos' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_datos")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/politica-seguridad' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("politica_seguridad")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/manual' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("manual")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/programa-transparencia' onClick={gotoTop} className='gray-1-color mb-1'>
                        {getTextByKey("programa_transparencia")}
                      </Link>
                    </li>
                    <li>
                      <a href={getTextByKeyLink("texto-super-industria-comercio")} target="blank" className='gray-1-color mb-1'>
                        {getTextByKey("texto-super-industria-comercio")}
                      </a>
                    </li>
                
                  </ul>
                </Collapse>
                
              </div>
              <div className='col-xl-2 text-center text-xl-end'>
                <div className='social mb-3 mb-lg-5 mb-xl-0'>
                  <a className='mx-3 me-xl-2' href={getTextByKey("facebook-link")} target='_blank'>
                    <Icon icon='ant-design:facebook-outlined' />
                  </a>
                  <a className='mx-3 me-xl-2' href={getTextByKey("instagram-link")} target='_blank'>
                    <Icon icon='akar-icons:instagram-fill' />
                  </a>
        
                  <a className='mx-3 me-xl-2 youtube' href={getTextByKey("youtube-link")} target='_blank'>
                    <Icon icon='ant-design:youtube-outlined' />
                  </a>
                  {/* <a className='mx-3 me-xl-2' href={"https://youtube.com/channel/" + getTextByKey("youtube-user")} target='_blank'>
                    <Icon icon='akar-icons:youtube-fill' />
                  </a> */}
                </div>
              </div>
            </div>
            <hr  className="hr-color" />
            <div className='row'>
              <div className="col-md-5 col-xl-2 offset-0 col-12 offset-md-0 my-3 my-md-0 text-center text-lg-start">
                <div className="gray-1-color source-sans-semibold big-text" dangerouslySetInnerHTML={{ __html: getTextBigByKey("lineas_footer") }}></div>
                <div className="d-flex align-items-center mb-0 options-footer-2">
                  <a target="_blank" href={`https://api.whatsapp.com/send?phone=+57${getTextByKey("numero-whatsapp")}&text=${getTextByKey('texto-adicional-whatsapp')}`} className="icon-info-banner mb-2">
                        <Icon className="primary-color" icon="ic:baseline-whatsapp"/>
                  </a>
                  <a target="_blank" href={`tel:${getTextByKey("numero")}`} className="icon-info-banner mx-1">
                      <h3 className="primary-color source-sans-bold mb-0">{getTextByKey('numero')}</h3>
                  </a>
                </div>
              </div>
              <div className='col-12 px-4 px-lg-0 offset-0 col-md-7 offset-md-0 col-xl-4 offset-xl-5 ps-xl-0 order-0 order-xl-1 text-center text-lg-start'>
                <p className='gray-1-color mb-0 carrefourMetis-bold'>{getTextByKey('suscribete')}</p>
                <p className='gray-1-color copy-suscribete mb-0'>
                  {getTextByKey('copy_suscribe')}
                </p>
                { loading &&
                      <div className='container'>
                          <div className="text-center-c">
                              <div className="loader-box">
                                  <img src="/img/SVG/loader.svg" alt="" />
                              </div>
                          </div>
                      </div>
                }
                { !loading &&
                  <form className='mb-5 mb-xl-0' onSubmit={onEmailSuscribeSubmit}>
                    <div className='d-flex justify-content-between align-items-strech mb-3 white-bg-color p-1 rounded'>
                      <input
                        name="email"
                        type='email'
                        placeholder={getTextByKey('ingresa_correo')}
                        className='px-2'
                        onChange={onSuscribeInputChange}
                      />
                      <button
                        type='submit'
                        className='btn-g px-lg-5 px-3'
                      >
                      {getTextByKey('suscribirme')}
                      </button>
                    </div>
                    <label className='container-check-s'>
                      <small className="gray-1-color">{getTextByKey("he_leido_nuevo")} <a className="gray-1-color" target="_blank" href="/terminos-condiciones">{getTextByKey('texto-terminos-condiciones').toLowerCase()} </a> 
                       y <a className="gray-1-color" href="/aviso-de-privacidad" target="_blank"> {getTextByKey('politica_privacidad').toLowerCase()}</a></small>
                      <input
                        type='checkbox'
                        name='conditionTerms'
                        value={isChecked}
                        onChange={handleCheckTermsCondition}
                        checked={ isChecked }
                      />
                      <span className='checkmark-s'>
                        <img src='/img/SVG/check.svg' />
                      </span>
                    </label>
                    <div>
                      {message && <small className={`note source-sans-bold ${!isChecked ? 'alert-danger' : 'gray-1-color' }`}>{message}</small>}
                      {success && <p className={`note source-sans-bold alert-sucess`}>{success}</p>}    
                    </div>
                  </form>
                }
              </div>
            </div>
            <hr className="hr-color d-block d-lg-none" />
            <div className="row">
            <div className='text-center'>
                <a className='iridian' target="_blank" href="https://iridian.co/">
                  <img className='' src='/img/logo-iridian.png' />
                </a>
              </div>
            </div>
          </div>
        </footer>
        <div className="primary-bg-color text-center py-2">
          <p className="white-color sm-p py-1">{getTextByKey('copyright')}</p> 
        </div>
      </div>
    );
}
