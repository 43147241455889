import React, {useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useApi } from '../context/apiContext';
import { useCart } from '../context/cartContext';
import { useAuth } from '../authContext';
import ModalCompra from './modalesRecibirPedido/ModalCompra';
import ModalInicio from './modalesRecibirPedido/ModalInicio';

export function Geolocalizacion () {
  const { getTextByKey, setModalInicio, setModalCompra } = useApi();
  const { currentDelivery,tiendaGeneral } = useCart();
  const { currentUser } = useAuth();

  const handleShowModal = () => {
    if(currentUser){
      setModalCompra(true);
      //console.log('aqui');
    }else{
      setModalInicio(true); 
    }    
  }

  useEffect(()=>{
    console.log('delivery header', currentDelivery);
  },[])

  return (
    <>
    <div className="localization" onClick={handleShowModal}>
      { tiendaGeneral ?
       ( <a className="d-flex align-items-center">
            <Icon icon={currentDelivery.tipo === "pickup" ? 'iconoir:small-shop' : 'bi:truck' } className="icon-location white-color" />
            <p className="white-color ms-2 mb-0 d-md-block d-none text-nowrap">
              <span>
                {currentDelivery.tipo === "pickup" ? `${getTextByKey('modal-compra-recoge')}` : `${getTextByKey('modal-compra-envio')}` } 
              </span>
            </p>
          </a>
        ) : (
          <a className="d-flex align-items-center">
            <Icon icon="ep:location" className="icon-location white-color" />
            <p className="white-color ms-2 mb-0 d-md-block d-none text-nowrap">
              <span>{getTextByKey("como_recibir")}</span>
            </p>
          </a>
        ) 
      }
       </div>
     
  </>
  )
}

export default Geolocalizacion