import React, { Fragment, useEffect, useState} from 'react'
import { getGallery } from '../../api/galleryApi';
import { images_products_route } from '../../api/productApi';
import { useApi } from '../../context/apiContext';
import '../../scss/nft.scss';
import ModalNeftComponent from './components/ModalNeftComponent';

export default function NftPage() {
    const [gallery, setGallery] = useState([]);

    const { getTextByKey, getTextBigByKey } = useApi();

    useEffect(() => {
        getGallery('nft-gallery').then((res) => {
            setGallery( res );
        }).catch((error) => {
            console.log('Error: ', error)
        })
    }, [])
    

  return (
    <Fragment>
        <div className='text-center py-2 py-md-3 py-xl-5'>
            <div className='titulo-nfts d-flex w-100 justify-content-center'dangerouslySetInnerHTML={{ __html: getTextBigByKey('titulo-nfts') }}></div>
        </div>
        <div className='container px-0 nft'>
            <div className='row'>
                {
                    gallery.map( (nft) => (
                        <div className='col-12 col-lg-6' key={nft.id}>
                            <img className='w-100' src={images_products_route + nft.image}/>
                        </div>
                    ))
                }
            </div>
            <div className='row'>
                <div className='col-6 offset-3 text-center py-2 py-md-3 py-lg-5'>
                    <div className='my-5' dangerouslySetInnerHTML={{ __html: getTextBigByKey('description-nft-page') }}></div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <a className='btn-g bb py-2 px-4' data-bs-toggle="modal" data-bs-target="#roadmapModal">{getTextByKey('benefits-nfts')}</a>
                        <a href="https://opensea.io/" target="_blank" className='btn-g bb py-2 px-4'>{getTextByKey('get-your-nfts')}</a>
                    </div>
                </div>
            </div>
        </div>
        <ModalNeftComponent/>
    </Fragment>
  )
}
