import React, { Fragment } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import '../scss/categorias_slider.scss';
import { useApi } from '../context/apiContext';
import { useEffect } from 'react';
import { getProductBestSellers } from '../api/productApi';
import ProductsmallComponent from './productsmallComponent';



export default function RecomendadosSliderComponent() {

    const { getTextByKey, extractNameFromLocate } = useApi();

    const [products, setProducts] = React.useState([]);

    useEffect (() => {
        getProductBestSellers().then((response) => {
            setProducts(response);
        });
    }, []);

  return (
    <Fragment>
        <Swiper 
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={40}
                slidesPerView={5}
                navigation
                simulateTouch={false}
                loop
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                breakpoints={{
                    // when window width is >= 640px
                    320: {
                        spaceBetween: 20,
                        slidesPerView: 2,
                    },
                    768: {
                        spaceBetween: 40,
                        slidesPerView: 3,
                    },
                    1200: {
                        spaceBetween: 20,
                        slidesPerView: 5,
                    },
                }}
                >
                {products.map((product, index) => (
                    <SwiperSlide key={index}>
                        <ProductsmallComponent product={product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
    </Fragment>
  )
}
