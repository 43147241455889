import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { auth } from '../../../firebaseConfig';
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { deleteAddress } from '../../../api/addressApi';

export function ModalEliminarDireccionComponent({ direccionId, modalEliminar, setModalEliminar, setDisableButton}) {
    const { getTextByKey, getTextBigByKey} = useApi();

		const gotoTop = event => {
			window.scrollTo(0, 0);
		};	

		const handleClose = () => {
				setModalEliminar(false);
				gotoTop();
		}

    const handleDelete =async (event) =>{
        event.preventDefault();
        setDisableButton(true)
        let eliminar = await deleteAddress(direccionId, setDisableButton);
		if(eliminar){
			location.href = "/profile-address";
		}
		handleClose();
    }

    return (
        <Fragment>
					<Modal className="modal-compra" show={modalEliminar} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center pt-4">
											<h3 className="carrefour-sans-bold gray-1-color mb-3">{getTextByKey('titulo-popup-eliminar-direccion')}</h3>
											<div dangerouslySetInnerHTML={{ __html: getTextBigByKey('texto-popup-eliminar-direccion') }}>
											</div>
												{/* <p>¿Está seguro que desea eliminar su dirección de envíos actual? Ten en cuenta que si lo eliminas, tendrás que cargar alguna dirección para realizar las compras en nuestra web.</p> */}
										</div>
										<div className="d-flex justify-content-center">
												<button type="button" onClick={handleClose} className="btn-g bb py-2 px-4 px-xl-5 mx-xl-3">Cancelar</button>
												<button type="button" onClick={(event)=>handleDelete(event)} className="btn-g py-2 px-4 px-xl-5 mx-xl-3">Eliminar dirección</button>
										</div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
