import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';

export const ModalExp = ({ showExt, setShowExt }) => {

    const { getTextByKey } = useApi();

    const handleClose = () => {
      setShowExt(false);
    }


    return (
      <Modal show={showExt} onHide={handleClose}  centered>
        <Modal.Body>
            <div className="text-end pe-2">
              <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
            </div>
            <div className="container">
              <div className="text-center">
                <p className='gray-1-color'>{getTextByKey('alerta-extensiones')}</p>
              </div> 
              <div className="text-center pt-3">
                  <button className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('entendido')}</button>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    )
}
