import React, { Component, Fragmentm, useState } from 'react'
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import FavoriteClick from "./FavoriteClick";
import '../scss/product.scss';
import { PrecioSinDcto } from './PrecioSinDcto';
import { useApi } from '../context/apiContext';
import { useCart } from '../context/cartContext';
import { useAuth } from '../authContext';
import Picture from './Picture';
import { default as ModalCompra } from './modalesRecibirPedido/ModalCompra';
import { default as ModalInicio } from './modalesRecibirPedido/ModalInicio';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export function ProductComponent(product){

  const { addToCart, showCart, checkIfExistInCart, currentDelivery, setCurrentProduct,tiendaGeneral,currentCart,agotado,
    pesoTotal,pesoMaxCompra,updateTope,addToCartOld } = useCart();
  const { currentUser } = useAuth();
  const [ quantity, setQunatity] = useState(1);
  const [ showModalCompra, setShowModalCompra ] = useState(false);
  const [ showModalCompracompo, setShowModalCompracompo ] = useState(false);
  
  const [ showModalInicio, setShowModalInicio ] = useState(false);
  const [ showModalIniciocompo, setShowModalIniciocompo ] = useState(false);
  const imgprod=product?.product?.images?.find((img)=>img.principal==1);



  const descNeto = product.product.price.precio_sin_descuento - product.product.price.precio;
  const dct = ((descNeto * 100) / (product.product.price.precio_sin_descuento)).toFixed(0);

  let cad_dct_caja = "";

  if (dct > 0 && dct < 99 ) {
    cad_dct_caja = <span className="tag-discount">
      -{dct}%
    </span>
  }

  const { getTextByKey, setModalInicio, setModalCompra,setShowAlertaTope } = useApi();
    
  const addCart = (e, product) => {
    e.preventDefault();
     e.stopPropagation();
    if(product.gramajes.length>0){
      location.href="/product/"+product.plu;
      return;
    }
    product.type="product"; 
    product.gramaje=0;
    product.id=product.plu;
   
    if(currentUser){
       if(!tiendaGeneral){
        addToCartOld(product,quantity);
        setModalCompra(true);
      }
    }else{
      addToCartOld(product,quantity);
      setModalInicio(true);
      //setCurrentProduct([{product, quantity}]);
    }
    let peso = 0;
    peso=product.peso*(quantity);
    peso=peso+pesoTotal;
    if(peso>pesoMaxCompra){
      updateTope(1);
      setShowAlertaTope(true);
      return;
    }
    
    if(tiendaGeneral){
      addToCart(product, quantity);
      /* alert("Producto agregado al carrito"); */
      if(currentCart.length == 0){
        showCart();
      }
    }
  }

  const checkIfExist = (actualItemId) => {
    return checkIfExistInCart(actualItemId);
  }


  const clickSwiper = (e) => {
    e.preventDefault();
    console.log(e);
  }

  


    return (
        <div className={product.className}>
          <div className="singular-product product-hover animate__animated animate__bounceIn">
          <Link to={"/product/"+product.product.slug }>
            <div className="image-product">
              <Picture src={ imgprod?.image} alt={'Product Item'} classImg={'w-100 image'}  />
              { product.product.cantidad > agotado &&
               <div className="add-car-icon cursor-pointer" onClick={(event) => addCart(event, product.product)}>
                <Icon className={`icon ${checkIfExist(product.product.plu) ? 'animationIconCart' : '' }`} icon="carbon:shopping-cart"/>
              </div>}
              { (product.product.price.precio_sin_descuento != undefined && product.product.price.precio_sin_descuento > 0 ) &&
                cad_dct_caja 
              }
              { product.product.cantidad <= agotado &&
                <div className="agotado position-absolute white-color tertiary-bg-color source-sans-semibold px-2">Agotado</div> 
              }
             
            </div>
            <div className="mt-2">
              {product.product.subcategorias && product.product.subcategorias.length > 0 && (
                <div className="d-flex">
                  {product.product.subcategorias.map((subcategoria) => (
                    <p className='sm-p mb-0 mx-1' key={subcategoria.id}>{subcategoria.nombre}</p>
                  ))}
                </div>
              )}
                  
                
              <div className='d-lg-flex justify-content-between align-items-center mb-1'>
                <h4 className='source-sans-bold mb-0 name'>
                {product.product.name.nombre.length > 40? product.product.name.nombre.slice(0, 40) + "..."
                : product.product.name.nombre}
                </h4>
              </div>
              <PrecioSinDcto precio={product.product.price} />
              { product.product.peso ? <p className="sm-p mb-0">Gramo a ${(product.product.price.precio/product.product.peso).toFixed(2)} </p> : ''}
            </div>
            </Link>
          </div>
      
        </div>
    );
}
