import React, { Fragment, useState, useRef } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useAuth } from "../authContext";
import { useCart } from "../context/cartContext";
import { useApi } from '../context/apiContext';
import { agregadoReceta } from '../pages/RecipesPage/RecipePage';

import '../scss/options_header.scss';
import { useEffect } from 'react';

export default function OpctionsHeaderComponent() {

    const [logged, setLogged] = React.useState(false);
    const { getTextByKey, convertCurrency } = useApi();
    const [ bolsa, setBolsa ] =  useState(1);
    const [prevNumberProducts, setPrevNumberProducts] = useState(0); 

    const [previousConvertedTotal, setPreviousConvertedTotal] = useState(null);
    const previousConvertedTotalRef = useRef();
    const {agregado} = useCart();
    const {agregadoReceta} = useCart();

    console.log('receta agregada', agregadoReceta);

    //Check the Auth Session
    const { currentUser, logout } = useAuth();
    React.useEffect(() => {
        if (currentUser) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [currentUser]);

    // console.log(currentUser);

    //Logout Event
    const handleLogout = async () => {
        try {
            await logout();
            clearAll();
            location.href = "/";
          
        } catch {
        }
    };

    const openSearch = event => {
        const filter = document.getElementById('buscador');
        filter.classList.toggle('show');
    };

    const { showCart, getNumberProducts, calculateTotal, total, envio, descuento, impuesto, impuestoBolsa, calculateImpt,
         pesoTotal, pesoXbolsa, coupon, updateCoupon, currentCart, clearCart, clearAll } = useCart();

    const openCar = event => {
        showCart();
        const overlayNoVisible = document.getElementById('overlay-no-visible');
        overlayNoVisible.classList.add('show-overlay-no-visible');
    };


    const [numberProducts, setNumberProducts] = React.useState(0);

    useEffect(() => {
        setNumberProducts(getNumberProducts());
    }, [getNumberProducts, numberProducts]);

    useEffect(() => {
        calculateTotal();
        calculateImpt();
    }, [calculateTotal, impuesto]);

    useEffect (() => {
        setBolsa(Math.ceil(pesoTotal/pesoXbolsa));
        if(bolsa == 0 || pesoTotal === 0 ){
          setBolsa(1);
        }
      }, [pesoTotal, pesoXbolsa, impuesto]);



    const navigate = useNavigate();

    // const handleShowModal = (currentUser) => {
    //     if (currentUser) {
    //       sessionStorage.setItem('something-to-tell', 'feedback-a')
    //         navigate('/login');
    //       setShowModal(true);
    //     }else{
    //       console.log('no user');
    //     }
    // }


    /* useEffect(() => {
        const aggregate = document.getElementById('aggregate');
        if (numberProducts > prevNumberProducts) { 
            aggregate.classList.add('active');
            setTimeout(() => {
                aggregate.classList.remove('active');
            }, 1000);
        }else if (numberProducts < prevNumberProducts) {
            aggregate.classList.remove('active');
        }else{
            console.log('no hay cambios');
            aggregate.classList.remove('active');
        }
    }, [numberProducts, prevNumberProducts]); */

    /* useEffect(() => {
        const aggregate = document.getElementById('aggregate');
        if (previousConvertedTotalRef.current !== null && convertCurrency((total + envio + ((impuestoBolsa) * (bolsa))) - (coupon ? coupon.descuento : 0)) > previousConvertedTotalRef.current) {
            aggregate.classList.add('active');
            setTimeout(() => {
                aggregate.classList.remove('active');
            }, 1000);
        }else{
            console.log('no hay cambios');
        }
        previousConvertedTotalRef.current = convertCurrency((total + envio + ((impuestoBolsa) * (bolsa))) - (coupon ? coupon.descuento : 0));
    }, [total, envio, impuestoBolsa, bolsa, coupon]); */

    

    return (
        <Fragment>
            <div className="options d-flex d-lg-block justify-content-around justify-content-md-end justify-content-xl-around align-items-center">
                <ul className='d-flex align-items-center'>
                    {
                        currentUser ? currentUser.isAnonymous?
                            <li>
                            <NavLink to="/login" className="gray-1-color p-2">
                                <Icon icon="ant-design:user-outlined" />
                            </NavLink>
                            <ul className='opt-user'>
                                <li><NavLink to="/login">{getTextByKey('iniciar-sesion')}</NavLink></li>
                                <li><NavLink to="/register">{getTextByKey('register')}</NavLink></li>
                            </ul>
                            </li>
                            :
                            <li className="logged">
                                <a className="p-2 gray-1-color position-relative link-active">
                                    <Icon icon="majesticons:user" />
                                    {currentUser && currentUser.displayName ?  <span className="name-user d-none d-md-block text-center text-uppercase">{currentUser.displayName.split(' ')[0] }</span> : ''}
                                </a>

                                <ul className='opt-user'>
                                    <li className='title'>Cuenta</li>
                                    <li><NavLink to="/profile-details" className="gray-1-color">Mi perfil</NavLink></li>
                                    <li><NavLink to="/profile-address" className="gray-1-color">Mis Direcciones</NavLink></li>
                                    <li><NavLink to="/profile-orders" className="gray-1-color">Pedidos</NavLink></li>
                                    {/* <li><NavLink to="/profile-favs" className=">Favoritos</NavLink></li> */}
                                    <li onClick={handleLogout}><NavLink to="#" className="gray-1-color">Cerrar sesión</NavLink></li>
                                </ul>
                                
                            </li>
                            :
                            <li>
                                <NavLink to="/login" className="gray-1-color p-2">
                                    <Icon icon="ant-design:user-outlined" />
                                </NavLink>
                                {!logged && <ul className='opt-user'>
                                    <li><NavLink to="/login">{getTextByKey('iniciar-sesion')}</NavLink></li>
                                    <li><NavLink to="/register">{getTextByKey('register')}</NavLink></li>
                                </ul>}
                            </li>
                    }

                    {/* {logged ? <li>
                        <NavLink to="/profile-favs" className="p-2 primary-color">
                            <Icon icon="akar-icons:heart" />
                        </NavLink>
                    </li>
                    :
                    <li>
                        <a className="p-2 primary-color" onClick={handleShowModal}>
                            <Icon icon="akar-icons:heart" />
                        </a>
                    </li>
                    } */}
                    
                    <li className='position-relative'>
                        <a className="p-2 gray-1-color" onClick={openCar}>
                            <Icon icon="carbon:shopping-cart"/>
                            {
                                numberProducts > 0 ? <div className="badge gray-1-bg-color white-color rounded-circle">{numberProducts}</div>
                                : <div className="badge gray-1-bg-color white-color rounded-circle">0</div>
                            }
                            <p className='gray-1-color price mb-0 source-sans-semibold text-nowrap'>
                                { currentCart.length > 0 ? (
                                   <span>{convertCurrency((total + envio  + ((impuestoBolsa)*(bolsa))) -(coupon ? coupon.descuento : 0))}</span>
                                 ) : (
                                    <span>$0</span>
                                 )
                                }
                            </p>
                        </a>
                        {/* {agregado && ( */}
                            <span className={`aggregate ${agregado === true ? 'active' : ''}`} id="aggregate">Producto agregado</span>
                        {/* )} */}
                    </li>
                    {/* <li>
                    <a className="p-2 primary-color text-uppercase" style={{ textDecoration: "none" }}>
                        {logged && <div className="name-user">{currentUser.email}</div>}
                    </a>
                </li> */}
                </ul>
            </div>
        </Fragment>
    )
}
