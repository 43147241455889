import React, { Fragment } from 'react'
import { useApi } from "../../context/apiContext";
import '../../scss/checkout.scss'
import { useState } from 'react';
import { useEffect } from 'react';
import { createOrder, getBanks, getTarjetas, pagarOrderPSE, pagarOrderWompi, get } from '../../api/productApi';
import { useAuth } from '../../authContext';
import { useCart } from '../../context/cartContext';
import SelectDireccionComponent from './components/SelectDireccionComponent';
import { listAddressUser } from '../../api';

const CheckoutFastPage = () => {

  const [tarjetas, setTarjetas] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [order, setOrder] = useState({
    uid: 5,
    method: 3,
    address: 66,
    currency: 1,
    store: 1,
    billing: 66,
    locale: 1,
    iva: 0,
    delivery_cost: 5000,
    taxes: 0,
    comments: '',
    subtotal: 50000,
    total: 55000,
    buyItems: [
      {
        productId: 'EV004S',
        price: 50000,
        quantity: 1,
        comments: '',
        discount: 0,
        toppings: '',
        promotionId: ''
      }
    ]
  });
  const { getTextByKey, address, cards, lastPayment, currentCurrency } = useApi();
  const { currentUser } = useAuth();
  const { total, envio, coupon, direccion, currentCart, clearCart } = useCart();
  const [ loading, setLoading]  = useState(false);
  const [ error, setError ] = useState("");
  const [ cuotas, setCuotas ] = useState(1);
  const [ addressList, setAddressList ] = useState([]);

  useEffect(() => {
    if(cards) setTarjetas(cards);
    if(address) setAddressList(address);
  }, [lastPayment, address, cards]);

  const createCheckoutOrder = async () => {
    setError("");
    setLoading(true);
    if(!selectedAddress) setError("Please select a address")
    if(!selectedCard) setError("Please select a payment card")
    order.uid = currentUser.uid;
    order.delivery_cost = envio;
    order.subtotal = total - (coupon ? coupon.descuento : 0);
    order.total = total + envio - (coupon ? coupon.descuento : 0);
    order.address = parseInt(selectedAddress);
    order.billing = parseInt(selectedAddress);
    order.card = selectedCard;
    order.method = "tarjeta";
    order.buyItems = [];
    order.comments = "";
    order.currency = currentCurrency;

    currentCart.forEach((item) => {
      if (item.type === "giftcard") {
        order.buyItems.push({
          productId: item.id,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
        });
      } else {
        order.buyItems.push({
          productId: item.plu,
          type: item.type,
          price: item.price.precio,
          quantity: item.quantity,
          comments: '',
          discount: 0,
          toppings: '',
          promotionId: '',
          products: item.products ? item.products : []
        });
      }

    });

    console.log(order);

    createOrder(order).then((response) => {
      setLoading(false);
      console.log('response: ', response)
      if (!response.id) {
        setError("Error al crear la orden");
      } else {
        let jsonWompi = {
          "tarjeta": selectedCard,
          "user": currentUser.uid,
          "compra": response.id,
          "cuota": cuotas
        }
        pagarOrderWompi(jsonWompi).then((responseWompi) => {
          console.log('response: ', responseWompi)
          if (responseWompi.estado === "APPROVED" || responseWompi.estado === "PENDING" || responseWompi.estado === "VOIDED" || responseWompi.estado === "ERROR" || responseWompi.estado === "DECLINED") {
            clearCart();
            navigate("/payment-success", { replace: true, state: { order: response.id, msg: responseWompi.msg } });
          } else {
            setError("Error al pagar la orden :" + responseWompi.estado + responseWompi.msg);
          }
        });
      }
    }).catch((error) => {
      setLoading(false);
      console.log('error: ', error)
      setError(error.message);
    })
  }


  return (
    <Fragment>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Dirección:</h3>
        <div>
            <select className={'p-2 form-control'} value={selectedAddress} onChange={(e)=>setSelectedAddress(e.target.value)}>
              <option value='0' disabled selected>Seleccionar</option>
              {addressList.map((address, index) => (
                <option key={index} value={address.id}>{address.address}</option>
              ))}
            </select>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Tarjeta:</h3>
        <div>
            <select className={'p-2 form-control'} value={selectedCard} onChange={(e)=>setSelectedCard(e.target.value)}>
              <option value='0' disabled selected >Seleccionar</option>
              {tarjetas.map((card, index) => (
                <option key={index} value={card.id}>{card.brand} - {card.last_four_numbers}</option>
              ))}
            </select>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <h3 className='text-uppercase'>Cuotas:</h3>
        <div>
          <select className={"p-2 form-control"} onChange={(e) => setCuotas(e.target.value)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((cuota, index) => (
              <option value={cuota} key={index}>{cuota}</option>
            ))}
          </select>
        </div>
      </div>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
          {!loading ? (
            <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer' onClick={()=> createCheckoutOrder()}>PAGAR</div>
          ) : (
            <div className='btn-g bb p-2 w-100 text-center text-uppercase pointer'><div className='spinner-border spinner-border-sm' role='status'></div></div>
          )}
      </div>
      {error.length > 0 && <p className='text-center'>{error}</p>}
    </Fragment>
  )
}

export default CheckoutFastPage