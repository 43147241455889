import sellyApi from './sellyApi'

export const getRecipeCategories = async (locale = null) => {
  try {
    const data = await sellyApi.get(`/recipes/categories`, {params: {locale}})
    return data.data;
  } catch (error) {
    return error;
  }
}

export const getRecipes = async ({page = null, limit = null, category = null, locale = null}) => {
  try {
    const data = await sellyApi.get(`/recipes/`, {params: {page, limit, category, locale}})
    return data.data.list;
  } catch (error) {
    return error;
  }
}

export const getRecipe = async (id, locale = null,tienda=null) => {
  try {
    const data = await sellyApi.get(`/recipes/${id}`, {params: {locale,tienda}})
    return data.data;
  } catch (error) {
    return error;
  }
}

export const filterRecipe = async (id) => {
  try {
    const data = await sellyApi.get(`/recipes/?locale=es&category=${id}`)
    return data.data;
  } catch (error) {
    return error;
  }
}



export default { getRecipes }
