import React from 'react'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getSearchResult } from '../../api/searchApi';
import { images_products_route } from "../../api/productApi";
import { ProductComponent } from '../../components/productComponent';
import { useApi } from '../../context/apiContext';
import RecipeComponent from '../../components/recipeComponent';
import { useCart } from '../../context/cartContext';

export const SearchPage = () => {

    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const { search:s } = useLocation();
    const search = new URLSearchParams(s).get('query');
    const [products, setProducts] = React.useState(false);
    const { tiendaGeneral} = useCart();
    const [recipes, setRecipes] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState({recipes: false, products: false});
    const OBJECTS_TO_SHOW = 8;

    React.useEffect(() => {
        searchProducts(search,tiendaGeneral)
    }, [search,tiendaGeneral])

    const toggleShow = (componentName, state) => {
        setShow(show => ({...show, [componentName]: state}));
    }

    const searchProducts = async (search,tienda) => {
        setLoading(true);
        const resultsSearch = await getSearchResult( search, currentLocale,tienda );
        if( resultsSearch ) {
            setProducts(resultsSearch.products);
            setRecipes(resultsSearch.recipes);
        } else {
            setProducts( [] );
            setRecipes([]);
        }
        setLoading(false);
    }



    if(loading)
        return (
        <div className='container'>
            <div className="text-center-c">
                <div className="loader-box">
                    <img src="/img/SVG/loader.svg" alt="" />
                </div>
            </div>
        </div>
        );


    let productsToShow = [];
    if (products) {
        productsToShow = show.products ? products : products.slice(0, OBJECTS_TO_SHOW);
    }

    let recipesToShow = [];
    if (recipes) {
        recipesToShow = show.recipes ? recipes : recipes.slice(0, OBJECTS_TO_SHOW);
    }


    return (
    <>
      <div className="container">
        <div className="row pt-lg-5 pt-3">
            <div className="col-xl-9">
                <div className='py-2 py-lg-5 mt-3'>
                    <div className='container-fluid productos gray-1-color'>
                        <h3>{getTextByKey("productos")}</h3>
                        <div className='row'>
                            {productsToShow.length > 0 ? (
                                <>
                                    {productsToShow.map((product, index) => <ProductComponent key={index} product={product} className="col-6 col-md-4 col-lg-3 px-0" />)}
                                    {
                                        products.length > OBJECTS_TO_SHOW && (
                                            <div className="text-center mt-3 mt-xl-5">
                                                <button type="button" className="btn-g primary-bg-color py-1 px-5" onClick={() => toggleShow('products', !show.products)}>
                                                    {show.products ? getTextByKey("ver-menos") : getTextByKey("ver-mas")}
                                                </button>
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <div className="col-12 my-5 text-center-c">
                                    <br /><br />
                                    <strong>{getTextByKey("no_results")}</strong>
                                    <br /><br />
                                    <hr />
                                </div>
                            )
                            }
                        </div>
                        <h3 className="mt-5">{getTextByKey("recetas")}</h3>
                        <div className="row">
                            {
                                <>
                                    { recipesToShow.length ? (
                                    <>
                                        {recipesToShow.map((receta)=> <RecipeComponent recipe={receta} key={receta.id} />)}
                                        {
                                            recipes.length > OBJECTS_TO_SHOW && (
                                                <div className="text-center mt-3 mt-xl-5">
                                                    <button type="button" className="btn-g primary-bg-color py-1 px-5" onClick={() => toggleShow('products', !show.products)}>
                                                        {show.recipes ? getTextByKey("ver-menos") : getTextByKey("ver-mas")}
                                                    </button>
                                                </div>
                                            )
                                        }
                                        
                                    </>
                                    ) : (
                                        <div className="col-12 my-5 text-center-c">
                                            <br /><br />
                                                <strong>{getTextByKey("no_results")}</strong>
                                            <br /><br />
                                            <hr />
                                        </div>
                                    )
                                    }
                                </>
                            }
                                 
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
      </div>

    </>
  )
}
