import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import { useApi } from '../../../context/apiContext';

export default function CombinacionComponent({ combination, route }) {

    const { convertCurrency } = useApi();

    return (
        <Fragment>
            <div className='combinacion-item combinacionacion-grid'>
                <div className='mb-2 mb-lg-4'>
                    <picture className='position-relative'>
                        <Link to={"/combination/" + combination.id}>
                            {combination.images.length > 0 && <img className='w-100' src={route + combination.images[0].image} alt={combination.name.nombre} />}
                        </Link>
                    </picture>
                </div>
                <h4 className='gotham-Bold text-uppercase'>{combination.name.nombre}</h4>
                <p>${convertCurrency(combination.prices[0].precio)}</p>
            </div>
        </Fragment>
    )
}
