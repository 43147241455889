import React, { Component, Fragment } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import { EffectFade } from 'swiper';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useApi } from '../../../context/apiContext';
import { useEffect } from 'react';
import { getGallery } from '../../../api/galleryApi';



const SliderComponent = () => {

    const [otherOption, setOtherOption] = useState(false);
    const [answerTalk, setAnswerTalk] = useState(false);
    const [listaPreguntaOne, setlistaPreguntaOne] = useState([]);
    const [listaPreguntaTwo, setlistaPreguntaTwo] = useState([]);
    const { getTextByKey, currentLocale } = useApi();


    useEffect(() => {
        console.log(currentLocale);
        getGallery("pregunta-encuesta-1").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                element.names.forEach(content => {
                    if (content.locale === currentLocale) {
                        listadoAux.push(content.titulo)
                    }
                })
            });
            setlistaPreguntaOne(listadoAux);
        });
        getGallery("pregunta-encuesta-2").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                element.names.forEach(content => {
                    if (content.locale === currentLocale) {
                        listadoAux.push(content.titulo)
                    }
                })
            });
            setlistaPreguntaTwo(listadoAux);
        });
    }, [currentLocale])

    return (
        <Fragment>
            <form>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".next"
                    }}
                    /* effect="fade" */
                    simulateTouch={false}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}>
                    <SwiperSlide>
                        <div className='py-2'>
                            <div className='form-group mb-4'>
                                <h2>¡{getTextByKey('amamos-tu-manera-pensar')}!</h2>
                            </div>
                            <div className='form-group mb-4'>
                                <div className='quinary-bg-color py-1 px-3 d-inline-block'>
                                    <h2 className='mb-0'>{getTextByKey('pregunta-encuesta-1')}</h2>
                                </div>
                            </div>
                            {
                                listaPreguntaOne.map( (option, index) => (
                                    <div className='form-group mb-2' key={index}>
                                        <label className="container-check-s mb-0">
                                            <p className='mb-0'>{option}</p>
                                            <input type="radio" name="como-llegar" onChange={e => setOtherOption(false)} />
                                            <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                        </label>
                                    </div>
                                ))
                            }

                            <div className='form-group mb-4'>
                                <label className="container-check-s mb-0">
                                    <p className='mb-0'>{ currentLocale === 'es' ? 'Otro, ¿cuál?' : 'Other, which one?' }</p>
                                    <input type="radio" name="como-llegar" id="other_option" checked={otherOption} onChange={e => setOtherOption(e.target.checked)} />
                                    <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                </label>
                            </div>

                            <div className={otherOption ? 'd-block' : 'd-none'}>
                                <div className='form-group mb-5' id="answer_other_option">
                                    <input type="text" className='p-2' placeholder='¿Cuál?' />
                                </div>
                            </div>

                            <div className='form-group mb-2 text-center'>
                                <button className='btn-g py-2 px-4 border-0 text-uppercase next'>{getTextByKey('btn-continuar')}</button>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='py-2'>
                            <div className='form-group mb-4'>
                                <label className='quinary-bg-color p-2'>{getTextByKey('pregunta-encuesta-2')}:</label>
                            </div>
                            {
                                listaPreguntaTwo.map( (option, index) => (
                                    <div className='form-group mb-2'>
                                        <label className="container-check-s mb-0" key={index}>
                                            <p className='mb-0'>{option}</p>
                                            <input type="radio" name="hablar" id="answer_talk" onChange={e => setAnswerTalk(true)} />"
                                            <span className="checkmark-s border"><img src="/img/SVG/check.svg" /></span>
                                        </label>
                                    </div>
                                ))
                            }
                            <div className={answerTalk ? 'd-block' : 'd-none'}>
                                <div className='form-group '>
                                    <textarea placeholder='Comenta aqui...' className='p-2'></textarea>
                                </div>
                            </div>
                            <div className='form-group mt-5 mb-2 text-center'>
                                <button className='btn-g py-2 px-4 text-uppercase border-0 next'>{getTextByKey('btn-continuar')}</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='py-2'>
                            <div className='form-group mb-4'>
                                <div className='quinary-bg-color py-1 px-3 d-inline-block'>
                                    <h2 className='mb-0'>{getTextByKey('pregunta-encuesta-3')}</h2>
                                </div>
                            </div>
                            <div className='form-group mb-5'>
                                <textarea placeholder='Comenta aquí...' className='p-2'></textarea>
                            </div>
                            <div className='form-group mb-2 text-center'>
                                <button className='btn-g py-2 px-4 border-0 text-uppercase next'>{getTextByKey('para-finalizar-btn')}</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='py-2'>
                            <div className='form-group mb-4'>
                                <label className='quinary-bg-color p-2'>{getTextByKey('pregunta-encuesta-4')}</label>
                            </div>
                            <div className='form-group mb-5'>
                                <textarea placeholder='Comenta aqui...' className='p-2'></textarea>
                            </div>
                            <div className='form-group mb-2 text-center'>
                                <NavLink to="/compra-news" className='btn-g py-2 px-4 text-uppercase border-0'>{getTextByKey('enviar-respuestas-btn')}</NavLink>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </form>
        </Fragment>
    )

}

export default SliderComponent;