import React from "react";
import { Fragment } from "react";
import { NavComponent } from "./components/NavComponent";
import AgregarDireccionForm from "../../components/AgregarDireccionForm";
import { useApi } from "../../context/apiContext";
import { Link } from "react-router-dom";

export default function  NuevaDireccionPage() {

	const { getTextByKey } = useApi();

    return (
        <Fragment>
            <div className="perfil my-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-5 px-xl-5">
                            <h1 className="primary-color carrefourMetis-bold mb-lg-5 mb-3 text-center text-lg-start">{getTextByKey('perfil')}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 px-xl-0">
                            <NavComponent />
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-1 px-lg-5 px-4">
					
                            <h3 className="gray-1-color source-sans-bold mb-4 text-center text-lg-start">{getTextByKey('agregar_nueva_direccion')}</h3>
                            <AgregarDireccionForm  />
            
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}