import React, { useEffect } from 'react'
import { useState } from 'react';
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { default as ModalCompra } from './ModalCompra';
import { Link } from 'react-router-dom';
import { getAuth, signInAnonymously } from "firebase/auth";
import { createAnonymousUser } from '../../helpers/userHelper';

export default function ModalInicio({ showModalInicio, setShowModalInicio }) {

    const { getTextByKey, getTextBigByKey } = useApi();

    const [showModalCompra, setShowModalCompra] = useState(false);
    const [ loader, setLoader ] = useState(false);

    const gotoTop = event => {
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
      setShowModalInicio(false);  
        gotoTop();
    }


    const onGuestContinueClick = () => {
      const auth = getAuth();
      setLoader(true);
  
      signInAnonymously(auth)
        .then(async ({ user }) => {
  
          const { uid } = user;
          const registerFormFields = {
            email: `${uid}@anonymous.cervalle.com`,
            //email_confirm: `${uid}@anonymous.cervalle.com`,
            password: `${uid}`,
            password_confirm: `${uid}`,
            nombre: `${uid}`,
            apellido: `${uid}`,
            documento: `${uid}`,
            celular: "3111111111",
            tipopersona: "",
            direccion: "",
            tipoDocumento: 2,
            ciudad: 3,
            barrio: "",
            juridico: "",
            uid: `${uid}`,
            notas: "",
            autoriza: true,
            anonimo: true,
            politicas: false
          }
  
          await createAnonymousUser(registerFormFields).then((response)=>{
            console.log(response);
             if(response){
              setShowModalInicio(false);
              setShowModalCompra(true);
              setLoader(false);
             }
          }).catch((error)=>{
            setLoader(false);
          })
        
        })
        .catch((error) => {
          setLoader(false);
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // ...
        });

    }

    return (
        <Fragment>
            <Modal className="modal-compra" show={showModalInicio} onHide={handleClose}  centered>
                <Modal.Body>
                    <div className="text-end pe-2 icon-close">
                      <Icon className='cursor-pointer gray-1-color' icon="material-symbols:close-rounded" width="30" height="30" onClick={handleClose}></Icon>
                    </div>
                    <div className="text-center">
                      <h4 className='gray-1-color'>{getTextByKey('modal-compra-titulo')}</h4>
                    </div>


                    <div className="content-opt d-md-flex justify-content-center d-block py-3 col-11 mx-auto">

                      <div className='wp-opt'>
                        <div className="box-opt secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0">
                          <div className="d-flex title-card align-items-center">
                            <div className='icon-compra primary-color d-flex align-items-center'>
                              <Icon icon="ph:user-circle-plus" className='d-inline-block'></Icon>
                            </div>
                            <p className='primary-color ms-2 mb-0'>{getTextByKey('modal-inicia-sesion')}</p>
                          </div>
                          <div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-inicia-sesion-texto") }}></div>
                          <div className="text-center">
                            <Link onClick={handleClose} to="/login" className='btn-g px-4'>{getTextByKey('seleccionar')}</Link>
                          </div>
                        </div>
                      </div>

                      <div className='wp-opt'>
                        <div className="box-opt secundary-bg-color py-2 px-3 rounded mx-2 my-3 my-md-0">
                          <div className="title-card d-flex align-items-center">
                            <div className='icon-compra primary-color d-flex align-items-center'>
                              <Icon icon="ph:user-circle"></Icon>
                            </div>
                            <p className='primary-color ms-2 mb-0'>{getTextByKey('modal-ingresa-invitado')}</p>
                          </div>
                          <div className="gray-1-color sm pb-4" dangerouslySetInnerHTML={{ __html: getTextBigByKey("modal-ingresa-invitado-texto") }}></div>
                          <div className="text-center">
                            <button className='btn-g px-4' onClick={onGuestContinueClick}>
                            {
                            !loader ? (<>{getTextByKey('seleccionar')}</>) : (<div className='spinner-border spinner-border-sm' role='status'></div>)
                          }</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  
                </Modal.Body>
            </Modal>      
            <ModalCompra showModalCompra={showModalCompra} setShowModalCompra={setShowModalCompra} setShowModalInicio={setShowModalInicio} />
        </Fragment>
    )
}
