import React, { useEffect ,Fragment} from 'react'
import { Icon } from '@iconify/react';
import { useApi } from '../../../context/apiContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useCart } from '../../../context/cartContext';
import { byStatus } from '../../../api/productApi';
import { useState } from 'react';


export default function ModalConfirmacion({ showModal, setShowModal, datosPagoExitoso, exitoso, orderId }) {

  const { getTextByKey, getTextBigByKey, convertCurrency } = useApi();
  const { total, envio, descuento, coupon } = useCart();
  const [ status, setStatus ] = useState('');

  const gotoTop = event => {
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    setShowModal(false);
    gotoTop();
  }

  const pagoTarjeta = true;

  const showMetodoPago = (metodoPago) => {
    switch (metodoPago) {
      case 'epayco':
        
        break;
    
      default:
        break;
    }
  }

  const obtenerestado=()=>{
    byStatus(orderId).then((data)=>{
      if(data.estado!='PENDIENTE'){
        setStatus(data);
      }
    })
  }


  useEffect(() => {
    console.log('status..');
   const intervalId = setInterval(() => {
      if (orderId !== 0 && status === '') {
        setStatus('');
        obtenerestado();
      }
    }, 10000); 

    return () => {
      clearInterval(intervalId);
    };
  }, [orderId, status]);

  useEffect(()=>{
    if(orderId!=0){
      setStatus('');
      obtenerestado();
    }
    //console.log('id', orderId);
  },[orderId])

  return (
    <Fragment>
      <Modal className='modal-confirmacion' show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <div className="close-icon pe-2">
            <Icon className='cursor-pointer' icon="bi:x-lg" onClick={handleClose}></Icon>
          </div>
          <div className="container">
          { status ? 
            <div className="text-center">
              <p className='gray-1-color source-sans-bold'>{status.estado}</p>
              <p className='gray-1-color mb-1 md-p'>{getTextByKey('numero-pedido')}: <strong>{datosPagoExitoso.numeroPedido}</strong> </p>
              <p className='gray-1-color mb-1 md-p'>{getTextByKey('fecha')}: <strong>{datosPagoExitoso.fecha}</strong> </p>
              <p className='gray-1-color mb-1 md-p'>
                {getTextByKey('metodo-pago')}: {
                  datosPagoExitoso.metodoPago == 'tarjeta' ||  datosPagoExitoso.metodoPago == 'pse' ? (
                    <strong>{getTextByKey('pago-tarjeta-online')}</strong>
                  ) : (datosPagoExitoso.metodoPago == 'efectivo' ? (
                    <strong>{getTextByKey('contraentrega_efectivo')}</strong>
                  ) : <strong> {getTextByKey('contraentrega_tarjeta')}</strong>)
                }
              </p>
              <p className="gray-1-color mt-2">{getTextByKey('total')} <strong>{convertCurrency(datosPagoExitoso.total)}</strong> </p>
              <p className='gray-1-color mt-2'>{status?.textoentrega}</p>
            </div>:
              <div className="text-center">
              <p className='gray-1-color source-sans-bold'>Verificando estado...</p>
              <p className='gray-1-color mb-1 md-p'>{getTextByKey('numero-pedido')}: <strong>{datosPagoExitoso.numeroPedido}</strong> </p>
              <p className='gray-1-color mb-1 md-p'>{getTextByKey('fecha')}: <strong>{datosPagoExitoso.fecha}</strong> </p>
              <p className='gray-1-color mb-1 md-p'>
                {getTextByKey('metodo-pago')}: {
                  datosPagoExitoso.metodoPago == 'tarjeta' ||  datosPagoExitoso.metodoPago == 'pse'  ? (
                    <strong>{getTextByKey('pago-tarjeta-online')}</strong>
                  ) : (datosPagoExitoso.metodoPago == 'efectivo' ? (
                    <strong>{getTextByKey('contraentrega_efectivo')}</strong>
                  ) : <strong> {getTextByKey('contraentrega_tarjeta')}</strong>)
                }
              </p>
              <p className="gray-1-color mt-2">{getTextByKey('total')} <strong>{convertCurrency(datosPagoExitoso.total)}</strong> </p>
              </div>
            }
              
    
            
            <div className="text-center pt-2">
              <Link to="/shop" className='btn-g px-4 mx-3' onClick={handleClose}>{getTextByKey('volver-tienda')}</Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}