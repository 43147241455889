import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
import ProductocombinacionComponent from './components/ProductocombinacionComponent';
import StickyBox from "react-sticky-box";
import { images_products_route, getCombinationsById } from "../../api/productApi";
import { useApi } from "../../context/apiContext";
import { useCart } from "../../context/cartContext";

import '../../scss/combinacionPage.scss';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export default function CombinacionPage() {

    const { getTextByKey, getPropiertyByLocale } = useApi();
    const { addToCart, showCart, checkIfExistInCart } = useCart();
    const [productosSelected, setProductosSelected] = React.useState([]);
    const [errorMsg, seterrorMsg] = useState('')

    const [combinations, setCombinations] = React.useState({ 
        images: [],
        productos: [],
        name: [ {nombre: ""}],
        prices: [{precio: 0}],
    });

    const params = useParams();

    const addAllCombinationsToCart = () => {
        if(productosSelected.length == combinations.productos.length){
            let combination = {
                plu:  combinations.id,
                parent: null,
                cantidad: 1,
                images: combinations.images,
                names: [],
                details: [],
                fabrications: [],
                inspirations: [],
                size: "",
                color: "8",
                price: {},
                hijos: [],
                talla: "2",
                quantity: 1,
                type: "combination",
                products: [] 
            };
            combination.names.push({locale: "es",  nombre: combinations.name.nombre});
            combination.price = combinations.prices[0];
            combination.products = productosSelected;
            seterrorMsg('');
            addToCart(combination, 1);
            showCart();
        } else {
            seterrorMsg(getTextByKey('alert-combinaciones'))
            setTimeout(() => {
                seterrorMsg('');
            }, 10000);
        }
    }

    useEffect(() => {
        getCombinationsById(params.id).then((res) => {
            console.log(res);
            if(res.length > 0){
                setCombinations(res[0]);
            }
        });
    }, []);

    const addProductHandle = (producto) => {
        let index = productosSelected.findIndex((item) => item.plu === producto.plu);
        if(index >= 0){
            productosSelected[index] = producto;
        } else {
            setProductosSelected([...productosSelected, producto]);
        }
        console.log(productosSelected);
    }
    const checkIfExistInSelected = (producto) => {
        let index = productosSelected.findIndex((item) => item.plu === producto.plu);
        if(index >= 0){
            return productosSelected[index];
        } else {
            return false;
        }
    }
    const removeProduct = (producto) => {
        let index = productosSelected.findIndex((item) => item.plu === producto.plu);
        if(index >= 0){
            productosSelected.splice(index, 1);
            setProductosSelected([...productosSelected]);
        }
        console.log("removed",productosSelected);
    }

    return (
        <Fragment>
            <div className='container-fluid combinacion-page'>
                <StickyBox className='stickybox'>
                    {combinations.images.length > 0 && <img className='w-100' src={images_products_route + combinations.images[0].image} alt={combinations.name.nombre} />}
                </StickyBox>
                <div className='combinaciones'>
                    <div className='combinaciones-group ps-md-4 py-3 py-md-0'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <h2 className='text-uppercase mb-0'>{combinations.name.nombre}</h2>
                            <h2 className='text-uppercase mb-0'>{combinations.productos.length} {getTextByKey("number_look")}</h2>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h2 className='text-uppercase mb-0'>${combinations.prices[0].precio.toLocaleString()}</h2>
                            <a className='btn-g bb add py-2 px-3 d-flex align-items-center' onClick={addAllCombinationsToCart}>
                                <Icon icon="grommet-icons:shop" className='icon me-3'/>
                                {productosSelected.length > 0 ? <span>AGREGAR {productosSelected.length} LAS PRENDAS A LA BOLSA</span> : <span>{getTextByKey("select-garments")}</span>}
                            </a>
                        </div>
                        {errorMsg ? <div className='error-msg text-end'>*{errorMsg}</div> : null}
                        <div className='row'>
                            {combinations.productos.map((producto, index) => (
                                <div className='col-6 position-relative' key={index}>
                                    {/* {checkIfExistInSelected(producto) && (
                                    <div className='delete-product'>
                                        <Icon icon="ant-design:close-circle-outlined" className='icon' onClick={() =>removeProduct(producto)} />
                                    </div>
                                    )} */}
                                    <ProductocombinacionComponent selected={checkIfExistInSelected(producto)} addProductHandle={addProductHandle}  producto={producto} url={images_products_route} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
