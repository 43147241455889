import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../../authContext";
import { useEffect, useState } from 'react';
import { getDepartamentos, getPaises } from "../../api/productApi";
import { getCiudades } from "../../api/productApi";
import { getTiposDocumentos } from "../../api/productApi";
import { createUser } from '../../helpers/userHelper';
import { useApi } from '../../context/apiContext';
import { useCart } from '../../context/cartContext';
import { useForm } from '../../hooks/useForm';
import { Icon } from '@iconify/react';
import { getGallery } from '../../api/galleryApi';
import { getInfoMap } from "../../api/mapApi";
import { getKml } from '../../api/storeApi';
import { createAddress } from "../../api/addressApi";


import "../../scss/login-register.scss";

const registerFormFields = {
    email: "",
   // email_confirm: "",
    password: "",
    password_confirm:"",
    nombre: "",
    apellido: "",
    documento:"",
    celular: "",
    tipopersona:"",
    direccion:"",
    tipoDocumento:0,
    ciudad:0,
    departamento: 0,
    barrio:"",
    juridico:0,
    uid: "unset",
    //pais: 48, //Colombia id
    //fechaNacimiento: "",
    //providerData: "unset",
    notas:"",
    //sexo: "M",
   politicas:false
}


export default function RegisterPage() {
    //const [paises, setPaises] = useState([]);
    const [ciudades, setCiudades] = useState([]);
		const [ departamentos, setDepartamentos ] = useState([]);
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const { getTextByKey, getTextBigByKey, currentLocale } = useApi();
    const { updateTienda,updateCurrentdelivery, updateDireccion } = useCart();
    const {
        onInputChange: onRegisterInputChange,
        formState,
        emailRegex,
        passwordRegex,
        phoneRegex,
        nameRegex
    } = useForm(registerFormFields);
   
    const [loading, setloading] = useState(false)
    const [errors, setErrors] = useState()
    const [ errorPass, setErrorPass ] = useState();
    const [ errorTel, setErrorTel ] = useState();
    const [ errorEmail, setErrorEmail ] = useState();
		const [ errorDpto, setErrorDpto ] = useState();
    const [ errorCiudad, setErrorCiudad ] = useState();
    const [ errorNombre, setErrorNombre ] = useState();
    const [ errorApellido, setErrorApellido ] = useState();
    const [ errorTipoDoc, setErrorTipoDoc ] = useState();
    const [ errorNumDoc, setErrorNumDoc ] = useState();
    const [ errorBarrio, setErrorBarrio ] = useState();
    const [ errorDireccion, setErrorDireccion ] = useState();
    const [ errorAlias, setErrorAlias ] = useState();
    const [listTratamiento, setListTratamiento] = useState([])
    const [typePassword, settypePassword] = useState('password')
    //const [ showFieldsJuridica, setShowFieldsJuridica ] = useState(false);
    //const [ showFieldsNatural, setShowFieldsNatural ] = useState(true);
    const [tipopersona, setTipopersona] = useState("natural")
    const [registro, setRegistro] = useState(0)
    const [ dptoId, setDptoId ] = useState('');
    const [ tienda, setTienda ] = useState(null);

    const [ lat, setLat ] = useState(null);
    const [ lng, setLng ] = useState(null);
    const [addressFromFields, setAddressFromFields] = useState ({
        alias: "",
        ciudad: 0,
        department: 0,
        tipocalle: "",
        direccion:"",
        base: "",
        numero: "",
        detalle: "",
        complement: "",
        zipcode: "",
        lat: "",
        lng: "",
        phone: "",
        barrio: "",
        uid: "unset"
    })
     
    const {complement,alias,base,numero,detalle, email, email_confirm, documento,tipoDocumento,password,password_confirm, celular, notas,nombre, apellido,politicas, departamento, ciudad,barrio,direccion, uid, providerData,tipocalle } = formState;

    //Check the Auth Session
    const { currentUser } = useAuth();
    useEffect(() => {
        if (currentUser) {
            if(!currentUser.isAnonymous)
             location.href = "/";
        }
				getDepartamentos().then((data) => {
					setDepartamentos(data);
				})
        getTiposDocumentos().then((data) => {
            setTiposDocumentos(data);
        });
    }, []);


    useEffect(() => {
       
        if(currentUser && registro!=0){
            if(!currentUser.isAnonymous){
            updateDireccion('');
            updateCurrentdelivery({tipo:'',dir:''});
            updateTienda('');
            location.href = "/shop";
            }
        }
    }, [registro]);

    useEffect(()=>{
        getCiudades(dptoId).then((data) => {
            setCiudades(data);
        });
    }, [dptoId])

    useEffect(() => {
        getGallery("title-form-register").then((data) => {
            const listadoAux = [];
            data.forEach(element => {
                element.names.forEach( content => {
                    if( content.locale === currentLocale ) {
                        listadoAux.push(content.titulo)
                    }
                })
            });
            setListTratamiento( listadoAux );
        });
    }, [currentLocale])

		const handleDpto = (event) => {
			setDptoId(event.target.value);
            onRegisterInputChange(event);
		}

    const onValidateEmailConfirm = (e) => {
        console.log(email);
        console.log(email_confirm);
        if( email !== email_confirm ) {
            setclassEmailConfirm('border-danger');
        } else {
            setclassEmailConfirm('');
        }
    }

    const resetError=()=>{
        setErrorNombre('');
        setErrorApellido('');
        setErrorTipoDoc('');
        setErrorNumDoc('');
        setErrorPass("");
        setErrorEmail("");
        setErrorTel("");
        setErrorDireccion('');
        setErrorAlias('');
        setErrorBarrio('');
        setErrorDpto("");
        setErrorCiudad("");
        setErrors('');
        
    }

    const showPass = () => {
        if( typePassword === 'text' ) {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }

    const onRegisterSubmit = async (event) => {
        event.preventDefault();
        resetError();
        setloading(true);

        const validate = await validateForm(formState)
      
        if(tipopersona=='juridica'){
            formState.juridico=1;
            formState.tipoDocumento=1;
          
        }
        //If empty errors, should create a new user through firebase and database
        // console.log('errors found', errors)
        if (!validate) {
            
            const newUser = await createUser(formState, setErrors, setloading, setRegistro)
            // crear direccion al nuevo usuario
            try{
                const ciudadSelect = ciudades.find((city) => city.id == ciudad);
                const dirfull=`${tipocalle} ${base}#${numero}-${detalle}`;
                const address = `${dirfull},${ciudadSelect.name.nombre},Colombia`;
                
                console.log("newUser",newUser,address);
                let ubicacion=await infoMap(address);
                if(ubicacion.ok){
                    let addressFields = {
                        ...addressFromFields,
                        user: newUser.uid,
                        uid: newUser.uid,
                        alias: alias,
                        city: ciudad,
                        tipocalle: tipocalle,
                        base: base,
                        numero: numero,
                        lat: ubicacion.lat,
                        lng: ubicacion.lng,
                        detalle: detalle,
                        complement: complement,
                    }
                    createAddress(addressFields).then((data) => {
                        
                     console.log("creodir");
                        setRegistro(1);
                        setloading(false);
                    }).catch((error)=>{
                        setRegistro(1);
                        setloading(false);
                    })
                }else{
                    setRegistro(1);
                }
            }catch(e){
                setRegistro(1);
            }


        }else{
            window.scroll(50, 0);
            setloading(false);
        }

    }

    const infoMap = async (address) => {
			
        try {
            const { data: { results } } = await getInfoMap(address);
            if (results && results.length > 0) {
                const { lat, lng } = results[0].geometry.location;
                
                let tienda= await getKml(lat, lng) 
                if(tienda.id){
                    return {ok:true,lat:lat,lng:lng};
                }else{
                    return {ok:false,lat:lat,lng:lng};
                }  
                  
                      
            }else{	
                return {ok:false,lat:0,lng:0};
            }
        } catch (error) {
            console.error(error);
            return {ok:false,lat:0,lng:0};
        }
    }

    const validateForm = async () => {
        let errorFound = false;
        if(tipopersona=='natural'){
            if (!nombre ) {
                errorFound = true;
                setErrorNombre('Este campo es requerido');
            } else {
                    
                if(!nombre.match(nameRegex)){
                    errorFound = true;
                    setErrorNombre('Formato inválido, este campo solo recibe letras');
        
                }
            }
            if(!apellido){
                errorFound = true;
                setErrorApellido('Este campo es requerido');
            } else {
                if(!apellido.match(nameRegex)){
                    errorFound = true;
                    setErrorApellido('Formato inválido, este campo solo recibe letras');
                }
            }
            if(tipoDocumento==0){
                errorFound = true;
                setErrorTipoDoc('Selecciona un tipo de documento');
            } 
           
       }else{
            if (!nombre ) {
                errorFound = true;
                setErrorNombre('Este campo es requerido');
             } 
       }
                
       if(!documento){
            errorFound = true;
            setErrorNumDoc('Este campo es requerido');
        } 
        if (!password.match(passwordRegex) || !password_confirm.match(passwordRegex) ){
            errorFound = true;
        setErrorPass("La contraseña debe tener al menos 6 caracteres");
        }

        //Validate the email format
        if (!email.match(emailRegex)){
            errorFound = true;
            setErrorEmail("El email no tiene un formato válido");
        }

        /*if (!email_confirm.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            errorFound = true;
        setErrors("El email no tiene un formato válido");*/

        if (password_confirm !== password) {
            errorFound = true;
            setErrorPass("La contraseña no coincide");
        }

        //Validate the phone format
        if (!celular.match(phoneRegex)){
            errorFound = true;
            setErrorTel("El teléfono no tiene un formato válido");
        }
       
     
        if(!tipocalle ){
            errorFound = true;
            setErrorDireccion('Este campo es requerido');
        }
        if(!alias){
            errorFound = true;
            setErrorAlias('Este campo es requerido');
        }
        if(!base || !numero || !detalle){
            errorFound = true;
            setErrorBarrio('Este campo es requerido');
        }
        if(departamento==0){
            errorFound = true;
            setErrorDpto("Debe seleccionar un departamento");
        }
        if (ciudad==0) {
            errorFound = true;
            setErrorCiudad("Debe seleccionar una ciudad");
        } 
    


    /*for await (const [key, value] of Object.entries(formData)) {
        let clearValue = typeof value === 'string' && value.trim();
            if (clearValue === "" && key!='notas') {
                        console.log('valores', tipopersona, key);
                        if(tipopersona=="juridica" && key == 'apellido'){
                            console.log('pasa');
                        }else{
                            console.log('AQUI',key,value);
            errorFound = true;
            setErrors("Todos los campos son obligatorios");
                        }
        }
    }*/
        
        return errorFound;
    }


   
    const onOptionChange = e => {
        setTipopersona(e.target.value)
    }

    const gotoTop = event => {
        hideCart();
        window.scrollTo(0, 0);
    };

    return (
        <Fragment>
            <div className='login-register pt-lg-5 pt-3 pb-5 px-lg-5 px-2' style={{ backgroundImage: `url("/img/bg-login-register.jpg")` }}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-11 col-xl-7'>
                            <form className='py-2 px-3 px-xl-5 white-color row' onSubmit={onRegisterSubmit}>
                                <h1 className='text-center carrefourMetis-bold pt-lg-4 pt-2'>{getTextByKey('registrarse-titulo')}</h1>
                                <h3 className='text-center mb-lg-5 mb-3'>{getTextByKey('completa-datos')}</h3>
                                <div className='d-flex justify-content-center text-center mb-lg-5 mb-3 group-radio-type-person'>
                                    <label className="d-flex align-items-center w-auto mx-3">
                                        <input type="radio" className='radio-button'  checked={tipopersona === "natural"} onChange={onOptionChange} name="tipopersona"  value="natural" />
                                        <small>{getTextByKey('natural')}</small>
                                    </label>
                                    <label className="d-flex align-items-center w-auto mx-3" >
                                        <input type="radio" className='radio-button'  checked={tipopersona === "juridica"} onChange={onOptionChange}   name="tipopersona"value="juridica" />
                                        <small>{getTextByKey('juridica')}</small> 
                                    </label>
                                </div>
                                
                                <div className="row">
                                        { tipopersona=='juridica' &&
																					<div className="col-12">
																						<div className={`form-group mb-3 ${errorNombre ? 'danger-alert' : ( nombre && !errorNombre ? 'sucess-alert' : '')}`}>
																								<label>{getTextByKey('nombre_empresa')}*</label>
																								<input
																										type="text"
																										className='p-2'
																										name="nombre"
																										onChange={onRegisterInputChange}
																										value={nombre}
																								/>
																									<div className='icon-war'>{ errorNombre ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( nombre && !errorNombre ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																								<span>{errorNombre ? <div className='alert-danger'>{errorNombre}</div> : null}</span>
																						</div>
																					</div>
                                        }
                                        { tipopersona=='natural' &&
																					<>
																					<div className='col-md-6'>
																						<div className={`form-group mb-3 ${errorNombre ? 'danger-alert' : ( nombre && !errorNombre ? 'sucess-alert' : '')}`}>
																								<label>{getTextByKey('nombres')}*</label>
																								<input
																										type="text"
																										className='p-2'
																										name="nombre"
																										onChange={onRegisterInputChange}
																										value={nombre}
																								/>
																								<div className='icon-war'>{ errorNombre ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( nombre && !errorNombre ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																								<span>{errorNombre ? <div className='alert-danger'>{errorNombre}</div> : null}</span>
																						</div>
																					</div>
																					<div className='col-md-6'>
																						<div className={`form-group mb-3 ${errorApellido ? 'danger-alert' : ( apellido && !errorNombre ? 'sucess-alert' : '')}`}>
																								<label>{getTextByKey('apellidos')}*</label>
																								<input
																										type="text"
																										className='p-2'
																										name="apellido"
																										onChange={onRegisterInputChange}
																										value={apellido}
																								/>
																								<div className='icon-war'>{ errorApellido ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( apellido && !errorApellido ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																								<span>{errorApellido ? <div className='alert-danger'>{errorApellido}</div> : null}</span>
																							</div>
																					</div>	
																					</>
                                            	

                                        }
                                        <div className="col-md-6">
																					<div className={`form-group mb-3 ${errorEmail ? 'danger-alert' : ( email && !errorNombre ? 'sucess-alert' : '')}`}>
																						<label>{getTextByKey('correo_electronico_form')}*</label>
																						<input
																								type="email"
																								className='p-2'
																								name="email"
																								onChange={onRegisterInputChange}
																								value={email}
																							/>
																							<div className='icon-war'>{ errorEmail ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( email && !errorEmail ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																							<span>{errorEmail ? <div className='alert-danger'>{errorEmail}</div> : null}</span>
																						</div>
																				</div>
																				<div className="col-md-6">
																					<div className={`form-group mb-3 ${ errorTel ? 'danger-alert' : ( celular && !errorTel ? 'sucess-alert': '')}`}>
																						<label>{getTextByKey('telefono')}*</label>
																						<input
																										type="number"
																										className={`p-2 ${ errorTel ? 'border-danger' : ''}`}
																										name="celular"
																										onChange={onRegisterInputChange}
																										value={celular}
																						/>
																							<div className='icon-war'>{ errorTel ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : (celular && !errorTel ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '')}</div>
																						<span>{errorTel ? <div className='alert-danger'>{errorTel}</div> : null}</span>
																					</div>
																				</div>
																				<div className="col-md-6">
																					<div className={`form-group mb-3 position-relative ${errorPass ? 'danger-alert' : ( password && !errorPass ? 'sucess-alert' : '')}`}>
																						<label>{getTextByKey('contrasena_form')}*</label>
																						<input
																								type={typePassword}
																								className={`p-2 ${ errorPass ? 'border-danger' : '' }`}
																								name="password"
																								onChange={onRegisterInputChange}
																								value={password}
																						/>
																						<a className="btn-show-pass" onClick={showPass}>
																								<Icon icon="bi:eye-slash" />
																						</a>
																						{/* <small className="mt-1 text-uppercase text-dark link-reset-password w-100 text-end">{getTextByKey("help-password-conditions")}</small> */}
																						<div className='icon-war'>{ errorPass ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( password && !errorPass ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																						<span>{errorPass ? <div className='alert-danger'>{errorPass}</div> : null}</span>
																					</div>
																				</div>
																			<div className="col-md-6">
																					<div className='form-group mb-3 position-relative'>
																							<label>{getTextByKey('confirmar_contrasena_form')}*</label>
																							<input
																									type={typePassword}
																									className='p-2'
																									onChange={onRegisterInputChange}
																									name="password_confirm" value={password_confirm}
																							/>
																							<a className="btn-show-pass" onClick={showPass}>
																									<Icon icon="bi:eye-slash" />
																							</a>
																					
																					</div>
																				</div>

																				<div className="col-md-6">
																					<div className={`form-group mb-3 ${ errorTipoDoc ? 'danger-alert' : ( tipoDocumento && !errorTipoDoc ? 'sucess-alert' : '')}`}>
																							<label>{getTextByKey('tipo_documento')}*</label>
																							<select className='p-2 select-gris' name="tipoDocumento" value={tipoDocumento}  onChange={onRegisterInputChange}>
																									<option value="0" >Selecciona una opción </option>
																									{tiposDocumentos.map((tipoDocumento, index) => (
																											<option key={index} value={tipoDocumento.id}>{tipoDocumento.ref + " (" + tipoDocumento.translations[0].name + ")"}</option>
																									))}
																							</select>
																							<div className='icon-war'>{ errorTipoDoc? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( tipoDocumento && !errorTipoDoc ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '')}</div>
																							<span>{errorTipoDoc ? <div className='alert-danger'>{errorTipoDoc}</div> : null}</span>
																					</div>
																				</div>
								
																				<div className='col-md-6'>
																					<div className={`form-group mb-3 ${ errorNumDoc ? 'danger-alert' : ( documento && !errorNumDoc ? 'sucess-alert' : '' )}`}>
																					<label>{ tipopersona=='natural' ? `${getTextByKey('numero_documento')}` : `${getTextByKey('nit')}` }*</label>
																					<input
																							type="number"
																							className='p-2'
																							name="documento"
																							onChange={onRegisterInputChange}
																							value={documento}
																					/>
																						<div className='icon-war'>{ errorNumDoc ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( documento && !errorNumDoc ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '' )}</div>
																							<span>{errorNumDoc ? <div className='alert-danger'>{errorNumDoc}</div> : null}</span>
																					</div>
																				</div>
																				<div className="col-md-6">
																					<div className={`form-group mb-3 ${errorDpto ? 'danger-alert' : ( departamento && !errorDpto ? 'sucess-alert': '')}`}>
																							<label>{getTextByKey('departamento')}*</label>
																							<select className='p-2 select-gris' name="departamento" value={departamento}
																							onChange={(event) => {
																								handleDpto(event);
																								
																							}}>
																								<option value="0" >Selecciona tu departamento </option>
																								{departamentos && departamentos.map((departamento, index) => (
																									<option key={index} value={departamento.id}>
																										{ departamento.name.nombre }
																										</option>
																								))}
																							</select>    
																							<div className='icon-war'>{ errorDpto ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( departamento && !errorDpto ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '')}</div>
																							<span>{errorDpto ? <div className='alert-danger'>{errorDpto}</div> : null}</span>                                   
																					</div>
																				</div>
                                  			<div className="col-md-6">
																					<div className={`form-group mb-3 ${errorCiudad ? 'danger-alert' : ( ciudad && !errorCiudad ? 'sucess-alert': '')}`}>
																							<label>{getTextByKey('ciudad')}*</label>
																							<select className='p-2 select-gris' name="ciudad" value={ciudad}  onChange={onRegisterInputChange}>
																								<option value="0" >Selecciona tu ciudad </option>
																								{ciudades.map((ciudad, index) => (
																								<option key={index} value={ciudad.id}>{ciudad.name.nombre}</option>
																								))}
																							</select>
																							<div className='icon-war'>{ errorCiudad ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( ciudad && !errorCiudad ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '')}</div>
																							<span>{errorCiudad ? <div className='alert-danger'>{errorCiudad}</div> : null}</span>
																					</div>
																				</div>
																				<div className="col-md-6">
																					<div className={`form-group mb-3 ${ errorDireccion ? 'danger-alert' : (direccion && !errorDireccion ? 'sucess-alert' : '')}`}>
																							<label>{getTextByKey('direccion')}*</label>
																							
                                                                                            <select name="tipocalle" id="" className="p-2 select-gris" value={tipocalle} onChange={onRegisterInputChange}>
                                                                                                <option value="0">Selecciona el tipo de calle</option>
                                                                                                <option value="Calle">Calle</option>
                                                                                                <option value="Carrera">Carrera</option>
                                                                                                <option value="Transversal">Transversal</option>
                                                                                                <option value="Avenida">Avenida</option>
                                                                                                <option value="Circunvalar">Circunvalar</option>
                                                                                                <option value="Diagonal">Diagonal</option>
                                                                                            </select>
																							<div className='icon-war'>{ errorDireccion ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( direccion && !errorDireccion ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '')}</div>
																							<span>{errorDireccion ? <div className='alert-danger'>{errorDireccion }</div> : null}</span>
																					</div>
																				</div>
																				<div className="col-md-6">
																					<div className={`form-group mb-3 ${ errorBarrio ? 'danger-alert' : (barrio && !errorBarrio ? 'sucess-alert' : '')}`}>
                                                                                        <label>Base, número, detalle *</label>
																						<div className="d-flex align-items-center">
                                                                                                <div>
                                                                                                        <input type="text" name="base" className="p-2" placeholder="10A" value={base} onChange={onRegisterInputChange} />
                                                                                                </div>
                                                                                                <div>#</div>
                                                                                                <div>
                                                                                                        <input type="text" name="numero" className="p-2" placeholder="10" value={numero} onChange={onRegisterInputChange} />
                                                                                                </div>
                                                                                                <div>-</div>
                                                                                                <div>
                                                                                                        <input type="text" name="detalle" className="p-2" placeholder="101" value={detalle} onChange={onRegisterInputChange} />
                                                                                                </div>
                                                                                        </div>
																					</div>
																				</div>

                                                                                
                                                                                <div className="col-md-6">
																					<div className={`form-group mb-3 `}>
																						<label>{getTextByKey('complemento')}</label>
																						<input
                                                                                            type="text"
                                                                                            className="p-2"
                                                                                            name="complement"
                                                                                            value={complement}
                                                                                            onChange={onRegisterInputChange}
                                                                                        />
																						
																						{/* <small className="mt-1 text-uppercase text-dark link-reset-password w-100 text-end">{getTextByKey("help-password-conditions")}</small> */}
																						
																					</div>
																				</div>
                                                                                <div className="col-md-6">
                                                                                    <div className='form-group mb-3 position-relative'>
                                                                                        <label>{getTextByKey('alias')}*</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="p-2"
                                                                                            name="alias"
                                                                                            value={alias}
                                                                                            onChange={onRegisterInputChange}
                                                                                        />
                                                                                        <div className='icon-war'>{ errorAlias ? <Icon className='alert-danger' icon='dashicons:warning'></Icon> : ( alias && !errorAlias ? <Icon className='alert-sucess' icon='ph:check-bold'></Icon> : '') }</div>
																						<span>{errorAlias ? <div className='alert-danger'>{errorAlias}</div> : null}</span>
                                                                                    
                                                                                    </div>
																				</div>



																				<div className="col-12">
																					<div className='form-group mb-3'>
																							<label>{getTextByKey('notas')}</label>
																							<input
																									type="text"
																									className='p-2'
																									onChange={onRegisterInputChange}
																									name="notas" value={notas}
																						/>
																						</div>
																					</div>
                               	 </div>
                              
                              
                      
                                {/* <div className='col-12 col-md-6 mb-4'>
                                    <div className='form-group'>
                                        <label>Fecha*</label>
                                        <input
                                            type="date"
                                            className='p-2 inputdate'
                                            name="fechaNacimiento"
                                            onChange={onRegisterInputChange}
                                            value={fechaNacimiento}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 mb-4'>
                                    <div className='form-group'>
                                        <label>Confirmar correo electrónico*</label>
                                        <input
                                            type="email"
                                            className={`${classEmailConfirm} p-2`}
                                            name="email_confirm"
                                            onChange={onRegisterInputChange} 
                                            onKeyUp={onValidateEmailConfirm}
                                            value={email_confirm}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className='col-12 col-md-6 mb-4'>
                                    <div className="form-group">
                                        <label>Tratamiento*</label>
                                        <select className='p-2' name="tratamiento" onChange={onRegisterInputChange} value={tratamiento}>
                                            <option value="N/A">{ currentLocale === 'es' ? 'Seleccione una opcion' : 'Select option'}</option>
                                            {
                                                listTratamiento.map( (option, index) => (<option value={option} key={index}>{option}</option> ))
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                {/* <div className='form-group'>
                                    <label className="container-check-s">
                                        <small>Suscribirme al newsletter</small>
                                        <input
                                            type="checkbox"
                                            name="newsletter"
                                            onChange={onRegisterInputChange}
                                            value={newsletter}
                                        />
                                        <span className="checkmark-s">
                                            <img src="/img/SVG/check.svg" />
                                        </span>
                                    </label>
                                </div> */}
                                <div className='form-group d-flex justify-content-center text-center'>
                                    <label className="d-flex align-items-center justify-content-center">
                                        <input
                                            type="radio"
                                            name="politicas"
                                            onChange={onRegisterInputChange}
                                            value={politicas}
                                            className='radio-button'
                                        />
                                        <div className='sm d-flex justify-content-center' dangerouslySetInnerHTML={{__html: getTextBigByKey('nota_registro')}}></div>
                                    </label>
                                </div>
                                {/* <div className='form-group'>
                                    <label className="container-check-s">
                                        <small>He leído y comprendido la política de datos, aviso de privacidad, acepto la política de compras de Cervalle y declaro que tengo 16 años o más</small>
                                        <input
                                            type="checkbox"
                                            name="politicas"
                                            onChange={onRegisterInputChange}
                                            value={politicas}
                                        />
                                        <span className="checkmark-s">
                                            <img src="/img/SVG/check.svg" />
                                        </span>
                                    </label>
                                </div> */}
                                {errors ? <div className='error-msg'>{errors}</div> : null}
                                <div className='form-group text-center mt-4'>
                                    <button className='btn-g py-2 px-5' disabled={loading}>{loading ? `${getTextByKey('registrando')}` : `${getTextByKey('registrarme')}`}</button>
                                </div>
                                <div className='text-center mt-3'>
                                    <p className='md-p'>
                                        <span>{getTextByKey('ya-tienes-cuenta')}</span>
                                        <Link to="/login" className='ms-2 white-color text-underline' onClick={gotoTop}>{getTextByKey('inicia-sesion')}</Link>
                                    </p>
                                </div>
                                {/* <div className='form-group text-center mb-3'>
                                    <label > <Link to="/login" className='text-uppercase'>{ currentLocale === 'es' ? 'O vuelve a' : 'Return to' } <span className="text-decoration-underline">{getTextByKey("login-button")}</span> </Link> </label>
                                </div> */}
                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
