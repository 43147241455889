import React, { Fragment, useState, fallback, setFallback, useEffect } from 'react'
import { useApi } from '../context/apiContext';
import { getGallery } from '../api/galleryApi';
import { images_products_route } from '../api/productApi';

export default function FindmysizeComponent() {

    const tableCM = 'img/tabla-medidas.jpg';
    const tablePLG = 'img/tabla-medidas-plg.jpg';
    const { currentLocale } = useApi();

    const imgSrc = 'img/tabla-medidas.jpg';

    const [ medidas, setMedidas ] = useState([])
    const [ imgTable, setImgTable ] = useState([])
    const [ info, setInfo ] = useState([])

    //useEffect para traer las tablas
    useEffect(() => {
        getGallery("tabla-medidas").then((data) => {
            setImgTable( currentLocale === 'es' ? data[0].image : data[2].image);
            setMedidas(data);
        });
      }, []);

    // useEffect para cambiar la imagen de la tabla
    useEffect(() => {
        setImgTable( currentLocale === 'es' ? medidas[0]?.image : medidas[2]?.image )
    }, [currentLocale])

    
    //useEfect para traer la información

    useEffect(() => {
        getGallery("tabla-medidas-info").then((data) => {
            setInfo(data);
        });
      }, []);
    

      const { getTextByKey, getPropiertyByLocale } = useApi();

    const changeTableCm = event =>{
        setImgTable( currentLocale === 'es' ? medidas[0].image : medidas[2].image )
    }
    
    const changeTablePlg = event =>{
        setImgTable( currentLocale === 'es' ? medidas[1].image : medidas[3].image )
        document.querySelector('.btn-cm').classList.remove('active');
        document.querySelector('.btn-pl').classList.contains('active');
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12 col-lg-4 d-flex justify-content-cener align-items-center'>
                    <picture>
                        {info.length > 0 && <img className="w-100" src={images_products_route+info[0].image} alt={"Principal"} />} 
                    </picture>
                </div>
                <div className='col-12 col-lg-8'>
                    <div className='px-3 px-lg-4 px-lg-5'>
                        <div className='mb-2 mb-lg-4 mb-xl-5'>
                            <h3 className='gotham-Bold text-uppercase'>{getTextByKey('medidas-titulo')}</h3>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mb-2 mb-lg-3 mb-xl-4'>
                                <p className='gotham-Bold text-uppercase'>{info.length > 1 && getPropiertyByLocale(info[1].names, "titulo")}</p>
                                <p>{info.length > 1 && getPropiertyByLocale(info[1].names, "descripcion")}</p>
                            </div>
                            <div className='col-md-6 mb-2 mb-lg-3 mb-xl-4'>
                                <p className='gotham-Bold text-uppercase'>{info.length > 1 && getPropiertyByLocale(info[2].names, "titulo")}</p>
                                <p>{info.length > 1 && getPropiertyByLocale(info[2].names, "descripcion")}</p>
                            </div>
                            <div className='col-md-6 mb-2 mb-lg-3 mb-xl-4'>
                                <p className='gotham-Bold text-uppercase'>{info.length > 1 && getPropiertyByLocale(info[3].names, "titulo")}</p>
                                <p>{info.length > 1 && getPropiertyByLocale(info[3].names, "descripcion")}</p>
                            </div>
                            <div className='col-md-6 mb-2 mb-lg-3 mb-xl-4'>
                                <p className='gotham-Bold text-uppercase'>{info.length > 1 && getPropiertyByLocale(info[4].names, "titulo")}</p>
                                <p>{info.length > 1 && getPropiertyByLocale(info[4].names, "descripcion")}</p>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <h3 className='gotham-Bold text-uppercase'>{ getTextByKey('tabla-medidas-titulo')}</h3>
                                <div className='d-flex align-items-center'>
                                    <a className='btn-g btn-cm py-1 px-2 active' onClick={changeTableCm}>CM</a>
                                    <a className='btn-g btn-pl py-1 px-2'  onClick={changeTablePlg}>PLG</a>
                                </div>
                            </div>
                            <picture>
                                <img className='w-100' id="table" src={images_products_route+imgTable}/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
