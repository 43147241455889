import { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { useApi } from "../../context/apiContext";

export default function FeedbackaPage() {
  const { getTextByKey, getTextBigByKey } = useApi();
  return (
    <Fragment>
        <div className="banner feedback position-relative">
            <img className="w-100 fondo" src="img/Group-142.jpg"/>
            <div className="content-banner">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="text-center" dangerouslySetInnerHTML={{ __html: getTextBigByKey("credit-page-title") }}></div>
                    {/* <h2 className="text-uppercase text-center secundary-color mb-4">  
                    RECIBIRÁS UN <span className="quinary-bg-color">CUPÓN DE CRÉDITO</span> EL CUAL podrás usar 
                    en <span className="border-bottom quinary-border-color">compras en nuestra tienda.</span></h2> */}
                    <div className="text-center py-4">
                      <NavLink to="/feedback-slider" className="btn-g py-2 px-4 text-uppercase border-0">empecemos</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </Fragment>
  )
}
