import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from "../../authContext";
import { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { loginUser } from '../../helpers/userHelper';
import { useApi } from '../../context/apiContext';
import { useCart } from '../../context/cartContext';
import { getImages } from "../../api/imageApi";
import {images_products_route} from "../../api/productApi";
import { Icon } from '@iconify/react';

import "../../scss/login-register.scss";

const loginFormFields = {
    email: "",
    password: "",
}

export default function LoginPage() {
    const { getTextByKey, getTextBigByKey } = useApi();
    const navigate = useNavigate();
    //Check the Auth Session
    const { currentUser, currentExists } = useAuth();
    const { updateTienda,updateCurrentdelivery, updateDireccion } = useCart();
    const [logeo, setLogeo] = useState(0)


    const [bgImageLoginRegister, setBgImageLoginRegister] = useState([]);
    const [typePassword, settypePassword] = useState('password')

    useEffect(() => {
        if (currentUser) {
            if(!currentUser.isAnonymous)
                location.href = "/";
        }

        getImages("bg-login-register").then((data)=> {
            setBgImageLoginRegister(data);
        });
    }, []);

    useEffect(() => {
       
        if(currentUser && logeo!=0){
            if(!currentUser.isAnonymous){
            updateDireccion('');
            updateCurrentdelivery({tipo:'',dir:''});
            updateTienda('');
            location.href = "/shop";
            }
        }
    }, [logeo]);

    

    //State the error message and the loading state
    const [errors, setErrors] = useState('')
    const [loading, setloading] = useState(false)
    const {
        onInputChange: onLoginInputChange,
        formState,
    } = useForm(loginFormFields);

    const { email, password } = formState;


    //Function to handle the login
    const onLoginSubmit = async e => {
        e.preventDefault();
        setloading(true);


        const validate = await validateForm(formState)

        //If empty errors, should login through firebase
        if (!validate) {
            setloading(true);
            setErrors();
            const login = await loginUser(formState, setErrors, setloading, setLogeo);
         
            if(login){
                console.log('logindos',login);
                currentExists();
            }else{
                console.log('login',login);
            }
        }

    };

    const validateForm = async (formData) => {
        let errorFound = false;

        //Validate the email format
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            errorFound = true;
        setErrors("El email no tiene un formato válido");

        //validate empty values
        for (const [key, value] of Object.entries(formData))
            //TODO make whitespaces validation
            if (value === "")
                errorFound = true;
        setErrors("Todos los campos son obligatorios");

        setloading(false)
        return errorFound;
    }

    const showPass = () => {
        if( typePassword === 'text' ) {
            settypePassword('password')
        } else {
            settypePassword('text')
        }
    }

    const gotoTop = event => {
        hideCart();
        window.scrollTo(0, 0);
    };

    return (
        <Fragment>
            {/* <div className='content-user' style={{ backgroundImage: `url(${images_products_route + bgImageLoginRegister[0].image})` }}> */}
            <div className='login-register bg-options-repeat pt-md-5 pt-3 pb-5' style={{ backgroundImage: `url("/img/bg-login-register.jpg")` }}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-10 col-lg-6 col-xl-5'>
                            <form className='py-2 px-3 px-xl-4 white-color' onSubmit={onLoginSubmit}>
                                <h1 className='text-center carrefourMetis-bold mb-3 mb-lg-5 pt-lg-4 pt-2'>{getTextByKey('iniciar-sesion')}</h1>
                                <h4 className='text-center mb-lg-5 mb-3'>{getTextByKey('titulo-compra-rapido')}</h4>
                                {errors ? <div className='error-msg'>{errors}</div> : null}
                                <div className='container px-lg-5 px-2'>
                                    <div className='form-group mb-3'>
                                        <label>{getTextByKey('correo_electronico_form')}*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className='p-2 white-border-color'
                                            value={email}
                                            onChange={onLoginInputChange}
                                        />
                                    </div>
                                    <div className='form-group mb-3 position-relative'>
                                            <label>{getTextByKey('contrasena_form')}*</label>
                                            <input
                                                type={typePassword}
                                                className='p-2'
                                                name="password"
                                                onChange={onLoginInputChange}
                                                value={password}
                                            />
                                            <a className="btn-show-pass" onClick={showPass}>
                                                <Icon icon="bi:eye-slash" />
                                            </a>
                                            {/* <small className="mt-1 text-uppercase text-dark link-reset-password w-100 text-end">{getTextByKey("help-password-conditions")}</small> */}
                                    </div>
                                    {/* <div className='form-group mb-5'>
                                        <label className="container-check-s">
                                            <small>Recordarme</small>
                                            <input
                                                type="checkbox"
                                                name="recordarme"
                                            />
                                            <span className="checkmark-s white-border-color">
                                                <img src="/img/SVG/check.svg" />
                                            </span>
                                        </label>
                                    </div> */}
                                    <div className='form-group text-center pt-lg-5 pt-3'>
                                        <button type="submit" className='btn-g py-2 px-5' disabled={loading}>{loading ? `Autenticando...` : `${getTextByKey('iniciar-sesion')}`}</button>
                                    </div>
                                    <div className='mt-3 text-center'>
                                        <Link to="/reset-password"><p className='white-color md-p'>{getTextByKey('olvidar-contrasena')}</p></Link>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <p className='white-color md-p'>
                                            <span>{getTextByKey('no-tienes-cuenta')}</span>
                                            <Link to="/register" className='ms-2 text-underline white-color' onClick={gotoTop}>{getTextByKey('registrate')}</Link>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}
