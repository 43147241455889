import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGifLoader from "./components/ReactGifLoader";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import './scss/var.scss';
import './scss/mixin.scss';
import './App.scss';
import './scss/breackpoint_only_mobile.scss';
import './scss/breackpoint_sm.scss';
import './scss/breackpoint_md.scss';
import './scss/breackpoint_lg.scss';
import './scss/breackpoint_only_tablet.scss';
import './scss/breackpoint_xl.scss';
import './scss/breackpoint_only_hd.scss';
import './scss/breackpoint_huge.scss';

import HomePage from "./pages/HomePage/HomePage";
import BasePage from "./pages/HomePage/BasePage";
import RecipesPage from "./pages/RecipesPage/RecipesPage";
import RecipePage from "./pages/RecipesPage/RecipePage";
import BlogPage from "./pages/BlogPage/BlogPage";
import AboutSingularBlogPage from "./pages/BlogPage/AboutSingularBlogPage";
import PointsSalePage from "./pages/PointsSalePage/PointsSalePage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import {CheckoutaPage} from "./pages/CheckoutPage/CheckoutPage";
import CheckoutbPage from "./pages/CheckoutPage/CheckoutbPage";
import CheckoutcPage from "./pages/CheckoutPage/CheckoutcPage";
import CheckoutFastPage from "./pages/CheckoutPage/CheckoutFastPage";
import ModaconscientePage from "./pages/ModaconscientePage/ModaconscientePage";
import AcercaPage from "./pages/AcercaPage/AcercaPage";
import SocialResponsabilityPage from "./pages/AcercaPage/SocialResponsabilityPage";
import DetallescuentaPage from "./pages/PerfilPage/DetallescuentaPage";
import MetodospagoPage from "./pages/PerfilPage/MetodospagoPage";
import DireccionesPage from "./pages/PerfilPage/DireccionesPage";
import NuevaDireccionPage from "./pages/PerfilPage/NuevaDireccionPage";
import FeedbackaPage from "./pages/FeedbackPage/FeedbackaPage";
import TarjetaaPage from "./pages/TarjetaregaloPage/TarjetaaPage";
import TarjetabPage from "./pages/TarjetaregaloPage/TarjetabPage";
import TarjetacPage from "./pages/TarjetaregaloPage/TarjetacPage";
import TarjetadPage from "./pages/TarjetaregaloPage/TarjetadPage";
import ContactoPage from "./pages/ContactoPage/ContactoPage";
import PreguntasPage from "./pages/PqrsPage/PreguntasPage";
import EnviosPage from "./pages/PqrsPage/EnviosPage";
import PoliticacomprasPage from "./pages/PqrsPage/PoliticacomprasPage";
import AvisoprivacidadPage from "./pages/PqrsPage/AvisoprivacidadPage";
import PoliticadatosPage from "./pages/PqrsPage/PoliticadatosPage";
import PoliticacambiosPage from "./pages/PqrsPage/PoliticacambiosPage";
import PoliticaRetractoPage from "./pages/PqrsPage/PoliticaRetractoPage";
import PoliticaReversionPagoPage from "./pages/PqrsPage/PoliticaReversionPagoPage";
import SeguridadPage from "./pages/PqrsPage/SeguridadPage";
import DerechosDeberesPage from "./pages/PqrsPage/DerechosDeberesPage";
import ManualPage from "./pages/PqrsPage/ManualPage";
import ProgramaTransparenciaPage from "./pages/PqrsPage/ProgramaTransparenciaPage";
import FormulariocontactoPage from "./pages/PqrsPage/FormulariocontactoPage";
import FeedbacksliderPage from "./pages/FeedbackPage/FeedbacksliderPage";
import ColeccionPage from "./pages/ShopPage/ColeccionPage";
import CombinacionesPage from "./pages/ShopPage/CombinacionesPage";
import CombinacionPage from "./pages/ShopPage/CombinacionPage";
import OfertasPage from "./pages/ShopPage/OfertasPage";
import FindsizePage from "./pages/FindsizePage/FindsizePage";
import PedidosPage from "./pages/PerfilPage/PedidosPage";
import FavoritosPage from "./pages/PerfilPage/FavoritosPage";
import NftPage from "./pages/NftPage/NftPage";
import NewsletterPage from "./pages/NewsPage/NewsletterPage";
import CompraPage from "./pages/FeedbackPage/CompraPage";
import BuscadorComponent from "./components/BuscadorComponent";
import WomenPage from "./pages/ShidoWomenPage/WomenPage";
import PagoExitosoPage from "./pages/CheckoutPage/PagoExitosoPage";
import LoginPage from "./pages/UserPage/LoginPage";
import RegisterPage from "./pages/UserPage/RegisterPage";
import { AuthProvider } from "./authContext";
import { CartProvider } from "./context/cartContext";
import { ApiProvider } from "./context/apiContext";
import PreorderPage from "./pages/HomePage/PreorderPage";
import ResetPassPage from "./pages/UserPage/ResetPassPage";
import Error404Page from "./pages/404Page/404Page";
import SatisfactionSurveyPage from "./pages/SatisfactionSurveyPage/SatisfactionSurveyPage";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import { RoutesList } from "./RoutesList";
import Modals from "./components/Modals";


export function App(){

    const isPreorder = window.location.pathname.includes('/preorder');

    const paddinTop = event => {
        if (isPreorder) {
            const element = document.getElementById('main');
            element.classList.add('no-padding');
        }
    };

    const scrollTop = () => {
        window.scroll(0, 0);
    }

    return (
        <Fragment>
            <AuthProvider>
            <CartProvider>
            <ApiProvider>
            <BrowserRouter>
                {!isPreorder && <Header/>}
                <div className="main" id="main" onLoad={paddinTop}>
                    <RoutesList />
                </div>
                <ReactGifLoader />
                <Modals />
                {!isPreorder &&<Footer/>}
            </BrowserRouter>
            </ApiProvider>
            </CartProvider>
            </AuthProvider>
        </Fragment>
    );
}