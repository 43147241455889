import React, { Fragment } from 'react'
import { Icon } from '@iconify/react';
import '../scss/buscador.scss'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getProductsSearch, getRecommendedHome, images_products_route } from '../api/productApi';
import { getGallery } from '../api/galleryApi';
import { useApi } from '../context/apiContext';
import { getSearchResult } from '../api/searchApi';



export default function BuscadorComponent({classProp, classIcon}) {

    const [loading, setLoading] = useState(false);
    const [showing, setShowing] = useState(false);
    const [popular, setPopular] = useState(true);
    const [products, setProducts] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const { currentLocale, getTextByKey } = useApi();


    useEffect (() => {
        setLoading(true);
        /*getRecommendedHome().then((data) => {
            setProducts(data);
            setLoading(false);
        });*/
    }, []);

    const searchProducts = async (e) => {
        setLoading(true);
        const search = e.target.value;
        setSearch(e.target.value);

        if( search.length > 2 ) {
            const resultsSearch = await getSearchResult( search, 'es' );
            if( resultsSearch ) {
                setProducts(resultsSearch.products.slice(0, 3));
                setRecipes(resultsSearch.recipes.slice(0, 3));
                if (resultsSearch.products.length || resultsSearch.recipes.length) {
                    setShowMore(true);
                }
            } else {
                setProducts( [] );
                setRecipes([]);
                setShowMore(false);
            }
            setPopular(false);
            setLoading(false);
        } else {
            const resultHome = await getRecommendedHome();
            setProducts(resultHome);
            setPopular(true);
            setLoading(false);
        }
    }

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const clearSearch = () => {
        setSearchQuery("");
        navigate(`/shop`);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    const onSearch = () => {
        navigate(`/busqueda?query=${encodeURI(search)}`);
    }

    const [logo, setLogo] =  useState([]);

    useEffect(() => {
        getGallery("logo-img").then((data) => {
            setLogo(data);
        });
    }, []);

    return (
        <Fragment>
                <div className={classProp}>
                    {searchQuery  && (
                            <a onClick={clearSearch} className={`py-1 px-2 ${classIcon}`}>
                                <Icon icon="tabler:arrow-back"></Icon>
                            </a>
                    ) }
                    <input 
                        type="text" 
                        className="px-3 input-search" 
                        placeholder={getTextByKey("buscar")} 
                        onKeyUp={searchProducts} 
                        onKeyPress={handleKeyPress}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    
                    <a onClick={onSearch} className={`py-1 px-2 icon-search ${classIcon}`}>
                        <Icon icon="bx:bx-search" />
                    </a>
                   
                    
                    {/* <div id="myDropdown" className="dropdown-content">
                        {
                            popular && (
                                <>
                                    <strong>{getTextByKey("lo_mas_buscado")}</strong>
                                    <ul>
                                        { products.length > 0 ? products.map((product) => (
                                            <li key={product.plu}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>))
                                            : <li key='-1'>
                                                <a>{getTextByKey("no_results")}</a>
                                            </li>
                                        }
                                    </ul>
                                </>
                            )
                        }
                        {
                            !popular && (
                                <>
                                    <strong>{getTextByKey("productos")}</strong>
                                    <ul>
                                        { products.length > 0 ? products.map((product) => (
                                            <li key={product.plu}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>))
                                            : <li key='-1'>
                                                <a>{getTextByKey("no_results")}</a>
                                            </li>
                                        }
                                    </ul>
                                    <strong>{getTextByKey("recetas")}</strong>
                                    <ul>
                                        { recipes.length > 0 ? recipes.map((recipe) => (
                                            <li key={recipe.id}>
                                                <Link to={`/recipe/${recipe.id}`}>
                                                    {recipe.title}
                                                </Link>
                                            </li>))
                                            : <li key='-1'>
                                                <a>{getTextByKey("no_results")}</a>
                                            </li>
                                        }
                                    </ul>
                                </>
                            )
                        }
                    </div> */}
                </div>
            {/* <div className='buscador' id="buscador">
                <div className='cols'>
                    <form>
                        <button><Icon icon="bx:bx-search" /></button>
                        <input type="search" placeholder='SEARCH' name='query' id="query" onKeyUp={searchProducts}></input>
                    </form>
                    {loading ? (
                        <div className='container-load-search'>
                            <div className="text-center-c">
                                <div className="loader-box">
                                    <img src="/img/SVG/loader.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {popular ? (
                                <div className='p-4' >
                                    <h3 className='fw-bold text-uppercase'>POPULARES</h3>
                                    <ul>
                                        {products.map((product, index) => (
                                            <li key={index} onClick={closeSearch}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className='p-4'>
                                    <h3 className='fw-bold text-uppercase'>RESULTADOS</h3>
                                    <ul>
                                        { products.length > 0 ? products.map((product, index) => (
                                            <li key={index} onClick={closeSearch}>
                                                <Link to={`/product/${product.plu}`}>
                                                    {product.name.nombre}
                                                </Link>
                                            </li>))
                                            : <li>
                                                <a>{currentLocale === 'es' ? 'Sin resultados' : 'Not results'}</a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            )}
                        </>

                    )}
                    <a className='close'><Icon icon="ep:close-bold" onClick={closeSearch} /></a>
                </div>
            </div> */}
        </Fragment>
    )
}
