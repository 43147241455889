import React, { Fragment } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import { useApi } from '../../../context/apiContext';

export default function ModalComponent({email, showModal, setShowModal}) {
    const { getTextByKey } = useApi();

    const handleClose = () => {
        setShowModal(false);
        location.href='/';
    }
    const goto=()=>{
      setShowModal(false);
      location.href='/';
    }

    const closeModal = ()=>{
        setShowModal(false);
    }
  return (
    <Fragment>
         <Modal className="modal" show={showModal} onHide={handleClose}>
            <Modal.Body>
                 <div className="modal-content px-5">
                    <div className="modal-header justify-content-center">
                        <h3 className="w-100 modal-title text-center mb-0" id="ModalConfirmationLabel">{getTextByKey('contrasena_restablecida')}</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-5">
                        <form>
                            <div className="form-group mb-3 text-center">
                                <label>{getTextByKey('texto-recibir-link-password')}</label>
                            </div>
                            <div className="form-group mb-3 text-center">
                                <label>{email}</label>
                            </div>
                            <div className="form-group mb-2 text-center">
                                <Link onClick={goto} className="btn-g py-2 px-4 text-uppercase">{getTextByKey('volver-inicio')}</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>   
    </Fragment>
  )
}
