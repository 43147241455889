import { Fragment } from "react";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import { useApi } from "../../context/apiContext";
import { images_products_route } from "../../api/productApi";
import { getGallery } from "../../api/galleryApi";
import { getImages } from "../../api/imageApi";
import { Icon } from "@iconify/react";
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import Picture from "../../components/Picture";
import '../../scss/about_page.scss';



export default function AcercaPage() {

    const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
    const FadeUp = batch(Fade(), Move(), Sticky());
    const { getTextByKey, getTextBigByKey, getPropiertyByLocale } = useApi();

    const [content, setContent] = useState([]);
    const [ rotador, setRotador ] = useState([]);
    const [ fichas, setFichas ] = useState([]);
    const [ rotador2, setRotador2 ] = useState([]);
    const [ logoVision, setLogoVision ] = useState([]);
    const [ logoMision, setLogoMision ] = useState([]);

    useEffect(() => {
      getGallery("about-shido-content").then((data) => {
        setContent(data[0]);
      });
      getImages("logo_vision").then((data) => {
        setLogoVision(data[0]);
      });
      getImages("logo_mision").then((data) => {
        setLogoMision(data[0]);
      });
      
    }, []);

    useEffect(() => {
      getGallery("nosotros-rotador").then((data) => {
          setRotador(data);
      });
    },[]);

    useEffect(() => {
      getGallery("fichas-nosotros").then((data) => {
          setFichas(data);
      });
    },[]);

    useEffect(() => {
      getGallery('rotador-nutriendo-futuro').then((data)=>{
        setRotador2(data);
      })
    }, [])
    


  return (
    <Fragment>
      <div className="about">
        <div className="banner-about mb-lg-5 mb-3">
          <div className="container-fluid gx-0">
              <div className="row gx-0">
                  <div className="col-12 col-lg-6">
                      <div className="p-4 p-sm-5 text-center text-lg-start">
                          <h1 className="primary-color fw-bold"><span className="text-uppercase">{getTextByKey('name')} </span><br /> {getTextByKey('nosotros-titulo')}</h1>
                          <div className="text-nosotros" dangerouslySetInnerHTML={{ __html: getTextBigByKey("nosotros-texto") }}></div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-6">
                      <Picture src={content.image} alt={"Img nosotros Cervalle"} classImg={"w-100 img-about"} />
                  </div>
              </div>
          </div>
        </div>

        <div className="mission-vision py-4">
                <div className="container">
                    <div className="px-xl-5">
                        <div className="row">
                            <div className="col-12 col-md-5 mb-4 px-md-5 px-3 mision-vision-text">
                                <div className="d-flex">
                                    <div className="me-3">
                                    {logoMision && <Picture src={logoMision.image} alt={"icon"} classImg={"icon"} />}
                                    </div>
                                    <div className="align-self-end">
                                        <h3 className="gray-1-color mb-0 carrefour-sans-bold">{getTextByKey('mision')}</h3>
                                    </div>
                                </div>
                                <div className="mt-3" dangerouslySetInnerHTML={{ __html: getTextBigByKey("mision-texto") }}></div>
                            </div>
                            <div className="col-12 col-md-5 mb-4 px-md-5 px-3 mision-vision-text">
                                <div className="d-flex">
                                    <div className="me-3">
                                    {logoVision && <Picture src={logoVision.image} alt={"icon"} classImg= {"icon"} />}
                                    </div>
                                    <div className="align-self-end">
                                        <h3 className="gray-1-color mb-0 carrefour-sans-bold">{getTextByKey('vision')}</h3>
                                    </div>
                                </div>
                                <div className="mt-3" dangerouslySetInnerHTML={{ __html: getTextBigByKey("vision-texto") }}></div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div className="about-slides rotador1 text-center mb-5">
          <div className="container px-lg-5 px-3">
          <Swiper modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
                  navigation
                  pagination={{clickable: true}}
                  autoplay={{ delay: 6000, disableOnInteraction: true }}
                  effect={ "coverflow" }
                  centeredSlides={ true }
                  coverflowEffect={{ rotate: 0, stretch: -180, depth: 600, modifier: 1, slideShadows: false }}
                  breakpoints={{
                      320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                  }}>
                {rotador && rotador.map((rotador, index)=>(
                  <SwiperSlide key={index}>
                    <div>
                      <Picture src={rotador.image} alt={"About slide item"}  classImg={"img-rotador"}/>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        <div className="container">
          <div className="text-center pt-5 pb-3">
            <h3 className="primary-color carrefour-sans-bold">{getTextByKey('produccion_tecnificada')}</h3>
            <div className="no-margin" dangerouslySetInnerHTML={{ __html: getTextBigByKey("produccion-tecnificada-texto") }}></div>
          </div>
          <div className="only-mobile features-about-mobile px-3 pb-5 pt-3">
            <Swiper modules={[Pagination, Autoplay]}
                  pagination={{clickable: true}}
                  autoHeight={true}
                  autoplay={{ delay: 6000, disableOnInteraction: true }}
                  centeredSlides={ true }
                  breakpoints={{
                      320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                      },
                  }}>
                { fichas &&
                  fichas.map((ficha, index)=>(
                    <SwiperSlide key={index}>
                      <div className="col-12 col-md-6 col-lg-4 p-2" key={index}>
                      <div className="singular-feature-about" >
                        <div className="content-visible text-center pink-5-bg-color p-2">
                            <Picture src={ficha.image} alt={"Feature about icon"} classImg={"icon"} />
                            <h3 className="primary-color fw-bold mt-3">{getPropiertyByLocale(ficha.names, "titulo")}</h3>
                        </div>
                        <div className="text-center py-3" dangerouslySetInnerHTML={{__html: getPropiertyByLocale(ficha.names, "descripcion")}}></div>
                      </div>
                    </div>
                    </SwiperSlide>
                  ))
                }
            </Swiper>
          </div>

        </div>
   

        <div className="my-5 features-about only-desk">
          <div className="container px-5">
            <div className="row px-5">
             
                { fichas &&
                  fichas.map((ficha, index)=>(
                    <div className="col-12 col-md-6 col-lg-4 p-2" key={index}>
                      <div className="singular-feature-about" >
                        <div className="content-visible">
                            <Picture src={ficha.image} alt={"Feature about icon"} classImg={"icon"} />
                          <h3 className="primary-color fw-bold mt-3">{getPropiertyByLocale(ficha.names, "titulo")}</h3>
                        </div>
                        <div className="content-hide">
                          <h3 className="primary-color fw-bold mb-4">{getPropiertyByLocale(ficha.names, "titulo")}</h3>
                          <div className="text-info" dangerouslySetInnerHTML={{__html: getPropiertyByLocale(ficha.names, "descripcion")}}></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
            </div>
          </div>
        </div>

   

        <section className="video-about">
          <div className="container">
            <h3 className="primary-color text-center carrefour-sans-bold mb-4">{getTextByKey('video-institucional')}</h3>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <iframe className="video" src={getTextByKey("nosotros_video_url") != '...' ? getTextByKey("nosotros_video_url") : ""} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="nurturing-future pt-5 pb-3 my-5">
          <div className="container px-lg-5 px-0">
              <h2 className="primary-color text-center fw-bold mb-4">{getTextByKey("nutriendo_futuro")}</h2>
              <div className="row">
                <div className="col-12 col-md-6 px-5">
                  <div className="gray-1-color text-center text-lg-start" dangerouslySetInnerHTML={{__html: getTextBigByKey("nutriendo_futuro_parrafo_1")}}></div>
                </div>
                <div className="col-12 col-md-6 px-5">
                  <div className="gray-1-color text-center text-lg-start" dangerouslySetInnerHTML={{__html: getTextBigByKey("nutriendo_futuro_parrafo_2")}}></div>
                </div>
              </div>
          </div>
          <div className="about-slides rotador-2 text-center my-5 px-lg-5 px-0">
            <div className="container">
              <Swiper modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
                    navigation
                    pagination={{clickable: true}}
                    autoplay={{ delay: 6000, disableOnInteraction: true }}
                    effect={ "coverflow" }
                    centeredSlides={ true }
                    coverflowEffect={{ rotate: 0, stretch: -180, depth: 600, modifier: 1, slideShadows: false }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        1024:{
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}>
                    { rotador2 && rotador2.map((rotador2, index)=>(
                        <SwiperSlide key={index}>
                          <div className="text-center">
                            <Picture src={rotador2.image} alt={"About slide item"} classImg={"img-rotador w-100"}/>
                            <div className="txt-content mt-5">
                              <Link to="/nuestro-programa" className="btn-g btn-rotador py-2 px-2 px-xl-5">
                                <span>{getTextByKey('boton-nutriendo')}</span>
                                <Icon className="ms-2 mb-1" icon="teenyicons:arrow-right-solid"/>
                              </Link>
                            </div>
                          </div>
                          
                      </SwiperSlide>
                    ))
                  }
              </Swiper>
            </div>
          </div>
        </section>


      </div>

    </Fragment>
  )
}
