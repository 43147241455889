import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import DropMenuComponent from "./DropMenuComponent";
import { Icon } from '@iconify/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import OpctionsHeaderComponent from "./OpctionsHeaderComponent";
import CarritoComponent from "./CarritoComponent";
import { useApi } from "../context/apiContext";
import { useAuth } from "../authContext";
import { getImages } from "../api/imageApi";
import { useCart } from "../context/cartContext";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BuscadorComponent from "./BuscadorComponent";
import Picture  from './Picture';
import Geolocalizacion from "./Geolocalizacion";
import ModalAlertaPeso from "./ModalAlertaPeso";
import Dropdown from 'react-bootstrap/Dropdown';

export function Header(){

		const { currentUser } = useAuth();
		const { hideCart, currentDelivery } = useCart();
    const { getTextByKey,currentCategories, currentSubcategories, extractNameFromLocate, getPropiertyByLocale} = useApi();

    const openDrop = event => {
        const drop = document.getElementById('drop-menu');
        drop.classList.toggle('show');
    };

		const [ categorias, setCategorias ] = useState([]);
		const [ subcategorias, setSubcategorias ] = useState([]);
    const [logo, setLogo] =  useState([]);
    const [ pse, setPse ] = useState([]);
		const [ toggle, setToggle ] = useState(false);

    const closeDrop = event => {
        window.scrollTo(0, 0);
        const drop = document.getElementById('drop-menu');
        drop.classList.remove('show');
        window.scrollTo(0, 0);
        hideCart();
    };

    const BackOptp = event => { 
        const opt = document.querySelectorAll('.opt');
        const opt2 = document.querySelectorAll('.opt2');
        const optp = document.querySelectorAll('.opt-p');
        optp.forEach((element) => {
            element.classList.add('show');
        });
        opt.forEach((element) => {
            element.classList.remove('show');
        });
        opt2.forEach((element) => {
            element.classList.remove('show');
        });
    }

    const showMenu = event => {
        const menu = document.getElementById('menu-mobile');
        menu.classList.toggle('show');
    }

    const closeMenu = event => {
        const menu = document.getElementById('menu-mobile');
        menu.classList.remove('show');
    }


    useEffect(() => {
                currentCategories.sort((a,b)=>a?.ordenmenu-b?.ordenmenu); 
              	setCategorias(currentCategories);
				setSubcategorias(currentSubcategories);
        getImages("logo").then((data) => {
            setLogo(data);
        });
        getImages("pse").then((data) => {
            setPse(data);
        });
				currentSubcategories.map((item)=> item.parent = item.categorias[0].id ? item.categorias[0].id : 0 );
    }, [currentCategories, currentSubcategories]);

    const getCategoriesFromParent = (id = 0) => {
        if(id == 0) return [];
        const subs=subcategorias.filter((f)=> f.parent == id);
        subs.sort((a ,b)=> a.orden -b.orden);
       
        return subs;
    }

    const gotoTop = event => {
        hideCart();
        window.scrollTo(0, 0);
    };


    const [overlayVisible, setOverlayVisible] = useState(false);

    const closeCart = event => {
        hideCart();
        const overlayNoVisible = document.getElementById('overlay-no-visible');
        overlayNoVisible.classList.remove('show-overlay-no-visible');
    };

    const toggleCarrito = () => {
        setOverlayVisible(!overlayVisible);
        console.log('toggleCarrito',);
    };

    return(
        <header id="header">
            <div className={`overlay-no-visible ${overlayVisible ? 'visible' : ''}`} onClick={closeCart} id="overlay-no-visible"></div>
						<ModalAlertaPeso />
            <div className="d-flex align-items-center justify-content-center">
                <p className="md-p mb-0 mx-2 source-sans-bold">{getTextByKey("linea_sabor")}</p>
                <a target="_blank" href={`https://api.whatsapp.com/send?phone=+57${getTextByKey("numero-whatsapp")}&text=${getTextByKey('texto-adicional-whatsapp')}`} className="icon-info-banner mb-2">
                    <Icon className="green-wp-color" icon="ic:baseline-whatsapp"/>
                </a>
                <a href={`tel:${getTextByKey("numero")}`} className="icon-info-banner mx-1 mb-2">
                    <Icon icon="ph:phone" className="blue-color"/>
                </a>
                <a href={`tel:${getTextByKey("numero")}`} className="icon-info-banner mx-1">
                    <h4 className="primary-color source-sans-bold mb-0">{getTextByKey('numero')}</h4>
                </a>
                
            </div>
            <div className="primary-bg-color header-2 p-2 d-flex align-items-center">
                <div onClick={openDrop} className="icon-menu d-lg-block d-none mx-5 cursor-pointer">
                    <Icon className="white-color icon" icon="ic:baseline-menu"/>
                </div>
                <div className='wp-call-menu d-lg-none'>
                    <a className="white-color call-menu" onClick={showMenu}>
                        <Icon icon="ic:baseline-menu" />
                    </a>
                </div>
								<Geolocalizacion />
          
								<div className="search-box-container">
										<BuscadorComponent 
											classProp={`search-box white-bg-color rounded position-relative d-flex align-items-center mb-1 overflow-hidden`}
											classIcon={`primary-color rounded secundary-bg-color`}
										/>
								</div>

                {	pse && pse[0] &&
									<div>
										{ pse[0].link ? (
											<a href={pse[0].link} target="_blank" className="d-md-flex align-items-center">
												{/*<Picture src={pse[0].image} alt="pse logo"  classImg={'logo-pse'}/>*/}
												<p className="pago-pse white-color mb-0 sm-p d-md-block d-none text-uppercase text-nowrap">{getTextByKey("pago_clientes")}</p>
											</a>
											) : (
												<a className="d-md-flex align-items-center">
													{/*<Picture src={pse[0].image} alt="pse logo"  classImg={'logo-pse'}/>*/}
													<p className="pago-pse white-color mb-0 sm-p d-md-block d-none text-uppercase text-nowrap">{getTextByKey("pago_clientes")}</p>
												</a>
											)
										}
									</div>
                                    }
            </div>
            <div className="options-mobile container-fluid d-flex justify-content-between align-items-center py-md-3 secundary-bg-color">
                <NavLink to="/" className="py-2 py-lg-0 mx-xl-5 mx-2 px-md-0 px-0">
                    {logo.length > 0 &&
										 <Picture classImg={"logo"} src={logo[0].image} alt={"Logo"} />
										 }
                   
                </NavLink>
                <ul className="menu desk d-lg-flex justify-content-center align-items-center p-0 py-md-3 py-lg-0">
                    <li>
                        <NavLink to="/shop/categoria/subcategoria/normal" className="py-1 px-2 py-md-2 px-md-2 px-xl-4 gray-1-color source-sans" onClick={gotoTop}>
                            {getTextByKey("menu_tienda")}
                        </NavLink>
                        </li>
                    {categorias.filter(category => category.menu).map((category, index) => (
                        <li key={index} className="dropdown">
                        <OverlayTrigger placement="top" delay={{show:4000,hide:400}} overlay={<Tooltip className="bg-transparent" >{getPropiertyByLocale(category.resumen, "resumen")}</Tooltip>}>
                            <div>
                            {	getCategoriesFromParent(category.id).length > 0 ?
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle  id="dropdown-basic">
                                            <div className="py-1 px-2 py-md-2 px-xl-3 gray-1-color d-flex align-items-center source-sans">
                                                <Link to={`shop/${category.slug}/subcategoria/normal`} onClick={gotoTop}>
                                                    <span>{extractNameFromLocate(category)}</span>
                                                </Link>
                                                <Icon icon="material-symbols:keyboard-arrow-down"></Icon> 
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            { getCategoriesFromParent(category.id).map((subitem, subkey) =>(
                                                <Dropdown.Item key={subkey}>
                                                    <Link to={`shop/${category.slug}/${subitem.slug}/normal`} className="dropdown-item gray-1-color" onClick={gotoTop}>
                                                        {getPropiertyByLocale(subitem.names, "nombre")}
                                                    </Link>
                                                </Dropdown.Item>
                                                ))	
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                                    :
                                <NavLink to={`shop/${category.slug}/subcategoria/normal`} onClick={gotoTop}
                                    className="py-1 px-2 py-md-2 px-xl-3 gray-1-color d-flex align-items-center source-sans">
                                    <span>{extractNameFromLocate(category)}</span>    
                                </NavLink>
                                }
                                </div>
                        </OverlayTrigger>
                    
                    
                    </li>
                    ))}          
                </ul>

                <OpctionsHeaderComponent/>
                <DropMenuComponent/>
                <CarritoComponent toggleCarrito={toggleCarrito}/>
  

                {/* <a className="p-2 primary-color call-menu" onClick={showMenu}>
                    <Icon icon="dashicons:menu" />
                </a> */}

                <div className="menu-mobile p-4 d-lg-none" id="menu-mobile">
                    
                    <div className="option d-flex align-items-center justify-content-between py-3">
                        <a className="back opt white-color" onClick={BackOptp}><Icon icon="grommet-icons:form-previous"/></a>
                        <a className="close white-color" onClick={closeMenu}><Icon icon="bi:x-lg"/></a>
                    </div>
                    <div className="content-opt">
                        <div className="pe-2">
                            <div className="py-2 opt-p show">
                                <ul className="ps-2">
																	<li><NavLink to="/shop" onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">{getTextByKey("tienda")}</NavLink></li>
																	{categorias.filter(category => category.menu).map((category, index) => (
																		<li key={index}>
																			<div className="d-flex justify-content-between align-items-center">
																				<NavLink to={`shop/${category.slug}/subcategoria/normal`} onClick={closeMenu} className="py-2 px-2 py-md-2 px-md-2 px-xl-4 primary-color">
																					{extractNameFromLocate(category)}
																				</NavLink>
																				{	getCategoriesFromParent(category.id).length > 0 &&
																					<button
																					data-bs-toggle="collapse" href={`#cat${category.id}`} role="button" aria-expanded="false" aria-controls={category.id}
																					onClick={()=>{
																						setToggle(!toggle)
																					}}>
																						<Icon className="white-color" icon={ !toggle ? `ic:twotone-keyboard-arrow-down` : `ic:twotone-keyboard-arrow-up` }/>
																					</button>
																				}
																			</div>
																		
																			<div className="collapse" id={`cat${category.id}`}>
																				<ul className="ps-4">
																				{ getCategoriesFromParent(category.id).map((subitem, subkey) =>(
																					<li className="mb-1 d-block" key={subkey}>
																						<NavLink to={`shop/${category.slug}/${subitem.slug}/normal`} onClick={closeMenu} className="gray-1-color">
																							{getPropiertyByLocale(subitem.names, "nombre")}
																						</NavLink>
																					</li>
																					))	
																				}	
																				</ul>
																			</div>
																		
																		</li>
																	))}
                                  
                                </ul>
                                <hr className="white-color" />
                                <ul className='py-3 mt-3 ps-2'>
                                    <li>
                                        <NavLink to="/recetas" onClick={closeMenu} className="mb-1 gray-1-color">
                                            <div className='d-flex align-items-center'><Icon className="icon" icon="tabler:chef-hat"/>{getTextByKey("recetas")}</div>
                                        </NavLink>
                                    </li>
                                    <li><NavLink to="/blogs" onClick={closeMenu} className="mb-1 gray-1-color"><div className='d-flex aling-items-center'><Icon className="icon" icon="material-symbols:edit-note-sharp"/>{getTextByKey("blog")}</div></NavLink></li>
                                    <li><NavLink to="/nosotros" onClick={closeMenu} className="mb-1 gray-1-color"><div className='d-flex aling-items-center'><Icon className="icon" icon="ic:round-star-outline"/>{getTextByKey("nosotros")}</div></NavLink></li>
                                    <li><NavLink to="/contacto" onClick={closeMenu} className="mb-1 gray-1-color"><div className='d-flex aling-items-center'><Icon className="icon" icon="fluent:call-12-regular"/>{getTextByKey("contacto")}</div></NavLink></li>
                                    <li><NavLink to='/puntos-venta' onClick={closeMenu} className="mb-1 gray-1-color"><div className='d-flex aling-items-center'><Icon className="icon" icon="ph:map-pin"/>{getTextByKey("puntos-venta")}</div></NavLink></li>
                                    { pse && pse[0] &&
                                        <li> 
                                            { 
                                                pse[0].link ? (
                                                    <a href={pse[0].link} target="_blank" onClick={closeMenu} className="mb-1 gray-1-color">
                                                        <div className='d-flex aling-items-center'>
                                                               {/* <Picture classImg={'img-pse'} src={pse[0].image} alt={'logo pse'} />*/}{getTextByKey("pago_clientes")}
                                                        </div>
                                                </a>
                                                ) : (
                                                    <a onClick={closeMenu} className="mb-1 gray-1-color">
                                                        <div className='d-flex aling-items-center'>
                                                                {/*<Picture classImg={'img-pse'} src={pse[0].image} alt={'logo pse'} />*/}{getTextByKey("pago_clientes")}
                                                        </div>
                                                    </a>
                                                )
                                            }
                                    	</li>
                                    }
                     
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className='social'>
                        <a className='mx-3 me-xl-2' href={"https://tiktok.com/" + getTextByKey("tiktok-user")} target='_blank'>
                            <Icon className="white-color" icon='ant-design:facebook-outlined' />
                        </a>
                        <a className='mx-3 me-xl-2' href={"https://instagram.com/" + getTextByKey("instagram-user")} target='_blank'>
                            <Icon className="white-color" icon='akar-icons:instagram-fill' />
                        </a>
            
                        <a className='mx-3 me-xl-2 youtube' href={getTextByKey("spotify-user")} target='_blank'>
                            <Icon className="white-color" icon='ant-design:youtube-outlined' />
                        </a>
                    </div>
                </div>
            </div>
      
        </header>
    );
}