import React, { useState } from 'react'
import { Fragment } from 'react'
import { Icon } from '@iconify/react';
import { useApi } from "../../../context/apiContext";
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useCart } from '../../../context/cartContext';

export default function CheckDropdownComponent(props) {

    const { getTextByKey, currentCategories, currentSubcategories, currentSizes, currentColors, currentLines, currentActivities, getPropiertyByLocale } = useApi();
    const [filters, setFilters] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [subcategories, setSubCategories] = React.useState([]);
		const [ isChecked, setIsChecked ] = useState('');
    const params = useParams();
		const category = categories.find((item) => item.id === parseInt(params.categoria));
		const { currentDelivery } = useCart();

	

    const location = useLocation();

    var filtersorder = [
        {
            id: 1,
            nameFilter: 'ordenar',
            list: [
								{'option':'Aleatorio', key: 'aleatorio'},
                {'option': 'Lo más vendidos', key: 'sellers'},
                {'option': 'Clasificación media', key: 'media'},
                {'option': 'Últimos', key: 'ultimos'},
                { 'option': 'Precio: bajo a alto', key: 'price_asc' },
                { 'option': 'Precio: alto a bajo', key: 'price_desc' },
            ]
        }
    ]

    const onChangeRadio = (id) => { 
			console.log('RADIO', id);
			setIsChecked(id);
		};
		

		const handleClick = (event) => {
			console.log('radio', event.target.value);
		}
        
    const generateQueryLink = (id, key) => {
        let query = "";
        switch (key) {
            case "categoria":
                query = `/${id}/${params.subcategorias | 'categoria'}/normal`;
                break; 
            case "subcategorias":
                query = `/${params.categoria |  'categoria'}/${id}/normal`;
                break;
            case "tiendaId":
                query = `/${params.categoria | 'categoria'}/${params.subcategorias}/normal`;
                break;
            case "price_asc":
                query = `/${params.categoria ?? 'categoria'}/${params.subcategorias ?? 'subcategoria'}/price_asc`;
                break;
            case "price_desc":
                query = `/${params.categoria ?? 'categoria'}/${params.subcategorias ?? 'subcategoria'}/price_desc`;
                break;
            case "aleatorio":
                query = `/${params.categoria | 'categoria'}/${params.subcategorias ?? 'subcategoria'}/aleatorio`;
                break;
            case "media":
                query = `/${params.categoria ?? 'categoria'}/${params.subcategorias ?? 'subcategoria'}/media`;
                break;
            case "sellers":
                query = `/${params.categoria ?? 'categoria'}/${params.subcategorias ?? 'subcategoria'}/sellers`;
                break;
            case "ultimos":
                query = `/${params.categoria ?? 'categoria'}/${params.subcategorias ?? 'subcategoria'}/ultimos`;
                break;
            default:
      				break;
        }
        return query; 
    }


    const getCategoriesFromParent = (id = 0) => {
        if(id == 0) return [];
        return subcategories.filter((f)=> f.parent == id);
    }

    useEffect(() => { 

        let getFilters = [];
        if (currentCategories) {
            let newFilters = {
                id: 1,
                key: "categoria",
                nameFilter: "Categoría",
                list: []
            };
            currentCategories.forEach((category) => {
                newFilters.list.push({
                    id: category.id,
                    name: category.names[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }
        if (currentSubcategories) {
            let newFilters = {
                id: 2,
                key: "subcategorias",
                nameFilter: "Subcategoría",
                list: []
            };
            currentSubcategories.forEach((subcategory) => {
                newFilters.list.push({
                    id: subcategory.id,
                    name: subcategory.names[0].nombre,
                });
            });
            getFilters.push(newFilters);
        }

        setFilters(getFilters);
        setCategories(currentCategories);
        currentSubcategories.map((item)=> item.parent = item.categorias[0].id ? item.categorias[0].id : 0 );
        setSubCategories(currentSubcategories);
    }, [currentCategories, currentSubcategories]);

    return (
        <Fragment>
            <div className='px-0'>

								{ category ?  <div className='form-group mb-3 mb-xl-0 me-xl-2' >
									<div className="item-filtro">
										<p className='gray-1-color source-sans-bold mb-1'>{getPropiertyByLocale(category.names, "nombre")}</p>
										<ul className='p-0'>
												{getCategoriesFromParent(category.id).map((subitem, subkey)=>(
													<li className='list-style-none' key={subkey}>
														<Link to={`/shop${generateQueryLink(subitem.id, 'subcategorias')}`}>
															<label className="rosa"> 
																	<input className='radio-rosa' type="radio" name={"name"} value={subitem.id} checked={params[subitem.key] == subitem.id} onClick={(event) => handleClick(event)} />
																	<p className='gray-1-color mb-0 ms-1 fw-300'>{getPropiertyByLocale(subitem.names, "nombre")}</p>
															</label>
															</Link>
													</li>
												))}
										</ul>
									</div>
                  
                </div> : ''}

            </div>
            <div className='px-0'>
                {
                    filtersorder && filtersorder.map((filter, index) => {
                        return (
                            <div className='form-group mb-3 mb-xl-0 me-xl-2' key={index}>
                                <div className="item-filtro" key={filter.id}>
                                <p className='gray-1-color mb-3 md-p'>Ordenar por:</p>
                                    <ul className="p-0">
        
                                        {filter.list.map((data, indexfilter) => {
                                            return (
                                                <li key={indexfilter} className="list-style-none mb-1">
                                                   
                                                    <Link to={`/shop${generateQueryLink(false, data.key)}`}>
                                                        <label className='d-flex align-items-center radio-button-container' onClick={()=>onChangeRadio(data.key)}>
                                                            <input type="radio" id={data.key} name={filter.nameFilter} checked={isChecked === data.key} value={data.key}  />
                                                            <p className='gray-1-color mb-0 ms-1 fw-300'>{data.option}</p>
										
                                                        </label>
                                                        
                                                    </Link>
                                                   
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}
