import sellyApi from "./sellyApi"


export const getPqrsCategories = async (locale) => {
    try {
        const { data } = await sellyApi.get(`/pqrs/types`, {params: {locale}});
        return data;
    } catch (error) {
        console.error('Error obteniendo categorias de pqrs', error);
    }
}

export const sendPqrs = async (formItems) => {
    const formData = new FormData();
    for(const formItem in formItems) {
        formData.append(formItem, formItems[formItem]);
    }
    try {
        const { data } = await sellyApi.postForm(`/pqrs/`, formData, {'Content-Type': 'multipart/form-data'});
        return data;
    } catch (error) {
        console.error('Error mandando pqrs', error);
        throw error;
    }
}