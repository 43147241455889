import React, { Fragment } from 'react';
import { useApi } from '../../../context/apiContext';
import { Icon } from '@iconify/react';
 
export function ModalGuardar() {
    const { getTextByKey } = useApi();
    
    return (
        <Fragment>
            <div className="modal fade modal-user" id="guardarModal" tabIndex="-1" aria-labelledby="guardarModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="text-end">
                        <Icon className='cursor-pointer gray-1-color close-modal' icon="material-symbols:close-rounded" data-bs-dismiss="modal"></Icon>
                        </div>
                        <div className="modal-body pt-4">
                          <div className="text-center pt-2">
                              <p className="carrefour-sans-bold mb-2 gray-1-color" id="guardarCuentaModal">{getTextByKey('guardar_cambios')}</p>
                              <p className='gray-1-color px-5 mb-0'>{getTextByKey('alerta_modal_guardar_cambios')}</p>
                        </div>
                          </div>
                          <div className="modal-footer justify-content-center border-0 pb-3">
                              <button type="button" className="btn-g bb py-2 px-4 px-xl-5 mx-xl-3" data-bs-dismiss="modal">{getTextByKey('cancelar')}</button>
                              <button type="button" className="btn-g py-2 px-4 px-xl-5 mx-xl-3">{getTextByKey('guardar')}</button>
                          </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}