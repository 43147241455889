import React, { useState, useEffect, useContext, createContext } from 'react';
import sellyApi from "../api/sellyApi";
import { getInventario } from '../api/productApi'
const CartContext = createContext();
import ModalInicio from '../components/modalesRecibirPedido/ModalInicio';
import ModalCompra from '../components/modalesRecibirPedido/ModalCompra';
import AyudaComponent from '../pages/FindsizePage/components/AyudaComponent';

export function useCart() {
    return useContext(CartContext);
}



export function CartProvider({ children }) {

    const [currentCart, setCurrentCart] = useState([]);
    const [currentAddCart, setCurrentAddCart] = useState(0);
    const [currentCartOld, setCurrentCartOld] = useState([]);
    const [cartIsShowing, setCartIsShowing] = useState(false);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubTotal] = useState(0);
    const [ impuesto, setImpuesto ] = useState(0);
    const [ impuestoUltra, setImpuestoUltra ] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [impuestoBolsa, setImpuestoBolsa ] = useState(0);
    const [agotado, setAgotado ] = useState(5);
    const [limite, setLimite ] = useState(10);
    const [limitePromo, setLimitePromo ] = useState(3);
    const [ pesoXbolsa, setPesoXbolsa ] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [ pesoTotal, setPesoTotal ] = useState(0);
    const [ pesoMaxCompra, setPesoMaxCompra ] = useState(25000);
    const [direccion, setDireccion] = useState(null);
    const [coupon, setCoupon] = useState(0);
    const [currentDelivery, setCurrentDelivery] = useState({tipo:'',dir:null});
    const [currentProduct, setCurrentProduct] = useState([]);
    const [tiendaGeneral, setTiendaGeneral] = useState(null);
    const [tope, setTope] = useState(0);
    const [currentNoDisponible, setcurrentNoDisponible] = useState([]);
    const [agregado, setAgregado] = useState(false);
     
 
    const showCart = () => {
        setCartIsShowing(true);
        console.log("Actual Cart",currentCart);
    }

    const hideCart = () => {
        setCartIsShowing(false);
    }
     const getNodisponibles=()=>{
        const nodisp= JSON.parse(localStorage.getItem('cart_no_disponible'))||[];
         return  nodisp;
     }  
    const addToCart = (product, quantity) => {
              const newCart = [...    currentCart];
              
             
                if(tope!=0){
                    return;
                }

                let productLimit =  product.price?.precio_sin_descuento ? limitePromo : limite;
                const index = newCart.findIndex((item) => item.id == product.id);
                if(product.gramaje!=0){
                    productLimit =  product.gramaje.price?.precio_sin_descuento ? limitePromo : limite;
                }
                if (index === -1) {
                    let cantidad = parseInt(quantity) ? parseInt(quantity) : 1;
                       if(cantidad>productLimit){
                           cantidad=productLimit;
                           return true;
                        }

                    if(product.cantidad <cantidad){
                        cantidad=product.cantidad;                      
                    }
                    if(product.cantidad-cantidad<=agotado){
                        cantidad=product.cantidad-agotado;
                        if(cantidad<=0)
                          return true;
                    }
                    if(product.gramaje!=0){
                        product.price.precio=product.gramaje.price.precio;
                        product.price.precio_sin_descuento=product.gramaje.price.precio_sin_descuento;
                        product.peso=product.gramaje.pesofinal;
                    }
                    product.quantity=cantidad;
                    newCart.push(product);
                    setAgregado(true);
          
                } else {
                    let cantidad=newCart[index].quantity+(parseInt(quantity) ? parseInt(quantity) : 1);
                        if(cantidad>productLimit){
                            cantidad=productLimit;
                            return true;
                        }

                    if(product.cantidad <cantidad){
                        cantidad=product.cantidad;                      
                    }
                    if(product.cantidad-cantidad<=agotado){
                        cantidad=product.cantidad-agotado;
                        if(cantidad<=0)
                         return true;
                    }
                    newCart[index].quantity= cantidad;
                    setAgregado(true);
                }

                localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
                setCurrentCart(newCart);
                setCurrentAddCart(newCart);
                
                setTimeout(() => {
                    setAgregado(false);
                }, 1000);
    };

    const addToCartOld = (product, quantity) => {   
        const newCart = [];
      
          if(tope!=0){
              return;
          }
         
          let productLimit =  product.price?.precio_sin_descuento ? limitePromo : limite;
        
          if(product.gramaje!=0){
              productLimit =  product.gramaje.price?.precio_sin_descuento ? limitePromo : limite;
          }
            let cantidad = parseInt(quantity) ? parseInt(quantity) : 1;
                if(cantidad>productLimit){
                    cantidad=productLimit;
                }

            if(product.cantidad <cantidad){
                cantidad=product.cantidad;                      
            }
            if(product.cantidad-cantidad<=agotado){
                cantidad=product.cantidad-agotado;
                if(cantidad<=0)
                        return true;
            }
            if(product.gramaje!=0){
                product.price.precio=product.gramaje.price.precio;
                product.price.precio_sin_descuento=product.gramaje.price.precio_sin_descuento;
                product.peso=product.gramaje.pesofinal;
            }
            product.quantity=cantidad;
            newCart.push(product);
    
          
          localStorage.setItem('cart_shido_old', JSON.stringify(newCart));
          setCurrentCartOld(newCart);

  };

    

    const addToReceta = async(product, quantity) => {
        
            const newCart = currentCart;
            let productLimit =  product.price?.precio_sin_descuento ? limitePromo : limite;
            const index = newCart.findIndex((item) => item.id == product.id);
            if(tope!=0){
                return true;
            }
            if(product.gramaje!=0){
                productLimit =  product.gramaje.price?.precio_sin_descuento ? limitePromo : limite;
            }
            if (index === -1) {
                let cantidad = parseInt(quantity) ? parseInt(quantity) : 1;
                    if(cantidad>productLimit){
                        cantidad=productLimit;
                    }

                if(product.cantidad <cantidad){
                    cantidad=product.cantidad;                      
                }
                if(product.cantidad-cantidad<=agotado){
                    cantidad=product.cantidad-agotado;
                    if(cantidad<=0)
                        return true;
                }
                if(product.gramaje!=0){
                    product.price.precio=product.gramaje.price.precio;
                    product.price.precio_sin_descuento=product.gramaje.price.precio_sin_descuento;
                    product.peso=product.gramaje.pesofinal;
                }
                product.quantity=cantidad;
                newCart.push(product);
                setAgregado(true);
                
            } else {
                let cantidad=newCart[index].quantity+(parseInt(quantity) ? parseInt(quantity) : 1);
                    if(cantidad>productLimit){
                        cantidad=productLimit;
                    }

                if(product.cantidad <cantidad){
                    cantidad=product.cantidad;                      
                }
                if(product.cantidad-cantidad<=agotado){
                    cantidad=product.cantidad-agotado;
                }
                newCart[index].quantity= cantidad;
                setAgregado(true);
            }
            
            localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
            
            setCurrentCart(newCart);

            console.log('currentCartContex',currentCart);

            setCurrentAddCart(newCart);

            setTimeout(() => {
                setAgregado(false);
            }, 1000);

            return true;
        
    };

    const addToRecetaOld = async(product, quantity) => {
        
        const newCart = currentCartOld;
        let productLimit =  product.price?.precio_sin_descuento ? limitePromo : limite;
        const index = newCart.findIndex((item) => item.id == product.id);
        if(tope!=0){
            return true;
        }
        if(product.gramaje!=0){
            productLimit =  product.gramaje.price?.precio_sin_descuento ? limitePromo : limite;
        }
        if (index === -1) {
            let cantidad = parseInt(quantity) ? parseInt(quantity) : 1;
                if(cantidad>productLimit){
                    cantidad=productLimit;
                }

            if(product.cantidad <cantidad){
                cantidad=product.cantidad;                      
            }
            if(product.cantidad-cantidad<=agotado){
                cantidad=product.cantidad-agotado;
                if(cantidad<=0)
                        return true;
            }
            if(product.gramaje!=0){
                product.price.precio=product.gramaje.price.precio;
                product.price.precio_sin_descuento=product.gramaje.price.precio_sin_descuento;
                product.peso=product.gramaje.pesofinal;
            }
            product.quantity=cantidad;
            newCart.push(product);
            setAgregado(true);
            
        } /*else {
            let cantidad=newCart[index].quantity+(parseInt(quantity) ? parseInt(quantity) : 1);
                if(cantidad>productLimit){
                    cantidad=productLimit;
                }

            if(product.cantidad <cantidad){
                cantidad=product.cantidad;                      
            }
            if(product.cantidad-cantidad<=agotado){
                cantidad=product.cantidad-agotado;
            }
            newCart[index].quantity= cantidad;
         
        }*/
        localStorage.setItem('cart_shido_old', JSON.stringify(newCart));
        setCurrentCartOld(newCart);
      
        setTimeout(() => {
            setAgregado(false);
        }, 1000);

        return true;
    
    };


    const validarCart= async(tienda)=>{
        const products = [...currentCart];
        console.log('entro validar cart', tienda ,products);
        const nodisponible=[];
        let inicial=products.length;
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const productLimit =  product.price?.sindescuento ? limitePromo : limite;
            let inventario = await getInventario(product.plu, tienda);
            console.log('inventario',inventario);
                if(inventario.response && inventario.response.status === 400){
                   console.log("errr",inventario );
                    products.splice(i, 1);
                    nodisponible.push(product);
                    i--;
                }else{
                    if(product.gramaje==0){
                        product.price.precio=inventario.precio;
                        product.price.precio_sin_descuento=inventario.precio_sin_descuento;
                    }else{
                        if(inventario.gramajes.length>0){
                          let gramaje=inventario.gramajes.find((grama)=>grama.id=product.gramaje.id);
                          if(gramaje){
                            product.price.precio=gramaje.price.precio;
                            product.price.precio_sin_descuento=gramaje.price.precio_sin_descuento;       
                          }else{
                            products.splice(i, 1);
                            nodisponible.push(product);
                            i--;
                          }
                        }else{
                            products.splice(i, 1);
                            nodisponible.push(product);
                            i--;
                        }
                    }
                    if(inventario.cantidad>0){
                        let cantidad=product.quantity;
                        if(cantidad>productLimit){
                            cantidad=productLimit;
                        }
                        if(cantidad > inventario.cantidad){
                           cantidad=inventario.cantidad
                        }  
                        if(inventario.cantidad-cantidad<=agotado){
                            cantidad=inventario.cantidad-agotado;
                            if(cantidad<=0){
                             products.splice(i, 1);
                             nodisponible.push(product);
                            }
                        }
                        product.quantity=cantidad;
                     }else{
                        products.splice(i, 1);
                        nodisponible.push(product);
                            i--;
                     }               
                }
            
        }
         
        localStorage.setItem('cart_shido_data', JSON.stringify(products));
        localStorage.setItem('cart_no_disponible',JSON.stringify(nodisponible));
        setcurrentNoDisponible(nodisponible);
        setCurrentCart(products);
        let total=inicial-products.length;
        const response= {ok:true, total:total};
        return response;
    }

    const validarCartOld= async(tienda)=>{
        const products = [...currentCartOld];
        console.log('entro validar cart', tienda,products);
        const nodisponible=[];
        let inicial=products.length;
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const productLimit =  product.price?.sindescuento ? limitePromo : limite;
            let inventario = await getInventario(product.plu, tienda);
            console.log('inventario',inventario);
                if(inventario.response && inventario.response.status === 400){
                   console.log("errr",inventario );
                    products.splice(i, 1);
                    nodisponible.push(product);
                    i--;
                }else{
                    if(product.gramaje==0){
                        product.price.precio=inventario.precio;
                        product.price.precio_sin_descuento=inventario.precio_sin_descuento;
                    }else{
                        if(inventario.gramajes.length>0){
                          let gramaje=inventario.gramajes.find((grama)=>grama.id=product.gramaje.id);
                          if(gramaje){
                            product.price.precio=gramaje.price.precio;
                            product.price.precio_sin_descuento=gramaje.price.precio_sin_descuento;       
                          }else{
                            products.splice(i, 1);
                            nodisponible.push(product);
                            i--;
                          }
                        }else{
                            products.splice(i, 1);
                            nodisponible.push(product);
                            i--;
                        }
                    }
                    if(inventario.cantidad>0){
                        let cantidad=product.quantity;
                        if(cantidad>productLimit){
                            cantidad=productLimit;
                        }
                        if(cantidad > inventario.cantidad){
                           cantidad=inventario.cantidad
                        }  
                        if(inventario.cantidad-cantidad<=agotado){
                            cantidad=inventario.cantidad-agotado;
                            if(cantidad<=0){
                                products.splice(i, 1);
                                nodisponible.push(product);
                            }
                        }
                        product.quantity=cantidad;
                     }else{
                        products.splice(i, 1);
                        nodisponible.push(product);
                            i--;
                     }               
                }
            
        }
         
        localStorage.setItem('cart_shido_data', JSON.stringify(products));
        localStorage.setItem('cart_no_disponible',JSON.stringify(nodisponible));
        setCurrentCart(products);
        setcurrentNoDisponible(nodisponible);
        let total=inicial-products.length;
        const response= {ok:true, total:total};
        return response;
    }

    const addC = async ({product, quantity}) =>{
        const newCurrentProduct = [...currentProduct];
        console.log('NEWCURRENT',newCurrentProduct);
        const index = newCurrentProduct.findIndex((p)=> p.product.plu === product.plu);
        if(index !== -1){
            newCurrentProduct[index].quantity += quantity;
        }else{
            newCurrentProduct.push({product, quantity});
        }
        console.log(newCurrentProduct);
        setCurrentProduct(newCurrentProduct);
    }

    const addSeveralToCart = (products, quantity) => {
        const newCart = [...currentCart];
        products.forEach(product => {
					if(currentDelivery.dir  && currentProduct){
            let inventario = getInventario(product.plu, currentDelivery.dir.id);
            if(inventario.response && inventario.response.status === 400){
                //no disponible, mostrar popup no disponible
                console.log("errr",inventario )
            }else{
                //disponible
                console.log("disponible",currentCart,product,quantity )
                const newCart = [...currentCart];
                const productLimit =  product.price?.sindescuento ? limitePromo : limite;
                const index = newCart.findIndex((item) => item.plu === product.plu);
                if (index === -1) {
                    product.quantity = parseInt(quantity) ? parseInt(quantity) : 1;
                    if(inventario.cantidad >= parseInt(product.quantity) ){
                        newCart.push(product);
                    }
                } else {
                    newCart[index].quantity += parseInt(quantity);
                    if(inventario.cantidad >= parseInt(newCart[index].quantity) ){
                        if (newCart[index].quantity > productLimit) newCart[index].quantity = productLimit;
                    }
                    
                }
                localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
                setCurrentCart(newCart);
            }
        	}
        });
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    };

    const updateCurrentdelivery=(current)=>{
        localStorage.setItem('cart_current_delivery',JSON.stringify(current));
        setCurrentDelivery(current);
    }

    const updateTope=(tope)=>{
        localStorage.setItem('cart_tope',tope);
        setTope(tope);
    }
    const removeToCart = (index) => {
        const newCart = [...currentCart];
        newCart.splice(index, 1);
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        localStorage.removeItem('cart_shido_old');
        setCurrentCartOld([]);
        setCurrentCart(newCart);
    }

    const updateCart = (index, product) => {
        const newCart = [...currentCart];
        newCart[index] = product;
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const setCartQuantityValue = (product, quantity) => {
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.id === product.id);
        if (index !== -1) {
            newCart[index].quantity = parseInt(quantity) ? parseInt(quantity) : 1;
            if (newCart[index].quantity < 1) newCart[index].quantity = 1;
        }
        localStorage.setItem('cart_shido_data', JSON.stringify(newCart));
        setCurrentCart(newCart);
    }

    const calculateSubtotal = async () => {
        let subtotal = 0;
      
        const newCart = [...currentCart];
        newCart.forEach((item)=>{
            let precio= item.price.precio_sin_descuento || item.price.precio;
            let precioBase=precio;
            precioBase
            if(item.impuestos){
                const sumaPorcentajes = item.impuestos.reduce((acc, imp) => acc + imp.porcentaje, 0);
                precioBase = precio / (1 + sumaPorcentajes);
                        
            }
          
            subtotal +=(precioBase*item.quantity);
          
        });

        setSubTotal(subtotal);
    }

    const calculateTotal = async () => {
        let total = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.type === "giftcard"){
                total += item.price * item.quantity;
            } else {
                let precio= item.price.precio;
                total += precio * item.quantity;
           }         
        });
        setTotal(total);
    }


    const calculateImpt = async () => {
        let impuesto = 0;
        const newCart = [...currentCart];
        newCart.forEach((item)=>{
            if(item.impuestos){
                let precio= item.price.precio;
                const impt=item.impuestos.find((imp)=>imp.llave=='iva');
                const sumaPorcentajes = item.impuestos.reduce((acc, imp) => acc + imp.porcentaje, 0);
            
                let precioBase = precio / (1 + sumaPorcentajes);
                if(impt){
                    impuesto  += (precioBase*(impt.porcentaje))*item.quantity;  
                 
                }
                            
            }
        });
        setImpuesto(impuesto);
    }

    const calculateImptUltra = async () => {
        let impuesto = 0;
        const newCart = [...currentCart];
        newCart.forEach((item)=>{
            if(item.impuestos){
                let precio= item.price.precio;
                const impt=item.impuestos.find((imp)=>imp.llave=='ultraprocesado');
                const sumaPorcentajes = item.impuestos.reduce((acc, imp) => acc + imp.porcentaje, 0);
                let precioBase = precio / (1 + sumaPorcentajes);
        
                if(impt){
                    impuesto += (precioBase*(impt.porcentaje))*item.quantity;   
                    
                }   
            }
        });
        setImpuestoUltra(impuesto);
    }


    const calculatePeso = async () => {
        let pesoTotal = 0;
        const newCart = [ ...currentCart ];
        newCart.forEach((item) =>{
            pesoTotal += item.peso * item.quantity;
        })

        setPesoTotal(pesoTotal);
    }

    const calculateDescuento = async () => {
        let descuento = 0;
        const newCart = [...currentCart];
        newCart.forEach((item) => {
            if(item.price.precio_sin_descuento){
                descuento += ((item.price.precio_sin_descuento - item.price.precio)*item.quantity);
            }
         });
        setDescuento(descuento);
    }

 

    const checkIfExistInCart = (id) => {
        const newCart = [...currentCart];
        const index = newCart.findIndex((item) => item.plu === id);
        if (index === -1) {
            return false;
        } else {
            return true;
        }
    }

    const getNumberProducts = () => {
        return currentCart.length;
    }
   const clearAll=()=>{
     clearCart();
     localStorage.removeItem('cart_shido_direccion');
     localStorage.removeItem('cart_tienda');
     localStorage.removeItem('cart_current_delivery');
     localStorage.removeItem('actualizo');
     setTiendaGeneral(null);
     setCurrentDelivery({tipo:'',dir:null});
     setDireccion(null);
   }

    const clearCart = () => {
        localStorage.removeItem('cart_shido_data');
        localStorage.removeItem('cart_cupon');
        localStorage.removeItem('cart_tope'); 
       localStorage.removeItem('alerta-tope');
       localStorage.removeItem('cart_shido_old');
        setTope(false);
        setCurrentCart([]);
        setCurrentCartOld([]);
        setCoupon(0);
    }

    const updateDireccion = (direccion) => {
        localStorage.setItem('cart_shido_direccion', direccion);
        setDireccion(direccion);
    }

    const updateTienda = (tienda) => {
        localStorage.setItem('cart_tienda', tienda);
        setTiendaGeneral(tienda);
    }

    const updateCoupon = (coupon) => {
        localStorage.setItem('cart_cupon',  JSON.stringify(coupon));
        setCoupon(coupon);
    }

    const applyCoupon = (value) => {
        let descuento=0;
        if (coupon!=0) {
            if(coupon.porcentaje){
               descuento=value-(value * (1 - coupon.descuento / 100));
            }else{
                if(value>0)
                    descuento=value - coupon.descuento;
            } 
            
        }
        return descuento;
    }

    const getEnvioPrice = async () => {
        console.log("getEnvioPrice",tiendaGeneral,currentDelivery)
        try {
            let  data  =0
            if(currentDelivery.tipo != 'pickup' && tiendaGeneral != null){
                data = await sellyApi.get(`/store/domicilio?tienda=`+tiendaGeneral);
                console.log("envio",data);
                data = data.data.envio;  
            }
            
            localStorage.setItem('cart_envio',data);
            setEnvio(data);
            return true;
        } catch (error) {
            return error;
        }
    };

    const getAgotado = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=producto-agotado&formato=JSON`);
            localStorage.setItem('cart_agotado',data);
            setAgotado(data);
            return true;
        } catch (error) {
            return error;
        }
    }
    const getLimite = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=limite-producto&formato=JSON`);
            localStorage.setItem('cart_limite',data);
            setLimite(data);
            return true;
        } catch (error) {
            return error;
        }
    }

    const getLimitePromo = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=limite-producto-promo&formato=JSON`);
            localStorage.setItem('cart_limite_promo',data);
            setLimitePromo(data);
            return true;
        } catch (error) {
            return error;
        }
    }
    const getImpuestoBolsa = async () => {
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=impuesto-bolsa&formato=JSON`);
            localStorage.setItem('cart_impuesto_bolsa',data);
            setImpuestoBolsa(data);
            return true;
        } catch (error) {
            return error;
        }
    }

    const getPesoMaxCompra = async() =>{
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=peso-maximo-compra&formato=JSON`);
            localStorage.setItem('cart_peso_max',data);
            setPesoMaxCompra(data);
            return true;
        } catch (error) {
            return error;
        }
    }

    const getPesoMaxXBolsa = async() =>{
        try {
            const { data } = await sellyApi.get(`/store/get-setting?llave=pesoMaxXbolsa&formato=JSON`);
            localStorage.setItem('cart_bolsa_max',data);
            setPesoXbolsa(data);
            return true;
        } catch (error) {
            return error;
        }
    }

    const getDelivery = (product, quantity) => {
        //console.log(product, quantity, 'currentDelivery');
        setCurrentProduct([{product, quantity}]);
    }

    


    useEffect(() => {
        localStorage.getItem('cart_shido_data') && setCurrentCart(JSON.parse(localStorage.getItem('cart_shido_data')));
        localStorage.getItem('cart_shido_old') && setCurrentCartOld(JSON.parse(localStorage.getItem('cart_shido_old')));
        localStorage.getItem('cart_shido_direccion') && setDireccion(localStorage.getItem('cart_shido_direccion'));
        localStorage.getItem('cart_current_delivery') && setCurrentDelivery(JSON.parse(localStorage.getItem('cart_current_delivery')));
        localStorage.getItem('cart_tienda') && setTiendaGeneral(localStorage.getItem('cart_tienda'));
        localStorage.getItem('cart_envio') ? setEnvio(parseFloat(localStorage.getItem('cart_envio'))) : getEnvioPrice();
        localStorage.getItem('cart_agotado') ? setAgotado(parseFloat(localStorage.getItem('cart_agotado'))) : getAgotado();
        localStorage.getItem('cart_limite') ? setLimite(parseFloat(localStorage.getItem('cart_limite'))) : getLimite();
        localStorage.getItem('cart_limite_promo') ? setLimitePromo(parseFloat(localStorage.getItem('cart_limite_promo'))) : getLimitePromo();
        localStorage.getItem('cart_impuesto_bolsa') ? setImpuestoBolsa(parseFloat(localStorage.getItem('cart_impuesto_bolsa'))) : getImpuestoBolsa();
        localStorage.getItem('cart_peso_max') ? setPesoMaxCompra(parseFloat(localStorage.getItem('cart_peso_max'))) : getPesoMaxCompra();
        localStorage.getItem('cart_bolsa_max') ? setPesoXbolsa(parseFloat(localStorage.getItem('cart_bolsa_max'))) : getPesoMaxXBolsa();
        localStorage.getItem('cart_cupon') && setCoupon(JSON.parse(localStorage.getItem('cart_cupon')));
        localStorage.getItem('cart_tope') && setTope(localStorage.getItem('cart_tope')); 
        calculateImpt();
        calculateImptUltra();
        calculatePeso();
    
    }, [])

    useEffect(() => {
        getEnvioPrice();
    },[tiendaGeneral,currentDelivery]  )

    const value = {
        currentCart,
        currentCartOld,
        addToCartOld,
        addToCart,
        addToReceta,
        currentAddCart,
        setCurrentAddCart,
        addToRecetaOld,
        addSeveralToCart,
        removeToCart,
        updateCart,
        calculateSubtotal,
        calculateTotal,
        getNumberProducts,
        clearCart,
        cartIsShowing,
        showCart,
        hideCart,
        subtotal,
        total,
        impuesto,
        impuestoUltra,
        envio,
        agotado,
        limite,
        limitePromo,
        impuestoBolsa,
        descuento,
        pesoTotal,
        pesoXbolsa,
        pesoMaxCompra,
        direccion,
        updateDireccion,
        coupon,
        updateCoupon,
        checkIfExistInCart,
        calculateDescuento,
        calculateImpt,
        calculateImptUltra,
        calculatePeso,
        applyCoupon,
        currentDelivery,
        getDelivery,
        setCurrentProduct,
        setCartQuantityValue,
        currentProduct,
        setCurrentDelivery,
        updateCurrentdelivery,
        tiendaGeneral,
        updateTienda,
        getNodisponibles,
        currentNoDisponible,
        validarCart,
        validarCartOld,
        updateTope,
        clearAll,
        tope,
        addC,
        agregado
    }

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}