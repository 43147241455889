import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ModalComponent from './components/ModalComponent'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import { useApi } from '../../context/apiContext';

export default function ResetPassPage() {

    const [error, seterror] = useState('')
    const [email, setemail] = useState('')
    const [showModal, setShowModal] = useState(false)
    const { getTextByKey, getTextBigByKey } = useApi();


    const resetPass = async e => {
        seterror('');
        e.preventDefault();
        const { email } = e.target.elements;
        const auth = getAuth();
        if(!email.value){
            seterror('Ingresa el correo electrónico');
            return;
        }

        setemail(email.value);

        sendPasswordResetEmail(auth, email.value)
            .then((data) => {
                setShowModal(true);
                console.log(data);
            })
            .catch((error) => {
                console.log(error.code);
                switch (error.code) {
                    case 'auth/user-not-found':
                      seterror('El correo no se encuentra registrado')
                      break;
                    case 'auth/invalid-email':
                     seterror('El correo no es valido')
                     break;
                    default:
                        seterror('Ha ocurrido un error, por favor vuelva a intentarlo');
                        break; 
                  }
            });
    };


  return (
    <Fragment>
        <div className='login-register bg-options-repeat py-5' style={{ backgroundImage: `url("/img/bg-login-register.jpg")` }}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 col-lg-6 col-xl-5'>
                        <form className='py-3 px-4 py-xl-4 white-color' onSubmit={resetPass}>
                            <h1 className='text-center carrefourMetis-bold mb-4'>{getTextByKey('olvidar-contrasena-titulo')}</h1>
                            <div className='text-center mb-3' dangerouslySetInnerHTML={{__html: getTextBigByKey('resset-pass-text')}}></div>
                            {error ? <div className='error-msg'>{error}</div> : null}
                            <div className='px-xl-5'>
                                <div className='form-group mb-4'>
                                    <label>{getTextByKey('correo_electronico_form')}*</label>
                                    <input type="email" name="email" className='p-2'/>
                                </div>
                                <div className='form-group text-center mt-3'>
                                    <button type="submit" className='btn-g py-2 px-4 px-xl-5'>{getTextByKey('restablecer')}</button>
                                </div>
                                <div className='form-group text-center mt-3'>
                                    <Link to="/login" className='white-color'>
                                        <p className='md-p mb-0'>
                                        {getTextByKey('vuelve-iniciar-sesion')}
                                        </p> 
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent email={email} showModal={showModal} setShowModal={setShowModal}/>
    </Fragment>
    
  )
}
